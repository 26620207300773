import {Component, OnInit} from '@angular/core';
import {CodeTableEntry, FilterService} from "../../../../api/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {ECodeTables} from "../../../../util/enum";
import {combineLatest} from "rxjs";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {UiFilterConfigService} from "../../../../services/ui-filter-config.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-filter-configs',
  templateUrl: './filter-configs.component.html',
})
export class FilterConfigsComponent implements OnInit {

  hubs: CodeTableEntry[] = [];
  allFilterConfigs: any[] = [];
  selectedHub: CodeTableEntry;
  filterConfigs: any[] = [];
  loading = false;

  constructor(
    private codeTableService: CodeTableService,
    private filterService: FilterService,
    private translateService: TranslateService,
    private uiFilterConfigService: UiFilterConfigService,
  ) {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.hub),
      this.filterService.getFilterConfigs(),
    ]).subscribe(([hubs, filterConfigs]) => {
      this.hubs = hubs;
      this.allFilterConfigs = filterConfigs;
      this.onSelectHub(this.hubs[0]);
    });
  }

  ngOnInit(): void {
  }

  onSelectHub(hub: CodeTableEntry) {
    this.selectedHub = hub;
    this.filterConfigs = this.allFilterConfigs.find(d => d.hub === hub.ident).filters;
  }

  onToggleChanged($event: MatSlideToggleChange, item: any) {
    this.loading = true;
    item.enabled = $event.checked;
    this.filterService.upsertFilterConfigs([{
      hub: this.selectedHub.ident,
      filters: [item]
    }]).subscribe(() => {
      this.loading = false;
    });
  }

  translationOf(filter: any) {
    // we only have one particular translation that doesn't match the ident, so, we are adding a
    // manually matched translation here as it's quicker than mapping all the translations
    if (filter.parentIdent === 'portfolio' && filter.ident === 'model') {
      return this.translateService.instant('riskProfile');
    }
    return this.translateService.instant(filter.ident);
  }
}
