import {Observable} from 'rxjs';

export class FileSavingUtil {

    static saveFile(blob, fileName) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
    }
}
