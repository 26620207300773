import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';

/**
 * Progress Card Component.
 * Component to display statistics data of filter and to animate the corresponding indicator.
 */
@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
})
export class ProgressCardComponent implements OnChanges {
  /**
   * Set component data title, actual number, maximum number and the percentage of the fill
   */
  @Input()
  set data(data: any) {
    this.title = data.title;
    this.actualNumber = Math.round(data.actualNumber);
    this.maxNumber = Math.round(data.maxNumber);
    this.currentPercentage = this.getPercentage(
      data.actualNumber,
      data.maxNumber
    );
  }

  /**
   * Title
   */
  title: string;
  /**
   * Current value
   */
  actualNumber: number;
  /**
   * Max value
   */
  maxNumber: number;
  /**
   * Current percentage value
   */
  currentPercentage = 0;
  /**
   * Starting point of the filling animation
   */
  startingPercentage: number;
  /**
   * Observable for data loading status
   */
  dataLoading$: Observable<boolean>;

  constructor(
    protected globalService: GlobalService,
    protected dataService: DataService
  ) {
    this.dataLoading$ = this.dataService.chartDataLoading$;
  }

  ngOnChanges() {
    // If component state changes, update currentPercantage so the displayed values are correct.
    this.currentPercentage = this.getPercentage(
      this.actualNumber,
      this.maxNumber
    );
  }

  /**
   * Get percentage of two input numbers
   * @param currVal Smaller amount for which the percantage should get calculated
   * @param totVal Maximum amount
   */
  getPercentage(currVal: number, totVal: number): number {
    return (100 / totVal) * currVal;
  }

  /**
   * Get width of the HTML indicator that gets animated. For animation purposes only.
   */
  getIndicatorStyles(): { width: string } {
    return {
      width: (this.currentPercentage > 0 ? this.currentPercentage : 0) + '%',
    };
  }
}
