import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {EmployeeRoutingModule} from './employee-routing.module';
import {EmployeeCatalogueComponent} from "./views/employee-catalogue/employee-catalogue.component";
import {EmployeeOverviewComponent} from "./views/employee-overview/employee-overview.component";
import {EmployeeOverviewDetailsComponent} from "./views/employee-overview/employee-overview-details/employee-overview-details.component";
import {EmployeePublicationTypesComponent} from "./views/employee-overview/employee-publication-types/employee-publication-types.component";
import {EmployeeOverviewActionsComponent} from "./views/employee-overview/employee-overview-actions/employee-overview-actions.component";
import {EmployeeOverviewCampaignsComponent} from "./views/employee-overview/employee-overview-campaigns/employee-overview-campaigns.component";

/**
 * Employee Module.
 * Contains all components related to employees
 */
@NgModule({
    declarations: [
      EmployeeCatalogueComponent,
      EmployeeOverviewComponent,
      EmployeeOverviewActionsComponent,
      EmployeeOverviewCampaignsComponent,
      EmployeeOverviewDetailsComponent,
      EmployeePublicationTypesComponent
    ],
    imports: [CommonModule, EmployeeRoutingModule, SharedModule],
  exports: [
    EmployeeCatalogueComponent,
    EmployeePublicationTypesComponent,
    EmployeeOverviewCampaignsComponent,
  ]
})
export class EmployeeModule {}
