import {Component} from "@angular/core";
import {Intermediary, UserInfo} from "../../../../api/core";
import {ActivatedRoute} from "@angular/router";
import {GlobalService} from "../../../../services/global.service";
import {usernameValueLabel} from "../../../../util/grid/grid-renderer.util";

@Component({
  selector: 'app-distributor-intermediary-overview',
  templateUrl: './distributor-intermediary-overview.component.html',
})
export class DistributorIntermediaryOverviewComponent {
  intermediary: Intermediary;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected globalService: GlobalService,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.intermediary = data.intermediary;
    });
  }

  usernameLabel(u: UserInfo) {
    return usernameValueLabel(u);
  }
}
