<app-card>
  <div card-header>
    <app-config-icon iconIdentifier="wysiwyg"></app-config-icon>
    {{ 'message' | translate }} - {{ timestamp }}
  </div>
  <div class="h4" card-body>
    <code>{{ error.message }}</code>
  </div>
</app-card>
<app-card>
  <div scard-header>
    <app-config-icon iconIdentifier="manage_search"></app-config-icon>
    {{ 'stack' | translate }}
  </div>
  <div class="h4" card-body>
    <code>{{ error.stack }}</code>
  </div>
</app-card>
