<div *ngIf="intermediary" class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div
    *ngIf="activeTab === tabs[0].text"
    class="intermediary-details-container aspark-container-padding"
  >
    <app-card class="details">
      <div card-header>
        <span>{{ 'details' | translate }}</span>
      </div>
      <div class="h4" card-body>
        <div class="detail-items-wrapper">
          <div class="start-col">
            <app-overview-detail-item
              labelRef="name"
              [value]="intermediary.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="key"
              [value]="intermediary.externalKey"
            ></app-overview-detail-item>
          </div>
          <div class="inside-col">
            <app-overview-detail-item
              labelRef="type"
              [value]="intermediary.type"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="businessUnit"
              [value]="intermediary.businessUnit?.name"
            ></app-overview-detail-item>
          </div>
          <div class="inside-col">
            <app-overview-detail-item
              labelRef="hub"
              [value]="intermediary.hub?.name"
            ></app-overview-detail-item>
          </div>
          <div class="end-col">
            <app-overview-detail-item
              labelRef="leadRelationshipManager"
              [value]="usernameLabel(intermediary.leadRelationshipManager)"
            ></app-overview-detail-item>
          </div>
        </div>
      </div>
    </app-card>
    <app-intermediary-overview-portfolios [intermediary]="intermediary">
    </app-intermediary-overview-portfolios>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="intermediary-overview-container">
    <app-intermediary-overview-employees [intermediary]="intermediary">
    </app-intermediary-overview-employees>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="intermediary-overview-container">
    <app-intermediary-overview-campaigns [intermediary]="intermediary">
    </app-intermediary-overview-campaigns>
  </div>
</div>
