<div class="filter-details-portfolio-container">
  <div class="filter-portfolio-details">
    <ng-container *ngIf="basicsVisible">
      <div class="filter-details-row">
        <app-filter
          [metaData]="getFilterMetaData(fields.portfolioType)"
          [value]="portfolioForm.portfolioType"
          *ngIf="
            (portfolioFilters.portfolioType | isFilterPortfolioFilterActive) &&
            portfolioTypes.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.model)"
          [value]="portfolioForm.model"
          *ngIf="
            (portfolioFilters.model | isFilterPortfolioFilterActive) &&
            portfolioStrategies.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.referenceCurrency)"
          [(value)]="portfolioForm.referenceCurrency"
          *ngIf="
            (portfolioFilters.referenceCurrency
              | isFilterPortfolioFilterActive) && currencies.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.bu)"
          [value]="portfolioForm.bu"
          *ngIf="
            (portfolioFilters.bu | isFilterPortfolioFilterActive) &&
            businessUnits.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.serviceCenter)"
          [value]="portfolioForm.serviceCenter"
          *ngIf="
            (portfolioFilters.serviceCenter | isFilterPortfolioFilterActive) &&
            serviceCenters.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.businessDivision)"
          [value]="portfolioForm.businessDivision"
          *ngIf="
            (portfolioFilters.businessDivision
              | isFilterPortfolioFilterActive) && businessDivisions.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.advisoryType)"
          [value]="portfolioForm.advisoryType"
          *ngIf="
            (portfolioFilters.advisoryType | isFilterPortfolioFilterActive) &&
            advisoryTypes.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.sustainabilityProfile)"
          [value]="portfolioForm.sustainabilityProfile"
          *ngIf="
            (portfolioFilters.sustainabilityProfile
              | isFilterPortfolioFilterActive) && sustainabilityProfiles.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <div class="toggles">
          <app-filter
            [metaData]="getFilterMetaData(fields.qualifiedInvestor)"
            [(value)]="portfolioForm.qualifiedInvestor"
            *ngIf="
              portfolioFilters.qualifiedInvestor | isFilterPortfolioFilterActive
            "
            [readOnly]="readOnly"
          ></app-filter>
          <app-filter
            [metaData]="getFilterMetaData(fields.waiveOfAdvice)"
            [(value)]="portfolioForm.waiveOfAdvice"
            *ngIf="
              portfolioFilters.waiveOfAdvice | isFilterPortfolioFilterActive
            "
            [readOnly]="readOnly"
          ></app-filter>
        </div>
        <app-filter
          [metaData]="getFilterMetaData(fields.mifid)"
          [value]="portfolioForm.mifid"
          *ngIf="
            (portfolioFilters.mifid | isFilterPortfolioFilterActive) &&
            mifidClientSegmentations.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.fidleg)"
          [(value)]="portfolioForm.fidleg"
          *ngIf="
            (portfolioFilters.fidleg | isFilterPortfolioFilterActive) &&
            fidlegClientSegmentations.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <div class="toggles">
          <app-filter
            *ngIf="
              portfolioFilters.riskBreachOnly | isFilterPortfolioFilterActive
            "
            [metaData]="getFilterMetaData(fields.riskBreachOnly)"
            [(value)]="portfolioForm.riskBreachOnly"
            [readOnly]="readOnly"
          ></app-filter>
          <app-filter
            *ngIf="
              portfolioFilters.excludeRiskBreach | isFilterPortfolioFilterActive
            "
            [metaData]="getFilterMetaData(fields.excludeRiskBreach)"
            [(value)]="portfolioForm.excludeRiskBreach"
            [readOnly]="readOnly"
          ></app-filter>
        </div>
        <app-filter
          [metaData]="getFilterMetaData(fields.rmDepartment)"
          [(value)]="portfolioForm.rmDepartment"
          *ngIf="
            (portfolioFilters.rmDepartment | isFilterPortfolioFilterActive)
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.rmLocation)"
          [value]="portfolioForm.rmLocation"
          *ngIf="
            (portfolioFilters.rmLocation | isFilterPortfolioFilterActive) &&
            rmLocations.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.rmMarket)"
          [value]="portfolioForm.rmMarket"
          *ngIf="
            (portfolioFilters.rmMarket | isFilterPortfolioFilterActive) &&
            rmMarkets.length
          "
          [readOnly]="readOnly"
        ></app-filter>
        <div class="toggles">
          <app-filter
            class="client-toggle-filter"
            [metaData]="getFilterMetaData(fields.allowOptOut)"
            [(value)]="portfolioForm.allowOptOut"
            *ngIf="portfolioFilters.allowOptOut | isFilterPortfolioFilterActive"
            [readOnly]="readOnly"
          ></app-filter>
        </div>
        <app-filter
          *ngIf="portfolioFilters.manager | isFilterPortfolioFilterActive"
          class="filter-chips"
          [metaData]="getFilterMetaData(fields.manager)"
          [(value)]="portfolioForm.manager"
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          *ngIf="portfolioFilters.advisor | isFilterPortfolioFilterActive"
          class="filter-chips"
          [metaData]="getFilterMetaData(fields.advisor)"
          [(value)]="portfolioForm.advisor"
          [readOnly]="readOnly"
        ></app-filter>
      </div>
    </ng-container>
    <ng-container *ngIf="keyFiguresVisible">
      <span class="h4">{{ 'keyFigures' | translate }}</span>
      <div class="filter-details-row">
        <app-filter
          [metaData]="getFilterMetaData(fields.riskRange)"
          [value]="portfolioForm.riskRange"
          (hasErrors)="formHasErrors($event)"
          *ngIf="portfolioFilters.riskRange | isFilterPortfolioFilterActive"
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.returnRange)"
          [value]="portfolioForm.returnRange"
          (hasErrors)="formHasErrors($event)"
          *ngIf="portfolioFilters.returnRange | isFilterPortfolioFilterActive"
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.valueRange)"
          [value]="portfolioForm.valueRange"
          (hasErrors)="formHasErrors($event)"
          *ngIf="portfolioFilters.valueRange | isFilterPortfolioFilterActive"
          [readOnly]="readOnly"
        ></app-filter>
        <app-filter
          [metaData]="getFilterMetaData(fields.liquidityRange)"
          [value]="portfolioForm.liquidityRange"
          (hasErrors)="formHasErrors($event)"
          *ngIf="
            portfolioFilters.liquidityRange | isFilterPortfolioFilterActive
          "
          [readOnly]="readOnly"
        ></app-filter>
      </div>
    </ng-container>
  </div>
</div>
