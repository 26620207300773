import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeightRange, WeightRangeRange} from 'src/app/models/filter.model';
import {EFilterCategories, EFilterSubType, EFilterType, ERangeWeightFilter} from 'src/app/util/enum';
import {FilterMetaData} from "../../../models/filter-meta-data.model";

/**
 * Range Weight Filter Component.
 * Component for combination of range and weight filter.
 */
@Component({
  selector: 'app-filter-range-weight',
  templateUrl: './filter-range-weight.component.html',
})
export class FilterRangeWeightComponent implements OnInit {
  /**
   * Component input to label
   */
  @Input() label: string;

  @Input() readOnly = false;
  /**
   * Component input to set filter value
   */
  @Input() filter: WeightRange;
  /**
   * Filter category that the filter belongs to
   */
  @Input() filterCategory: EFilterCategories; // TODO: make really configurable, this is temporarily used to make some filters unavailable
  /**
   * Component output to notifiy that filter should be deleted
   */
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Flag to mark to show/hide range slider
   */
  hasExposure = false;
  /**
   * Local value for weight select field
   */
  weightSelect: WeightRange.WeightEnum;
  /**
   * Possible weight options
   */
  weightOptions: WeightRange.WeightEnum[] = Object.values(
    WeightRange.WeightEnum
  );

  rangeMeta: FilterMetaData = new FilterMetaData(
    EFilterType.rangeSlider,
    '',
    EFilterSubType.rangeMinMaxPercentage
  );

  constructor() {}

  ngOnInit(): void {
    this.weightSelect = this.filter.weight;
    this.hasExposure = !this.filter.weight;
    if (!this.filter.weight) {
      this.filter.range = {
        min: this.filter.range.min || 0,
        max: this.filter.range.max || 1,
      };
    }
  }

  /**
   * Provides range weight filter enum to html
   */
  get fields() {
    return ERangeWeightFilter;
  }

  /**
   * Change handler for filter with select field
   */
  onWeightSelectChange(): void {
    this.filter.weight = this.weightSelect;
  }

  onFilterChanged(value: WeightRangeRange) {
    this.filter.range = {...value};
  }

  /**
   * Toggles hasExposure flag
   */
  toggleHasExposure(): void {
    this.hasExposure = !this.hasExposure;
    if (this.hasExposure) {
      this.filter.weight = undefined;
      this.filter.range.max = 1;
      this.filter.range.min = 0;
    } else {
      this.filter.range.max = null;
      this.filter.range.min = null;
    }
  }

  /**
   * Click handler on delete icon
   */
  onDeleteClick(): void {
    this.delete.emit();
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }
}
