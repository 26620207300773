<app-sub-header [header]="'allowedUsers' | translate" [isSmall]="true">
  <app-config-icon
    *ngIf="_campaign?.story && !autoSyncAllowedUsers && isDraft && canEdit"
    iconIdentifier="restore"
    [tooltip]="'restoreAllowedUsers' | translate"
    (click)="restoreAllowedUsersFromStory()"
  ></app-config-icon>
  <app-config-icon
    *ngIf="canEdit"
    iconIdentifier="edit_m"
    [tooltip]="'allowedUsersEdit' | translate"
    (click)="editAllowed()"
  ></app-config-icon>
  <mat-slide-toggle
    color="primary"
    *ngIf="autoSyncFeature"
    [disabled]="!autoSyncEnabled"
    [checked]="autoSyncAllowedUsers"
    (change)="handleToggleAutoSync($event)"
    [matTooltip]="autoSyncMessage"
  >
  </mat-slide-toggle>
</app-sub-header>
<div class="allowed-users-grid-container">
  <app-card [showHeader]="false">
    <app-grid
      card-body
      tableId="allowed-positions"
      [columnDefs]="positionColumnDefs"
      [gridOptions]="positionGridOptions"
      [rowData]="positions"
    ></app-grid>
  </app-card>
  <app-card [showHeader]="false">
    <app-grid
      card-body
      tableId="allowed-users"
      [columnDefs]="userColumnDefs"
      [gridOptions]="userGridOptions"
      [rowData]="users"
    ></app-grid>
  </app-card>
</div>
