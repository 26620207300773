import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent} from 'ag-grid-community';
import {finalize, first, Observable} from 'rxjs';
import {
  AvailableChannel,
  CampaignActionService,
  CampaignIntermediaryActionService,
  ChannelService,
  ContactChannel,
} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EModalType} from 'src/app/util/enum';
import {ActionType} from "../campaign-actions-list-utils";
import {genNumberColumn} from "../../../../util/grid/grid-renderer.util";
import {GlobalService} from "../../../../services/global.service";
import {genIconColumn} from "../../../../shared/grid/cell-renderers/icon.renderer";

/**
 * What this component expects as input.
 */
export interface ChannelAction {
  id: number;
  type: ActionType;
  portfolioIdent?: string;
  clientIdent?: string;
  channel?: ContactChannel;
}

@Component({
  selector: 'app-campaign-action-channel-selection',
  templateUrl: './campaign-action-channel-selection.component.html',
})
export class CampaignActionChannelSelectionComponent
  implements OnInit, OnDestroy, ModalSubComponent {
  showWarning = false;
  loading = true;

  action: ChannelAction;
  rowData: AvailableChannel[];
  gridApi: GridApi;
  readonly = false;

  channelColumns: ColDef[] = [
    {
      checkboxSelection: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      lockVisible: true,
      lockPosition: true,
    },
    {
      field: 'type.name',
      headerName: this.translateService.instant('type'),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      field: 'salutation',
      headerName: this.translateService.instant('salutation'),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      field: 'address',
      headerName: this.translateService.instant('address'),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      ...genNumberColumn('priority', this.translateService.instant('priority'), this.globalService,
        data => this.globalService.getFormattedValue(data.value, 0)),
      suppressHeaderMenuButton: true,
      resizable: true,
      floatingFilter: false,
      sort: 'asc'
    },
  ];

  channelGridOptions: GridOptions = {
    rowHeight: 36,
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    rowSelection: 'single',
    onGridReady: (event) => this.gridReady(event),
    onSelectionChanged: (event: SelectionChangedEvent) => {
      // If no row is selected, disable the save button
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = event.api.getSelectedNodes().length === 0;
    }
  };

  constructor(
    private channelService: ChannelService,
    private campaignActionService: CampaignActionService,
    private campaignIntermediaryActionService: CampaignIntermediaryActionService,
    private translateService: TranslateService,
    private globalService: GlobalService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.action = data.data.action;
    this.readonly = !!data.data.readonly;
    if (this.readonly) {
      this.channelColumns = [
        genIconColumn({
          field: 'selected',
          headerName: '',
          icon: (data) => data.isSelected ? 'check' : undefined
        }),
        ...this.channelColumns.filter((c, k) => k)
      ];
    }
    this.channelGridOptions = {
      ...this.channelGridOptions,
      rowSelection: this.readonly ? undefined : 'single'
    }
  }

  ngOnInit(): void {
    this.getChannels();
  }

  ngOnDestroy(): void {
  }

  private getChannels() {
    let serviceCall: Observable<AvailableChannel[]>;
    if (this.action.type === ActionType.CampaignAction) {
      serviceCall = this.channelService
        .getAvailableChannels(
          this.action.portfolioIdent,
          this.action.clientIdent
        );
    } else {
      serviceCall = this.campaignIntermediaryActionService.availableChannels(this.action.id)
    }
    return serviceCall
      .pipe(
        first(),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: (channels) => (this.rowData = channels),
      });
  }

  modalAction(modalType: EModalType): void {
    if (this.gridApi.getSelectedNodes().length === 0) {
      this.dialogRef.close(false);
      return;
    }
    const selectedChannel: AvailableChannel =
      this.gridApi.getSelectedNodes()[0]?.data;
    let serviceCall: Observable<any>;
    if (this.action.type === ActionType.CampaignAction) {
      serviceCall = this.campaignActionService
        .createChannelForAction(this.action.id, selectedChannel);
    } else {
      serviceCall = this.campaignIntermediaryActionService.updateAction(
        this.action.id,
        {id: this.action.id, channel: selectedChannel}
      )
    }
    switch (modalType) {
      case EModalType.editCampaignChannel:
        if (!selectedChannel) {
          this.showWarning = true;
          this.dialogRef.componentInstance.resetToolbarActionButtons();
        } else {
          this.showWarning = false;
          if (!this.isCurrentChannel(selectedChannel)) {
            serviceCall.pipe(
              first(),
              finalize(() =>
                this.dialogRef.componentInstance.resetToolbarActionButtons()
              )
            )
              .subscribe({
                next: (data) => this.dialogRef.close(data),
              });
          } else {
            this.dialogRef.close(undefined);
          }
        }
        break;
      default:
        break;
    }
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    event.api.forEachNode((node) => {
      node.data.isSelected = this.isCurrentChannel(node.data);
      if (node.data.isSelected) {
        node.setSelected(true);
      }
    });
  }

  private isCurrentChannel(node: AvailableChannel): boolean {
    return this.action.channel?.type?.ident === node.type.ident
      && this.action.channel?.salutation === node.salutation
      && this.action.channel?.address === node.address;
  }
}
