import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { INestedMenuItem } from 'src/app/models/menu.model';

/**
 * Add Filter Details Component.
 * Component for dynamically adding filter.
 */
@Component({
  selector: 'app-filter-add',
  templateUrl: './filter-add.component.html',
})
export class FilterAddComponent {
  /**
   * Component input of the different options for the menu
   */
  @Input() options: INestedMenuItem[];

  @Input() readOnly = false;

  /**
   * Component output when a menu item was selected
   */
  @Output() selectChange: EventEmitter<INestedMenuItem> =
    new EventEmitter<INestedMenuItem>();

  /**
   * View child reference to the menu trigger
   */
  @ViewChild('menuTrigger', { read: MatMenuTrigger })
  menuTrigger: MatMenuTrigger;

  constructor() {}

  /**
   * Event handler when a menu item is clicked. Closes menu
   * @param option Clicked menu item
   */
  onAddFilterSelect(option: INestedMenuItem): void {
    this.selectChange.emit(option);
    this.menuTrigger.closeMenu();
  }
}
