<div class="filter-details-advanced-container">
  <ng-container>
    <div class="blockly-wrapper">
      <div class="blockly-container">
        <div id="blocklyDiv">
          <mat-progress-bar mode="indeterminate" *ngIf="!codetablesLoaded"></mat-progress-bar>
        </div>
      </div>
    </div>
    <p><small>{{ 'closedEntriesMsg' | translate }}</small></p>
  </ng-container>
</div>
