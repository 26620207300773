import {Component, EventEmitter, Input, Output} from "@angular/core";
import {MatButtonToggleChange} from "@angular/material/button-toggle";


@Component({
  selector: 'app-filter-details-operator-button',
  templateUrl: 'filter-details-operator-button.component.html'
})
export class FilterDetailsOperatorButtonComponent {

  @Input() operator: string;

  @Input() readOnly = false;

  @Output() operatorChange = new EventEmitter<string>();

  constructor() {
  }

  onOperatorChanged($event: MatButtonToggleChange) {
    this.operatorChange.emit($event.value);
  }

}
