import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    switch (displayFormat) {
      case 'DD.MM.YYYY':
        return date
          .toLocaleDateString('de-DE', {
            year: 'numeric',
            day: '2-digit',
            month: '2-digit',
          })
          .replace(/\//gi, '.');
      default:
        return date.toDateString();
    }
  }

  parse(value: string): any {
    const parts = value.split('.');
    if (parts.length === 3) {
      return new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }
    return new Date(value);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
  },
};

export const MIN_DATE = new Date(2000, 0, 1);
export const MAX_DATE = new Date(2999, 0, 1);
