import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[toast-notification-component]',
  styleUrls: [`./toast-notification.component.scss`],
  templateUrl: `./toast-notification.component.html`,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastNoficationComponent extends Toast implements OnInit {
  iconIdentifier: string;
  iconColorClass: string;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.setIconIdentifierAndColorClass();
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  private setIconIdentifierAndColorClass(): void {
    if (this.toastClasses.includes('toast-error')) {
      this.iconIdentifier = 'cancel';
      this.iconColorClass = 'color-error';
    } else if (this.toastClasses.includes('toast-warning')) {
      this.iconIdentifier = 'warning';
      this.iconColorClass = 'color-warning';
    } else if (this.toastClasses.includes('toast-success')) {
      this.iconIdentifier = 'check_circle_outline';
      this.iconColorClass = 'color-success';
    } else if (this.toastClasses.includes('toast-info')) {
      this.iconIdentifier = 'info';
      this.iconColorClass = 'color-primary';
    }
  }
}
