import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EAnimationStates, EViewRoutes} from 'src/app/util/enum';
import {GlobalService} from 'src/app/services/global.service';
import {Story} from 'src/app/api/core';
import {PermissionService} from '../../services/permission.service';
import {EProtectedActions} from "../../util/protected-actions";

/**
 * Story Component.
 * Component for story cards
 */
@Component({
  selector: 'app-story-card',
  templateUrl: './story-card.component.html',
})
export class StoryCardComponent implements OnInit, OnDestroy {
  /**
   * Component input to set story data
   */
  @Input() story: Story;
  /**
   * Defines length of abstract displayed on story card
   */
  @Input() abstractLength = 'normal';
  /**
   * Flag to signal if story is being highlighted
   */
  isHighlight = false;
  /**
   * Story image style
   */
  storyImageStyle: any = null;

  hasStoryOverviewPermissions: boolean;

  constructor(
    protected globalService: GlobalService,
    protected permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    this.hasStoryOverviewPermissions = this.permissionService.hasAnyPermission(EProtectedActions.viewStories);
  }

  ngOnDestroy(): void {
  }

  /**
   * Provides animation states to html
   */
  get animationStates() {
    return EAnimationStates;
  }

  /**
   * Click handler on story click to emit output
   */
  onStoryClick(): void {
    if (this.hasStoryOverviewPermissions) {
      this.globalService.navigate(EViewRoutes.storyOverview + this.story.id);
    }
  }
}
