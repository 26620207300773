import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridActionLanguageInfo } from 'src/app/models/grid.model';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import {CampaignAction, CampaignActionStatus, CampaignStatus, ContentMatchState} from 'src/app/api/core';
import {ColDef, ICellRendererParams} from "ag-grid-community";

@Component({
  template: `
    <div class="grid-custom-cell">
      <app-config-icon
        iconIdentifier="edit_m"
        class="edit-cell-icon"
      ></app-config-icon>
      <span *ngIf="!(hidden | isRendererHidden: data)">{{ language }}</span>
      <app-config-icon
        *ngIf="showWarning && !(hidden | isRendererHidden: data)"
        iconIdentifier="warning"
        [tooltip]="tooltip | translate"
      ></app-config-icon>
      <app-config-icon
        *ngIf="showError && !(hidden | isRendererHidden: data)"
        iconIdentifier="error"
        [tooltip]="tooltip | translate"
      ></app-config-icon>
    </div>
  `,
})
export class ActionLanguageRenderer implements AgRendererComponent {
  @HostBinding('class.grid-cell-action-language') theme = true;
  data: CampaignAction;
  language: string;
  showWarning: boolean;
  showError: boolean;
  tooltip = 'noLanguageWarning';
  hidden: (data: any) => boolean;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.language = this.data.language.name;

    const info: GridActionLanguageInfo =
      params?.colDef?.cellRendererParams?.actionsParams?.actionLanguageInfo;
    if (info) {
      this.hidden = info?.hidden;
      if (info.campaignStatus === CampaignStatus.LAUNCHED && this.data.status === CampaignActionStatus.PENDING) {
        const contentMatchState = (this.data as any).contentMatchState as ContentMatchState;
        this.showError = (contentMatchState === ContentMatchState.MISSING);
        this.showWarning = (contentMatchState === ContentMatchState.FALLBACK);
      } else {
        this.showWarning = false;
        this.showError = false;
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genActionLanguageColumn(info: GridActionLanguageInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: ActionLanguageRenderer,
    sortable: false,
    filter: info?.customFilter,
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        actionLanguageInfo: info,
      },
    },
  };
}
