import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_PRODUCT_OVERVIEW_CONFIG = {
  id: 16,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Product Overview',
        en: 'Product Overview',
      },
      anchor: 'productOverview',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Overview',
        en: 'Overview',
      },
      anchor: 'productOverview',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Basic information about the product and its performance, only products with data will show the statistics charts.',
        en: 'Basic information about the product and its performance, only products with data will show the statistics charts.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Product overview',
        en: 'Product overview',
      },
      url: '/assets/images/help/help_product_overview.png',
    },
  ],
  view: EViewRoutes.productOverview,
};
