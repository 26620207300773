<div class="container">
  <div
    [class]="isUploading ? 'video-wrapper white-background' : 'video-wrapper'"
  >
    <video
      id="video_playback"
      class="video-js vjs-default-skin"
      playsinline
    ></video>
    <ng-container *ngIf="isCapturing">
      <video
        id="video_capture"
        class="video-js vjs-default-skin"
        playsinline
      ></video>
    </ng-container>
    <div class="progress-wrapper" *ngIf="isUploading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngIf="documentId && !isUploading">
      <button mat-stroked-button (click)="downloadDocument()">
        <app-config-icon iconIdentifier="movie"></app-config-icon>
        Download
      </button>
      <button mat-stroked-button (click)="deleteDocument()">
        <app-config-icon
          iconIdentifier="delete_forever"
          class="color-danger"
        ></app-config-icon>
        Delete
      </button>
    </ng-container>
  </div>
</div>
