import {
  EFilterType,
  EFilterSubType,
  EGroupFilterSelectStatus,
} from 'src/app/util/enum';
import { IKeyValue } from './key-value.model';
import { INestedMenuItem } from './menu.model';

/**
 * Interface for filter meta data.
 * Filter meta data is required to render filters correctly
 */
export interface IFilterMetaData {
  /**
   * Type of filter
   */
  type: EFilterType;
  /**
   * Label of filter
   */
  label: string;
  /**
   * Optional range type of filter.
   * Only expected for range type filters
   */
  subType?: EFilterSubType;
  /**
   * Optional select options of filter.
   * Only expected for select or multiselect filters
   */
  options?: IKeyValue[];
  /**
   * Optional nested select options of filter
   * Only expected for nested select or multiselec filters
   */
  nestedOptions?: INestedMenuItem[];
  /**
   * Optional placeholder
   */
  placeholder?: string;
}

/**
 * Class that implements {@link IFilterMetaData}
 */
export class FilterMetaData implements IFilterMetaData {
  constructor(
    public type: EFilterType,
    public label: string,
    public subType?: EFilterSubType,
    public options?: IKeyValue[],
    public nestedOptions?: INestedMenuItem[],
    public placeholder?: string,
  ) {}
}

/**
 * Interface for group filter select status
 */
export interface IGroupFilterSelect {
  [key: string]: EGroupFilterSelectStatus;
}
