import {Component} from '@angular/core';
import {ICONS_CONFIG} from '../../../../assets/config/icons_config';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
})
export class IconsComponent {

  icons: string[];

  constructor() {
    this.icons = Object.keys(ICONS_CONFIG);
  }
}
