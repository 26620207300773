<div class="basic-flex">
  <ng-container *ngFor="let btn of primaryButtons">
  <button
    *ngIf="!btn.show || btn.show()"
    mat-raised-button
    class="primary-button"
    [color]="btn.color"
    [matTooltip]="btn.tooltip ? btn.tooltip() : undefined"
    [disabled]="btn.disabled && btn.disabled()"
    (click)="btn.click()"
  >
    <app-config-icon [iconIdentifier]="btn.icon"></app-config-icon>
    <span>{{ btn.text | translate }}</span>
  </button>
  </ng-container>
  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="hasMenuButtons">
    <app-config-icon iconIdentifier="more_vert"></app-config-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let btn of dropdownButtons">
      <button
        *ngIf="!btn.show || btn.show()"
        mat-menu-item
        class="mat-mdc-menu-item-button"
        [matTooltip]="btn.tooltip ? btn.tooltip() : undefined"
        [disabled]="btn.disabled && btn.disabled()"
        (click)="btn.click()"
      >
        <app-config-icon [iconIdentifier]="btn.icon"></app-config-icon>
        <span>{{ btn.text | translate }}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>
