import {Component, ViewChild} from "@angular/core";
import {ColDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {AppMessage, AppMessageService, AppMessageState, CodeTableEntry, UserRoleType} from "../../../../api/core";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {
  genCodeTableColumn,
  genDateColumn,
  genEnumColumn,
  genNumberColumn,
  genTextColumn
} from "../../../../util/grid/grid-renderer.util";
import {CodeTableService} from "../../../../services/code-table.service";
import {ECodeTables, EModalType} from "../../../../util/enum";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {DialogWidth, ModalService} from "../../../../services/modal.service";
import {ModalData} from "../../../../models/modal.model";
import {GridComponent, GridResetEvent} from "../../../../shared/grid/grid.component";
import {AppMessageEditDialogComponent} from "../app-message-edit-dialog/app-message-edit-dialog.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {showMessageViewDialog} from "../app-message-view-dialog/app-message-view-dialog.component";

@Component({
  selector: 'app-messages',
  templateUrl: './app-messages.component.html'
})
export class AppMessagesComponent {
  @ViewChild('grid') grid: GridComponent;
  hubs: CodeTableEntry[];
  roles: UserRoleType[] = Object.values(UserRoleType);
  colDefs: ColDef<AppMessage>[] = [
    genIconButtonColumn({
      callback: data => this.onPreview(data),
      tooltip: this.translateService.instant('preview'),
      icon: 'preview',
    }),
    genIconButtonColumn({
      callback: data => this.onEdit(data),
      tooltip: this.translateService.instant('edit'),
      icon: 'edit_m',
    }),
    {
      ...genEnumColumn({
        field: 'state',
        values: [
          AppMessageState.DRAFT,
          AppMessageState.PUBLISHED,
          AppMessageState.CLOSED,
        ],
        headerName: this.translateService.instant('state')
      }),
    },
    genTextColumn(
      'title',
      this.translateService.instant('title'),
    ),
    genDateColumn({
      field: 'validFrom',
      headerName: this.translateService.instant('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
      filterParamsInfo: {
        customPath: 'validFrom'
      }
    }),
    genDateColumn({
      field: 'validTo',
      headerName: this.translateService.instant('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
      filterParamsInfo: {
        customPath: 'validTo'
      }
    }),
    genCodeTableColumn({
      field: 'hub',
      headerName: this.translateService.instant('hub'),
      filterValues: () => this.hubs,
    }),
    {
      ...genNumberColumn(
        'readerCount',
        this.translateService.instant('appMessagesHasReaders'),
        this.globalService,
        (data) => this.globalService.getFormattedValue(data.value, 0)
      ),
      sortable: false,
      floatingFilter: false,
    },
    {
      ...genEnumColumn({
        field: 'roles',
        values: this.roles,
        headerName: this.translateService.instant('roles'),
        filterParamsInfo: {
          customPath: 'roles.role'
        }
      }),
      flex: 1,
      sortable: false,
    },
    genIconButtonColumn({
      callback: data => this.onDelete(data),
      tooltip: this.translateService.instant('delete'),
      icon: 'delete',
      hidden: (data) => data.readerCount > 0,
    })
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
  };
  data: GridDataProvider = this.appMessageService.listAll.bind(this.appMessageService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly appMessageService: AppMessageService,
    private readonly codeTableService: CodeTableService,
    private readonly modalService: ModalService,
  ) {
    codeTableService.getCodeTable(ECodeTables.hub)
      .subscribe(hubs => { this.hubs = hubs; })
  }

  onAdd() {
    const modalData: ModalData = {
      type: EModalType.appMessagesAdd,
      title: EModalType.appMessagesAdd,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        message: {
          title: '',
          content: '',
          validFrom: '',
          validTo: '',
          hub: null,
          roles: []
        } as AppMessage,
        hubs: this.hubs,
        roles: this.roles,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: AppMessageEditDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF
    );
    ref.afterClosed().subscribe((result: AppMessage | null) => {
      if (!result) return;
      this.appMessageService.createMessage(result)
        .subscribe(_ => {
          this.grid.reload();
      });
    });
  }

  onEdit(message: AppMessage) {
    const modalData: ModalData = {
      type: EModalType.appMessagesEdit,
      title: EModalType.appMessagesEdit,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        message: {
          ...message
        } as AppMessage,
        hubs: this.hubs,
        roles: this.roles,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: AppMessageEditDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF
    );
    ref.afterClosed().subscribe((result: AppMessage | null) => {
      if (!result) return;
      this.appMessageService.editMessage(message.id, result)
        .subscribe(_ => {
          this.grid.reload();
      });
    });
  }

  onDelete(message: AppMessage) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: EModalType.appMessagesDelete,
      component: null,
      data: {
        message: this.translateService.instant('appMessagesDeleteMessage'),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.appMessageService.deleteMessage(message.id)
            .subscribe(() => {
              this.grid.reload();
              modalRef.close();
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }
  onPreview(message: AppMessage) {
    showMessageViewDialog(message, this.translateService, this.modalService, () => {});
  }

  private onGridReady(event: GridReadyEvent) {
    this.setDefaultFilter(event.api);
  }

  gridFilterReset(event: GridResetEvent) {
    const api = event.api;
    this.setDefaultFilter(api);
  }

  private setDefaultFilter(api: GridApi) {
    api.setColumnFilterModel('state', {
        values: [
          AppMessageState.DRAFT,
          AppMessageState.PUBLISHED,
        ]
      }
    ).then(() => api.onFilterChanged());
  }
}
