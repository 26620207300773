import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProductCatalogueComponent } from './views/product-catalogue/product-catalogue.component';
import { ProductOverviewComponent } from './views/product-overview/product-overview.component';
import { ProductRoutingModule } from './product-routing.module';
import { ProductDetailsFormComponent } from './views/product-overview/product-details-form/product-details-form.component';
import { ProductDetailsComponent } from './views/product-overview/product-details/product-details.component';
import {ProductSearchDialogComponent} from "./shared/product-search-dialog/product-search-dialog.component";

/**
 * Product Module.
 * Contains all components related to products
 */
@NgModule({
  declarations: [
    ProductCatalogueComponent,
    ProductDetailsFormComponent,
    ProductOverviewComponent,
    ProductDetailsComponent,
    ProductSearchDialogComponent,
  ],
  imports: [CommonModule, ProductRoutingModule, SharedModule],
  exports: [ProductCatalogueComponent],
})
export class ProductModule {}
