import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {TranslateService} from '@ngx-translate/core';
import * as Blockly from 'blockly';
import {Block} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';


export class BlockFilterComposite extends BlockDefinition {

  constructor(ident: string, readonly operators: string[], readonly inputCheckTypes: string[], active: boolean, readonly field?: string) {
    super(
      'filter_composite',
      ident,
      active,
    );
  }

  protected extras(translateService: TranslateService, color: string): Record<string, any> {
    const checkTypes = this.inputCheckTypes;
    return {
      inputCounter: 2,
      minInputs: 2,
      operators: this.operators.map(op => [op, op]),
      /**
       * Create XML to represent number of text inputs.
       */
      mutationToDom(): Element {
        const self = this as any;
        const container = Blockly.utils.xml.createElement('mutation');
        const inputNames = self.inputList.map((input) => input.name).join(',');
        container.setAttribute('inputs', inputNames);
        container.setAttribute('next', self.inputCounter);
        return container;
      },

      /**
       * Parse XML to restore the text inputs.
       */
      domToMutation(xmlElement: Element): void {
        const self = this as any;
        if (xmlElement.getAttribute('inputs')) {
          self.deserializeInputs_(xmlElement);
        } else {
          self.deserializeCounts_(xmlElement);
        }
      },

      /**
       * Parses XML based on the 'inputs' attribute (non-standard).
       */
      deserializeInputs_(xmlElement: Element): void {
        const self = this as any;
        const items = xmlElement.getAttribute('inputs');
        if (items) {
          const inputNames = items.split(',');
          self.inputList = [];

          inputNames.forEach((name) => {
            if (name === 'ADD0') {
              self.appendDropdownField(this.appendValueInput(name).setCheck(checkTypes));
            } else {
              self.appendValueInput(name).setCheck(checkTypes);
            }
          });
        }
        const next = parseInt(xmlElement.getAttribute('next'), 10);
        self.inputCounter = next;
      },

      /**
       * Parses XML based on the 'items' attribute (standard).
       */
      deserializeCounts_(xmlElement: Element): void {
        const self = this as any;
        const itemCount = Math.max(
          parseInt(xmlElement.getAttribute('items'), 10),
          self.minInputs
        );
        // Two inputs are added automatically.
        for (let i = self.minInputs; i < itemCount; i++) {
          this.appendValueInput('ADD' + i).setCheck(checkTypes);
        }
        self.inputCounter = itemCount;
      },

      /**
       * Check whether a new input should be added and determine where it should go.
       */
      getIndexForNewInput(connection: Blockly.Connection): number {
        const self = this as any;
        if (!connection.targetConnection) {
          // self connection is available
          return null;
        }

        let connectionIndex: number;
        for (let i = 0; i < self.inputList.length; i++) {
          if (self.inputList[i].connection === connection) {
            connectionIndex = i;
          }
        }

        if (connectionIndex === self.inputList.length - 1) {
          // self connection is the last one and already has a block in it, so
          // we should add a new connection at the end.
          return self.inputList.length + 1;
        }

        const nextInput = self.inputList[connectionIndex + 1];
        const nextConnection =
          nextInput && nextInput.connection.targetConnection;
        if (
          nextConnection &&
          !nextConnection.sourceBlock_.isInsertionMarker()
        ) {
          return connectionIndex + 1;
        }

        // Don't add new connection
        return null;
      },

      /**
       * Called when a block is dragged over one of the connections on this block.
       */
      onPendingConnection(connection: Blockly.Connection): void {
        const self = this as any;
        const insertIndex = self.getIndexForNewInput(connection);
        if (insertIndex == null) {
          return;
        }
        self.appendValueInput('ADD' + self.inputCounter++).setCheck(checkTypes);
        self.moveNumberedInputBefore(self.inputList.length - 1, insertIndex);
      },

      /**
       * Called when a block drag ends if the dragged block had a pending connection
       * with this block.
       */
      finalizeConnections(): void {
        const self = this as any;
        if (self.inputList.length > self.minInputs) {
          let toRemove = [];
          self.inputList.forEach((input) => {
            const targetConnection = input.connection.targetConnection;
            if (!targetConnection) {
              toRemove.push(input.name);
            }
          });
          if (self.inputList.length - toRemove.length < self.minInputs) {
            // Always show at least two inputs
            toRemove = toRemove.slice(self.minInputs);
          }
          const operatorRemoved = toRemove.includes('ADD0');
          const operatorVal = self.getFieldValue('operator');
          toRemove.forEach((inputName) => self.removeInput(inputName));
          // re-assign the input names and operator selector if the first input is removed
          if (operatorRemoved) {
            for (let i = 0; i < self.inputList.length; i++) {
              self.inputList[i].name = `ADD${i}`;
              if (i === 0) {
                self.appendDropdownField(self.inputList[i]);
                self.setFieldValue(operatorVal, 'operator');
              }
            }
          }
        }
      },

      appendDropdownField(input) {
        const self = this as any;
        input.appendField(
          new Blockly.FieldDropdown(self.operators),
          'operator'
        );
      },
      compactConnections(): void {
        const self = this as any;
        const toRemove = self.inputList.filter(i => !i.connection.targetConnection).map(d => d.name);
        toRemove.forEach(r => {
          if (r !== 'ADD0') {
            self.removeInput(r);
          }
        });
      }
    };
  }

  init(translateService: TranslateService, block: Block, color: string) {
    block.setStyle('list_blocks');
    this.appendDropdownField(translateService, block.appendValueInput('ADD0'));
    block.appendValueInput('ADD1').setCheck(this.inputCheckTypes);
    block.setOutput(true, 'Boolean');
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const order = javascriptGenerator.ORDER_NONE;
    const operator = block.getFieldValue('operator');
    const children = ((block as any).inputList || [])
      .map((it) => {
        const inputCode =
          javascriptGenerator.valueToCode(block as Block, it.name, order) || 'null';
        let child: any;
        try {
          child = JSON.parse(inputCode);
        } catch (e) {
          child = [];
        }
        return child;
      })
      .filter(x => x); // remove null entries
    const case0 = this.outputCase0(operator, children, order);
    if (case0) return case0;
    return {
      result: {
        type: 'composite',
        operator,
        children,
        field: this.field,
      },
      order,
    };
  }

  // (A or B) and (c or D)...
  outputCase0(operator: string, children: any[], order: any): BlockOutput | null {
    if (operator != 'and') return null;
    if (!children.some(c => c.type == 'composite')) return null;
    return {
      result: {
        type: 'composite',
        operator: 'xand',
        children,
        field: this.field,
      },
      order,
    };
  }

  appendDropdownField(translateService: TranslateService, input) {
    input.appendField(
      new Blockly.FieldDropdown(
        this.operators.map(op => [
          op, op,
        ])
      ),
      'operator'
    );
  }
}
