import {Component, Inject} from "@angular/core";
import {ColDef, GridOptions, GridReadyEvent, ValueFormatterParams} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {CampaignAction, CampaignActionService, ClientDocumentAccess} from "../../../../api/core";
import {GlobalService} from "../../../../services/global.service";
import {Subscription} from "rxjs";
import {genDateColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-campaign-action-documents',
  templateUrl: './campaign-action-documents.component.html'
})
export class CampaignActionDocumentsComponent {
  columnDefs: ColDef<ClientDocumentAccess>[] = [
    {
      ...genTextColumn(
        'attachment.filename',
        this.translateService.instant('attachment'),
        (data) => data.value.split('/').slice(-1)
      ),
      flex: 1,
    },
    genTextColumn(
      'readOn',
      this.translateService.instant('viewed'),
      data => this.translateService.instant(data.value ? 'yes' : 'no' )
    ),
    genDateColumn({
      field: 'readOn',
      headerName: this.translateService.instant('readOn'),
      dateFormatter: (params: ValueFormatterParams) =>
        params.data.readOn ?
        [
          this.globalService.dateToFrChLocale(params.data.readOn),
          this.globalService.timeToFrChLocale(params.data.readOn)
        ].join(' ') : '',
    }),
  ];

  gridOptions: GridOptions = {
    suppressRowClickSelection: true,
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };

  campaignAction: CampaignAction;
  subscriptions: Subscription[] = [];
  documents: ClientDocumentAccess[];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly campaignActionsService: CampaignActionService,
    protected readonly globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        campaignAction: CampaignAction,
        documents: ClientDocumentAccess[]
      }
    }
  ) {
    this.campaignAction = data.data.campaignAction;
    this.documents = data.data.documents;
  }

  gridReady(event: GridReadyEvent) {
  }
}
