import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_PLAYGROUND_CONFIG = {
  id: 13,
  version: '0.1',
  navigation: [
    {
      title: {
        en: 'Targeting / Filtering',
        de: 'Targeting / Filtering',
      },
      anchor: 'playgroundHome',
    },
    {
      title: {
        en: 'Details',
        de: 'Details',
      },
      anchor: 'playgroundDetails',
      children: [
        {
          title: {
            de: 'Filters',
            en: 'Filters',
          },
          anchor: 'playgroundDetailsFilters',
        },
        {
          title: {
            de: 'Risk/Return Graph',
            en: 'Risk/Return Graph',
          },
          anchor: 'playgroundDetailsRisk',
        },
        {
          title: {
            de: 'Bar and Pie Charts',
            en: 'Bar and Pie Charts',
          },
          anchor: 'playgroundDetailsCharts',
        },
      ],
    },
    {
      title: {
        en: 'Filter Editor Dialog',
        de: 'Filter Editor Dialog',
      },
      anchor: 'playgroundDialog',
    },
    {
      title: {
        en: 'Advanced Filter',
        de: 'Advanced Filter',
      },
      anchor: 'playgroundAdvanced',
    },
    {
      title: {
        en: 'Portfolio Preview',
        de: 'Portfolio Preview',
      },
      anchor: 'playgroundPortfolios',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        en: 'Targeting / Filtering',
        de: 'Targeting / Filtering',
      },
      anchor: 'playgroundHome',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'This is the place to test filters with actual data.',
        de: 'This is the place to test filters with actual data.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        en: 'Details',
        de: 'Details',
      },
      anchor: 'playgroundDetails',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'We have 3 areas: <b>(1)Filters</b>, <b>(2)Risk/Return Graph</b> and <b>(3)Bar and Pie Charts</b>',
        de: 'We have 3 areas: <b>(1)Filters</b>, <b>(2)Risk/Return Graph</b> and <b>(3)Bar and Pie Charts</b>',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Playground Details',
        de: 'Playground Details',
      },
      url: '/assets/images/help/help_playground_details_overview.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Filters',
        en: 'Filters',
      },
      anchor: 'playgroundDetailsFilters',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Shows the filters currently applied, an edit button to edit the filters, and the summary of the filtered portfolios.',
        en: 'Shows the filters currently applied, an edit button to edit the filters, and the summary of the filtered portfolios.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The current filter can be saved for later use, or loaded from a previously saved filter (My Filters).',
        en: 'The current filter can be saved for later use, or loaded from a previously saved filter (My Filters).',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Playground Details with some filters applied',
        de: 'Playground Details with some filters applied',
      },
      url: '/assets/images/help/help_playground_details_filters.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Risk/Return Graph',
        en: 'Risk/Return Graph',
      },
      anchor: 'playgroundDetailsRisk',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Shows the distribution of the filtered portfolios in the risk/return space.',
        en: 'Shows the distribution of the filtered portfolios in the risk/return space.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Click and drag to select a region of the graph to quickly apply a filter.',
        en: 'Click and drag to select a region of the graph to quickly apply a filter.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Playground Details Risk/Return Graph',
        de: 'Playground Details Risk/Return Graph',
      },
      url: '/assets/images/help/help_playground_details_risk.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Bar and Pie Charts',
        en: 'Bar and Pie Charts',
      },
      anchor: 'playgroundDetailsCharts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Select a pie or bar chart symbol to show the corresponding chart.',
        en: 'Select a pie or bar chart symbol to show the corresponding chart.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By clicking on a bar chart segment you can quickly apply a filter.',
        en: 'By clicking on a bar chart segment you can quickly apply a filter.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Playground Details Asset Class Bar Chart',
        de: 'Playground Details Asset Class Bar Chart',
      },
      url: '/assets/images/help/help_playground_details_bar_chart.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        en: 'Filter Editor Dialog',
        de: 'Filter Editor Dialog',
      },
      anchor: 'playgroundDialog',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'When you click on the Filter Edit button, this dialog is shown',
        de: 'When you click on the Filter Edit button, this dialog is shown',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Filter Editor Dialog',
        de: 'Filter Editor Dialog',
      },
      url: '/assets/images/help/help_playground_dialog_en.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The different tabs show filter options for the different categories.',
        de: 'The different tabs show filter options for the different categories.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: '<b>Generel logical behaviour:</b> The logical operator <b>AND</b> is generally applied to <b>different</b> filter crieteria. For a multiple choice within a single filter criterion, the operator <b>OR</b> is applied. If you want to perform more complex logical operations, e.g. AND/OR/NOT, please use advanced filtering described in the paragraph below.',
        de: '<b>Generel logical behaviour:</b> The logical operator <b>AND</b> is generally applied to <b>different</b> filter crieteria. For a multiple choice within a single filter criterion, the operator <b>OR</b> is applied. If you want to perform more complex logical operations, e.g. AND/OR/NOT, please use advanced filtering described in the paragraph below.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        en: 'Advanced Filter',
        de: 'Advanced Filter',
      },
      anchor: 'playgroundAdvanced',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The Advanced Filter allows the user to create a filter that is not supported in the other tabs.',
        de: 'The Advanced Filter allows the user to create a filter that is not supported in the other tabs.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Advanced Filter - Operators',
        de: 'Advanced Filter - Operators',
      },
      url: '/assets/images/help/help_playground_advanced_operators_en.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The Advanced Filter can create a filter using logic operations like: not, and, or, nor.',
        de: 'The Advanced Filter can create a filter using logic operations like: not, and, or, nor.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'Just drag and drop the blocks from the left pane to the playground canvas on the right, and connect the blocks to create your own filter.',
        de: 'Just drag and drop the blocks from the left pane to the playground canvas on the right, and connect the blocks to create your own filter.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Advanced Filter Example',
        de: 'Advanced Filter Example',
      },
      url: '/assets/images/help/help_playground_advanced_example_en.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'If you need more that the default two inputs of the and/or operator, just drag the new block  to the operator block, near the last input, and it will automatically show new inputs.',
        de: 'If you need more that the default two inputs of the and/or operator, just drag the new block  to the operator block, near the last input, and it will automatically show new inputs.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The filters for clients and positions are a bit special. The reason for this is that a portfolio can have multiple clients and multiple positions.',
        de: 'The filters for clients and positions are a bit special. The reason for this is that a portfolio can have multiple clients and multiple positions.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: '',
        de: '',
      },
      url: '/assets/images/help/filter-client.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The filter above would on first viewing filter all portfolios that have a client that is male and is older or equal to 50 years. This however is incorrect. Since a portfolio can have multiple clients what will be searched for is a portfolio that will match both criteria, so a portfolio that has a client that is male and a client that is older than 50. This means that a portfolio that has 2 clients (1 female older than 50 and another male of age 20) would still match this filter.',
        de: 'The filter above would on first viewing filter all portfolios that have a client that is male and is older or equal to 50 years. This however is incorrect. Since a portfolio can have multiple clients what will be searched for is a portfolio that will match both criteria, so a portfolio that has a client that is male and a client that is older than 50. This means that a portfolio that has 2 clients (1 female older than 50 and another male of age 20) would still match this filter.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'To allow us to create the filter that we initially thought about we need to use a new block. This block is called the <b>each</b> combinator. Below you can see an example.',
        de: 'To allow us to create the filter that we initially thought about we need to use a new block. This block is called the <b>each</b> combinator. Below you can see an example.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: '',
        de: '',
      },
      url: '/assets/images/help/filter-each-client.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'This each block enforces that all the criteria included in this block need to match on a single client for the portfolio to be selected. In the case above this means that the portfolio needs to have at least one client that is older than 15 and younger than 40 for the portfolio to be selected.',
        de: 'This each block enforces that all the criteria included in this block need to match on a single client for the portfolio to be selected. In the case above this means that the portfolio needs to have at least one client that is older than 15 and younger than 40 for the portfolio to be selected.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The same thing as with the clients can also happen with the positions. To solve this we have the same type of <b>each</b> block also present in the position filtering options as seen below:',
        de: 'The same thing as with the clients can also happen with the positions. To solve this we have the same type of <b>each</b> block also present in the position filtering options as seen below:',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: '',
        de: '',
      },
      url: '/assets/images/help/filter-each-position.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'The filter above would only select portfolios that have positions that match all 3 criteria on one single position. So only portfolios that have a position of asset type bond with the maturity being between the selected dates will qualify.',
        de: 'The filter above would only select portfolios that have positions that match all 3 criteria on one single position. So only portfolios that have a position of asset type bond with the maturity being between the selected dates will qualify.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        en: 'Portfolio Preview',
        de: 'Portfolio Preview',
      },
      anchor: 'playgroundPortfolios',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        en: 'This tab shows the portfolios selected by the current filter.',
        de: 'This tab shows the portfolios selected by the current filter.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Portfolio Preview',
        de: 'Portfolio Preview',
      },
      url: '/assets/images/help/help_playground_portfolios_en.png',
    },
  ],
  view: EViewRoutes.playground,
};
