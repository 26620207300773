import { EModalType } from 'src/app/util/enum';

/**
 * Interface for modal data.
 * Allows to build a single modal component and still make content dynamic
 */
export interface IModalData {
  /**
   * Modal type
   */
  type: EModalType;
  /**
   * Data that should be forwarded to modal content component
   */
  data: any;
  /**
   * Modal title
   */
  title: string;
  /**
   * Submit button on modal
   */
  submitBtn?: ModalButton;
  /**
   * Cancel button on modal
   */
  cancelBtn?: ModalButton;
  /**
   * Component to be rendered in modal
   * @param component Component for modal content
   */
  component(component: any);
}

/**
 * Class that implements {@link IModalData}
 */
export class ModalData implements IModalData {
  constructor(
    public type: EModalType,
    public data: any,
    public title: string,
    public component: any,
    public submitBtn?: ModalButton,
    public cancelBtn?: ModalButton
  ) {}
}

/**
 * Interface for modal button
 */
export interface ModalButton {
  label: string;
  class?: string;
  callback?: (param?: any) => void;
}

/**
 * Interface for components that are loaded into the modal
 */
export interface ModalSubComponent {
  modalAction: (modalType?: EModalType) => void;
}
