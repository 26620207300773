import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageComponent} from 'src/app/shared/page/page.component';
import {AuthGuard} from 'src/app/util/auth.guard';
import {ERoutePaths, EViewRoutes, EViewTitles,} from 'src/app/util/enum';
import {IntermediaryOverviewComponent} from './views/intermediary-overview/intermediary-overview.component';
import {IntermediaryResolver} from 'src/app/util/resolvers';
import {PermissionGuard} from 'src/app/util/permission.guard';
import {IntermediaryListComponent} from "./views/intermediary-list/intermediary-list.component";

/**
 * Intermediary route definition.
 * Contains all routing definitions related to intermediaries
 */
const intermediaryRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: IntermediaryListComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.intermediaries,
          viewRoute: EViewRoutes.intermediaries,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.overview,
        component: IntermediaryOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.intermediaryOverview,
          viewRoute: EViewRoutes.intermediaryOverview,
          allowedRoles: [],
        },
        resolve: {
          intermediary: IntermediaryResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(intermediaryRoutes)],
  exports: [RouterModule],
})
export class IntermediaryRoutingModule {}
