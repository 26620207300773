import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { PermissionService } from 'src/app/services/permission.service';

/**
 * Component used for login page.
 */
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  detailsOpen = false;

  constructor(
    private oauthService: OAuthService,
    protected globalService: GlobalService,
    protected permissionService: PermissionService
  ) {}

  get claims() {
    return this.oauthService.getIdentityClaims();
  }

  get hasValidAccessToken() {
    return this.oauthService.hasValidAccessToken();
  }

  get hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  get id_token() {
    return this.oauthService.getIdToken();
  }

  get access_token() {
    return this.oauthService.getAccessToken();
  }

  get id_token_expiration() {
    return this.oauthService.getIdTokenExpiration();
  }

  get access_token_expiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  ngOnInit(): void {}

  /**
   * Gets executed on submit and performs login function in global service, sending username and password that were
   * entered by user in HTML form.
   */
  onSubmit(): void {
    this.globalService.login();
  }

  onToggleDetails(): void {
    this.detailsOpen = !this.detailsOpen;
  }

  /**
   * Signs out currently signed in user
   */
  signOut(): void {
    this.globalService.logout();
  }

  /**
   * Gets username from JWT token
   */
  getUserName(): string {
    return this.permissionService.currentUsername;
  }
}
