import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {ColDef, ColumnState, GridApi} from 'ag-grid-community';

export class TableStateUtil {
  constructor(injector: Injector) {
    this.activateRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
  }

  private STATE_HISTORY_MAX_COUNT = 50;

  private queryParamsSub: Subscription;
  private appliedState: string;
  private tableId: string;

  private readonly activateRoute: ActivatedRoute;
  private router: Router;
  private statePage = 0;

  private static hash(s: string): string {
    /* eslint-disable no-bitwise */
    let hash = 0;
    let i;
    let chr;
    if (s.length === 0) {
      return s;
    }
    for (i = 0; i < s.length; i++) {
      chr = s.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash).toString(36);
    /* eslint-enable */
  }

  private static getColumnStateFromLocalStorage(
    tableName: string
  ): ColumnState[] | null {
    return (
      JSON.parse(localStorage.getItem('columnOrdering-' + tableName)) || null
    );
  }

  private static saveColumnStateToLocalStorage(
    tableName: string,
    columnOrdering: ColumnState[]
  ) {
    localStorage.setItem(
      'columnOrdering-' + tableName,
      JSON.stringify(columnOrdering)
    );
  }

  private static removeColumnStateFromLocalStorage(tableName: string) {
    localStorage.removeItem('columnOrdering-' + tableName);
  }

  private static getColumnHash(columns: ColDef[]): string {
    return this.hash(columns.map((c) => c.field).join(''));
  }

  /**
   * Registers listener that will read the URL state and apply it to the grid.
   * @param tableId The tableId to use for the state
   * @param gridApi
   */
  public observeFor(tableId: string, gridApi: GridApi) {
    this.queryParamsSub = this.activateRoute.queryParams.subscribe((params) => {
      try {
        const tableStateId = params[this.getParamName(tableId)];
        if (tableStateId && tableStateId !== this.appliedState) {
          const state = this.getTableState(tableStateId);
          if (state) {
            if (state.filterModel) {
              gridApi.setFilterModel(state.filterModel);
            }
            if (state.sortModel) {
              gridApi.applyColumnState({
                state: state.sortModel,
                applyOrder: false,
              });
            }
            this.statePage = state.currentPage || 0;
          }
        }
      } catch (e) {
        throw e;
        // if parsing didn't work -> ignore the parameters
      }
    });
  }

  private getTableState(appliedStateId: string): TableState | null {
    const stateMap = this.getStateMap();
    return stateMap[appliedStateId] || null;
  }

  public destroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
    this.appliedState = null;
  }

  public saveTableStateFromApi(gridApi: GridApi) {
    const filterModel = gridApi.getFilterModel();
    const sortModel = this.buildSortModel(gridApi);
    const strFilterModel = Object.keys(filterModel).length
      ? filterModel || null
      : null;
    const strSortModel =
      Array.isArray(sortModel) && sortModel.length ? sortModel || null : null;

    this.saveTableState(
      strFilterModel,
      strSortModel,
      gridApi.paginationGetCurrentPage()
    );
  }

  private buildSortModel(gridApi: GridApi) {
    return gridApi.getColumnState().map((state) => ({
      colId: state.colId,
      sort: state.sort,
      sortIndex: state.sortIndex,
      width: state.width,
    }));
  }

  public saveTableState(filterModel, sortModel, currentPage: number) {
    if (!this.tableId) {
      return;
    }
    if (currentPage === -1) {
      currentPage = this.statePage;
    }

    const stateId =
      (filterModel || sortModel || currentPage || null) &&
      this.genStateId(filterModel, sortModel, currentPage);
    if (stateId) {
      this.saveState(filterModel, sortModel, currentPage, stateId);
      this.appliedState = stateId;
    } else {
      this.appliedState = null;
    }
    this.statePage = currentPage || 0;
    this.router.navigate([], {
      relativeTo: this.activateRoute,
      queryParams: this.getQueryParams(stateId),
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private getQueryParams(stateId: string): object {
    const params = {};
    params[this.getParamName()] = stateId;
    return params;
  }

  private getParamName(tableId: string = this.tableId) {
    return tableId + 'State';
  }

  /**
   * Generate a hash from a given filterModel and sortModel
   */
  public genStateId(
    filterModel: object,
    sortModel: object,
    currentPage: number
  ): string {
    const state =
      currentPage +
      JSON.stringify(this.sortObject(filterModel)) +
      JSON.stringify(this.sortObject(sortModel));

    return TableStateUtil.hash(state);
  }

  private sortObject(o: any) {
    if (!o) {
      return o;
    }
    return Object.keys(o)
      .sort()
      .reduce((r, k) => ((r[k] = o[k]), r), {});
  }

  public saveState(
    filterModel: any,
    sortModel: any,
    currentPage: number,
    stateId: string
  ) {
    const stateMap = this.getStateMap();
    stateMap[stateId] = {
      filterModel,
      sortModel,
      currentPage,
      timestamp: +new Date(),
    };
    const entries = Object.entries(stateMap);
    entries
      .sort((e1, e2) => e2[1].timestamp - e1[1].timestamp)
      .slice(this.STATE_HISTORY_MAX_COUNT, Number.MAX_VALUE)
      .map((e) => e[0])
      .forEach((stateIdToDelete) => delete stateMap[stateIdToDelete]);
    localStorage.setItem('tableStates', JSON.stringify(stateMap));
  }

  private getStateMap(): StateMap {
    return JSON.parse(localStorage.getItem('tableStates')) || {};
  }

  /**
   * Change a given GridApi's pagination if not corresponding to the state and returns true iff the pagination needed
   * to be changed.
   * @param agGridApi
   */
  adjustPageIfNeeded(agGridApi: GridApi) {
    if (!this.tableId) {
      return;
    }
    if (agGridApi.paginationGetCurrentPage() !== this.statePage) {
      agGridApi.paginationGoToPage(this.statePage);
      return true;
    } else {
      return false;
    }
  }

  public saveColumnState(tableId: string, gridApi: GridApi, columns: ColDef[]) {
    if (tableId) {
      const columnOrdering: ColumnState[] = gridApi
        .getColumnState()
        .map((state) => ({
          colId: state.colId,
          hide: state.hide,
          pinned: state.pinned,
          width: state.width,
        })); // Remove the ordering (asc/desc) from the state
      TableStateUtil.saveColumnStateToLocalStorage(`${tableId}-${TableStateUtil.getColumnHash(columns)}`, columnOrdering);
    }
  }

  public clearColumnState(tableId: string, columns: ColDef[]) {
    if (tableId) {
      TableStateUtil.removeColumnStateFromLocalStorage(`${tableId}-${TableStateUtil.getColumnHash(columns)}`);
    }
  }

  public getColumnState(tableId: string, columns: ColDef[]) {
    if (tableId) {
      return TableStateUtil.getColumnStateFromLocalStorage(`${tableId}-${TableStateUtil.getColumnHash(columns)}`);
    } else {
      return null;
    }
  }

  public loadPageSize(tableId: string): string {
    return localStorage.getItem(`pageSize-${tableId || 'all'}`) || 'auto';
  }

  public savePageSize(tableId: string, value: string) {
    localStorage.setItem(`pageSize-${tableId || 'all'}`, value);
  }
}
interface TableState {
  filterModel: { [key: string]: any };
  sortModel: ColumnState[];
  currentPage: number;
  timestamp: number;
}
type StateMap = { [key: string]: TableState };

/**
 * Clears all table states from local storage, essentially resetting
 * all table layouts to their default state.
 */
export function clearTableStatesFromLocalStorage() {
  ['columnOrdering', 'tableStates', 'pageSize'].forEach((state) => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(state)) {
        localStorage.removeItem(key);
      }
    });
  });
}
