import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_MONITORING_CAMPAIGN_OVERVIEW_CONFIG = {
  id: 9,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Monitoring campaign overview',
        en: 'Monitoring campaign overview',
      },
      anchor: 'monitoringCampaignOverview',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Monitoring Campaign overview',
        en: 'Monitoring Campaign overview',
      },
      anchor: 'monitoringCampaignOverview',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all monitoring statistics for a specific LAUNCHED campaigns',
        en: 'Lists all monitoring statistics for a specific LAUNCHED campaigns.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Monitoring campaign overview - 1',
        en: 'Monitoring campaign overview - 1',
      },
      url: '/assets/images/help/help_monitoring_campaign_overview_1.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '1 - Relationship managers',
        en: '1 - Relationship managers',
      },
      anchor: 'monitoringCampaignOverviewRelationshipManagers',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Campaign statistics are classified by relationship manager in charge of portfolios.',
        en: 'Campaign statistics are classified by relationship manager in charge of portfolios.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Monitoring campaign overview - 2',
        en: 'Monitoring campaign overview - 2',
      },
      url: '/assets/images/help/help_monitoring_campaign_overview_2.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '2, 3 - Portfolio movements',
        en: '2, 3 - Portfolio movements',
      },
      anchor: 'monitoringCampaignOverviewPortfolioMovements',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The first table contains a summary of all portfolio movements for BUY and SELL products specified in the campaign. The second contains a detailed list of all portfolio-movements.',
        en: 'The first table contains a summary of all portfolio movements for BUY and SELL products specified in the campaign. The second contains a detailed list of all portfolio-movements.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '4 - Charts',
        en: '4 - Charts',
      },
      anchor: 'monitoringCampaignOverviewCharts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Proportion of contacted portfolios, executed and sent actions.',
        en: 'Proportion of contacted portfolios, executed and sent actions.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '5 - Performance',
        en: '5 - Performance',
      },
      anchor: 'monitoringCampaignOverviewPerformance',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Accumulative performance of BUY and SELL products used in the campaign.',
        en: 'Accumulative performance of BUY and SELL products used in the campaign.',
      },
    },
  ],
  view: EViewRoutes.monitoringCampaignOverview,
};
