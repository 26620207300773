<div class="page-content-container">
  <app-story-overview-toolbar></app-story-overview-toolbar>
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div *ngIf="activeTab === tabs[0].text" class="overview-container-parent">
    <div class="overview-details-container">
      <div class="details-column">
        <app-story-overview-details></app-story-overview-details>
        <div class="stats-filters-wrapper">
          <app-statistics class="horizontal"></app-statistics>
          <app-filter-active-tags
            [headerActionType]="filterHeaderActionType.edit"
            [editDisabled]="
            !(protectedActions.editStoryFilters | protectedAction)
          "
          ></app-filter-active-tags>
        </div>
      </div>
      <div class="charts-column">
        <app-risk-return-chart-card
        ></app-risk-return-chart-card>
        <app-additional-charts-card
          class="additional-charts-card"
          [story]="story"
          (selectedChartChanged)="selectedChartChanged($event)"
        ></app-additional-charts-card>
      </div>
    </div>
  </div>

  <div *ngIf="activeTab === tabs[1].text" class="content-container">
    <app-content-card
      class="story-contents"
      [allowAddOrEditContent]="
        protectedActions.editStoryContents | protectedAction
      "
      (addOrEditContent)="handleAddOrEditContent($event)"
      (deleteContent)="handleDeleteContent($event)"
      (toggleAutoSync)="handleToggleAutoSyncContent()"
    ></app-content-card>
    <app-products-card
      class="product-list"
      [allowEditProducts]="protectedActions.editStoryProducts | protectedAction"
      [productType]="storyProductType.BUY"
      (editProducts)="handleEditProducts(storyProductType.BUY)"
      (deleteProduct)="handleDeleteProduct($event, storyProductType.BUY)"
      (toggleAutoSync)="handleToggleAutoSyncProducts(storyProductType.BUY)"
    ></app-products-card>
    <app-products-card
      class="product-list"
      [allowEditProducts]="protectedActions.editStoryProducts | protectedAction"
      [productType]="storyProductType.SELL"
      (editProducts)="handleEditProducts(storyProductType.SELL)"
      (deleteProduct)="handleDeleteProduct($event, storyProductType.SELL)"
      (toggleAutoSync)="handleToggleAutoSyncProducts(storyProductType.SELL)"
    ></app-products-card>
  </div>

  <div *ngIf="activeTab === tabs[3].text" class="intermediary-list-container">
    <div class="grid-container">
      <div class="grid-actions">
        <app-config-icon
          iconIdentifier="refresh"
          (click)="intermediaryPreview.refresh()"
          [tooltip]="'refresh' | translate"
        ></app-config-icon>
      </div>
      <app-intermediary-list
        #intermediaryPreview
        [data]="intermediaryData"
        [currentFilter]="currentFilter"
        [showHub]="false"
      >
      </app-intermediary-list>
    </div>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="tab-content-container">
    <div class="grid-container">
      <div class="grid-actions">
        <app-config-icon
          iconIdentifier="refresh"
          (click)="portfolioPreview.refresh()"
          [tooltip]="'refresh' | translate"
        ></app-config-icon>
      </div>
      <app-portfolio-list #portfolioPreview [data]="data">
      </app-portfolio-list>
    </div>

  </div>

  <div *ngIf="activeTab === tabs[4].text" class="tab-content-container">
    <app-overview-allowed-users
      [story]="story"
      (toggleAutoSync)="handleToggleAutoSyncAllowedUsers()"
    ></app-overview-allowed-users>
  </div>
</div>
