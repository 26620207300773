<div class="chart-container" *ngIf="chartOptions.series.length > 0">
  <div
    class="info-container"
    *ngIf="chartType === chartTypes.scatter && currentData?.scatterIncomplete"
  >
    <span
      class="h4 color-accent-warning not-all-scatter-show"
      matTooltip="{{ currentData?.scatter?.length | number }}/{{
        currentData?.statistics?.filteredPortfolios | number
      }}"
    >
      {{ 'notAllScatterPortfoliosShown' | translate }}</span
    >
  </div>
  <highcharts-chart
    *ngIf="!loading && afterViewInit"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
    [(update)]="updateFlag"
    [oneToOne]="oneToOneFlag"
    [class.chart-with-warning]="
      chartType === chartTypes.scatter && currentData?.scatterIncomplete
    "
  >
  </highcharts-chart>
</div>
