import {
  AssetRange,
  CompositeList,
  DateRange,
  FilterClient,
  FilterConfig,
  FilterIntermediaries,
  FilterOrgPosition,
  FilterPortfolio,
  FilterPosition,
  WeightRange
} from '../models/filter.model';
import {FilterAdvanced, FilterAdvancedField, FilterBody, UserRoleData} from '../api/core';
import {
  fnaAssetClassValue,
  fnaAssetClassWeight,
  fnaClientAge,
  fnaClientDomicile,
  fnaClientGender,
  fnaCurrencyValue,
  fnaCurrencyWeight,
  fnaExcludeEAM,
  fnaExcludeEWA,
  fnaPortfolioAdvisor,
  fnaPortfolioAdvisoryType,
  fnaPortfolioAllowOptOut,
  fnaPortfolioBusinessDivision,
  fnaPortfolioBusinessUnit,
  fnaPortfolioFidleg,
  fnaPortfolioLiquidity,
  fnaPortfolioManager,
  fnaPortfolioMifid,
  fnaPortfolioQualifiedInvestor,
  fnaPortfolioReferenceCurrency,
  fnaPortfolioReturn,
  fnaPortfolioRisk,
  fnaPortfolioRiskProfile,
  fnaPortfolioRiskState,
  fnaPortfolioRmDepartment,
  fnaPortfolioRmLocation,
  fnaPortfolioRmMarket,
  fnaPortfolioServiceCenter,
  fnaPortfolioSustainabilityProfile,
  fnaPortfolioType,
  fnaPortfolioValue,
  fnaPortfolioWaiveOfAdvice,
  fnaPositionClass,
  fnaPositionIds,
  fnaPositionIsin,
  fnaPositionIsinPercentage,
  fnaPositionIsinTotalValue,
  fnaPositionIssuerName,
  fnaPositionMaturity,
  fnaPositionMoodyRating,
  fnaPositionNextCall,
  fnaPositionPerformance,
  fnaPositionProductInvestmentHorizon,
  fnaPositionProductRatingApac,
  fnaPositionProductRatingMe,
  fnaPositionRanking,
  fnaPositionRatingSourceLGT,
  fnaPositionRatingSP,
  fnaPositionSubClass,
  fnaPositionSustainabilityRating,
  fnaPositionType,
  fnaRegionValue,
  fnaRegionWeight,
  fnaSectorValue,
  fnaSectorWeight
} from './filter.names';
import {EFilterCategories} from "../util/enum";

export function convertFilterConfigToFilterBody(filterConfig: FilterConfig, userRole: UserRoleData): FilterBody {
  const advanced = mapAdvanced(userRole, filterConfig?.advanced as FilterAdvanced);
  const filterBody: FilterBody = {
    id: filterConfig?.id,
    standard: {
      type: 'composite',
      operator: 'and',
      children: [],
    },
    advanced
  };
  addClientFilterToFilterBody(filterBody, filterConfig?.client);
  addIntermediariesFilterToFilterBody(filterBody, filterConfig?.intermediaries);
  addPortfolioFilterToFilterBody(filterBody, filterConfig?.portfolio);
  addPositionFilterToFilterBody(filterBody, filterConfig?.position);
  addAssetFilterToFilterBody(filterBody, filterConfig?.assetsInclude, 'include');
  addAssetFilterToFilterBody(filterBody, filterConfig?.assetsExclude, 'exclude');
  addAssetClassFilterToFilterBody(filterBody, filterConfig?.assetClass);
  addCurrencyFilterToFilterBody(filterBody, filterConfig?.currency);
  addRegionFilterToFilterBody(filterBody, filterConfig?.region);
  addGicsFilterToFilterBody(filterBody, filterConfig?.gics);
  addOrgPositionFilterToFilterBody(filterBody, filterConfig?.orgPosition);
  return filterBody;
}

function mapAdvanced(userRole: UserRoleData, filterConfig?: FilterAdvanced) {
  if (filterConfig?.metadata !== '') {
    return mapFilterConfig(filterConfig, userRole);
  }
  return undefined;
}

function mapFilterConfig(src: FilterAdvanced, userRole: UserRoleData): FilterAdvanced {
  if (!src?.metadata) return src;
  const serializer = new XMLSerializer();
  const dom = new DOMParser();
  const tree = dom.parseFromString(src.metadata, 'text/xml');
  const root = tree.children[0]?.children[0]?.children[0]?.children[0];
  mapFilterNode(root, userRole);
  src.metadata = serializer.serializeToString(tree);
  return src;
}

function mapFilterNode(root: Element, userRole: UserRoleData) {
  const childTypes = ['block', 'value', 'field', 'mutation'];
  const rChildren = Array.from(root.children || [])
    .filter(child => childTypes.indexOf(child.nodeName) >= 0);
  switch (root.nodeName) {
    // Adds a mutationToDom function to generate a mutation xml element, see https://github.com/confinale/aspark/pull/8354
    case 'mutation':
      const baseAmount = +root.getAttribute("baseAmount");
      if (!baseAmount) break; // if not baseAmount, skip
      const exchangeRate = userRole.selectedExchange;
      /*
       <block type="filter_field-number-portfolio" id="k$*V$/Y.!$BKLO595x5a">
          <mutation baseAmount="50"/>
          <field name="field">portfolio.Value</field>
          <field name="operator">=</field>
          <field name="field_value">100</field>
       </block>
       */
      const parent = root.parentElement;
      const fieldEl = Array.from(parent.children)
        .find(e => e.getAttribute("name") == "field_value");
      if (fieldEl) {
        // if <field name="field_value">100</field> found
        const newAmount = baseAmount / exchangeRate;
        fieldEl.innerHTML = newAmount.toString();
      }
      break;
  }
  rChildren.forEach((child) => {
    mapFilterNode(child, userRole);
  });
}

function isNotNullOrEqual<T>(actual: T, expected: T): boolean {
  if (actual == null) {
    return false;
  }
  return actual !== expected;
}

function addClientFilterToFilterBody(
  filterBody: FilterBody,
  client: FilterClient
): FilterBody {
  if (!client) {
    return;
  }
  const children = filterBody.standard.children;
  filterList(children, fnaClientGender, client?.gender);
  filterList(children, fnaClientDomicile, client?.domiciles);
  const hasMin = isNotNullOrEqual(client?.ageRange?.from, 0);
  const hasMax = isNotNullOrEqual(client?.ageRange?.to, 100);
  const minChild: FilterAdvancedField = {
    type: 'age',
    name: fnaClientAge,
    operator: 'gte',
    valueNum: client.ageRange.from,
  };
  const maxChild: FilterAdvancedField = {
    type: 'age',
    name: fnaClientAge,
    operator: 'lte',
    valueNum: client.ageRange.to,
  };
  if (hasMin && hasMax) {
    children.push({
      type: 'composite',
      operator: 'each',
      field: 'clients',
      name: fnaClientAge,
      children: [
        minChild,
        maxChild,
      ],
    });
    return;
  }
  if (hasMin) {
    children.push(minChild);
  }
  if (hasMax) {
    children.push(maxChild);
  }
}

function addIntermediariesFilterToFilterBody(
  filterBody: FilterBody,
  intermediaries?: FilterIntermediaries
): FilterBody {
  if (!intermediaries) {
    return;
  }
  const children = filterBody.standard.children;
  children.push({
    type: fnaExcludeEAM,
    operator: 'eq',
    field: fnaExcludeEAM,
    name: fnaExcludeEAM,
    valueNum: (intermediaries.excludeEAM ?? false) ? 1.0 : 0.0,
  }, {
    type: fnaExcludeEWA,
    operator: 'eq',
    field: fnaExcludeEWA,
    name: fnaExcludeEWA,
    valueNum: (intermediaries.excludeEWA ?? false) ? 1.0 : 0.0,
  });
}

function addPortfolioFilterToFilterBody(
  filterBody: FilterBody,
  portfolio: FilterPortfolio
): FilterBody {
  if (!portfolio) {
    return;
  }
  const children = filterBody.standard.children;
  filterList(children, fnaPortfolioType, portfolio?.portfolioType);
  filterList(children, fnaPortfolioRiskProfile, portfolio?.model);
  filterList(children, fnaPortfolioReferenceCurrency, portfolio?.referenceCurrency);
  filterList(children, fnaPortfolioBusinessUnit, portfolio?.bu);
  filterList(children, fnaPortfolioServiceCenter, portfolio?.serviceCenter);
  filterList(children, fnaPortfolioBusinessDivision, portfolio?.businessDivision);
  filterList(children, fnaPortfolioAdvisoryType, portfolio?.advisoryType);
  filterList(children, fnaPortfolioSustainabilityProfile, portfolio?.sustainabilityProfile);
  filterList(children, fnaPortfolioMifid, portfolio?.mifid);
  filterList(children, fnaPortfolioFidleg, portfolio?.fidleg);
  filterList(children, fnaPortfolioRmMarket, portfolio?.rmMarket);
  filterList(children, fnaPortfolioRmLocation, portfolio?.rmLocation);
  filterList(children, fnaPortfolioManager, portfolio?.manager);
  filterList(children, fnaPortfolioAdvisor, portfolio?.advisor);
  filterStr(children, fnaPortfolioRmDepartment, portfolio?.rmDepartment?.key);
  filterDoubleRange(children, fnaPortfolioRisk, portfolio?.riskRange);
  filterDoubleRange(children, fnaPortfolioReturn, portfolio?.returnRange);
  filterDoubleRange(children, fnaPortfolioValue, portfolio?.valueRange);
  filterDoubleRange(children, fnaPortfolioLiquidity, portfolio?.liquidityRange);
  filterCheck(children, fnaPortfolioQualifiedInvestor, portfolio?.qualifiedInvestor);
  filterCheck(children, fnaPortfolioWaiveOfAdvice, portfolio?.waiveOfAdvice);
  if (portfolio?.riskBreachOnly) {
    children.push({
      type: 'list',
      name: fnaPortfolioRiskState,
      operator: 'ne',
      valueList: ['within']
    });
  }
  if (portfolio?.excludeRiskBreach) {
    children.push({
      type: 'list',
      name: fnaPortfolioRiskState,
      operator: 'eq',
      valueList: ['within']
    });
  }
  if (portfolio?.allowOptOut) {
    children.push({
      type: 'optOut',
      name: fnaPortfolioAllowOptOut,
      operator: 'eq',
      valueNum: 1,
    });
  }
}

function addPositionFilterToFilterBody(
  filterBody: FilterBody,
  position: FilterPosition
): FilterBody {
  if (!position) {
    return;
  }
  const children = filterBody.standard.children;
  filterDoubleRange(children, fnaPositionPerformance, position.value, {
    each: 'positionData.positions',
    scale: 100,
    nullMin: 0,
    nullMax: 1,
  });
  filterList(children, fnaPositionType, position?.assetType);
  filterList(children, fnaPositionClass, position?.assetClass);
  filterList(children, fnaPositionSubClass, position?.assetSubClass);
  filterList(children, fnaPositionMoodyRating, position?.ratingMoody);
  filterList(children, fnaPositionRatingSP, position?.ratingSP);
  filterList(children, fnaPositionSustainabilityRating, position?.sustainabilityRating);
  filterList(children, fnaPositionProductRatingApac, position?.productRatingApac);
  filterList(children, fnaPositionProductRatingMe, position?.productRatingMe);
  filterList(children, fnaPositionProductInvestmentHorizon, position?.productInvestmentHorizon);
  filterStr(children, fnaPositionIssuerName, position?.issuerName?.key);
  filterList(children, fnaPositionRanking, position?.ranking);
  filterList(children, fnaPositionRatingSourceLGT, position?.ratingSourceLGT);
  filterList(children, fnaPositionIds, position?.ids);
  filterList(children, fnaPositionIds, position?.excludedIds, 'ne');
  filterDateRange(children, fnaPositionNextCall, position?.nextCallDate, {each: 'positionData.positions'});
  filterDateRange(children, fnaPositionMaturity, position?.maturityDate, {each: 'positionData.positions'});
}

function addAssetFilterToFilterBody(
  filterBody: FilterBody,
  list: CompositeList<AssetRange>,
  type: 'include' | 'exclude',
): FilterBody {
  const assets = list?.children;
  if (!assets?.length) {
    return;
  }
  const children: FilterAdvancedField[] = [];
  assets.forEach((asset) => {
    if (asset?.hasExposure) {
      const item: FilterAdvancedField = {
        type: 'exists',
        name: fnaPositionIsin,
        field: asset.key,
        operator: 'exists',
        valueStr: asset.name,
      };
      children.push(item);
    } else {
      const item: FilterAdvancedField = {
        type: 'composite',
        operator: 'and',
        children: [],
      };
      const hasMin = asset?.range?.min != null;
      const hasMax = asset?.range?.max != null;
      const name = asset.isValue ? fnaPositionIsinTotalValue : fnaPositionIsinPercentage;
      const minField: FilterAdvancedField = {
        type: 'number',
        name,
        field: asset.key,
        operator: 'gte',
        valueNum: asset.range.min,
        valueStr: asset.name,
      };
      const maxField: FilterAdvancedField = {
        type: 'number',
        name,
        field: asset.key,
        operator: 'lte',
        valueNum: asset.range.max,
        valueStr: asset.name,
      };
      if (hasMin) {
        item.children.push(minField);
      }
      if (hasMax) {
        item.children.push(maxField);
      }
      if (item.children.length) {
        children.push(item);
      }
    }
  });
  filterBody.standard.children.push({
    type: 'composite',
    operator: list.operator == 'and' ? 'and' : 'mor',
    name: EFilterCategories.assets,
    valueStr: type,
    children,
  });
  return filterBody;
}

function addAssetClassFilterToFilterBody(filterBody: FilterBody, list: CompositeList<WeightRange>): FilterBody {
  return weightRangeToFilterBody(filterBody, list, EFilterCategories.assetClass, fnaAssetClassWeight, fnaAssetClassValue)
}

function addCurrencyFilterToFilterBody(filterBody: FilterBody, list: CompositeList<WeightRange>): FilterBody {
  return weightRangeToFilterBody(filterBody, list, EFilterCategories.currency, fnaCurrencyWeight, fnaCurrencyValue)
}

function addRegionFilterToFilterBody(filterBody: FilterBody, list: CompositeList<WeightRange>): FilterBody {
  return weightRangeToFilterBody(filterBody, list, EFilterCategories.region, fnaRegionWeight, fnaRegionValue)
}

function addGicsFilterToFilterBody(filterBody: FilterBody, list: CompositeList<WeightRange>): FilterBody {
  return weightRangeToFilterBody(filterBody, list, EFilterCategories.sector, fnaSectorWeight, fnaSectorValue)
}

function filterList(children: FilterAdvancedField[], name: string, source?: string[], operator: string = 'eq') {
  if (source?.length) {
    children.push({
      type: 'list',
      name,
      operator,
      valueList: source
    });
  }
}

function filterStr(children: FilterAdvancedField[], name: string, source?: string, operator: string = 'eq') {
  if (source) {
    children.push({
      type: 'list',
      name,
      operator,
      valueList: [source]
    });
  }
}

function filterLike(children: FilterAdvancedField[], name: string, source?: string, operator: string = 'eq') {
  if (source?.length) {
    children.push({
      type: 'like',
      name,
      operator,
      valueStr: source
    });
  }
}

function filterDoubleRange(children: FilterAdvancedField[], name: string, source?: DoubleRange,
                           opts: { each?: string; scale?: number; nullMin?: number; nullMax?: number } = {}) {
  if (!source) {
    return;
  }
  const scale = opts.scale || 1;
  const hasMin = source?.min != null && source.min !== opts.nullMin;
  const hasMax = source?.max != null && source.max !== opts.nullMax;
  const minChild: FilterAdvancedField = {
    type: 'number',
    name,
    operator: 'gte',
    valueNum: source.min * scale,
  };
  const maxChild: FilterAdvancedField = {
    type: 'number',
    name,
    operator: 'lte',
    valueNum: source.max * scale,
  };
  if (opts.each && hasMin && hasMax) {
    children.push({
      type: 'composite',
      operator: 'each',
      field: opts.each,
      name,
      children: [
        minChild,
        maxChild,
      ]
    });
    return;
  }
  if (hasMin) {
    children.push(minChild);
  }
  if (hasMax) {
    children.push(maxChild);
  }
}

function filterDateRange(children: FilterAdvancedField[], name: string, source?: DateRange, opts: { each?: string } = {}) {
  if (!source) {
    return;
  }
  const hasMin = source?.from != null && /\d{4}-\d{2}-\d{2}/.test(source.from);
  const hasMax = source?.to != null && /\d{4}-\d{2}-\d{2}/.test(source.to);
  const minChild: FilterAdvancedField = {
    type: 'date',
    name,
    operator: 'gte',
    valueStr: source.from,
  };
  const maxChild: FilterAdvancedField = {
    type: 'date',
    name,
    operator: 'lte',
    valueStr: source.to,
  };
  if (opts.each && hasMin && hasMax) {
    children.push({
      type: 'composite',
      operator: 'each',
      field: opts.each,
      name,
      children: [
        minChild,
        maxChild,
      ]
    });
    return;
  }
  if (hasMin) {
    children.push(minChild);
  }
  if (hasMax) {
    children.push(maxChild);
  }
}

function filterCheck(children: FilterAdvancedField[], name: string, source?: boolean, operator: string = 'eq') {
  if (source != null) {
    children.push({
      type: 'checkbox',
      name,
      operator,
      valueNum: source ? 1 : 0,
    });
  }
}

function weightRangeToFilterBody(
  filterBody: FilterBody,
  list: CompositeList<WeightRange>,
  category: string,
  nameWeight: string,
  nameRange: string
): FilterBody {
  const weightRanges = list?.children;
  if (!weightRanges?.length) {
    return;
  }
  const children: FilterAdvancedField[] = [];
  weightRanges.forEach((weightRange) => {
    filterWeightRange(children, nameWeight, nameRange, weightRange);
  });
  filterBody.standard.children.push({
    type: 'composite',
    operator: list.operator == 'and' ? 'and' : 'mor',
    name: category,
    children,
  });
  return filterBody;
}

function filterWeightRange(children: FilterAdvancedField[], nameWeight: string, nameRange: string, source?: WeightRange) {
  if (!source) {
    return;
  }
  if (source?.range?.min != null || source?.range?.max != null) {
    const item: FilterAdvancedField = {
      type: 'composite',
      operator: 'and',
      children: [],
    };
    if (source?.range?.min != null) {
      item.children.push({
        type: 'number',
        name: nameRange,
        field: source.key,
        operator: 'gte',
        valueNum: source.range.min,
      });
    }
    if (source?.range?.max != null) {
      item.children.push({
        type: 'number',
        name: nameRange,
        field: source.key,
        operator: 'lte',
        valueNum: source.range.max,
      });
    }
    children.push(item);
    // only consider range, even if weight for same field was also provided
    // does not affect loaded filters, because then 2 filterForm elements were created
    return;
  }
  if (source?.weight) {
    children.push({
      type: 'list',
      name: nameWeight,
      field: source.key,
      operator: 'eq',
      valueList: [source.weight]
    });
  }
}

function addOrgPositionFilterToFilterBody(
  filterBody: FilterBody,
  orgPositions: FilterOrgPosition,
): FilterBody {
  const positionLength = orgPositions?.positions?.length ?? 0;
  if (positionLength === 0) {
    return filterBody;
  }
  const children = filterBody.standard.children;
  children.push({
    type: 'orgPosition',
    name: 'orgPosition',
    operator: 'in',
    valueList: orgPositions.positions || [],
  });
  return filterBody;
}
