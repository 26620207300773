<div class="story-container"
     (click)="onStoryClick()"
     *ngIf="story"
     [attr.data-linked]="hasStoryOverviewPermissions">
  <div
    class="image"
    [ngStyle]="{ 'background-image': 'url(' + story?.coverImageUrl + ')' }"
    *ngIf="story?.coverImageUrl"
  ></div>
  <div class="preview aspect aspect-16-9" *ngIf="!story?.coverImageUrl">
    <app-config-icon
      iconIdentifier="landscape"
      class="image-placeholder"
    ></app-config-icon>
  </div>
  <div class="story-title h4"><div>{{ story.name }}</div></div>
</div>
