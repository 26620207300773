import { GridApi, IServerSideGetRowsRequest } from 'ag-grid-community';
import {GlobalService} from "../../services/global.service";

export class DatasourceFilter {
  constructor(
    agGridApi: GridApi,
    private readonly globalService: GlobalService,
    request: IServerSideGetRowsRequest = null) {
    this.createFilter(agGridApi, request);
  }
  private filter = {};

  private static getFilterComparatorType(filter: DatasourceGridFilter): string {
    const filterType = filter.type;
    let result: string;
    if (filterType === 'contains') {
      result = 'like';
    } else if (filterType === 'notContains') {
      result = 'nlike';
    } else if (filterType === 'equals' || filter.filterType === 'set') {
      result = 'eq';
    } else if (filterType === 'notEqual') {
      result = 'neq';
    } else if (filterType === 'startsWith') {
      result = 'starts with';
    } else if (filterType === 'endsWith') {
      result = 'ends with';
    } else if (filterType === 'lessThan') {
      result = 'lt';
    } else if (filterType === 'lessThanOrEqual') {
      result = 'le';
    } else if (filterType === 'greaterThan') {
      result = 'gt';
    } else if (filterType === 'greaterThanOrEqual') {
      result = 'ge';
    } else if (filterType === 'inRange') {
      result = 'ir';
    } else if (filterType === 'in') {
      result = 'eq';
    } else {
      result = null;
    }
    if (!result) {
      console.error('Filter not supported');
      console.error(filter);
    }
    return result;
  }

  private createFilter(
    agGridApi: GridApi,
    request: IServerSideGetRowsRequest
  ): void {
    const filterModels = (agGridApi && agGridApi.getFilterModel()) || {};
    Object.keys(filterModels).forEach((value) => {
      const filter = filterModels[value];
      if (filter) {
        const columnDef = agGridApi.getColumnDef(value);
        const filterParams = columnDef.filterParams;
        let formattedFilterText = filter.filter;
        let formattedFilterToText = filter.filterTo;
        if (filterParams && filterParams.textFormatter instanceof Function) {
          formattedFilterText = filterParams.textFormatter(formattedFilterText);
          if (formattedFilterToText) {
            formattedFilterToText = filterParams.textFormatter(
              formattedFilterToText
            );
          }
        }
        if (filter.filterType) {
          let values = [];
          if (filter.filterType === 'set') {
            values = filter.values || values;
          } else if (filterParams.filterType === 'number') {
            formattedFilterText = this.globalService.parseFormattedValue(formattedFilterText)?.toString();
            if (formattedFilterToText !== undefined) {
              formattedFilterToText = this.globalService.parseFormattedValue(formattedFilterToText).toString();
            }
            values.push(
              formattedFilterText +
              ((formattedFilterToText !== undefined &&
                  ' ' + formattedFilterToText) ||
                '')
            );
          } else {
            values.push(
              formattedFilterText +
                ((formattedFilterToText !== undefined &&
                  ' ' + formattedFilterToText) ||
                  '')
            );
          }
          const filterComparatorType =
            DatasourceFilter.getFilterComparatorType(filter);
          let fieldName =
            filterParams?.filterPath ||
            filterParams?.customPath ||
            filter.field ||
            value;
          if (filterParams.subField) {
            fieldName += '.' + filterParams.subField;
          }
          this.filter[fieldName] = {
            name: fieldName,
            type: filterComparatorType,
            values,
          };
        }
      }
    });

    if (request) {
      const groupKeys = request.groupKeys;
      if (request.rowGroupCols && groupKeys) {
        const type = 'eq';
        for (let i = 0; i < groupKeys.length; i++) {
          let name = '';
          const groupCol = request.rowGroupCols[i];
          const cellRendererParams = agGridApi.getColumnDef(
            groupCol.field
          ).cellRendererParams;
          if (cellRendererParams) {
            name = cellRendererParams.entityParams.dataBind;
          } else {
            name = groupCol.field;
          }
          const values = [];
          const value: any = groupKeys[i];
          let valueToPush = value;
          if (typeof value === 'object' && 'id' in value) {
            valueToPush = value.id;
          }
          values.push(valueToPush);
          this.filter[name] = { name, type, values };
        }
      }
    }
  }

  public applyFilter(filter: DatasourceFieldFilter): void {
    this.filter[filter.name] = filter;
  }

  public toString(): string {
    const values: DatasourceFieldFilter[] = Object.values
      ? Object.values(this.filter)
      : Object.keys(this.filter).map((key) => this.filter[key]);
    return (
      values
        .map((item) => item.name + '=' + item.type + ' ' + item.values)
        .join('&') || undefined
    );
  }
}

export interface DatasourceFieldFilter {
  name: string;
  type: string;
  values: string[];
}

export interface DatasourceGridFilter {
  filter: string;
  filterType: string;
  type: string;
}
