import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Tab} from "../../models/tabs.model";
import {ActivatedRoute} from "@angular/router";
import {PermissionService} from "../../services/permission.service";


@Component({
  selector: 'app-tabs-picker',
  templateUrl: './tabs-picker.component.html'
})
export class TabsPickerComponent {
  _tabs: Tab[];
  @Input() defaultTab: string;
  @Input() tabChangeAllowed = true;
  @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() activeTab: string;

  @Input() set tabs(tabs: Tab[]) {
    this._tabs = tabs;
    if (tabs) {
      this.activatedRoute.data.subscribe((data) => {
        const tab = tabs.find((tab) => tab.routerLink === data.viewRoute && !tab.hidden);
        if (tab) {
          setTimeout(() => {
            this.onTabClicked(tab.text, true);
          });
        }
      });
    }
  }

  get tabs() {
    return this._tabs;
  }

  get activeTabIcon() {
    return this._tabs.find(t => t.text == this.activeTab)?.icon
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
  ) {
  }

  ngOnInit(): void {
    this.activeTab = this.defaultTab;
  }

  ngOnDestroy(): void {
  }

  onTabClick(): void {
  }

  onTabClicked(tab: string, force: boolean = false): void {
    if (force || this.tabChangeAllowed) {
      this.activeTab = tab;
      this.tabChanged.emit(tab);
    }
  }

  hasPermission(tab: Tab) {
    if (tab.permission) {
      return this.permissionService.hasAnyPermission(tab.permission);
    }
    return true;
  }
}
