/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CampaignStatus = 'DRAFT' | 'LAUNCHED' | 'FROZEN' | 'CLOSED' | 'TERMINATED';

export const CampaignStatus = {
    DRAFT: 'DRAFT' as CampaignStatus,
    LAUNCHED: 'LAUNCHED' as CampaignStatus,
    FROZEN: 'FROZEN' as CampaignStatus,
    CLOSED: 'CLOSED' as CampaignStatus,
    TERMINATED: 'TERMINATED' as CampaignStatus
};

