import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {Client, ClientService, GridFilterOptionsParams,} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {ConfigService} from 'src/app/services/config.service';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genNumberColumn,
  genTextColumnWithAutoCompleteFilter,
} from 'src/app/util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {GridResetEvent} from '../../../shared/grid/grid.component';
import {MatCheckbox} from "@angular/material/checkbox";
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";

/**
 * Component to display all products that can be filtered by tags.
 */
@Component({
  selector: 'app-client-catalogue',
  templateUrl: './client-catalogue.component.html',
})
export class ClientCatalogueComponent implements OnInit, OnDestroy {
  @ViewChild(MatCheckbox)
  matSelectAll: MatCheckbox;
  /**
   * Data Provider for Product grid
   */
  data: GridDataProvider = this.clientService.getClients.bind(
    this.clientService
  );
  /**
   * Column definition for the "all product" grid
   */
  columnDefs: ColDef[] = [];

  /**
   * Grid options for "all clients"
   */
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.clientOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
    },
  };

  subscription: Subscription;
  gridApi: GridApi<Client>;

  constructor(
    protected clientService: ClientService,
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    protected configService: ConfigService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected permissionService: PermissionService,
  ) {
    this.columnDefs = this.generateColumnDefs();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  gridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.subscription = I18n.getColumns(this.translateService, event.api);
    this.gridFilterReset({ api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilterModel = api.getColumnFilterModel('closed');
    if (!closedFilterModel) {
      api.setColumnFilterModel('closed', { values: ['false'] })
        .then(() => api.onFilterChanged());
    }
  }

  private generateColumnDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    return [
      genLinkColumn({
        field: 'personNumber',
        headerName: I18n.getColName('personNumber'),
        link: (data: any) => `${EViewRoutes.clientOverview}${data.id}`,
      }),
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'fullName',
          headerName: I18n.getColName('fullName'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'fullName',
          },
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'firstName',
          headerName: I18n.getColName('firstName'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'firstName',
          },
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'lastName',
          headerName: I18n.getColName('lastName'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.clientService.getGridFilterOptions(data),
            autoCompleteField: 'lastName',
          },
        }),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      genCodeTableColumn({
        field: 'type',
        headerName: I18n.getColName('type'),
        observable: this.codeTableService.getCodeTable(ECodeTables.clientType),
      }),
      genCodeTableColumn({
        field: 'gender',
        headerName: I18n.getColName('gender'),
        observable: this.codeTableService.getCodeTable(ECodeTables.gender),
      }),
      genNumberColumn(
        'birthYear',
        I18n.getColName('birthyear'),
        this.globalService,
        (d: any) => (!d.value || d.value === 0 ? '' : d.value.toString())
      ),
      genBooleanColumn(
        'closed',
        I18n.getColName('CLOSED'),
        this.translateService
      ),
    ]
  }
}
