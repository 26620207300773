import {Component, Inject} from "@angular/core";
import {AppMessage} from "../../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {ModalData} from "../../../../models/modal.model";
import {EModalType} from "../../../../util/enum";
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-message-view-dialog',
  templateUrl: './app-message-view-dialog.component.html'
})
export class AppMessageViewDialogComponent {
  message: AppMessage;
  content: SafeHtml;

  constructor(
    protected sanitizer: DomSanitizer,
    protected translateService: TranslateService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { message: AppMessage; } }
  ) {
    this.message = data.data.message;
    this.content = sanitizer.bypassSecurityTrustHtml(this.message.content);
  }
}

export function showMessageViewDialog(
  message: AppMessage,
  translateService: TranslateService,
  modalService: ModalService,
  callback: () => void) {
  const modalData: ModalData = {
    type: EModalType.appMessagesEdit,
    title: message.title,
    data: {
      message: {
        ...message
      } as AppMessage,
    },
    cancelBtn: {
      label: translateService.instant('conf'),
    },
    component: AppMessageViewDialogComponent,
  };
  const ref = modalService.openDefaultDialog(
    modalData,
    undefined,
    true,
    false,
    DialogWidth.AUTO,
    DialogHeight.AUTO,
  );
  ref.afterClosed().subscribe(callback);
}
