import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, catchError, map, Observable, of} from 'rxjs';
import {Campaign, CampaignStatus, LicenseData, LicenseService, UserRoleData, UserService} from 'src/app/api/core';
import {EViewRoutes} from 'src/app/util/enum';
import {NotificationService} from './notification.service';
import {EProtectedActions} from '../util/protected-actions';
import {DataService} from "./data.service";

/**
 * Service for handling permission that control the display of certain frontend pages and actions.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private data: UserRoleData;

  private _licenseData: LicenseData;
  /**
   * User source
   */
  private userSource: BehaviorSubject<UserRoleData>;
  /**
   * Current user
   */
  user$: Observable<UserRoleData>;

  /*
   * Observable that emits true if user is logged in
   */
  private loggedInSource: BehaviorSubject<boolean>;
  logged$: Observable<boolean>;

  constructor(
    protected userService: UserService,
    protected licenseService: LicenseService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected dataService: DataService,
    private router: Router
  ) {
    this.userSource = new BehaviorSubject<UserRoleData>(null);
    this.user$ = this.userSource.asObservable();
    this.loggedInSource = new BehaviorSubject<boolean>(false);
    this.logged$ = this.loggedInSource.asObservable();
  }

  /**
   * Updates current user
   * @param user UserRoleData
   */
  updateUser(user: UserRoleData): void {
    this.userSource.next(user);
    this.data = user;
  }

  updateLoggedIn(loggedIn: boolean): void {
    this.loggedInSource.next(loggedIn);
  }

  getLicenseData(): Observable<LicenseData> {
    if (this._licenseData) {
      return of(this._licenseData)
    } else {
      return this.licenseService.getLicense().
        pipe(map((license: LicenseData) => {
          this._licenseData = license;
          return license;
      }));
    }
  }

  get userRoleData(): UserRoleData {
    return this.data;
  }

  get currentPermissions(): string[] {
    return (this.userRoleData?.roles || []);
  }

  get currentUsername(): string {
    return this.userRoleData?.username;
  }

  updateAndGetPermissions(): Observable<string[]> {
    if (this.data) {
      return of(this.data.roles as string[]);
    } else {
      return this.userService.getUserRoles()
        .pipe(map(
          (userRoleData: UserRoleData) => {
            this.updateUser(userRoleData);
            return this.data.roles;
          }
        ));
    }
  }

  checkRoutePermission(protectedActions: EProtectedActions[]): Observable<boolean> | boolean {
    if (this.userRoleData) {
      if (this.hasRouteAccess(protectedActions)) {
        return true;
      } else {
        this.noPermissionErrorForRoute();
        return false;
      }
    } else {
      return this.updateAndGetPermissions().pipe(
        map(() => {
          if (this.hasRouteAccess(protectedActions)) {
            return true;
          } else {
            this.noPermissionErrorForRoute();
            return false;
          }
        }),
        catchError((error) => {
          this.notificationService.handleError(error);
          return of(false);
        })
      );
    }
  }

  hasRouteAccess(protectedActions: EProtectedActions[]): boolean {
    if (!this.userRoleData) {
      return false;
    }
    if (protectedActions.filter(Boolean).length === 0) {
      return true;
    }
    const permissions = this.currentPermissions;
    return protectedActions.some(a => permissions.includes(a));
  }

  private noPermissionErrorForRoute(): void {
    this.notificationService.handleError(
      this.translateService.instant('missingPermissionForRouteError')
    );
    this.router.navigate([EViewRoutes.home]);
  }

  hasPermissionForCampaignOverview(campaign: Campaign): boolean {
    if (campaign.decentralized === true
      && [CampaignStatus.DRAFT, CampaignStatus.FROZEN, CampaignStatus.TERMINATED]
        .includes(campaign.status)
    ) {
      return this.hasAnyPermission(EProtectedActions.decentralizedCampaignEdit);
    }
    switch (campaign.status) {
      case CampaignStatus.LAUNCHED:
      case CampaignStatus.CLOSED:
        return this.hasAnyPermission(EProtectedActions.viewCampaigns) || campaign.decentralized;
      case CampaignStatus.DRAFT:
      case CampaignStatus.FROZEN:
      case CampaignStatus.TERMINATED:
        return this.hasAnyPermission(EProtectedActions.viewCampaignsAll);
      default:
        return false;
    }
  }

  hasAnyPermission(...protectedActions: EProtectedActions[]): boolean {
    if (!this.userRoleData) {
      return false;
    }
    const permissions = [...this.currentPermissions];
    return protectedActions.some(a => permissions.includes(a));
  }

  hasAllPermissions(...protectedActions: EProtectedActions[]): boolean {
    if (!this.userRoleData) {
      return false;
    }
    const permissions = [...this.currentPermissions];
    return protectedActions.filter(a => permissions.includes(a)).length === protectedActions.length;
  }
}
