<ng-container *ngIf="currentIcon.type === 'material'">
  <span
    matTooltip="{{ currentIcon.tooltip }}"
    class="material-symbols-outlined"
    >{{ currentIcon.name }}</span
  >
</ng-container>

<ng-container *ngIf="currentIcon.type === 'custom'">
  <img
    src="{{ currentIcon.customProperties.path }}"
    matTooltip="{{ currentIcon.tooltip }}"
    alt="{{ currentIcon.customProperties.altText }}"
  />
</ng-container>
