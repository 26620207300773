<div class="page-content-container">
  <app-tabs-picker-toolbar>
    <button mat-stroked-button (click)="onAdd('INTRO')">
      <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
      {{ 'addSalutationIntro' | translate }}
    </button>
    <button mat-stroked-button (click)="onAdd('OUTRO')">
      <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
      {{ 'addSalutationGreeting' | translate }}
    </button>
  </app-tabs-picker-toolbar>
  <div class="salutation-container">
    <app-sub-header [header]="'salutationIntros'">
    </app-sub-header>
    <app-grid
      tableId="salutations-intro-catalogue"
      [rowData]="intros"
      [columnDefs]="introColumns"
      [gridOptions]="introsGridOptions"
      card-body
    ></app-grid>
  </div>
  <div class="salutation-container">
    <app-sub-header [header]="'salutationGreetings'">
    </app-sub-header>
    <app-grid
      tableId="salutations-greeting-catalogue"
      [rowData]="greetings"
      [columnDefs]="greetingColumns"
      [gridOptions]="greetingsGridOptions"
      card-body
    ></app-grid>
  </div>
</div>
