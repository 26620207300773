import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { EViewRoutes, EViewTitles } from 'src/app/util/enum';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() breadcrumbs: string[] = [];
  @Input() isSmall = false;
  @Input() subHeader: string = '';

  constructor(
    public translateService: TranslateService,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  get viewTitles() {
    return EViewTitles;
  }

  onBreadcrumbClick(breadcrumb: string, isLast: boolean): void {
    if (!isLast) {
      this.globalService.navigate(EViewRoutes[breadcrumb]);
    }
  }
}
