import {Component, HostBinding} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {CodeTableService} from '../../../services/code-table.service';
import {ECodeTables} from '../../../util/enum';
import {GridViewedStatusInfo} from '../../../models/grid.model';
import {genIconColumn} from './icon.renderer';
import {ColDef, ICellRendererParams} from "ag-grid-community";
import {CodeTableEntry} from "../../../api/core";

export const VIEWED_ICONS = {
  yes: 'check',
  no: 'hourglass_empty',
  not_sent: undefined
};

@Component({
  template: `
    <div class="filter-item">
      <ng-container *ngIf="this.icon">
        <app-config-icon
          *ngIf="this.icon"
          [iconIdentifier]="this.icon"
        ></app-config-icon>
        <span [ngClass]="closedClass">{{ label }}</span>
      </ng-container>
      <ng-container *ngIf="!this.icon">
        <span [ngClass]="closedClass">{{ label }}</span>
      </ng-container>
    </div>
  `,
})
export class ViewedFilterCellRenderer implements AgRendererComponent {
  @HostBinding('class.grid__icon-button') theme = true;
  viewedValues: CodeTableEntry[] = [];
  icon: string;
  label: string;
  closedClass = {'color-warning': false};

  constructor(private codeTableService: CodeTableService) {
    this.codeTableService.getCodeTable(ECodeTables.viewedStatus).subscribe((data) => {
      this.viewedValues = data;
    });
  }

  agInit(params: ICellRendererParams): void {
    const item = this.viewedValues.find(d => d.id === params.value);
    if (item) {
      this.label = item.name;
      this.closedClass['color-warning'] = item.closed;
      if (VIEWED_ICONS[item.ident]) {
        this.icon = VIEWED_ICONS[item.ident];
      }
    } else {
      this.label = params.value;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genViewedColumn(info: GridViewedStatusInfo, data: () => any[]): ColDef {
  return {
    ...genIconColumn({
      field: info.field,
      headerName: info.headerName,
      icon: info.icon,
      tooltip: info.tooltip,
    }),
    filter: 'agSetColumnFilter',
    filterParams: {
      customPath: info.customPath,
      cellRenderer: ViewedFilterCellRenderer,
      values: info.values,
      comparator: (a, b) => a < b ? 1 : -1,
      valueFormatter: (params) => {
        const entry = data().find((d) => d.id === Number(params.value));
        return entry.name;
      },
    },
    suppressColumnsToolPanel: false,
  };
}

