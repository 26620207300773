/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SuitabilityState = 'OK' | 'CONFIRM' | 'RESTRICTED' | 'NOT_OK' | 'UNKNOWN' | 'ERROR' | 'PENDING';

export const SuitabilityState = {
    OK: 'OK' as SuitabilityState,
    CONFIRM: 'CONFIRM' as SuitabilityState,
    RESTRICTED: 'RESTRICTED' as SuitabilityState,
    NOTOK: 'NOT_OK' as SuitabilityState,
    UNKNOWN: 'UNKNOWN' as SuitabilityState,
    ERROR: 'ERROR' as SuitabilityState,
    PENDING: 'PENDING' as SuitabilityState
};

