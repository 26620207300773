<div class="first-col">
  <app-chart
    *ngIf="pieParticipated"
    [chartType]="chartTypes.homeParticipatedPie"
    [data]="pieParticipated"
  ></app-chart>
</div>
<div class="second-col">
  <app-distributor-summary
    [summary]="chartData"
    [showHeader]="false"
    [showViewed]="true"
    [showMonitoringValues]="true">
  </app-distributor-summary>
  <div class="link-row" (click)="goToAllClients()"
       *ngIf="protectedActions.distributor | protectedAction">
    <span>
      {{ 'viewAllClients' | translate }}
    </span>
  </div>
</div>
