import {Injectable} from "@angular/core";
import {IsCampaignEditablePipe} from "../shared/shared.pipe";
import {CampaignStatus} from "../api/core";

@Injectable({providedIn: 'root'})
export class PipeLogicService {

  isCampaignEditablePipe(status: CampaignStatus): boolean {
    return ![
      CampaignStatus.CLOSED,
      CampaignStatus.TERMINATED,
    ].includes(status);
  }
}
