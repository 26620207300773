<app-tabs-picker-toolbar>
  <button mat-stroked-button (click)="onRefresh()">
    <app-config-icon iconIdentifier="refresh"></app-config-icon>
    {{ 'refresh' | translate }}
  </button>
</app-tabs-picker-toolbar>
<app-grid
  tableId="monitoring-outgoing-messages"
  [columnDefs]="colDefs"
  [rowData]="rowData"
  [gridOptions]="gridOptions"
></app-grid>
