import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PortfolioCatalogueComponent } from './views/portfolio-catalogue/portfolio-catalogue.component';
import { PortfolioRoutingModule } from './portfolio-routing.module';
import { PortfolioOverviewComponent } from './views/portfolio-overview/portfolio-overview.component';
import { PortfolioOverviewCampaignsComponent } from './views/portfolio-overview/portfolio-overview-campaigns/portfolio-overview-campaigns.component';
import { PortfolioListComponent } from './views/portfolio-list/portfolio-list.component';

/**
 * Portfolio Module.
 * Contains all components related to products
 */
@NgModule({
  declarations: [
    PortfolioCatalogueComponent,
    PortfolioListComponent,
    PortfolioOverviewComponent,
    PortfolioOverviewCampaignsComponent,
  ],
  imports: [CommonModule, PortfolioRoutingModule, SharedModule],
    exports: [PortfolioListComponent, PortfolioCatalogueComponent],
})
export class PortfolioModule {}
