<form [formGroup]="productForm" class="modal-form aspark-container-padding">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'customDescription' | translate }}</mat-label>
      <textarea
        matInput
        formControlName="customDescription"
        type="text"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="row row-double tags" *ngIf="product">
    <div class="tags-wrapper">
      <mat-form-field>
        <mat-label>Tags</mat-label>
        <mat-chip-grid #chipList aria-label="Tags selection">
          <mat-chip-row
            *ngFor="let tag of tags.value"
            (removed)="removeTag(tag)"
          >
            {{ tag.name }}
            <button matChipRemove>
              <app-config-icon iconIdentifier="cancel"></app-config-icon>
            </button>
          </mat-chip-row>
          <input
            placeholder="{{ 'newTag...' | translate }}"
            #tagInput
            [formControl]="tagControl"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matAutocomplete]="autoTag"
            (matChipInputTokenEnd)="getOrCreateTag($event)"
          />
          <mat-autocomplete
            #autoTag="matAutocomplete"
            (optionSelected)="onTagSelect($event)"
          >
            <mat-option
              *ngFor="let option of filteredTags$ | async"
              [value]="option"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-chip-grid>
      </mat-form-field>
      <span class="body">{{ 'pressEnterNewTag' | translate }}</span>
    </div>
  </div>
</form>
