import {FieldDefinition} from '../api/core';
import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FROALA_LICENSE} from "../../main";

const MAX_IMAGE_SIZE = 200 * 1024;
const MAX_IMAGE_WIDTH = 1000;
const MAX_IMAGE_HEIGHT = 1000;
const IMAGE_QUALITY_START = 0.8;
const IMAGE_QUALITY_DELTA = 0.1;
const IMAGE_QUALITY_MIN = 0.5

type ImageData = {
  dataURL: string;
  imageType: string;
};

export function loadImageFile(imageFile: File): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve({
        dataURL: e.target.result.toString(),
        imageType: "image/jpeg",
      });
    };
    reader.readAsDataURL(imageFile);
  });
}

export async function resizeImage(src: ImageData): Promise<ImageData> {
  const img = await loadImage(src.dataURL);
  const scale = Math.min(
    MAX_IMAGE_WIDTH / img.width,
    MAX_IMAGE_HEIGHT / img.height,
    1
  );
  let quality = IMAGE_QUALITY_START;
  //console.log({quality, scale, width: img.width, height: img.height, imageSize: (src.dataURL.length/1024)});
  let scaled = await scaleImage(src, scale, quality);
  while (scaled.dataURL.length > MAX_IMAGE_SIZE && quality >= IMAGE_QUALITY_MIN) {
    quality -= IMAGE_QUALITY_DELTA;
    //console.log({quality, imageSize: (scaled.dataURL.length/1024)});
    scaled = await scaleImage(src, scale, quality);
  }
  return scaled;
}
function loadImage(dataURL: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.onload = () => {
      resolve(img);
    };
    img.src = dataURL;
  });
}
async function scaleImage(src: ImageData, scale: number, quality: number): Promise<ImageData> {
  const img = await loadImage(src.dataURL);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const width0 = img.width;
  const height0 = img.height;
  const width1 = width0 * scale;
  const height1 = height0 * scale;
  canvas.width = width1;
  canvas.height = height1;
  ctx.drawImage(img, 0, 0, width0, height0, 0, 0, width1, height1);
  return {
    ...src,
    dataURL: canvas.toDataURL(src.imageType, quality),
  };
}

@Injectable({providedIn: 'root'})
export class FroalaEditorService {

  // current froala defaults
  defaultFontSize = 14;
  defaultLineHeight = 1.6;
  defaultTextInputPadding = 20;
  defaultParagraphMargin = 14;

  editorOptions = {
    key: FROALA_LICENSE,
    attribution: false,
    // heightMin: 400,
    // heightMax: 401, // 400 adds a scrollbar
    toolbarSticky: false,
    quickInsertButtons: ['image',/* 'video', 'embedly',*/ 'table', 'ul', 'ol', 'hr'],
    toolbarButtons: [
      'fullscreen',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      '|',
      'fontFamily',
      'fontSize',
      'textColor',
      'inlineStyle',
      'paragraphStyle',
      '|',
      'paragraphFormat',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'quote',
      '-',
      'insertLink',
      'insertImage',
      // 'insertVideo',
      // 'insertFile',
      'insertTable',
      '|',
      // 'emoticons',
      'specialCharacters',
      'insertHR',
      'selectAll',
      'clearFormatting',
      // '|',
      // 'print',
      // 'help',
      // 'html',
      '|',
      'undo',
      'redo',
      // 'trackChanges',
      // 'markdown'
    ],
    imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
    events: {
      // Handle images as base 64 and avoid uploading it
      "image.beforeUpload": function (files) {
        const editor = this;
        if (files.length) {
          loadImageFile(files[0])
            .then(src => {
              return resizeImage(src);
            })
            .then(src => {
              //console.log({dataURL: src.dataURL.length / 1024});
              editor.image.insert(src.dataURL, null, null, editor.image.get());
            });
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      }
    }
  }

  constructor(private translateService: TranslateService) {
  }

  getEditorOptions(fieldDefinition: FieldDefinition): any {
    const lines = fieldDefinition.heightInLines;
    const label = this.translateService.currentLang === 'de' ? fieldDefinition.labelDe : fieldDefinition.labelEn;
    if (lines === 1) {
      return { // using default height
        ...this.editorOptions,
        multiLine: false, // disables adding new lines with enter
        placeholderText: label,
      }
    }
    const lineHeight = this.defaultFontSize * this.defaultLineHeight;
    const height = 2 * this.defaultTextInputPadding + 2 * this.defaultParagraphMargin + lineHeight * lines;
    return {
      ...this.editorOptions,
      heightMin: height,
      heightMax: height + 1, // height adds a scrollbar
      placeholderText: label,
    }
  }

}
