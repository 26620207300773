import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {TranslateService} from '@ngx-translate/core';
import * as Blockly from 'blockly';
import {Block} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import {fnaPositionIsinPercentage, fnaPositionIsinTotalValue} from '../../../../services/filter.names';

export class BlockFilterAsset extends BlockDefinition {
  constructor(
    ident: string,
    active: boolean,
    readonly percentage: boolean,
    readonly suffix: string,
    readonly outputCheckTypes: string[] = ['Boolean'],
    readonly exchangeRate: number = 1
  ) {
    super(`filter_field-asset-${percentage ? 'percentage' : 'value'}`, ident, active);
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const searchPlaceholder: string = translateService.instant('typeHere..');
    block.appendDummyInput('value')
      .appendField(
        [this.ident, 'ISIN']
          .map(s => translateService.instant(s))
          .join(': ')
      )
      .appendField(
        new Blockly.FieldTextInput(searchPlaceholder),
        'value'
      );
    block.appendDummyInput('operator')
      .appendField(
        new Blockly.FieldDropdown(BlockDefinition.comparators),
        'operator'
      )
    block.appendDummyInput('field_value')
      .appendField(
        this.fieldNumber(this.percentage ? 100 : 1000, BlockDefinition.numValidator),
        'field_value'
      )
      .appendField(this.suffix);

    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const target = block.getFieldValue('value');
    const value = +block.getFieldValue('field_value');
    const operator = block.getFieldValue('operator');
    return {
      result: {
        type: 'number',
        name: this.percentage ? fnaPositionIsinPercentage : fnaPositionIsinTotalValue,
        field: target,
        operator: BlockFilterAsset.literalComparators[operator],
        valueNum: value,
      },
      order: javascriptGenerator.ORDER_NONE,
    };
  }

  protected extras(translateService: TranslateService, color: string): Record<string, any> {
    const self = this;
    return {
      ...super.extras(translateService, color),
      ...super.extrasCurrency(self.exchangeRate,
          block => {
        const field = block.getField('field_value');
        return +(field?.getValue() || 0);
      },block => this.suffix),
    };
  }
}
