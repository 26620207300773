<div class="summary-header" *ngIf="showHeader">
  <div class="h1">{{ 'activities' | translate }}</div>
  <div class="h2">({{ 'last12Months' | translate }})</div>
</div>
<div class="summary-row" *ngIf="showReceived">
  <div class="summary-icon received" [matTooltip]="'receivedTooltip' | translate">
    <app-config-icon iconIdentifier="bar_chart"></app-config-icon>
  </div>
  <div class="summary-content">
    <div class="summary-title">
      {{ 'distributorSummaryReceived' | translate }}
    </div>
    <div class="summary-value">
      {{ summary.received | numberLocaleDeCH }}
    </div>
  </div>
</div>
<div class="summary-row" *ngIf="showViewed">
  <div class="summary-icon viewed" [matTooltip]="'viewedTooltip' | translate">
    <app-config-icon iconIdentifier="bar_chart"></app-config-icon>
  </div>
  <div class="summary-content">
    <div class="summary-title">
      {{ 'distributorSummaryViewed' | translate }}
    </div>
    <div class="summary-value">
      {{ summary.viewed | numberLocaleDeCH }}
    </div>
  </div>
</div>
<div class="summary-row" *ngIf="showMonitoringValues">
  <div class="summary-icon executed">
    <app-config-icon iconIdentifier="bar_chart"></app-config-icon>
  </div>
  <div class="summary-content">
    <div class="summary-title">
      {{ 'distributorSummaryExecuted' | translate }}
    </div>
    <div class="summary-value">
      {{ summary.executions | numberLocaleDeCH }}
    </div>
  </div>
</div>
<div class="summary-row" *ngIf="showMonitoringValues">
  <div class="summary-icon turnover">
    <app-config-icon iconIdentifier="bar_chart"></app-config-icon>
  </div>
  <div class="summary-content">
    <div class="summary-title">
      {{ this.labelBuilder.labelWithCurrency('distributorSummaryTurnOver') }}
    </div>
    <div class="summary-value">
      {{ summary.turnover | numberLocaleDeCH }}
    </div>
    <div class="summary-details" *ngIf="showTurnoverDetails">
      <div class="label">{{ 'distributorSummaryBuy'|translate }}</div>
      <div class="value">{{ summary.buy | numberLocaleDeCH }}</div>
    </div>
    <div class="summary-details" *ngIf="showTurnoverDetails">
      <div class="label">{{ 'distributorSummarySell'|translate }}</div>
      <div class="value">{{ summary.sell | numberLocaleDeCH }}</div>
    </div>
  </div>
</div>
<div class="summary-row">
  <a class="color-primary" [routerLink]="goBackRoute"
     *ngIf="goBackRoute && goBackLinkMsgKey && goBackRoute !== activeRoute">
    {{ goBackLinkMsgKey | translate }}
  </a>
</div>
