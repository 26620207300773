import {Injectable} from '@angular/core';
import {ColDef} from 'ag-grid-community';
import {CodeTableService} from './code-table.service';
import {
  genCodeTableColumn,
  genDateColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genTextColumn,
  genTranslatedTextColumn,
} from 'src/app/util/grid/grid-renderer.util';
import {ValueFormatterParams} from 'ag-grid-enterprise';

@Injectable({
  providedIn: 'root',
})
export class GridService {

  constructor(protected codeTableService: CodeTableService) {}

  getPortfolioPositionAutoGroupColumnDef(_this: any): ColDef {
    return {
      headerName: _this.translateService.instant('assetClass'),
      cellRendererParams: {
        suppressCount: true
      },
      suppressHeaderMenuButton: true,
    };
  }

  getPortfolioPositionColDef(_this: any): ColDef[] {
    return [
      {
        ...genCodeTableColumn({
          field: 'assetClass',
          headerName: _this.translateService.instant('assetClass')
        }),
        rowGroup: true,
        hide: true,
      },
      genCodeTableColumn({
        field: 'assetSubClass',
        headerName: _this.translateService.instant('assetSubClass')
      }),
      genCodeTableColumn({
        field: 'assetType',
        headerName: _this.translateService.instant('assetType'),
      }),
      genTextColumn('assetName', _this.translateService.instant('name'), null, {
        customPath: 'asset.name',
      }),
      genTextColumn('assetIsin', _this.translateService.instant('isin'), null, {
        customPath: 'asset.isin',
      }),
      {
        ...genTextColumn(
          'currency.ident',
          _this.translateService.instant('currency'),
          null,
          {
            customPath: 'currency.ident',
          }
        ),
      },
      {
        ...genCodeTableColumn({
          field: 'rating',
          headerName: _this.translateService.instant('rating'),
        }),
        hide: true,
      },
      {
        ...genDateColumn({
          field: 'nextCallDate',
          headerName: _this.translateService.instant('nextCallDate'),
          dateFormatter: (params: ValueFormatterParams) =>
            _this.globalService.dateToFrChLocale(params.value),
        }),
        hide: true,
      },
      {
        ...genDateColumn({
          field: 'maturityDate',
          headerName: _this.translateService.instant('maturityDate'),
          dateFormatter: (params: ValueFormatterParams) =>
            _this.globalService.dateToFrChLocale(params.value),
        }),
        hide: true,
      },
      {
        ...genNumberColumn(
          'quantity',
          _this.translateService.instant('quantity'),
          _this.globalService
        ),
        hide: true,
      },
      {
        ...genNumberColumn(
          'price',
          _this.translateService.instant('price'),
          _this.globalService
        ),
        hide: true,
      },
      {
        ...genNumberColumn(
          'totalValue',
          _this.labelBuilder.labelWithCurrency('totalValue'),
          _this.globalService
        ),
        aggFunc: 'sum',
      },
      {
        ...genPercentageNumberColumn(
          'percentage',
          _this.translateService.instant('percentage'),
          _this.globalService,
          true,
          false,
        ),
        floatingFilter: false, // percentage filter not working for in-memory row model
        aggFunc: 'sum',
      },
    ];
  }
}
