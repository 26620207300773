import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// openapi
import {
  ApiModule as ApiModuleCore,
  BASE_PATH as BASE_PATH_COR,
} from './api/core';
// util
import { environment } from 'src/environments/environment';
// feature modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CdkColumnDef } from '@angular/cdk/table';
import { ServerErrorInterceptor } from './util/server-error.interceptor';
import { GlobalErrorHandler } from './util/global-error-handler';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { ConfigLoaderService } from './services/config-loader.service';
import { translateLoader } from './util/MultiTranslateHttpLoader';
import { ToastNoficationComponent } from './shared/toast-notification/toast-notification.component';

/** Import froala plugins **/
import 'froala-editor/js/plugins.pkgd.min.js';
import {AngularMaterialModule} from "./angular-material/angular-material.module";
/**
 * Main Module.
 */
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true,
                allowedUrls: ['/api'],
            },
        }),
        // openapi
        ApiModuleCore,
        // libraries
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [HttpClient],
            },
        }),
        // feature modules
        AngularMaterialModule,
        SharedModule,
        CoreModule,
        ToastrModule.forRoot({
            toastComponent: ToastNoficationComponent,
            tapToDismiss: true,
            preventDuplicates: true,
            countDuplicates: true,
            newestOnTop: true,
            progressBar: true,
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-top-right',
        })], providers: [
        TranslateModule,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (translateService: TranslateService) => () => {
                translateService.setDefaultLang('en');
                return lastValueFrom(translateService.use('en'));
            },
            deps: [TranslateService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (configLoaderService: ConfigLoaderService) => () => lastValueFrom(configLoaderService.loadAuthConfiguration()),
            deps: [ConfigLoaderService],
            multi: true,
        },
        { provide: BASE_PATH_COR, useValue: environment.BASE_PATH_COR },
        CdkColumnDef,
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor() {}
}
