import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef,} from '@angular/material/dialog';
import {IModalData, ModalData} from 'src/app/models/modal.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EModalType} from 'src/app/util/enum';
import {TranslateService} from '@ngx-translate/core';

export enum DialogWidth {
  DEFAULT = 'calc(100vw - 112px)',
  AUTO = 'auto',
  CONFIRMATION = '40vw',
  HALF = '50vw',
}

export enum DialogHeight {
  DEFAULT = '90vh',
  AUTO = 'auto',
  HALF = '50vh',
}

/**
 * Service for handling all the functionality related to modal calls
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  /**
   * Function to open default dialog
   * @param modalData
   * @param autoFocus
   * @param disableClose
   * @param panelClass
   * @param width
   * @param height
   * @returns
   */
  openDefaultDialog(
    modalData: ModalData,
    panelClass?: string | string[],
    autoFocus = true,
    disableClose = false,
    width: DialogWidth = undefined,
    height: DialogHeight = undefined,
  ): MatDialogRef<any> {
    const posLeft = !width || width === DialogWidth.DEFAULT ? '96px' : undefined;
    return this.openModal(
      modalData,
      autoFocus,
      disableClose,
      panelClass,
      width,
      height,
      posLeft
    );
  }

  /**
   * Function to open confirmation dialog
   * @param modalData
   * @returns
   */
  openConfirmationDialog(modalData: ModalData): MatDialogRef<any> {
    return this.openModal(
      modalData,
      false,
      true,
      undefined,
      DialogWidth.CONFIRMATION,
      DialogHeight.AUTO
    );
  }

  /**
   * Function to open prompt text dialog (confirmation variation)
   * @param title
   * @param message
   * @param value
   * @param placeholder
   * @param okLabel
   * @param cancelLabel
   * @returns
   */
  openPromptText(
    title: string,
    message: string,
    value: string,
    placeholder?: string,
    okLabel: string = 'ok',
    cancelLabel: string = 'cancel'
  ): MatDialogRef<string> {
    const modalData: IModalData = {
      title,
      type: EModalType.promptText,
      data: {
        message,
        promptText: value,
        promptPlaceholder: placeholder,
      },
      component: null,
      submitBtn: {
        label: this.translateService.instant(okLabel),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          modalRef.close(modalRef.componentInstance.promptText);
        },
      },
      cancelBtn: {
        label: this.translateService.instant(cancelLabel),
      },
    };
    return this.openModal(
      modalData,
      false,
      false,
      undefined,
      DialogWidth.CONFIRMATION,
      DialogHeight.AUTO
    );
  }

  /**
   * Opens the modal using matdialog
   */
  private openModal(
    modalData: ModalData,
    autoFocus = true,
    disableClose = false,
    panelClass?: string | string[],
    width: DialogWidth = DialogWidth.DEFAULT,
    height: DialogHeight = DialogHeight.DEFAULT,
    posLeft?: string,
    posTop?: string,
    maxHeight: DialogHeight = DialogHeight.DEFAULT
  ): MatDialogRef<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = modalData;
    dialogConfig.panelClass = panelClass;

    dialogConfig.disableClose = disableClose;
    dialogConfig.width = width;
    dialogConfig.maxWidth = width;
    dialogConfig.height = height;
    dialogConfig.maxHeight = maxHeight;
    dialogConfig.position = { top: posTop, left: posLeft };
    if (autoFocus) {
      dialogConfig.autoFocus = 'first-tabbable';
    }

    return this.dialog.open(ModalComponent, dialogConfig);
  }
}
