import { Component, Input, OnChanges } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { Icon } from 'src/app/models/icons_config.model';
import { ICONS_CONFIG } from 'src/assets/config/icons_config';

@Component({
  selector: 'app-config-icon',
  templateUrl: './config-icon.component.html',
})
export class ConfigIconComponent implements OnChanges {
  /**
   * Identifier of icon in icon config
   */
  @Input() iconIdentifier: string;
  /**
   * Tooltip to overwrite default tooltip of icon in icon config
   */
  @Input() tooltip?: string;
  /**
   * Whether the icon should be inlined, automatically sizing the icon to match
   * the font size of the element the icon is contained in.
   */
  @Input() inline = true;
  /**
   * Whether the outlined font set should be used ord not
   */
  @Input() outlined = false;
  /**
   * Icon to display
   */
  public currentIcon: Icon;
  /**
   * Icon to display as default if input iconIdentifier is not found in config
   */
  public iconNotAvailable = {
    name: 'image_not_supported',
    type: 'material',
    tooltip: 'Icon not found',
  } as Icon;

  constructor(protected configService: ConfigService) {}

  ngOnChanges() {
    if (ICONS_CONFIG) {
      this.currentIcon = { ...ICONS_CONFIG[this.iconIdentifier] };
      if (!this.currentIcon) {
        this.currentIcon = this.iconNotAvailable;
      } else if (this.tooltip) {
        this.currentIcon.tooltip = this.tooltip;
      }
    }
  }
}
