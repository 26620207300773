import {Component, OnInit} from '@angular/core';
import {Employee} from 'src/app/api/core';
import {ActivatedRoute} from '@angular/router';
import {EMPLOYEE_TABS} from 'src/app/util/tab.constants';
import {Tab} from 'src/app/models/tabs.model';

/**
 * Component for employee overview.
 */
@Component({
  selector: 'app-employee-overview',
  templateUrl: './employee-overview.component.html',
})
export class EmployeeOverviewComponent implements OnInit {

  employee: Employee;
  tabs: Tab[] = EMPLOYEE_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;

  constructor(protected activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe((data) => {
      this.employee = data.employee;
    });
  }

  ngOnInit(): void {}

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }
}
