  <app-tabs-picker-toolbar>
    <button mat-stroked-button (click)="onAdd()">
      <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
      {{ 'bucketAdd' | translate }}
    </button>
  </app-tabs-picker-toolbar>
  <app-grid
    #grid
    tableId="bucket-list"
    [data]="dataProvider"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
  ></app-grid>
