import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { CampaignAction } from 'src/app/api/core';
import { TranslateService } from '@ngx-translate/core';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import { GridDialogContentInfo } from 'src/app/models/grid.model';
import {ColDef, ICellRendererParams} from "ag-grid-community";

@Component({
  template: `
    <div class="grid-custom-cell">
      <app-config-icon
        [iconIdentifier]="icon"
        class="edit-cell-icon"
      ></app-config-icon>
      <span>{{ label }}</span>
    </div>
  `,
})
export class DialogContentRenderer implements AgRendererComponent {
  @HostBinding('class.grid-cell-dialog-content') theme = true;
  label: string;
  data: CampaignAction;
  icon: string;
  tooltip: string;
  callback: (data: any, action: string) => void;

  constructor(protected translateService: TranslateService) {}

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    const dialogContentInfo: GridDialogContentInfo =
      params.colDef.cellRendererParams.actionsParams.dialogContentInfo;
    this.icon = dialogContentInfo.iconGetter ? dialogContentInfo.iconGetter(this.data) : 'edit_m';
    const labelVal = dialogContentInfo.labelValue(params.data);
    if (labelVal) {
      this.label = dialogContentInfo.translateLabel
        ? this.translateService.instant(labelVal)
        : labelVal;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genDialogContentColumn(info: GridDialogContentInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: DialogContentRenderer,
    sortable: false,
    filter: info?.customFilter,
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        dialogContentInfo: info,
      },
    },
  };
}
