import {Component, Input} from "@angular/core";
import {Content, ContentAttachment} from "../../api/core";
import {DocumentTransferService} from "../../services/document-transfer.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-content-attachments',
  templateUrl: './content-attachments.component.html'
})
export class ContentAttachmentsComponent {
  @Input()
  content: Content;
  @Input()
  attachments: ContentAttachment[];
  @Input()
  isEditable: boolean;

  constructor(
    readonly documentTransferService: DocumentTransferService,
  ) {
  }

  onAdd(e: Event) {
    if (!this.isEditable) return;
    const input = e.target as HTMLInputElement;
    const file = input.files[0];
    this.documentTransferService.uploadAttachment(file.name,file)
      .pipe(finalize(() => (input.value = '')))
      .subscribe((info) => {
        this.attachments.push({
          filename: file.name,
          source: info.documentId,
        });
      });
  }

  onDownload(e: Event, a: ContentAttachment) {
    e.preventDefault();
    this.documentTransferService.downloadAttachment(a);
  }
  onDelete(a: ContentAttachment) {
    if (!this.isEditable) return;
    const index = this.attachments.findIndex(ca => a.id == ca.id)
    if (index >=0) {
      this.attachments.splice(index, 1);
    }
  }
}
