import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

/**
 * Service for handling all functionaility to trigger notifications in the application
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  /**
   * Handle info type notification
   * @param info Info notification message
   */
  handleInfo(info: string): void {
    this.toastr.info(info, this.translateService.instant('notificationInfo'));
  }

  /**
   * Handle success type notification
   * @param success Success notification message
   */
  handleSuccess(success: string): void {
    this.toastr.success(
      success,
      this.translateService.instant('notificationSuccess')
    );
  }

  /**
   * Handle warning notification message
   * @param warning Warning notification message
   */
  handleWarning(warning: string): void {
    this.toastr.warning(
      warning,
      this.translateService.instant('notificationWarning')
    );
  }

  /**
   * Handle error notification message
   * @param error Error notification message
   */
  handleError(error: string | Error | HttpErrorResponse): void {
    let message;
    if (typeof error === 'string') {
      message = error;
    } else if (
      error instanceof HttpErrorResponse &&
      error.error &&
      error.error.error
    ) {
      message = error.error.error; // See ErrorMessage.yaml
    } else {
      message = error.message;
    }
    this.toastr.error(
      message,
      this.translateService.instant('notificationError')
    );
  }
}
