import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ColDef, GridOptions, RowDoubleClickedEvent, ValueFormatterParams} from 'ag-grid-community';
import { StoredError } from 'src/app/models/error.model';
import { ErrorService } from 'src/app/services/error.service';
import { GlobalService } from 'src/app/services/global.service';
import { genIconButtonColumn } from 'src/app/shared/grid/cell-renderers/icon-button.renderer';
import { EViewRoutes } from 'src/app/util/enum';

/**
 * Component that shows error catalogue
 */
@Component({
  selector: 'app-error-catalogue',
  templateUrl: './error-catalogue.component.html',
})
export class ErrorCatalogueComponent implements OnInit {
  errorColumns: ColDef[] = [
    {
      ...genIconButtonColumn({
        getRouterUrl: (data: any) =>
          `${EViewRoutes.errorOverview}${data.timestamp}`,
        tooltip: this.translateService.instant('viewError'),
        icon: 'pageview',
        iconOutlined: true,
      }),
      sortable: false,
      resizable: true,
    },
    {
      field: 'message',
      headerName: this.translateService.instant('message'),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      field: 'stack',
      headerName: this.translateService.instant('stack'),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
    {
      field: 'timestamp',
      headerName: this.translateService.instant('timestamp'),
      valueFormatter: (params) => this.dateFormatter(params),
      suppressHeaderMenuButton: true,
      resizable: true,
    },
  ];
  errorGridOptions: GridOptions = {
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.errorOverview}${event.data.timestamp}`
      ),
  };
  rowData: StoredError[];

  constructor(
    protected translateService: TranslateService,
    protected errorService: ErrorService,
    protected datePipe: DatePipe,
    protected globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.rowData = this.errorService.getErrorList();
  }

  private dateFormatter(params: ValueFormatterParams): string {
    return this.datePipe.transform(params.value, 'HH:mm:ss - dd.MM.yyyy');
  }
}
