import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import {Block} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import * as Blockly from 'blockly';

export class BlockFilterExposure extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-exposure',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    block
      .appendDummyInput('field_value')
      .appendField(translateService.instant(this.ident) + ':')
      .appendField(new Blockly.FieldDropdown(this.fields[0].entries), 'target')
      .appendField(translateService.instant('exposure'))
      .appendField(
        new Blockly.FieldDropdown(BlockDefinition.comparators),
        'operator'
      )
      .appendField(
        this.fieldNumber(100, BlockDefinition.numValidator),
        'field_value'
      )
      .appendField('%');
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const target = block.getFieldValue('target');
    const value = +block.getFieldValue('field_value') / 100.0;
    const operator = block.getFieldValue('operator');

    return {
      result: {
        type: 'number',
        name: `${this.ident}.Value`,
        field: target,
        operator: BlockFilterExposure.literalComparators[operator],
        valueNum: value,
      },
      order: javascriptGenerator.ORDER_NONE,
    };
  }
}
