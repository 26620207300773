import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  GridFilterConfig, GridFilterItemComponent, GridFilterItemComponentFilter,
  GridFilterModelItem,
} from 'src/app/models/grid.model';
import { KeyValue } from 'src/app/models/key-value.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-grid-filter-item-numeric',
  templateUrl: './grid-filter-item-numeric.component.html',
})
export class GridFilterItemNumericComponent implements OnInit, GridFilterItemComponent {
  @Input() filterConfig: GridFilterConfig;
  @Input() filterModel: GridFilterModelItem;
  @Output() applyFilter = new EventEmitter<void>();

  typeOptions: KeyValue[] = [
    /* Contains is not supported by the backend for type numeric
    {
      key: this.translateService.instant('contains'),
      value: 'contains',
    },*/
    /* notContains is not supported by the backend for type numeric
    {
      key: this.translateService.instant('notContains'),
      value: 'notContains',
    },*/
    {
      key: this.translateService.instant('equals'),
      value: 'equals',
    },
    {
      key: this.translateService.instant('notEqual'),
      value: 'notEqual',
    },
    {
      key: this.translateService.instant('lessThan'),
      value: 'lessThan',
    },
    {
      key: this.translateService.instant('lessThanOrEqual'),
      value: 'lessThanOrEqual',
    },
    {
      key: this.translateService.instant('greaterThan'),
      value: 'greaterThan',
    },
    {
      key: this.translateService.instant('greaterThanOrEqual'),
      value: 'greaterThanOrEqual',
    },
    {
      key: this.translateService.instant('inRange'),
      value: 'inRange',
    },
  ];
  numericFilterForm = this.fb.group({
    type: [this.typeOptions[0].value],
    filter: [''],
    filterTo: [''],
  });

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    if (this.filterModel) {
      this.numericFilterForm.patchValue({
        type: this.filterModel.type,
        filter: this.filterModel.filter,
        filterTo: this.filterModel.filterTo,
      });
    }
  }

  getModel(): GridFilterItemComponentFilter | undefined {
    const filter = this.numericFilterForm.value.filter;
    const filterTo = this.numericFilterForm.value.filterTo;
    const type = this.numericFilterForm.value.type;
    let result = {config: this.filterConfig, model: undefined, form: this.numericFilterForm};
    if (filter) {
      if (type === 'inRange' && filterTo) {
        result = {...result, model: {filterType: 'number', type, filter, filterTo}}
      } else {
        result = {...result, model: {filterType: 'text', type, filter}}
      }
    }
    return result;
  }

  onApplyFilter() {
    this.applyFilter.next();
  }
}
