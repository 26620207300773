<div class="filter-details-org-positions-container">
  <div class="filter-org-positions-details">
    <app-grid
      #grid
      tableId="filter-details-org-positions-grid"
      class="flex-2"
      [columnDefs]="columnDefsTree"
      [gridOptions]="gridOptionsTree"
      [rowData]="orgPositions"
      [pagination]="false"
      disableSidebar="true"
    ></app-grid>
    <div class="buttons">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!canAdd"
        (click)="addWithChildren()"
        [matTooltip]="
          translateService.instant('org_add_selected_with_children')
        "
      >
        <app-config-icon iconIdentifier="double_arrow"></app-config-icon>
      </button>
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!canAdd"
        (click)="addOnly()"
        [matTooltip]="translateService.instant('org_add_selected_only')"
      >
        <app-config-icon iconIdentifier="chevron_right"></app-config-icon>
      </button>
    </div>
    <div class="flex-1">
      <app-grid
        #gridPosition
        tableId="filter-details-users-grid"
        [columnDefs]="positionColumnDefs"
        [gridOptions]="gridOptionsPosition"
        [rowData]="positions"
        [pagination]="false"
        disableSidebar="true"
        [gridFilterEnabled]="false"
      ></app-grid>
    </div>
  </div>
</div>
