<div *ngIf="product" class="page-content-container">
  <div class="aspark-container-padding">
    <app-product-details
      *ngIf="product"
      [product]="product"
    >
      <app-config-icon
        iconIdentifier="edit_m"
        class="color-primary"
        [tooltip]="'editProductDetails' | translate"
        (click)="editProductDetails()"
        *ngIf="protectedActions.editProduct | protectedAction"
      ></app-config-icon>
    </app-product-details>
  </div>
</div>
