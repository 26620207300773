<div *ngIf="loading" class="loading-container">Loading</div>
<div *ngIf="showWarning" class="warning-message">
  Warning: {{ 'warningNoSender' | translate }}
</div>
<ng-container *ngIf="!loading && rowData">
  <app-grid
    tableId="campaign-action-sender-selection-grid"
    [columnDefs]="columns"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    card-body
  >
  </app-grid>
</ng-container>
