import {Component, DoCheck, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TranslateService} from '@ngx-translate/core';
import {of, Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {CampaignService, StoryService} from 'src/app/api/core';
import {FilterConfig} from 'src/app/models/filter.model';
import {ModalData, ModalSubComponent} from 'src/app/models/modal.model';
import {ConfigService} from 'src/app/services/config.service';
import {DataService} from 'src/app/services/data.service';
import {FilterUtilsService, isFilterCategoryActivePipe} from 'src/app/services/filter-utils.service';
import {NotificationService} from 'src/app/services/notification.service';
import {EFilterCategories} from 'src/app/util/enum';
import {ModalComponent} from '../modal/modal.component';
import {FilterDetailsAdvancedComponent} from './filter-details-advanced/filter-details-advanced.component';
import {IKeyValue, KeyValue} from "../../models/key-value.model";
import {UiFilterConfigService} from "../../services/ui-filter-config.service";

// array of sorted categories as they are presented in the component, used for reference tab selection
const SORTED_TAB_CATEGORIES = [
  EFilterCategories.client,
  EFilterCategories.portfolio,
  EFilterCategories.position,
  EFilterCategories.assets,
  EFilterCategories.assetClass,
  EFilterCategories.currency,
  EFilterCategories.region,
  EFilterCategories.sector,
  EFilterCategories.orgPosition,
  EFilterCategories.advanced,
];
/**
 * Filter Details Component.
 * Component for filter details. Serves as parent component for all categories of filters
 */
@Component({
  selector: 'app-filter-details',
  templateUrl: './filter-details.component.html',
})
export class FilterDetailsComponent
  implements OnInit, OnDestroy, DoCheck, ModalSubComponent
{
  @ViewChild('advFilter') advFilter: FilterDetailsAdvancedComponent;
  /**
   * Filter form with current filter config
   */
  filterForm: FilterConfig;
  /**
   * Subscription to filter source. Keep reference to be able to unsubscribe
   */
  filterSubscription: Subscription;
  /**
   * Index of active tab
   */
  activeTab: number;

  formHasErrors: boolean;
  assetsFormHasErrors: boolean;

  readOnly = false;

  tabCategories = [...SORTED_TAB_CATEGORIES];

  constructor(
    protected filterService: FilterUtilsService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected configService: ConfigService,
    protected translateService: TranslateService,
    protected storyService: StoryService,
    protected campaignService: CampaignService,
    protected filterConfigService: UiFilterConfigService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData
  ) {}

  ngOnInit(): void {
    this.filterSubscription = this.dataService.filter$.subscribe((filter) =>
      this.handleFilter(filter)
    );
    this.readOnly = this.modalData.data.readOnly;
    this.tabCategories = this.tabCategories.filter((category) => isFilterCategoryActivePipe(this.filterConfigService, category));
    this.activeTab = this.tabCategories.indexOf(
      this.modalData.data.category as EFilterCategories
    ) || 0;
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  ngDoCheck(): void {
    this.formHasErrors = this.hasFormErrors();
  }

  /**
   * Provide filter categories to html
   */
  get filterCategories() {
    return EFilterCategories;
  }

  /**
   * Handles incoming filter
   * @param filter Provided filter
   */
  handleFilter(filter: FilterConfig): void {
    if (filter) {
      this.handleIncomingFilterConfig(filter);
    } else {
      this.filterForm = this.filterService.getInitialFilterForm();
    }
    // required timeout as it takes a few millis to load the HTML component that will have blockly injected.
    setTimeout(() => this.initializeAdvancedTab());
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.initializeAdvancedTab();
  }

  private initializeAdvancedTab() {
    if (
      this.activeTab ===
      this.tabCategories.indexOf(this.filterCategories.advanced)
    ) {
      this.advFilter.ngOnInit();
    }
  }

  hasFormErrors(): boolean {

    this.assetsFormHasErrors =
      this.filterForm.assetsInclude?.children.some((filter) => filter.formHasErrors)
      ||
      this.filterForm.assetsExclude?.children.some((filter) => filter.formHasErrors);
    const hasErrors =
      this.filterForm.portfolio?.formHasErrors ||
      this.filterForm.position?.formHasErrors ||
      this.assetsFormHasErrors;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = hasErrors;
    return hasErrors;
  }

  modalAction(): void {
    this.advFilter?.onFilterSubmit();
    this.replaceEmptyMinMaxAssetFilters()
    const submitForm = this.removeEmptyFilter({ ...this.filterForm });
    let channel;
    if (this.modalData.data?.story || this.modalData.data?.campaign) {
      channel = this.filterService.upsertFilter(
        this.modalData.data?.story,
        this.modalData.data?.campaign,
        this.filterForm
      );
    } else {
      channel = of(submitForm);
    }
    channel.subscribe({
      next: (filter) => {
        this.dataService.updateFilter(filter);
        // update filter on story or campaign if available
        if (
          this.modalData.data?.story &&
          this.modalData.data.story.filter !== filter.id
        ) {
          this.modalData.data.story.filter = filter.id;
          this.storyService
            .updateStory(this.modalData.data.story)
            .pipe(
              first(),
              finalize(() =>
                this.dialogRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe(() =>
              this.notificationService.handleSuccess(
                this.translateService.instant('filterUpdatedSuccess')
              )
            );
        } else if (
          this.modalData.data?.campaign &&
          this.modalData.data.campaign.filter !== filter.id
        ) {
          this.modalData.data.campaign.filter = filter.id;
          this.campaignService
            .updateCampaign(this.modalData.data.campaign)
            .pipe(
              first(),
              finalize(() =>
                this.dialogRef.componentInstance.resetToolbarActionButtons()
              )
            )
            .subscribe(() =>
              this.notificationService.handleSuccess(
                this.translateService.instant('filterUpdatedSuccess')
              )
            );
        } else {
          this.notificationService.handleSuccess(
            this.translateService.instant('filterUpdatedSuccess')
          );
          this.dialogRef.componentInstance.resetToolbarActionButtons();
        }
        this.dialogRef.close();
      },
      error: (error) => {
        this.dialogRef.componentInstance.resetToolbarActionButtons();
        throw error;
      },
    });
  }

  private handleIncomingFilterConfig(config: FilterConfig): void {
    this.filterForm = config;
    this.initializeNullFilters();
  }

  /**
   * Helper function to initialize all filter values that get null instead of their initial value
   * (e.g. arrays should be initialized with [])
   * Note: we should decide if this is to be done in the backend or frontend (currently mixed)
   */
  private initializeNullFilters(): void {
    this.filterForm.assetsInclude = this.filterForm.assetsInclude ?? {operator: 'mor', children: []};
    this.filterForm.assetsExclude = this.filterForm.assetsExclude ?? {operator: 'mor', children: []};
    this.filterForm.assetClass = this.filterForm.assetClass ?? {operator: 'mor', children: []};
    this.filterForm.client.ageRange = this.filterForm.client.ageRange ?? {
      from: null,
      to: null,
    };
    this.filterForm.client.gender = this.filterForm.client.gender ?? [];
    this.filterForm.client.domiciles = this.filterForm.client.domiciles ?? [];
    this.filterForm.intermediaries = this.filterForm.intermediaries ?? {
      excludeEAM: false,
      excludeEWA: false,
    };
    this.filterForm.intermediaries.excludeEAM = this.filterForm.intermediaries.excludeEAM ?? true;
    this.filterForm.intermediaries.excludeEWA = this.filterForm.intermediaries.excludeEWA ?? true;
    this.filterForm.currency = this.filterForm.currency ?? {operator: 'mor', children: []};
    this.filterForm.gics = this.filterForm.gics ?? {operator: 'mor', children: []};
    this.filterForm.portfolio.advisoryType =
      this.filterForm.portfolio.advisoryType ?? [];
    this.filterForm.portfolio.bu = this.filterForm.portfolio.bu ?? [];
    this.filterForm.portfolio.businessDivision =
      this.filterForm.portfolio.businessDivision ?? [];
    this.filterForm.portfolio.referenceCurrency =
      this.filterForm.portfolio.referenceCurrency ?? [];
    this.filterForm.portfolio.manager = this.filterForm.portfolio.manager ?? [];
    this.filterForm.portfolio.mifid = this.filterForm.portfolio.mifid ?? [];
    this.filterForm.portfolio.fidleg = this.filterForm.portfolio.fidleg ?? [];
    this.filterForm.portfolio.model = this.filterForm.portfolio.model ?? [];
    this.filterForm.portfolio.portfolioType =
      this.filterForm.portfolio.portfolioType ?? [];
    this.filterForm.portfolio.returnRange = this.filterForm.portfolio
      .returnRange ?? { min: null, max: null };
    this.filterForm.portfolio.riskRange = this.filterForm.portfolio
      .riskRange ?? { min: null, max: null };
    this.filterForm.portfolio.rmLocation =
      this.filterForm.portfolio.rmLocation ?? [];
    this.filterForm.portfolio.rmMarket =
      this.filterForm.portfolio.rmMarket ?? [];
    this.filterForm.portfolio.serviceCenter =
      this.filterForm.portfolio.serviceCenter ?? [];
    this.filterForm.portfolio.sustainabilityProfile =
      this.filterForm.portfolio.sustainabilityProfile ?? [];
    this.filterForm.portfolio.valueRange = this.filterForm.portfolio
      .valueRange ?? { min: null, max: null };
    this.filterForm.portfolio.liquidityRange = this.filterForm.portfolio
      .liquidityRange ?? { min: null, max: null };
    this.filterForm.position.assetType =
      this.filterForm.position.assetType ?? [];
    this.filterForm.position.excludedIds =
      this.filterForm.position.excludedIds ?? [];
    this.filterForm.position.ids = this.filterForm.position.ids ?? [];
    this.filterForm.position.nextCallDate = this.filterForm.position
      .nextCallDate ?? { from: null, to: null, expires: null };
    this.filterForm.position.ranking = this.filterForm.position.ranking ?? [];
    this.filterForm.position.ratingMoody =
      this.filterForm.position.ratingMoody ?? [];
    this.filterForm.position.ratingSP = this.filterForm.position.ratingSP ?? [];
    this.filterForm.position.sustainabilityRating =
      this.filterForm.position.sustainabilityRating ?? [];
     this.filterForm.position.productRatingApac =
      this.filterForm.position.productRatingApac ?? [];
    this.filterForm.position.productRatingMe =
      this.filterForm.position.productRatingMe ?? [];
    this.filterForm.position.productInvestmentHorizon =
      this.filterForm.position.productInvestmentHorizon ?? [];
    this.filterForm.position.issuerName = this.filterForm.position.issuerName ?? null;
    this.filterForm.region = this.filterForm.region ?? {operator: 'mor', children: []};
    this.filterForm.position.assetClass =
      this.filterForm.position.assetClass ?? [];
    this.filterForm.position.assetSubClass =
      this.filterForm.position.assetSubClass ?? [];
    this.filterForm.position.ratingSourceLGT =
      this.filterForm.position.ratingSourceLGT ?? [];
    this.filterForm.orgPosition ??= { positions: [] };
  }

  /**
   * Remove empty or inactive filters for elements that show add button
   * @param filterConfig Current filter config
   */
  private removeEmptyFilter(filterConfig: FilterConfig): FilterConfig {
    filterConfig.assetsInclude.children = filterConfig.assetsInclude.children.filter(
      (filter) =>
        filter.key != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    filterConfig.assetsExclude.children = filterConfig.assetsExclude.children.filter(
      (filter) =>
        filter.key != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    filterConfig.assetClass.children = filterConfig.assetClass.children.filter(
      (filter) =>
        filter.weight != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    filterConfig.currency.children = filterConfig.currency.children.filter(
      (filter) =>
        filter.weight != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    filterConfig.region.children = filterConfig.region.children.filter(
      (filter) =>
        filter.weight != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    filterConfig.gics.children = filterConfig.gics.children.filter(
      (filter) =>
        filter.weight != null ||
        filter.range?.max != null ||
        filter.range?.min != null
    );
    if (filterConfig.position.excludedIds == null) {
      filterConfig.position.excludedIds = [];
    }
    if (filterConfig.orgPosition == null) {
      filterConfig.orgPosition = { positions: [] };
    }
    return filterConfig;
  }

  /**
   * Replaces asset filters with empty min and max values with hasExposure filter
   */
  replaceEmptyMinMaxAssetFilters(): void {
    this.filterForm.assetsInclude.children?.forEach((filter) => {
      if (!filter.hasExposure && (filter.range?.min == null && filter.range?.max == null)) {
        filter.isValue = false;
        filter.hasExposure = true;
      }
    });

    this.filterForm.assetsExclude.children?.forEach((filter) => {
      if (!filter.hasExposure && (filter.range?.min == null && filter.range?.max == null)) {
        filter.isValue = false;
        filter.hasExposure = true;
      }
    });
  }
}

/**
 * Helper function to filter options based on closed flag
 * @param entries base entries
 * @param values values that are selected and should be added to the final list
 */
export function filteredOptions(entries: any[], values: string[]): IKeyValue[] {
  if (entries.find( x => x !== undefined)) {
    return entries.map(
      (entry) => {
        if (!entry.closed || values.includes(entry.ident)) {
          return new KeyValue(entry.ident, entry.name)
        }
        return null;
      }
    ).filter(d => d);
  }
}
