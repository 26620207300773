import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Intermediary, IntermediaryService, Portfolio, UserInfo} from '../../../../api/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../../services/global.service';
import {ApplyColumnStateParams, ColDef, GridOptions, GridReadyEvent, RowDoubleClickedEvent} from 'ag-grid-community';
import {EViewRoutes} from '../../../../util/enum';
import {
  genCodeTableColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genRiskStateColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel
} from '../../../../util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {LabelBuilder} from "../../../../util/label-builder";
import {I18n} from "../../../../services/i18n.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-intermediary-overview-portfolios',
  templateUrl: './intermediary-overview-portfolios.component.html',
})
export class IntermediaryOverviewPortfoliosComponent implements OnInit, OnDestroy {
  @Input() intermediary: Intermediary;

  @Input() fromDistributor = false;

  portfolioAdvisors: UserInfo[];
  portfolioRelationshipManagers: UserInfo[];

  portfolios: Portfolio[] = [];
  colDefs: ColDef[] = [];
  getColDefs(): ColDef[] {
    let distributorFields = ['number', 'portfolioType', 'bpName', 'bpNumber'];
    let cols = [
      genLinkColumn({
        field: 'number',
        headerName: this.fromDistributor ?
          this.translateService.instant('portfolioNumber') : this.translateService.instant('number'),
        link: (data: any) => `${this.portfolioOverviewRoute}${data.id}`,
        filterParamsInfo: {
          autoCompleteParams: () => this.portfolios.map(d => d.number)
        },
      }),
      genCodeTableColumn({
        field: 'portfolioType',
        headerName: this.translateService.instant('portfolioType'),
      }),
      genCodeTableColumn({
        field: 'advisoryType',
        headerName: this.translateService.instant('advisoryType'),
      }),
      genCodeTableColumn({
        field: 'strategy',
        headerName: this.translateService.instant('strategy'),
      }),
      genTextColumn(
        'referenceCurrency.ident',
        this.translateService.instant('referenceCurrency')
      ),
      genNumberColumn(
        'portfolioValue',
        this.labelBuilder.labelWithCurrency('portfolioValue'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'risk',
        this.translateService.instant('risk'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'riskSpreadMin',
        this.translateService.instant('riskSpreadMin'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'riskSpreadMax',
        this.translateService.instant('riskSpreadMax'),
        this.globalService
      ),
      genRiskStateColumn({
        field: 'riskState',
        headerName: this.translateService.instant('riskState'),
      }),
      {
        ...genTextColumn('bpName', this.translateService.instant('bpName')),
        floatingFilter: false,
        sortable: false,
      },
      genTextColumnWithAutoCompleteFilter({
        field: 'bpNumber',
        headerName: this.translateService.instant('bpNumber'),
        autoCompleteParams: () => this.portfolios.map(d => d.bpNumber)
      }),
      genCodeTableColumn({
        field: 'preferredLanguage',
        headerName: this.translateService.instant('preferredLanguage'),
      }),
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchPortfolioRelationshipManagers.bind(this),
          () => this.portfolioRelationshipManagers
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
      },
      {
        ...genUserEnumColumn(
          'advisor.username',
          this.translateService.instant('advisor'),
          this.fetchPortfolioAdvisors.bind(this),
          () => this.portfolioAdvisors
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.advisor),
      },
    ];
    if (this.fromDistributor) {
      cols = cols.filter((col) => distributorFields.includes(col.field));
    }
    return cols;
  }

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${this.portfolioOverviewRoute}${event.data.id}`
      ),
  };

  portfolioOverviewRoute: EViewRoutes;
  subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private globalService: GlobalService,
    private intermediaryService: IntermediaryService,
    private labelBuilder: LabelBuilder,
  ) {}

  ngOnInit(): void {
    this.portfolioOverviewRoute = this.fromDistributor ?
      EViewRoutes.distributorPortfolioOverview : EViewRoutes.portfolioOverview;
    this.colDefs = this.getColDefs();
    if (this.fromDistributor) {
      this.intermediaryService.getDistributorIntermediaryPortfolios(this.intermediary.id).subscribe(
        portfolios => {
          this.portfolios = portfolios;
        });
    } else {
      this.intermediaryService.getIntermediaryPortfolios(this.intermediary.id).subscribe(
        portfolios => {
          this.portfolios = portfolios;
        });
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private gridReady(event: GridReadyEvent): void {
    // set default sort if nothing is set
    if (event.api.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'bpNumber',
            sort: 'asc',
          },
        ],
      };
      event.api.applyColumnState(columnState);
    }
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
  }

  private fetchPortfolioAdvisors(params: any) {
    this.intermediaryService.getPortfolioAdvisors(this.intermediary.id)
      .subscribe((data) => {
        this.portfolioAdvisors = data;
        params.success(data.map((d) => d.username));
    });
  }

  private fetchPortfolioRelationshipManagers(params: any) {
    this.intermediaryService.getPortfolioRelationshipManagers(this.intermediary.id)
      .subscribe((data) => {
        this.portfolioRelationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }
}
