<form [formGroup]="formGroup" *ngIf="formGroup" class="modal-form">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'ident' | translate}}</mat-label>
      <input matInput formControlName="ident" type="text" />
      <mat-error *ngIf="formGroup.get('ident').hasError('pattern')">
        {{ 'fieldDefinitionIdentValidCharacters' | translate}}
      </mat-error>
      <mat-error *ngIf="formGroup.get('ident').hasError('identNotUnique')">
        {{ 'fieldDefinitionIdentNotUnique' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'labelEn' | translate}}</mat-label>
      <input matInput formControlName="labelEn" type="text" />
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'labelDe' | translate}}</mat-label>
      <input matInput formControlName="labelDe" type="text" />
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'description' | translate}}</mat-label>
      <input matInput formControlName="description" type="text" />
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'mandatory' | translate}}</mat-label>
      <mat-select formControlName="mandatory">
        <mat-option [value]="true">{{ 'yes' | translate}}</mat-option>
        <mat-option [value]="false">{{ 'no' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'editorType' | translate}}</mat-label>
      <mat-select formControlName="editorType">
        <mat-option *ngFor="let option of editorTypes" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'customEditable' | translate}}</mat-label>
      <mat-select formControlName="customEditable">
        <mat-option [value]="true">{{ 'yes' | translate}}</mat-option>
        <mat-option [value]="false">{{ 'no' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <div class="row-container">
      <ng-container *ngIf="formGroup.get('editorType').value == FieldDefinitionType.RICHTEXT">
        <div class="center-content">
          <app-config-icon
            iconIdentifier="info"
            [tooltip]="'fieldDefinitionMaxLength' | translate"
          ></app-config-icon>
        </div>
      </ng-container>
      <mat-form-field>
        <mat-label>{{ 'maximumCharacters' | translate }}</mat-label>
        <input matInput formControlName="maxLength" type="number"/>
      </mat-form-field>
      <div class="center-content">
        <mat-slide-toggle formControlName="maxLengthUnlimited" color="primary">
          {{ 'unrestricted' | translate }}
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="row row-single">
    <div class="row-container">
      <ng-container *ngIf="formGroup.get('editorType').value == FieldDefinitionType.RICHTEXT">
        <div class="center-content">
          <app-config-icon
            iconIdentifier="info"
            [tooltip]="'fieldDefinitionHeightInLines' | translate"
          ></app-config-icon>
        </div>
      </ng-container>
      <mat-form-field>
        <mat-label>{{ 'heightInLinesDialog' | translate }}</mat-label>
        <input matInput formControlName="heightInLines" type="number"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row row-double">
    <mat-form-field>
      <mat-label>{{'showInGeneralContent' | translate}}</mat-label>
      <mat-select formControlName="showInGeneral">
        <mat-option [value]="true">{{ 'yes' | translate}}</mat-option>
        <mat-option [value]="false">{{ 'no' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'showInCustomContent' | translate}}</mat-label>
      <mat-select formControlName="showInCustom">
        <mat-option [value]="true">{{ 'yes' | translate}}</mat-option>
        <mat-option [value]="false">{{ 'no' | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
