import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpNavigationItem } from 'src/app/models/help.model';

/**
 * Help Navigatzion Component.
 * Component for dynamic display of help vavigation
 */
@Component({
  selector: 'app-help-navigation',
  templateUrl: './help-navigation.component.html',
})
export class HelpNavigationComponent implements OnInit {
  @Input() navigationItems: HelpNavigationItem[];

  currentLanguage: string;
  toggles: boolean[];

  constructor(protected readonly translateService: TranslateService) {
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit() {
    this.toggles = Array(this.navigationItems.length).fill(false);
  }

  onItemClick(anchor: string): void {
    document.getElementById(anchor).scrollIntoView();
  }

  toggle(index: number): void {
    this.toggles[index] = !this.toggles[index];
  }
}
