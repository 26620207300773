import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { INestedMenuItem, NestedMenuItem } from 'src/app/models/menu.model';
import { NotificationService } from 'src/app/services/notification.service';
import { CodeTableService } from 'src/app/services/code-table.service';
import { ECodeTables, EFilterCategories } from 'src/app/util/enum';
import {CompositeList, WeightRange} from 'src/app/models/filter.model';
import {CodeTableEntry} from "../../../api/core";

/**
 * Sector (gics) Filter Details Component.
 * Component for filter details for sector (gics) category.
 */
@Component({
  selector: 'app-filter-details-sector',
  templateUrl: './filter-details-sector.component.html',
})
export class FilterDetailsSectorComponent implements OnInit {
  /**
   * Component input that references gics range filter in filter config
   */
  @Input() filterForm: CompositeList<WeightRange>;

  @Input() readOnly = false;
  /**
   * ALl possible gcis ooptions
   */
  gics: CodeTableEntry[];
  /**
   * Nested gics correctly structured
   */
  nestedGics: INestedMenuItem[] = [];
  /**
   * Still available gics options
   */
  availableGics: INestedMenuItem[] = [];

  get filterCategories() {
    return EFilterCategories;
  }

  constructor(
    protected globalService: GlobalService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  ngOnInit(): void {
    this.codeTableService.getCodeTable(ECodeTables.gics).subscribe((data) => {
      this.gics = data;
      this.nestedGics = this.createNestedGics(data);
      this.availableGics = this.getAvailableGics();
    });
  }
  createNestedGics(gics: CodeTableEntry[]): any {
    const tmpGics = gics.map((g) => ({
      key: g.ident,
      label: g.name,
      children: [],
      parentIdent: g.additionalData?.parentIdent,
    }));
    const root = [];
    // Cache found parent index
    const map = {};
    tmpGics.forEach((node) => {
      // No parentId means top level
      if (!node.parentIdent)
        {return root.push(
          new NestedMenuItem(node.key, node.label, node.children)
        );}

      // Insert node as child of parent in flat array
      let parentIndex = map[node.parentIdent];
      if (typeof parentIndex !== 'number') {
        parentIndex = tmpGics.findIndex((el) => el.key === node.parentIdent);
        map[node.parentIdent] = parentIndex;
      }

      if (!tmpGics[parentIndex].children) {
        return (tmpGics[parentIndex].children = [node]);
      }

      tmpGics[parentIndex].children.push(
        new NestedMenuItem(node.key, node.label, node.children)
      );
    });
    return root;
  }

  /**
   * Get available gics options (those which are not added to filter yet)
   */
  private getAvailableGics(): INestedMenuItem[] {
    return this.nestedGics
      .map((gics) => this.globalService.deepCopyNestedMenuItems(gics))
      .filter((gics) => this.filterGics(gics));
  }

  /**
   * Filter function of gics menu object according to level
   * @param gics GICS Menu object
   */
  private filterGics(gics: INestedMenuItem): boolean {
    if (gics.children.length > 0) {
      gics.children = gics.children.filter((item) => this.filterGics(item));
      return (
        gics.children.length > 0 ||
        !this.filterForm.children.find((filter) => filter.key === gics.key)
      );
    } else {
      return !this.filterForm.children.find((filter) => filter.key === gics.key);
    }
  }

  /**
   * Adds filter with provided key
   * @param gics Key of gics filter
   */
  addFilter(gics: INestedMenuItem): void {
    const index = this.filterForm.children.findIndex(
      (filter) => filter.key === gics.key
    );
    // if filter not yet added, add it to filterForm
    if (index === -1) {
      this.filterForm.children.push({
        key: gics.key,
        range: {
          max: null,
          min: null,
        },
        weight: null,
      });
      // remove added key from available keys
      this.availableGics = this.getAvailableGics();
    }
  }

  /**
   * Deletes filter with provided key
   * @param gics Key of gics filter
   */
  deleteFilter(gics: string): void {
    const index = this.filterForm.children.findIndex((filter) => filter.key === gics);
    this.filterForm.children.splice(index, 1);
    this.availableGics = this.getAvailableGics();
  }

  /**
   * Get label based on key
   * @param key Filter key
   */
  getLabel(key: string): string {
    return this.gics.find((g) => g.ident === key).name;
  }

  changeOperator($event: string) {
    $event === 'and' ? this.filterForm.operator = 'and' : this.filterForm.operator = 'mor';
  }
}
