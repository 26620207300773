import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { Params } from '@angular/router';
import {commonFilterParams, commonParams} from "../../../util/grid/grid-renderer.util";
import {GridFilterParamsInfo} from "../../../models/grid.model";
import {ColDef} from "ag-grid-community";

/**
 * Router Link cell renderer.
 */
@Component({
  template: `
    <a class="color-primary" [routerLink]="[link]" [queryParams]="queryParams">
      <app-config-icon
        *ngIf="icon && iconAlign != 'right'"
        class="{{ iconClass }}"
        style="{padding-right: 1rem;}"
        [iconIdentifier]="this.icon"
        [tooltip]="iconTooltip"
        [outlined]="iconOutlined"
      ></app-config-icon>
      <span>{{value}}</span>
      <app-config-icon
        *ngIf="icon && iconAlign == 'right'"
        class="{{ iconClass }}"
        style="{padding-left: 1rem;}"
        [iconIdentifier]="this.icon"
        [tooltip]="iconTooltip"
        [outlined]="iconOutlined"
      ></app-config-icon>
    </a>
  `,
  styles: ['a {text-decoration: none; display: flex; flex-direction: row; align-items: center;}'],
})
export class LinkCellRenderer implements AgRendererComponent {
  data: any;
  link: string;
  value: string;
  queryParams: Params | null;
  icon: string;
  iconClass: string | undefined;
  iconAlign: 'left' | 'right';
  iconTooltip: string | undefined;
  iconOutlined: boolean;

  agInit(params: any): void {
    this.data = params.data;
    this.link = params.link(this.data);
    this.value = params.value;
    this.icon = (typeof params.icon === 'function') ? params.icon(this.data) : params.icon;
    this.iconAlign = params.iconAlign || 'left';
    this.iconClass = params.iconClass;
    this.iconTooltip = (typeof params.iconTooltip === 'function') ? params.iconTooltip(this.data) : params.iconTooltip;
    this.iconOutlined = !!params.iconOutlined;

    if (params.getQueryParams) {
      this.queryParams = params.getQueryParams(this.data);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  protected readonly undefined = undefined;
}

export interface LinkColumnParams {
  field: string,
  headerName: string,
  link: (data: any) => string,
  getQueryParams?: (data: any) => Params,
  valueFormatterFunc?: (data: any) => string,
  filterParamsInfo?: GridFilterParamsInfo,
  icon?: string | undefined | ((data: any) => (string | undefined)),
  iconAlign?: 'left' | 'right' | undefined,
  iconClass?: string | undefined,
  iconTooltip?: string | undefined  | ((data: any) => (string | undefined)),
  iconOutlined?: boolean | undefined,
}

/**
 * Generates a link column.
 * @param linkParams
 */
export function genLinkColumn(linkParams: LinkColumnParams): ColDef {
  const filterParamsInfo = linkParams.filterParamsInfo || {};
  const field = linkParams.field;
  const headerName = linkParams.headerName;
  const link = linkParams.link;
  const getQueryParams = linkParams.getQueryParams;
  const {icon, iconAlign , iconClass, iconTooltip, iconOutlined} = linkParams;
  return {
    field,
    headerName,
    ...commonParams(field, headerName, linkParams.valueFormatterFunc),
    filterParams: {
      ...commonFilterParams(filterParamsInfo),
      // TODO this will be removed again in a later stage, currently added to disable certaing filter shortcuts
      textFormatter: filterParamsInfo?.isMultiSelect ? undefined : (v: string) => v.replace(/,/g, '').toLowerCase(),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRenderer: LinkCellRenderer,
    cellRendererParams: {
      getQueryParams,
      field,
      link,
      icon,
      iconAlign ,
      iconClass,
      iconTooltip,
      iconOutlined
    },
  };
}
