import {EHelpContentType, EViewRoutes} from 'src/app/util/enum';

export const HELP_ERRORS_CONFIG = {
  id: 6,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Errors List',
        en: 'Errors List',
      },
      anchor: 'howto-language',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'When an application error occurs',
        en: 'When an application error occurs',
      },
      anchor: 'errors-list',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The following list shows the errors that have occurred in the system and allows the retrieving of additional details on the error.',
        en: 'The following list shows the errors that have occurred in the system and allows the retrieving of additional details on the error.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/7b.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Make sure to double click on the error to switch to the detail view (2). Please take a screenshot of the occurring errors. Send the screenshot together with your documentation on the steps you performed to your system administrator.',
        en: 'Make sure to double click on the error to switch to the detail view (2). Please take a screenshot of the occurring errors. Send the screenshot together with your documentation on the steps you performed to your system administrator.',
      },
    },
  ],
  view: EViewRoutes.errors,
};
