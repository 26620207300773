import {
  ApplyColumnStateParams,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowSelectedEvent,
  ValueFormatterParams
} from "ag-grid-community";
import {AppMessageRead, AppMessageService} from "../../../../api/core";
import {Component, Inject} from "@angular/core";
import {genDateColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {ModalData} from "../../../../models/modal.model";
import {EModalType} from "../../../../util/enum";

@Component({
  selector: 'app-message-list-dialog',
  templateUrl: './app-message-list-dialog.component.html'
})
export class AppMessageListDialogComponent {
  contentTitle: string;
  content: SafeHtml;
  colDefs: ColDef[] = [
    {
      ...genTextColumn(
        'title',
        this.translateService.instant('title'),
        null,
        {
          customPath: 'message.title'
        }
      ),
      floatingFilter: false,
      flex: 3,
    },
    {
      ...genDateColumn({
        field: 'readAt',
        headerName: this.translateService.instant('readOn'),
        dateFormatter: (params: ValueFormatterParams) =>
          [
            this.globalService.dateToFrChLocale(params.data.readAt),
            this.globalService.timeToFrChLocale(params.data.readAt)
          ].join(' '),
        filterParamsInfo: {
          customPath: 'read'
        }
      }),
      floatingFilter: false,
    }
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    rowSelection: 'single',
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowSelected: (event: RowSelectedEvent<AppMessageRead>) => {
      this.onRowSelected(event);
    },
    onFirstDataRendered: (event: GridReadyEvent<AppMessageRead>) => this.onFirstDataRendered(event),
    getRowId: (params) => params.data.id,
    onGridReady: (event: GridReadyEvent) => this.gridReady(event),
  };
  dataProvider: GridDataProvider = this.appMessageService.getMyOldMessages.bind(this.appMessageService);

  constructor(
    readonly sanitizer: DomSanitizer,
    readonly translateService: TranslateService,
    readonly globalService: GlobalService,
    readonly appMessageService: AppMessageService,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { } }
  ) {
  }

  onRowSelected(event: RowSelectedEvent<AppMessageRead>) {
    this.selectRow(event.node?.isSelected() ? event.data : undefined);
  }

  onFirstDataRendered(event: GridReadyEvent<AppMessageRead>) {
    const renderedNodes = event.api.getRenderedNodes();
    const selectedNodes = event.api.getSelectedNodes();
    if (renderedNodes.length && selectedNodes.length === 0) {
      const firstRow = renderedNodes[0];
      event.api.setNodesSelected({
        nodes: [firstRow],
        newValue: true,
      });
    }
  }

  private gridReady(event: GridReadyEvent) {
    // set default sort if nothing is set
    if (event.api.getColumnState().findIndex((c) => c.sort) === -1) {
      this.setDefaultSorting(event.api);
    }
  }

  private setDefaultSorting(api: GridApi) {
    const columnState: ApplyColumnStateParams = {
      state: [
        {
          colId: 'readAt',
          sort: 'desc',
        },
      ],
    };
    api.applyColumnState(columnState);
  }

  selectRow(row?: AppMessageRead) {
    if (row) {
      setTimeout(() => {
        this.contentTitle = row.title;
        this.content = this.sanitizer.bypassSecurityTrustHtml(row.content);
      }, 100);
    } else {
      this.contentTitle = undefined;
      this.content = undefined;
    }
  }
}

export function showMessageListDialog(
  translateService: TranslateService,
  modalService: ModalService) {
  const modalData: ModalData = {
    type: EModalType.appMessagesList,
    title: EModalType.appMessagesList,
    data: {},
    cancelBtn: undefined,
    submitBtn: undefined,
    component: AppMessageListDialogComponent,
  };
  modalService.openDefaultDialog(
    modalData,
    undefined,
    false,
    false,
    DialogWidth.DEFAULT,
    DialogHeight.DEFAULT,
  );
}
