<div class="content-wrapper body" *ngIf="client">
  <div class="detail-items-wrapper">
    <div class="detail-items">
      <app-overview-detail-item
        labelRef="fullName"
        [value]="client?.fullName"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="lastName"
        [value]="client?.lastName"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="firstName"
        [value]="client?.firstName"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="birthyear"
        [value]="client?.birthYear"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="gender"
        [value]="client.gender.name"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="type"
        [value]="client.type.name"
      ></app-overview-detail-item>
      <app-overview-detail-item
        labelRef="personNumber"
        [value]="client.personNumber"
      ></app-overview-detail-item>
    </div>
  </div>
</div>
