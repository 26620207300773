/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActionOperationType = 'EXECUTE' | 'REVERT' | 'NO_ACTION' | 'DELETE';

export const ActionOperationType = {
    EXECUTE: 'EXECUTE' as ActionOperationType,
    REVERT: 'REVERT' as ActionOperationType,
    NOACTION: 'NO_ACTION' as ActionOperationType,
    DELETE: 'DELETE' as ActionOperationType
};

