import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import {ECodeTables, EFilterSubType, EFilterType, EGroupFilterSelectStatus,} from 'src/app/util/enum';
import {IFilterMetaData, IGroupFilterSelect,} from 'src/app/models/filter-meta-data.model';
import {ISliderOptions} from 'src/app/models/slider-options.model';
import {IKeyValue, KeyValue} from 'src/app/models/key-value.model';
import {FormControl} from '@angular/forms';
import {NotificationService} from 'src/app/services/notification.service';
import {debounceTime, switchMap} from 'rxjs/operators';
import {forkJoin, of, Subscription} from 'rxjs';
import {INestedMenuItem} from 'src/app/models/menu.model';
import {
  AdvisorsService,
  AssetService,
  PortfolioService,
  Product,
  RelationshipManagerService,
  User,
} from 'src/app/api/core';
import {DatePipe} from '@angular/common';
import {CodeTableService} from 'src/app/services/code-table.service';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {LabelBuilder} from "../../util/label-builder";
import {MAX_DATE, MIN_DATE} from "../../util/date-formatter";

/**
 * Filter Component.
 * Component for all types of filters
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnInit, OnDestroy {
  /**
   * Component input to set filter meta data
   */
  @Input() metaData: IFilterMetaData;
  /**
   * Component input to set filter value
   */
  @Input() value: any;

  @Input()
  readOnly = false;
  /**
   * Component output to signal change of value
   */
  @Output() valueChange = new EventEmitter();
  /**
   * One or more filters have errors
   */
  @Output() hasErrors: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @angular-slider/ngx-slider range slider options
   */
  rangeSlider: ISliderOptions;
  /**
   * Local value for filter with select field
   */
  selectFilter: any;
  /**
   * Local value for filter with input field
   */
  inputFilter: any;
  /**
   * Local value of min value for range filter
   */
  private _rangeMin: any;
  get rangeMin(): any {
    return this._rangeMin;
  }
  set rangeMin(value: any) {
    this.checkRangeMinMaxError(value, this._rangeMax);
    this._rangeMin = value;
  }
  /**
   * Local value of max value for range filter
   */
  private _rangeMax: any;
  get rangeMax(): any {
    return this._rangeMax;
  }
  set rangeMax(value: any) {
    this.checkRangeMinMaxError(this._rangeMin, value);
    this._rangeMax = value;
  }
  /**
   * Options for filter with select field
   */
  selectOptions: IKeyValue[];
  /**
   * Options for filter with select field
   */
  filteredOptions: IKeyValue[];
  /**
   * Options for filter with nested select field
   */
  nestedSelectOptions: INestedMenuItem[];
  /**
   * Tooltip in read only view for filter with select field
   */
  tooltip: string = '';

  filteredChips: IKeyValue[];
  selectedChips: IKeyValue[] = [];
  continentSelect: IGroupFilterSelect = {};
  @ViewChild('chipInput', { read: ElementRef, static: false })
  chipInput: ElementRef<HTMLInputElement>;
  chipControl: FormControl = new FormControl({value: null, disabled: this.readOnly});
  placeholder = "";
  // helpers for checks and visibility
  isFromTo: boolean;
  isOnlyMinMax: boolean;
  isMinMaxPercentage: boolean;
  @ViewChild('autoComplete', { read: ElementRef, static: false })
  autoInput: ElementRef<HTMLInputElement>;
  autoList: IKeyValue[];
  autoControl: FormControl = new FormControl(null);
  minMaxRangeError = false;
  private isCountryMultiSelect: boolean;

  private chipSubscription: Subscription;
  private autoSubscription: Subscription;

  minDate = MIN_DATE;
  maxDate = MAX_DATE;
  minDateFromRange = MIN_DATE;
  maxDateFromRange = MAX_DATE;

  constructor(
    protected advisorService: AdvisorsService,
    protected assetService: AssetService,
    protected relationshipManagerService: RelationshipManagerService,
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    protected labelBuilder: LabelBuilder,
    protected datePipe: DatePipe,
    protected portfolioService: PortfolioService,
  ) {}

  ngOnInit(): void {
    this.setHelpers();
    let filter: any;
    this.placeholder = this.metaData.placeholder || "";
    switch (this.metaData.type) {
      case EFilterType.dateRange:
        this.rangeMin = this.value?.from;
        this.rangeMax = this.value?.to;
        break;
      case EFilterType.date:
      case EFilterType.text:
      case EFilterType.toggle:
      case EFilterType.amount:
        this.inputFilter = this.value;
        break;
      case EFilterType.percentage:
        this.inputFilter = this.value ? this.value * 100 : this.value;
        break;
      case EFilterType.select:
      case EFilterType.multiSelectDropdown:
        this.selectFilter = this.value;
        this.selectOptions = this.metaData.options;
        this.filteredOptions = this.selectOptions;
        this.tooltip = (this.readOnly && this.metaData.type === EFilterType.multiSelectDropdown) ?
          this.selectOptions
          .filter(o => this.value.includes(o.key))
          .map(o => o.value).join(', ') : '';
        this.selectSearchOnKey('');
        break;
      case EFilterType.countryMultiSelect:
        this.selectFilter = this.value;
        this.nestedSelectOptions = this.metaData.nestedOptions;
        this.nestedSelectOptions.forEach((continent) =>
          this.setContinentSelectStatus(continent)
        );
        this.tooltip = (this.readOnly) ?
          [...this.nestedSelectOptions
            .filter(continent => this.continentSelect[continent.key] === EGroupFilterSelectStatus.all)
            .map(continent => continent.label),
          ...this.nestedSelectOptions
            .filter(continent => this.continentSelect[continent.key] === EGroupFilterSelectStatus.some)
            .map(continent => continent.children
              .filter(country => this.value.includes(country.key))
              .map(country => country.label).join(', '))
            ].join(', ') : '';
        break;
      case EFilterType.range:
        // TODO check if percentage minmax range still works for all
        filter = this.value as { max: number; min: number };
        if (filter.min || filter.min === 0) {
          this.rangeMin = filter.min;
        } else {
          this.rangeMin = null;
        }
        if (filter.max || filter.max === 0) {
          this.rangeMax = filter.max;
        } else {
          this.rangeMax = null;
        }
        break;
      case EFilterType.rangeSlider:
        if (this.isMinMax()) {
          filter = this.value as { max: number; min: number };
          this.rangeSlider = this.isMinMaxPercentage
            ? this.createRangeSliderConfig( {
              minValue: 0,
              maxValue: 100,
              min: filter.min * 100 || 0,
              max: filter.max * 100 || 100,
            })
            : this.createRangeSliderConfig({
              minValue: 0,
              maxValue: 100,
              min: filter.min || 0,
              max: filter.max || 100,
            });
        } else {
          filter = this.value as { from: number; to: number };
          this.rangeSlider = this.createRangeSliderConfig(
            {
              minValue: 0,
              maxValue: 100,
              min: filter.from || 0,
              max: filter.to || 100
            });
        }
        break;
      case EFilterType.chips:
        filter = this.value;
        if (filter && filter.length > 0) {
          if (this.metaData.subType === EFilterSubType.chipsProduct) {
            forkJoin(
              filter.map((isin: string) =>
                this.assetService.getAssetByIsin(isin)
              )
            ).subscribe({
              next: (results: Product[]) =>
                results.forEach((a: Product) =>
                  this.selectedChips.push(
                    new KeyValue(a.isin, `${a.name} (${a.isin})`)
                  )
                ),
            });
          } else if (
            this.metaData.subType === EFilterSubType.chipsRelationshipManager
          ) {
            this.codeTableService
              .getCodeTable(ECodeTables.relManager)
              .subscribe((relationshipManagers) => {
                filter.forEach((username) => {
                  const manager = relationshipManagers.find(
                    (rm) => rm.ident === username
                  );
                  if (manager) {
                    this.selectedChips.push(
                      new KeyValue(manager.ident, manager.name)
                    );
                  }
                });
              });
          } else if (this.metaData.subType === EFilterSubType.chipsAdvisor) {
            this.codeTableService
              .getCodeTable(ECodeTables.relAdvisor)
              .subscribe((advisors) => {
                filter.forEach((username) => {
                  const advisor = advisors.find((rm) => rm.ident === username);
                  if (advisor) {
                    this.selectedChips.push(
                      new KeyValue(advisor.ident, advisor.name)
                    );
                  }
                });
              });
          } else {
            // something went wrong
          }
        }
        if (this.metaData.subType === EFilterSubType.chipsProduct) {
          this.chipSubscription = this.chipControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value
                    ? this.assetService.searchAssets(value, 'name,isin')
                    : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe(
              (products: Product[]) =>
                (this.filteredChips = products
                  .filter(
                    (a) =>
                      !this.selectedChips.find((chip) => chip.key === a.isin)
                  )
                  .map((a) => new KeyValue(a.isin, `${a.name} (${a.isin})`)))
            );
        } else if (
          this.metaData.subType === EFilterSubType.chipsRelationshipManager
        ) {
          this.chipSubscription = this.chipControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value
                    ? this.relationshipManagerService.getRelationshipManagers(
                        value
                      )
                    : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe(
              (relationshipManagers: User[]) =>
                (this.filteredChips = relationshipManagers
                  .filter(
                    (rm) =>
                      !this.selectedChips.find(
                        (chip) => chip.key === rm.username
                      )
                  )
                  .map((rm) => new KeyValue(rm.username, rm.fullname)))
            );
        } else if (this.metaData.subType === EFilterSubType.chipsAdvisor) {
          this.chipSubscription = this.chipControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value ? this.advisorService.getAdvisors(value) : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe(
              (advisors: User[]) =>
                (this.filteredChips = advisors
                  .filter(
                    (rm) =>
                      !this.selectedChips.find(
                        (chip) => chip.key === rm.username
                      )
                  )
                  .map((rm) => new KeyValue(rm.username, rm.fullname)))
            );
        }
        break;
      case EFilterType.autocomplete:
        filter = this.value;
        if (this.metaData.subType === EFilterSubType.autoProducts) {
          this.autoSubscription = this.autoControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value
                    ? this.assetService.searchAssets(value, 'name,isin')
                    : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe((products: Product[]) => {
              this.autoList = products.map(
                (a) => new KeyValue(a.isin, `${a.name} (${a.isin})`)
              );
            });
        } else if (this.metaData.subType == EFilterSubType.autoRmDepartment) {
          if (!this.autoControl.value && this.value) this.autoControl.setValue(this.value.key);
          this.autoSubscription = this.autoControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value
                    ? this.portfolioService.getPortfolioRmDepartments(value)
                    : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe((rmDepartments: string[]) => {
              this.autoList = rmDepartments.map(
                (rmDepartment) => new KeyValue(rmDepartment, rmDepartment)
              );
            });
        } else if(this.metaData.subType == EFilterSubType.autoIssuer) {
          if (!this.autoControl.value && this.value) this.autoControl.setValue(this.value.key);
          this.autoSubscription = this.autoControl.valueChanges
            .pipe(
              debounceTime(300),
              switchMap(
                (value) =>
                  value
                    ? this.portfolioService.getPortfolioIssuers(value)
                    : of([]) // in FF there's a duplicate fetch with null value, this avoids an error
              )
            )
            .subscribe((issuers: string[]) => {
              this.autoList = issuers.map((issuer) => new KeyValue(issuer, issuer));
            });
        }
        break;
      default:
        break;
    }
  }

  clearCurrentValue(): void {
    switch (this.metaData.type) {
      case EFilterType.autocomplete:
        this.autoControl.setValue(null);
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.chipSubscription) {
      this.chipSubscription.unsubscribe();
    }
    if (this.autoSubscription) {
      this.autoSubscription.unsubscribe();
    }
  }

  get filterTypes() {
    return EFilterType;
  }

  get filterSelectStatus() {
    return EGroupFilterSelectStatus;
  }

  get filterOptions(): Record<string, any> {
    const options: Record<string, any> = {};
    (this.metaData?.options || []).forEach((itm: IKeyValue) => {
      options[itm.key] = itm.value;
    });
    return options;
  }

  onClearSuffix() {
    this.autoControl.setValue(null);
    this.value = null;
    this.valueChange.emit(this.value);
  }

  /**
   * Change handler for date filters.
   * Needs to transform to backend comaptible format from MatDatePicker format
   */
  onFilterDateInputChange(): void {
    this.value = this.datePipe.transform(this.inputFilter, 'yyyy-MM-dd');
    this.valueChange.emit(this.value);
  }

  /**
   * Change handler for range date min filters.
   * Needs to transform to backend comaptible format from MatDatePicker format
   */
  onFilterDateRangeMinChange(): void {
    this.value.from = this.datePipe.transform(this.rangeMin, 'yyyy-MM-dd');
    this.minDateFromRange = new Date(this._rangeMin);
    this.minDateFromRange.setDate(this._rangeMin.getDate() + 1);
    this.checkRangeMinMaxError();
  }

  /**
   * Change handler for range date max filters.
   * Needs to transform to backend comaptible format from MatDatePicker format
   */
  onFilterDateRangeMaxChange(): void {
    this.value.to = this.datePipe.transform(this.rangeMax, 'yyyy-MM-dd');
    this.maxDateFromRange = new Date(this._rangeMax);
    this.maxDateFromRange.setDate(this._rangeMax.getDate() - 1);
    this.checkRangeMinMaxError();
  }

  /**
   * Change handler for filter with input field (not date)
   */
  onFilterInputChange(): void {
    this.value = this.inputFilter;
    if (this.inputFilter) {
      switch (this.metaData.type) {
        case EFilterType.percentage:
          this.value = this.inputFilter / 100;
          break;
        default:
          break;
      }
    }
    this.valueChange.emit(this.value);
  }

  /**
   * Change handler of min value for range filter
   */
  onRangeMinChange(): void {
    this.value.min = this.rangeMin;
    this.checkRangeMinMaxError();
  }

  /**
   * Change handler for max value for range filter
   */
  onRangeMaxChange(): void {
    this.value.max = this.rangeMax;
    this.checkRangeMinMaxError();
  }

  checkRangeMinMaxError(min: any = this._rangeMin, max: any = this._rangeMax): void {
    this.minMaxRangeError =
      max !== null &&
      min !== null &&
      min >= max;
    this.hasErrors.emit(this.minMaxRangeError);
  }

  /**
   * Change handler for @angular-slider/ngx-slider range filter
   * @param changeContext Change event context from @angular-slider/ngx-slider
   */
  onRangeSliderChange(ev: Event): void {
    const target = ev.target as HTMLInputElement;
    const parent = target.parentElement;
    const value = +target.value;
    const isMin = parent.children.item(0) == target;
    if (this.isMinMax()) {
      if (isMin) {
        this.value = {
          ...this.value,
          min: this.isMinMaxPercentage ? value / 100 : value
        };
      } else {
        this.value = {
          ...this.value,
          max: this.isMinMaxPercentage ? value / 100 : value
        };
      }
    } else {
      if (isMin) {
        this.value = {
          ...this.value,
          from: value
        };
      } else {
        this.value = {
          ...this.value,
          to: value
        };
      }
    }
    this.valueChange.emit(this.value);
  }

  /**
   * Change handler for filter with select field
   */
  onSelectChange(): void {
    this.value = this.selectFilter === 'null' ? null : this.selectFilter;
    this.valueChange.emit(this.value);
  }

  /**
   * Toggles multiselect options on click
   * @param value Clicked multiselect option
   */
  onMultiSelectClick(value: any): void {
    if (this.isMultiSelectOptionActive(value)) {
      const index = this.value.findIndex((v: any) => v === value);
      this.value.splice(index, 1);
    } else {
      this.value.push(value);
    }
    if (this.isCountryMultiSelect) {
      this.nestedSelectOptions.forEach((continent) =>
        this.setContinentSelectStatus(continent)
      );
    }
  }

  /**
   * Helper funtion to track item during iteration over items
   * @param index Index of item
   * @param item Iterated item
   */
  trackByFn(index: number, item: any): number {
    return index;
  }

  /**
   * Toggles toggle filter
   */
  toggleToggleFilter(): void {
    if (this.value) {
      this.value =
        this.metaData.subType === EFilterSubType.toggleRequired ? false : null;
    } else {
      this.value = true;
    }
    this.valueChange.emit(this.value);
  }

  /**
   * Remove selected chip from the selected chip list
   * @param index chip that should be removed
   */
  removeChip(index: number): void {
    if (index >= 0) {
      this.selectedChips.splice(index, 1);
      this.valueChange.emit(this.selectedChips.map((kv) => kv.key));
    }
  }

  /**
   * Function called when selected one of the existing chips in the autocomplete chip list
   * @param event emitted by clicking on a autocomplete suggestion
   */
  selectChip(event: MatAutocompleteSelectedEvent): void {
    this.selectedChips.push(event.option.value);
    // reset form control to get all values
    this.chipControl.setValue(null);
    // reset html input to show no value
    this.chipInput.nativeElement.value = null;
    this.valueChange.emit(this.selectedChips.map((chip) => chip.key));
  }

  selectAuto(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value as IKeyValue;
    this.autoControl.setValue(value.value);
    this.autoInput.nativeElement.value = value.value;
    this.valueChange.emit(value);
  }

  /**
   * Set status of continent checkbox
   */
  setContinentSelectStatus(continent: INestedMenuItem): void {
    const countries = continent.children.map((c) => c);
    this.continentSelect[continent.key] = countries.every((c) =>
      this.selectFilter?.includes(c.key)
    )
      ? EGroupFilterSelectStatus.all
      : countries.some((c) => this.selectFilter?.includes(c.key))
      ? EGroupFilterSelectStatus.some
      : EGroupFilterSelectStatus.none;
  }

  /**
   * Handle change of continent checkbox
   * @param key Key continent
   * @param event Event from MatCheckbox
   */
  onContinentCheckChange(key: string, event: any): void {
    if (event) {
      if (this.continentSelect[key] === EGroupFilterSelectStatus.all) {
        // remove all countries from continent that was unselected
        this.selectFilter = this.selectFilter.filter(
          (country) =>
            !this.nestedSelectOptions
              .find((continent) => continent.key === key)
              .children.map((c) => c.key)
              .includes(country)
        );
        const newValue = this.value.filter(
          (country) =>
            !this.nestedSelectOptions
              .find((continent) => continent.key === key)
              .children.map((c) => c.key)
              .includes(country)
        );
        // update status for continent
        this.continentSelect[key] = EGroupFilterSelectStatus.none;
        if (newValue.length === 0) {
          this.value.splice(0);
        } else {
          this.value = newValue;
        }
      } else {
        // add all missing countries from continent that was selected
        this.nestedSelectOptions
          .find((continent) => continent.key === key)
          .children.map((c) => c.key)
          .forEach((country) => {
            if (!this.selectFilter.includes(country)) {
              this.selectFilter.push(country);
            }
            if (!this.value.includes(country)) {
              this.value.push(country);
            }
          });
        // deep copy list to update checkboxes
        this.selectFilter = [...this.selectFilter];
        // update status of continent
        this.continentSelect[key] = EGroupFilterSelectStatus.all;
      }
    }
  }

  private setHelpers(): void {
    this.isFromTo = this.metaData.subType === EFilterSubType.rangeFromTo;
    this.isOnlyMinMax = this.metaData.subType === EFilterSubType.rangeMinMax;
    this.isMinMaxPercentage =
      this.metaData.subType === EFilterSubType.rangeMinMaxPercentage;
    this.isCountryMultiSelect =
      this.metaData.type === EFilterType.countryMultiSelect;
  }

  /**
   * Check if range filters uses min/max values
   */
  private isMinMax(): boolean {
    return (
      this.metaData.subType === EFilterSubType.rangeMinMax ||
      this.isMinMaxPercentage
    );
  }

  /**
   * Check if multiselect option is selected
   * @param value Multiselect option
   */
  private isMultiSelectOptionActive(value: any): boolean {
    return this.value.findIndex((v: any) => v === value) > -1;
  }

  /**
   * Generate initial @angular-slider/ngx-slider options
   * @param cfg Config
   */
  private createRangeSliderConfig(cfg: ISliderOptions): ISliderOptions {
    return {
      step: 1,
      disabled: this.readOnly,
      formatLabel: (value: number): string => this.isMinMax() ? value + '%' : value.toString(),
      ...cfg
    }
  }

  public selectSearchOnKey(value: string): void {
    const filteredValues = new Set<string>(this.selectFilter);
    this.selectOptions.filter((o) =>
      o.value.toLowerCase().startsWith(value.toLowerCase())
    ).forEach(o => {
      filteredValues.add(o.key);
    });
    this.filteredOptions = Array.from(filteredValues)
      .map(key => this.selectOptions.find(o => o.key == key));
  }
}
