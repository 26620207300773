<div class="toast-notification">
  <div class="toast-notification-title">
    <app-config-icon
      *ngIf="iconIdentifier"
      [class]="iconColorClass"
      [iconIdentifier]="iconIdentifier"
    ></app-config-icon>
    <div>{{ title }}</div>
  </div>
  <div class="toast-notification-message">{{ message }}</div>
  <app-config-icon class="close-icon" iconIdentifier="close"></app-config-icon>
</div>
