<div class="header">
  <div class="h1">{{ progressName | translate }}</div>
  <div class="h2">{{ message | translate }}</div>
  <div class="h3 progress-error" *ngIf="progressEvent?.type === 'ERROR'">
    {{progressEvent.message | translate}}
  </div>
</div>
<div class="progress">
  <div class="progress-value" *ngIf="progressEvent && progressEvent.type !== 'ERROR' && progressEvent.progress != null">
    {{ progressEvent.progress }} %
  </div>
  <div class="progress-value" *ngIf="!progressEvent">-</div>
  <mat-progress-bar
    [mode]="progressEvent &&  progressEvent.progress != null ? 'determinate' : 'indeterminate'"
    [value]="progressEvent?.progress ?? 0"
  ></mat-progress-bar>
</div>
<div class="spacer">&nbsp;</div>
