import {EHelpContentType, EViewRoutes} from 'src/app/util/enum';

export const HELP_PORTFOLIO_OVERVIEW_CONFIG = {
  id: 14,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Overview',
        en: 'Overview',
      },
      anchor: 'portfolioOverview',
    },
    {
      title: {
        de: `Details`,
        en: 'Details',
      },
      anchor: 'portfolioOverviewDetails',
    },
    {
      title: {
        de: 'All Campaigns',
        en: 'All Campaigns',
      },
      anchor: 'portfolioOverviewCampaigns',
    },
    {
      title: {
        de: 'All Clients',
        en: 'All Clients',
      },
      anchor: 'portfolioOverviewClients',
    },
    {
      title: {
        de: 'All Positions',
        en: 'All Positions',
      },
      anchor: 'portfolioOverviewPositions',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Portfolio Overview',
        en: `Portfolio Overview`,
      },
      anchor: 'portfolioOverview',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: `Shows the relevant information of a selected portfolio`,
        en: `Shows the relevant information of a selected portfolio`,
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'portfolioOverviewDetails',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Basic information about the portfolio at the top and a list of all positions of this portfolio below.',
        en: 'Basic information about the portfolio at the top and a list of all positions of this portfolio below.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: `Portfolio Details`,
        en: 'Portfolio Details',
      },
      url: '/assets/images/help/portfolio_overview_details.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'All Campaigns',
        en: 'All Campaigns',
      },
      anchor: 'portfolioOverviewCampaigns',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all campaigns that this portfolio has been a part of.',
        en: 'Lists all campaigns that this portfolio has been a part of.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Portfolio Campaigns',
        en: 'Portfolio Campaigns',
      },
      url: '/assets/images/help/portfolio_overview_campaigns.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'All Clients',
        en: 'All Clients',
      },
      anchor: 'portfolioOverviewClients',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all clients of this portfolio.',
        en: 'Lists all clients of this portfolio.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Clients of this portfolio',
        en: 'Clients of this portfolio',
      },
      url: '/assets/images/help/portfolio_overview_clients.png',
    },
  ],
  view: EViewRoutes.portfolioOverview,
};
