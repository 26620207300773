import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { StoredError } from 'src/app/models/error.model';
import { EMPTY, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { EViewRoutes } from 'src/app/util/enum';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private readonly errorKey = 'aSparkErrors';

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private zone: NgZone
  ) {}

  handleClientError(error: Error): void {
    const message = error.message ? error.message : error.toString();
    this.addToErrorList({
      message,
      timestamp: Date.now(),
      stack: error.stack ? error.stack : '',
    });
  }

  registerServerError(error: HttpErrorResponse): void {
    let errorMessage: string =
      typeof error.error === 'string' ? error.error : error.error?.error;
    if (!errorMessage) {
      errorMessage = error.message ? error.message : 'Unknown error';
    }
    this.addToErrorList({
      message: errorMessage,
      timestamp: Date.now(),
    });
  }

  getErrorList(): StoredError[] {
    const storedErrors = sessionStorage.getItem(this.errorKey);
    if (storedErrors) {
      return JSON.parse(storedErrors) as StoredError[];
    } else {
      return [];
    }
  }

  getErrorByTimestamp(timestamp: number): Observable<StoredError> {
    const errors = this.getErrorList();
    const error = errors.find((e) => e.timestamp === timestamp);
    if (error) {
      return of(error);
    } else {
      this.globalService.navigate(EViewRoutes.errors);
      return EMPTY;
    }
  }

  handleForbiddenError(): void {
    const currentUrl = this.router.routerState.snapshot.url;
    const viewRoute = this.globalService.getOverviewPathByUrl(currentUrl);
    switch (viewRoute) {
      case EViewRoutes.campaignOverview:
        this.zone.run(() => this.router.navigate([EViewRoutes.campaigns]));
        break;
      case EViewRoutes.storyOverview:
        this.zone.run(() => this.router.navigate([EViewRoutes.stories]));
        break;
      default:
        break;
    }
  }

  handleUnauthorizedError(): void {
    this.zone.run(() => this.globalService.navigate(EViewRoutes.login));
  }

  private addToErrorList(error: StoredError): void {
    const storedErrors = sessionStorage.getItem(this.errorKey);
    if (storedErrors) {
      const array: StoredError[] = JSON.parse(storedErrors) as StoredError[];
      array.push(error);
      sessionStorage.setItem(this.errorKey, JSON.stringify(array));
    } else {
      sessionStorage.setItem(this.errorKey, JSON.stringify([error]));
    }
  }
}
