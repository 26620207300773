<app-card [showSpinner]="chartDataLoading$ | async">
  <div card-header>
    <span>{{ 'filters' | translate }}</span>
  </div>
  <app-config-icon
    *ngIf="!editDisabled"
    iconIdentifier="edit_m"
    [tooltip]="'editFilter' | translate"
    (click)="onEditFilterClick()"
    card-action
  ></app-config-icon>
  <app-config-icon
    *ngIf="enableShowReadOnlyFilter"
    iconIdentifier="view"
    [tooltip]="'showReadOnlyFilter' | translate"
    (click)="onShowReadOnlyFilterClick()"
    card-action
  ></app-config-icon>
  <app-config-icon
    *ngIf="!editDisabled && currentCampaign?.story"
    card-action
    iconIdentifier="restore"
    [tooltip]="'restoreFiltersFromStory' | translate"
    (click)="restoreFiltersFromStory()"
  ></app-config-icon>
  <div class="active-tags-card-body" card-body>
    <div
      class="active-tags-container"
      [ngClass]="{ 'no-edit': !canEditTags() }"
    >
      <app-tag @tags *ngIf="!activeFilters.length" class="no-hover">{{
        'noActiveFilters' | translate
      }}</app-tag>
      <app-tag
        @tags
        *ngFor="let tag of activeFilters"
        class="highlight"
        [filterTag]="tag"
        [selectable]="true"
        (deleteTag)="onDeleteFilterTagClick($event)"
        (clickedTag)="onClickedFilterTag($event)"
        [class.disabled]="editDisabled"
      >
        <!-- CLIENT / PORTFOLIO / POSITION-->
        <ng-container
          *ngIf="
            tag.category === 'client' ||
            tag.category === 'position' ||
            tag.category === 'portfolio'
          "
        >
          {{ tag.category | translate }}:
          {{ tag.text | translate }}
        </ng-container>

        <ng-container
          *ngIf="tag.category === 'intermediaries'"
        >
          {{ tag.category | translate }}:
          {{ tag.text + 'Tag' | translate }}
        </ng-container>

        <ng-container *ngIf="tag.category === 'orgPosition'">
          {{ tag.text | translate }}
        </ng-container>

        <!-- ASSETS -->
        <ng-container *ngIf="tag.category === 'assets'">
          {{ tag.category | translate }} ({{tag.type | translate}}):
          {{ tag.text }}
        </ng-container>

        <!-- ASSET CLASS -->
        <ng-container
          *ngIf="tag.category === 'assetClass' && assetClasses?.length"
        >
          {{ tag.category | translate }}:
          {{ tag.field | getFilterLabel : assetClasses }}
        </ng-container>

        <!-- CURRENCY -->
        <ng-container *ngIf="tag.category === 'currency' && currencies?.length">
          {{ tag.category | translate }}:
          {{ tag.field | getFilterLabel : currencies }}
        </ng-container>

        <!-- REGION -->
        <ng-container *ngIf="tag.category === 'region' && regions?.length">
          {{ tag.category | translate }}:
          {{ tag.field | getFilterLabel : regions }}
        </ng-container>

        <!-- SECTOR -->
        <ng-container *ngIf="tag.category === 'sector' && gics?.length">
          {{ tag.category | translate }}:
          {{ tag.field | getFilterLabel : gics }}
        </ng-container>

        <!--ADVANCED-->
        <ng-container *ngIf="tag.category === 'advanced'">
          {{ tag.text }}
        </ng-container>
      </app-tag>
    </div>
    <div *ngIf="!editDisabled" class="filter-separator"></div>
    <div *ngIf="!editDisabled" class="user-filters">
      <mat-form-field hintLabel="{{ 'userFilterPlaceholder' | translate }}">
        <mat-select [(value)]="selectedFilter">
          <mat-option *ngFor="let uf of userFilters" [value]="uf">
            {{ uf.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="selected-filter-action-container">
        <div class="icon-actions">
          <app-config-icon
            *ngIf="selectedFilter"
            iconIdentifier="clear"
            [tooltip]="'clear' | translate"
            (click)="clearFilterTemplate()"
          ></app-config-icon>
          <app-config-icon
            *ngIf="selectedFilter"
            class="icon-delete"
            iconIdentifier="delete"
            [tooltip]="'delete' | translate"
            (click)="deleteFilterTemplate()"
          ></app-config-icon>
          <app-config-icon
            *ngIf="selectedFilter"
            iconIdentifier="edit_m"
            [tooltip]="'edit' | translate"
            (click)="editFilterTemplate()"
          ></app-config-icon>
        </div>
        <div class="save-actions">
          <button
            mat-stroked-button
            (click)="saveFilterTemplate()"
            *ngIf="activeFilters.length"
          >
            <app-config-icon iconIdentifier="save"></app-config-icon>
            {{ 'save' | translate }}
          </button>
          <button
            mat-stroked-button
            (click)="saveAsFilterTemplate()"
            [disabled]="!selectedFilter"
            *ngIf="activeFilters.length"
          >
            <app-config-icon iconIdentifier="save_as"></app-config-icon>
            {{ 'saveAs' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-card>
