import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import * as Blockly from 'blockly';
import {Block} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';

export class BlockFilterListEditorCallback extends BlockDefinition {
  constructor(
    ident: string,
    readonly fields: AdvancedFilterField[],
    readonly outputCheckTypes: string[] = ['Boolean'],
    readonly operator: string = 'eq',
    readonly editCallback: (block: Block) => void = null
  ) {
    super(
      'filter_field-list-callback',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const dropdown = new Blockly.FieldDropdown(this.fields.find((f) => f.active).entries);
    block
      .appendDummyInput('field_input')
      .appendField(translateService.instant(this.ident) + ':');

    dropdown.showEditor = (e) => {
      this.editCallback(block);
    };
    block.appendDummyInput('field_value').appendField(
      // only show values of first active field in preview
      dropdown,
      'value'
    );
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const field = block.getFieldValue('field') || this.ident;
    const target = (block.getFieldValue('value') || '');
    return {
      result: {
        type: this.ident,
        name: field,
        operator: this.operator,
        valueList: target ? [target] : [],
      },
      order: javascriptGenerator.ORDER_NONE,
    };
  }
}
