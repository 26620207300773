import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { TranslateService } from '@ngx-translate/core';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import { GridCustomContentInfo } from 'src/app/models/grid.model';
import {EPortfolioActionStatus} from "../../../util/enum";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import {ContentMatchState} from "../../../api/core";

@Component({
  template: `
    <div *ngIf="!(hidden | isRendererHidden: data)" class="custom-content-cell">
      <app-config-icon
        *ngIf="hasContentMatch"
        iconIdentifier="preview"
        class="{{ iconClass }}"
        [tooltip]="viewTooltip"
        style="{{ callback ? 'cursor: pointer;' : ''}}"
        (click)="callback && callback(data, previewAction)"
      ></app-config-icon>
      <app-config-icon
        *ngIf="isPending && hasContentMatch"
        [iconIdentifier]="icon"
        [tooltip]="tooltip"
        style="{{ callback ? 'cursor: pointer;' : ''}}"
        (click)="callback && callback(data, action)"
      ></app-config-icon>
      <app-config-icon
        *ngIf="!!data && !!data.hasContentOverride && isPending"
        iconIdentifier="delete"
        class="{{ iconClass }}"
        style="{{ callback ? 'cursor: pointer;' : ''}}"
        (click)="callback && callback(data, deleteAction)"
      ></app-config-icon>
      <span *ngIf="label" class="label">{{ label }}</span>
    </div>
  `,
})
export class CustomContentRenderer implements AgRendererComponent {
  @HostBinding('class.grid-cell-custom-content') theme = true;
  data: any;
  icon: string;
  action: string;
  tooltip: string;
  deleteAction = 'delete';
  previewAction = 'preview';
  viewTooltip: string;
  label: string;
  iconClass: string;
  callback: (data: any, action: string) => void;
  hidden: (data: any) => boolean;
  isPending = false;
  hasContentMatch = false;

  constructor(
    protected translateService: TranslateService,
  ) {
  }

  agInit(params: ICellRendererParams): void {
    this.data = params.data;
    this.isPending = this.data.status === EPortfolioActionStatus.pending;
    const info: GridCustomContentInfo =
      params?.colDef?.cellRendererParams?.actionsParams?.customContentInfo;

    this.viewTooltip = this.translateService.instant('viewCustomContent');
    this.hasContentMatch = this.data.contentMatchState !== ContentMatchState.MISSING;

    if (typeof info.label === 'function') {
      this.label = info.label(this.data);
    } else {
      this.label = info.label;
    }

    if (this.data.hasContentOverride) {
      this.icon = 'edit_m';
      this.tooltip = this.translateService.instant('editContent');
      this.action = 'edit';
    } else {
      this.icon = 'add';
      this.tooltip = this.translateService.instant('addCustomContent');
      this.action = 'add';
    }
    if (info) {
      this.callback = info.callback;
      this.hidden = info.hidden;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genCustomContentColumn(info: GridCustomContentInfo): ColDef {
  return {
    ...commonParams(info.field, info?.headerName),
    cellRenderer: CustomContentRenderer,
    sortable: false,
    width: 64,
    minWidth: 64,
    filterParams: {
      ...commonFilterParams(info.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        customContentInfo: info,
      },
      innerRenderer: CustomContentRenderer,
    },
  };
}
