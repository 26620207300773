<form [formGroup]="contentForm" class="modal-form" *ngIf="fromBuilt">
  @for (fd of this.fieldDefinitions; track fd.id) {
    <div class="row row-single" *ngIf="isVisible(fd) && fd.editorType === FieldDefinitionType.PLAIN">
      <mat-form-field>
        <mat-label>{{ fd[label] }}</mat-label>
        <input
          *ngIf="fd.heightInLines == 1"
          matInput
          formControlName="{{ fd.ident }}"
          type="text"
        />
        <textarea
          *ngIf="fd.heightInLines > 1"
          matInput
          formControlName="{{ fd.ident }}"
          type="text"
          [rows]="fd.heightInLines"
        ></textarea>
        <mat-error *ngIf="contentForm.get(fd.ident)?.errors?.maxlength">
          {{ 'inputTooLong' | translate : {maxLength: fd.maxLength} }}
        </mat-error>
        <mat-error *ngIf="contentForm.get(fd.ident)?.errors?.required || contentForm.get(fd.ident)?.errors?.pattern">
          {{ 'inputRequired' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row row-single rich-text-section"
         [class.error]="contentForm.get(fd.ident).invalid"
         [class.required]="fd.mandatory"
         *ngIf="isVisible(fd) && fd.editorType === FieldDefinitionType.RICHTEXT"
         [ngClass]="{'single-line': fd.heightInLines === 1}"
    >
      <mat-label>{{ fd[label] }}</mat-label>
      <textarea
        [froalaEditor]="getEditorOptions(fd)"
        formControlName="{{ fd.ident }}"
      ></textarea>
      <mat-error *ngIf="contentForm.get(fd.ident)?.errors?.maxlength">
        {{ 'inputTooLongSimple' | translate : {maxLength: fd.maxLength} }}
      </mat-error>
      <mat-error *ngIf="contentForm.get(fd.ident)?.errors?.required || contentForm.get(fd.ident)?.errors?.pattern">
        {{ 'inputRequired' | translate }}
      </mat-error>
    </div>
  }
  <ng-container *ngIf="!isCustomContent && guiConfig.content.attachments">
    <div class="row row-double">
      <app-content-attachments
        [content]="content"
        [attachments]="attachments"
        [isEditable]="isEditable">
      </app-content-attachments>
    </div>
  </ng-container>
  <ng-container *ngIf="isCustomContent && guiConfig.content.customAttachments">
    <div class="row row-double">
      <app-content-attachments
        [content]="content"
        [attachments]="attachments"
        [isEditable]="guiConfig.content.customEditAttachments">
      </app-content-attachments>
    </div>
  </ng-container>
</form>
