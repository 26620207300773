<app-tabs-picker-toolbar>
  <button mat-stroked-button (click)="onAdd()">
    <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
    {{ 'appMessagesAdd' | translate }}
  </button>
</app-tabs-picker-toolbar>
<app-grid
  #grid
  tableId="app-messages"
  [columnDefs]="colDefs"
  [data]="data"
  [gridOptions]="gridOptions"
  (gridFiltersReset)="gridFilterReset($event)"
></app-grid>
