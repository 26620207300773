<div class="page-content-container">
  <div class="home-container" *ngIf="hasAnyRole">
    <div class="home-action-grid">
      <app-sub-header [header]="'yourPendingActions'"></app-sub-header>
      <app-grid
        tableId="home-actions-grid"
        [rowData]="campaigns"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
      ></app-grid>
    </div>
    <div class="home-extension aspark-container-padding">
      <app-card class="home-chart">
        <div card-header>
          {{ 'activities' | translate }} ({{ 'last12Months' | translate }})
        </div>
        <div card-body>
          <app-home-chart></app-home-chart>
        </div>
      </app-card>
      <app-card class="home-best-actions">
        <div card-header>
          <span>{{ 'nextBestAction' | translate }}</span>
          <app-config-icon
            iconIdentifier="info"
            [tooltip]="'bestActionsTooltip' | translate"
          ></app-config-icon>
        </div>
        <div card-body>
          <app-home-best-actions></app-home-best-actions>
        </div>
      </app-card>
    </div>
  </div>
  <ng-container *ngIf="!hasAnyRole">
    <app-sub-header [header]="'home'"></app-sub-header>
    <div class="h4 home-warning">
      {{ 'warningUserWithoutRoles' | translate }}
    </div>
  </ng-container>
</div>
