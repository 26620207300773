<form [formGroup]="formGroup" *ngIf="formGroup" class="modal-form">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'name' | translate }}</mat-label>
      <input matInput formControlName="name" type="text"/>
      <mat-error *ngIf="formGroup.get('name').hasError('pattern')">
        {{ 'contentDefinitionReservedName' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row row-double" id="last-row">
    <div class="selected-available-container">
      <app-card class="selected">
        <div card-header>
          <span>{{ 'fieldDefinitionsSelected' | translate }}</span>
          <app-config-icon
            iconIdentifier="info"
            [tooltip]="'fieldDefinitionsSelectedTooltip' | translate"
          ></app-config-icon>
        </div>
        <div card-body>
          <!--default drag-and-drop does not work with pagination-->
          <app-grid
            tableId="field-definition-picker-selected"
            [rowData]="initialSelected"
            [columnDefs]="selectedColDefs"
            [gridOptions]="selectedGridOptions"
            [pagination]="false"
          ></app-grid>
        </div>
      </app-card>

      <app-card class="available">
        <div card-header>
          <span>{{ 'fieldDefinitionsAvailable' | translate }}</span>
        </div>
        <div card-body>
          <app-grid
            tableId="field-definition-picker-available"
            [rowData]="available"
            [columnDefs]="availableColDefs"
            [gridOptions]="availableGridOptions"
          ></app-grid>
        </div>
      </app-card>

      <div class="actions">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!canAdd()"
          (click)="addSelection()"
          aria-label="Add Selected Field Definitions"
        >
          <app-config-icon iconIdentifier="keyboard_arrow_left"></app-config-icon>
        </button>
      </div>
    </div>
  </div>
</form>
