<app-card>
  <div card-header>
    <span>{{ 'details' | translate }}</span>
  </div>

  <div class="h4" card-body>
    <div class="content-wrapper body" *ngIf="employee">
      <div class="detail-items-wrapper">
        <div class="detail-items">
          <app-overview-detail-item
            labelRef="name"
            [value]="employee.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="lastName"
            [value]="employee.lastName"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="firstName"
            [value]="employee.firstName"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="gender"
            [value]="employee.gender.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="key"
            [value]="employee.externalKey"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="preferredLanguage"
            [value]="employee.preferredLanguage.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="channel"
            [value]="employee.preferredChannelType.name"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="closed"
            [value]="employee.closed ? 'yes' : 'no' | translate"
          ></app-overview-detail-item>
          <app-overview-detail-item
            labelRef="intermediary"
            valueIcon="pageview"
            [value]="employee.intermediary.name"
            [clickable]="true"
            (editClicked)="showIntermediary()"
          ></app-overview-detail-item>
        </div>
      </div>
    </div>
  </div>
</app-card>
