<app-card [showHeader]="false">
  <div card-body>
    <app-grid
      tableId="app-messages-old"
      [columnDefs]="colDefs"
      [data]="dataProvider"
      [gridOptions]="gridOptions"
      [gridFilterEnabled]="false"
    ></app-grid>
  </div>
</app-card>
<div class="app-message-card">
  <app-card *ngIf="contentTitle">
    <div card-header>
      <span>{{ contentTitle }}</span>
    </div>
    <div card-body class="app-message-content" [innerHTML]="content"></div>
  </app-card>
</div>
