<div class="filter-details-client-container">
  <ng-container *ngIf="personalDetailsVisible">
    <span class="h3 sub-section">{{ 'personalDetails' | translate }}</span>
    <div class="filter-details-row">
      <app-filter
        [metaData]="getFilterMetaData(fields.ageRange)"
        [(value)]="clientForm.ageRange"
        [readOnly]="readOnly"
        *ngIf="clientFilters.ageRange | isFilterClientFilterActive"
      ></app-filter>
      <app-filter
        class="middle"
        [metaData]="getFilterMetaData(fields.gender)"
        [(value)]="clientForm.gender"
        [readOnly]="readOnly"
        *ngIf="
          (clientFilters.gender | isFilterClientFilterActive) && genders.length
        "
      ></app-filter>
      <app-filter
        class="client-specific-container__domicile"
        [metaData]="getFilterMetaData(fields.domiciles)"
        [(value)]="clientForm.domiciles"
        [readOnly]="readOnly"
        *ngIf="
          (clientFilters.domiciles | isFilterClientFilterActive) &&
          domiciles.length
        "
      ></app-filter>
    </div>
  </ng-container>
</div>
