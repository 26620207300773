<form [formGroup]="formGroup" *ngIf="formGroup" class="aspark-container-padding">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'name' | translate}}</mat-label>
      <input matInput formControlName="name" type="text" />
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'hub' | translate}}</mat-label>
      <mat-select formControlName="hub">
        <mat-option *ngFor="let hub of hubs" [value]="hub">
          {{ hub.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{'publicationType' | translate}}</mat-label>
      <input matInput style="display: none!important">
      <mat-selection-list formControlName="publicationTypes" (selectionChange)="updateCodeTableEntries()">
        <mat-list-option *ngFor="let pt of publicationTypes" [value]="pt" color="primary">
          {{pt.name}}
        </mat-list-option>
      </mat-selection-list>
    </mat-form-field>
  </div>

</form>
