import { HelpData } from 'src/app/models/help.model';
import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';
import { HELP_CAMPAIGNS_CONFIG } from './help_campaigns_config';
import { HELP_CAMPAIGN_OVERVIEW_CONFIG } from './help_campaign_overview_config';
import { HELP_CLIENTS_CONFIG } from './help_clients_config';
import { HELP_CLIENT_OVERVIEW_CONFIG } from './help_client_overview_config';
import { HELP_ERRORS_CONFIG } from './help_errors_config';
import { HELP_ERROR_OVERVIEW_CONFIG } from './help_error_overview_config';
import { HELP_GENERAL_CONFIG } from './help_general_config';
import { HELP_HOME_CONFIG } from './help_home_config';
import { HELP_MONITORING_CAMPAIGN_CONFIG } from './help_monitoring_campaign_config';
import { HELP_MONITORING_CAMPAIGN_OVERVIEW_CONFIG } from './help_monitoring_campaign_overview_config';
import { HELP_MONITORING_RELATIONSHIP_MANAGER_CONFIG } from './help_monitoring_relationship_manager_config';
import { HELP_PLAYGROUND_CONFIG } from './help_playground_config';
import { HELP_PORTFOLIOS_CONFIG } from './help_portfolios_config';
import { HELP_PORTFOLIO_OVERVIEW_CONFIG } from './help_portfolio_overview_config';
import { HELP_PRODUCTS_CONFIG } from './help_products_config';
import { HELP_PRODUCT_OVERVIEW_CONFIG } from './help_product_overview_config';
import { HELP_STORIES_CONFIG } from './help_stories_config';
import { HELP_STORY_OVERVIEW_CONFIG } from './help_story_overview_config';

// **********************navigation item template
// {
//   title: {
//     de: 'Title',
//     en: 'Title',
//   },
//   anchor: 'anchor',
//   children: [
//     {
//       title: {
//         de: 'Subtitel',
//         en: 'Subtitle',
//       },
//       anchor: 'anchor',
//     }
//   ],
// }
// **********************image content template
// {
//   type: EHelpContentType.image,
//   text: {
//     de: 'Label',
//     en: 'Label',
//   },
//   url: 'url',
// }
// **********************header content template
// {
//   type: EHelpContentType.header,
//   text: {
//     de: 'Titel',
//     en: 'Title',
//   },
//   anchor: 'anchor',
// }
// **********************subheader content template
// {
//   type: EHelpContentType.subheader,
//   text: {
//     de: 'Subtitel',
//     en: 'Subtitle',
//   },
//   anchor: 'anchor',
// }
// **********************paragraph content template
// {
//   type: EHelpContentType.paragraph,
//   text: {
//     de: 'Abschnitt',
//     en: 'Paragraph',
//   },
// }
// **********************icon content template
// {
//   type: EHelpContentType.icon,
//   text: {
//     de: 'Icon',
//     en: 'Icon',
//   },
//   iconIdentifier: 'thumb_up'
// }

export const HELP_CONFIG: HelpData = {
  general: HELP_GENERAL_CONFIG,
  views: [
    HELP_CAMPAIGN_OVERVIEW_CONFIG,
    HELP_CAMPAIGNS_CONFIG,
    HELP_CLIENT_OVERVIEW_CONFIG,
    HELP_CLIENTS_CONFIG,
    HELP_ERROR_OVERVIEW_CONFIG,
    HELP_ERRORS_CONFIG,
    HELP_HOME_CONFIG,
    HELP_MONITORING_CAMPAIGN_CONFIG,
    HELP_MONITORING_CAMPAIGN_OVERVIEW_CONFIG,
    HELP_MONITORING_RELATIONSHIP_MANAGER_CONFIG,
    HELP_PLAYGROUND_CONFIG,
    HELP_PORTFOLIO_OVERVIEW_CONFIG,
    HELP_PORTFOLIOS_CONFIG,
    HELP_PRODUCT_OVERVIEW_CONFIG,
    HELP_PRODUCTS_CONFIG,
    HELP_STORIES_CONFIG,
    HELP_STORY_OVERVIEW_CONFIG,
  ],
};
