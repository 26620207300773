<div class="menu-label">{{ 'select' | translate }}:</div>
<div class="colored-menu-trigger"
     [matMenuTriggerFor]="tabMenu"
>
  <app-config-icon
    [iconIdentifier]="activeTabIcon"
    [matTooltip]="'buckets' | translate"
  ></app-config-icon>
  <div>{{ (activeTab || '') | translate }}</div>
  <app-config-icon iconIdentifier="expand_more"></app-config-icon>
  <mat-menu #tabMenu="matMenu" class="tab-menu">
    <ng-container *ngIf="tabs.length > 0">
      <button
        mat-menu-item
        *ngFor="let tab of tabs"
        class="mat-menu-item-flex"
        (click)="onTabClicked(tab.text)"
      >
        <app-config-icon [iconIdentifier]="tab.icon"></app-config-icon>
        <span>{{ tab.text | translate }}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>
