<mat-tab-group *ngIf="!helpUrl">
  <mat-tab label="{{ viewTabName | translate }}" *ngIf="viewHelp">
    <app-help-navigation
      [navigationItems]="viewHelp.navigation"
    ></app-help-navigation>
    <app-help-content [contentItems]="viewHelp.content"></app-help-content>
  </mat-tab>
  <mat-tab label="{{ 'generalHelp' | translate }}" *ngIf="generalHelp">
    <app-help-navigation
      [navigationItems]="generalHelp.navigation"
    ></app-help-navigation>
    <app-help-content [contentItems]="generalHelp.content"></app-help-content>
  </mat-tab>
</mat-tab-group>
<iframe *ngIf="helpUrl" [src]="helpUrl"></iframe>
