import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_MONITORING_CAMPAIGN_CONFIG = {
  id: 8,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Monitoring campaign',
        en: 'Monitoring campaign',
      },
      anchor: 'monitoringCampaign',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Monitoring Campaign',
        en: 'Monitoring Campaign',
      },
      anchor: 'monitoringCampaign',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all monitoring statistics for LAUNCHED campaigns.',
        en: 'Lists all monitoring statistics for LAUNCHED campaigns.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Monitoring campaign',
        en: 'Monitoring campaign',
      },
      url: '/assets/images/help/help_monitoring_campaign.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '1 - Columns',
        en: '1 - Columns',
      },
      anchor: 'monitoringCampaignColumns',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Customizable columns, these allow to filter the data according the monitoring data captured in aSpark.',
        en: 'Customizable columns, these allow to filter the data according the monitoring data captured in aSpark.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '2 - Charts',
        en: '2 - Charts',
      },
      anchor: 'monitoringCampaignCharts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Contact-Rate: Describes how many portfolios where contacted.',
        en: 'Contact-Rate: Describes how many portfolios where contacted.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Executed-rate: Describes how many actions where executed.',
        en: 'Executed-rate: Describes how many actions where executed.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '3 - Overview',
        en: '3 - Overview',
      },
      anchor: 'monitoringCampaignOverview',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Shows the monitoring data for the specific campaign',
        en: 'Shows the monitoring data for the specific campaign',
      },
    },
  ],
  view: EViewRoutes.monitoringCampaign,
};
