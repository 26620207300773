import { Component, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExternalLink } from '../../api/core';

@Component({
  selector: 'app-external-link-list',
  templateUrl: './external-link-list.component.html',
})
export class ExternalLinkListComponent {
  @Input()
  links: ExternalLink[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: { links: ExternalLink[] } }
  ) {
    this.links = data.data.links;
  }
}
