import {Component, Input, OnInit} from '@angular/core';
import {
  ECodeTables,
  EFilterSubType,
  EFilterType,
  EPortfolioFilter,
  EPortfolioFilterTranslation,
} from 'src/app/util/enum';
import {FilterMetaData, IFilterMetaData,} from 'src/app/models/filter-meta-data.model';
import {IKeyValue, KeyValue} from 'src/app/models/key-value.model';
import {CodeTableService} from 'src/app/services/code-table.service';
import {forkJoin} from 'rxjs';
import {FilterPortfolio} from 'src/app/models/filter.model';
import {UiFilterConfigService} from '../../../services/ui-filter-config.service';
import {filteredOptions} from "../filter-details.component";
import {CodeTableEntry} from "../../../api/core";

/**
 * Portfolio Filter Details Component.
 * Component for filter details for category portfolio
 */
@Component({
  selector: 'app-filter-details-portfolio',
  templateUrl: './filter-details-portfolio.component.html',
})
export class FilterDetailsPortfolioComponent implements OnInit {
  /**
   * Filter values for all portfolio filters
   */
  @Input() portfolioForm: FilterPortfolio;
  /**
   * Current story id
   */
  @Input() storyId: string;

  @Input() readOnly = false;

  advisoryTypes: IKeyValue[] = [];
  businessDivisions: IKeyValue[] = [];
  businessUnits: IKeyValue[] = [];
  currencies: IKeyValue[] = [];
  fidlegClientSegmentations: IKeyValue[] = [];
  mifidClientSegmentations: IKeyValue[] = [];
  portfolioStrategies: IKeyValue[] = [];
  portfolioTypes: IKeyValue[] = [];
  rmLocations: IKeyValue[] = [];
  rmMarkets: IKeyValue[] = [];
  serviceCenters: IKeyValue[] = [];
  sustainabilityProfiles: IKeyValue[] = [];

  constructor(
    protected codeTableService: CodeTableService,
    protected filterConfigService: UiFilterConfigService,
  ) {
  }

  ngOnInit() {
    this.initCodeTables();
  }

  /**
   * Provides portfolio filter enum to html
   */
  get fields() {
    return EPortfolioFilter;
  }

  /**
   * Generates meta data for given portfolio filter
   * @param filter Portfolio filter
   */
  getFilterMetaData(filter: EPortfolioFilter): IFilterMetaData {
    switch (filter) {
      case EPortfolioFilter.portfolioType:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.portfolioType,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.model:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.model,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.riskRange:
        return new FilterMetaData(
          EFilterType.range,
          EPortfolioFilterTranslation.riskRange,
          EFilterSubType.rangeMinMaxPercentage
        );
      case EPortfolioFilter.returnRange:
        return new FilterMetaData(
          EFilterType.range,
          EPortfolioFilterTranslation.returnRange,
          EFilterSubType.rangeMinMaxPercentage
        );
      case EPortfolioFilter.referenceCurrency:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.referenceCurrency,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.valueRange:
        return new FilterMetaData(
          EFilterType.range,
          EPortfolioFilterTranslation.valueRange,
          EFilterSubType.rangeMinMax
        );
      case EPortfolioFilter.liquidityRange:
        return new FilterMetaData(
          EFilterType.range,
          EPortfolioFilterTranslation.liquidityRange,
          EFilterSubType.rangeMinMaxPercentage
        );
      case EPortfolioFilter.bu:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.bu,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.mifid:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.mifid,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.fidleg:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.fidleg,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.rmLocation:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.rmLocation,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.rmMarket:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.rmMarket,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.rmDepartment:
        return new FilterMetaData(
          EFilterType.autocomplete,
          EPortfolioFilterTranslation.rmDepartment,
          EFilterSubType.autoRmDepartment,
          [
            { key: 'clearSuffix', value: 'clear'}
          ],
          undefined,
          "chooseRmDepartment"
        );
      case EPortfolioFilter.advisoryType:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.advisoryType,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.serviceCenter:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.serviceCenter,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.businessDivision:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.businessDivision,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.sustainabilityProfile:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EPortfolioFilterTranslation.sustainabilityProfile,
          undefined,
          this.getOptions(filter)
        );
      case EPortfolioFilter.qualifiedInvestor:
        return new FilterMetaData(
          EFilterType.toggle,
          EPortfolioFilterTranslation.qualifiedInvestor
        );
      case EPortfolioFilter.waiveOfAdvice:
        return new FilterMetaData(
          EFilterType.toggle,
          EPortfolioFilterTranslation.waiveOfAdvice
        );
      case EPortfolioFilter.manager:
        return new FilterMetaData(
          EFilterType.chips,
          EPortfolioFilterTranslation.manager,
          EFilterSubType.chipsRelationshipManager
        );
      case EPortfolioFilter.advisor:
        return new FilterMetaData(
          EFilterType.chips,
          EPortfolioFilterTranslation.advisor,
          EFilterSubType.chipsAdvisor
        );
      case EPortfolioFilter.riskBreachOnly:
        return new FilterMetaData(
          EFilterType.toggle,
          EPortfolioFilterTranslation.riskBreachOnly,
          EFilterSubType.toggleRequired
        );
      case EPortfolioFilter.excludeRiskBreach:
        return new FilterMetaData(
          EFilterType.toggle,
          EPortfolioFilterTranslation.excludeRiskBreach,
          EFilterSubType.toggleRequired
        );
      case EPortfolioFilter.allowOptOut:
        return new FilterMetaData(
          EFilterType.toggle,
          EPortfolioFilterTranslation.allowOptOut,
          EFilterSubType.toggleRequired
        );
      default:
        return null;
    }
  }

  /**
   * Provide filter categories to html
   */
  get portfolioFilters() {
    return EPortfolioFilter;
  }

  /**
   * Provides boolean for html for whether or not
   * basics should be visible.
   */
  get basicsVisible() {
    const config = this.filterConfigService.getConfigFlags();
    return (
      config.portfolio.filters.portfolioType ||
      config.portfolio.filters.model ||
      config.portfolio.filters.referenceCurrency ||
      config.portfolio.filters.bu ||
      config.portfolio.filters.waiveOfAdvice ||
      config.portfolio.filters.mifid ||
      config.portfolio.filters.fidleg ||
      config.portfolio.filters.qualifiedInvestor ||
      config.portfolio.filters.rmLocation ||
      config.portfolio.filters.rmDepartment ||
      config.portfolio.filters.rmMarket ||
      config.portfolio.filters.manager ||
      config.portfolio.filters.advisoryType ||
      config.portfolio.filters.serviceCenter ||
      config.portfolio.filters.businessDivision ||
      config.portfolio.filters.sustainabilityProfile
    );
  }

  /**
   * Provides boolean for html for whether or not
   * basics should be visible.
   */
  get keyFiguresVisible() {
    const config = this.filterConfigService.getConfigFlags();
    return (
      config.portfolio.filters.riskRange ||
      config.portfolio.filters.returnRange ||
      config.portfolio.filters.valueRange
    );
  }

  private initCodeTables(): void {
    forkJoin({
      advisoryTypes: this.codeTableService.getCodeTable(
        ECodeTables.advisoryType
      ),
      businessDivisions: this.codeTableService.getCodeTable(
        ECodeTables.businessDivision
      ),
      businessUnits: this.codeTableService.getCodeTable(
        ECodeTables.businessUnit
      ),
      currencies: this.codeTableService.getCodeTable(ECodeTables.currency),
      fidlegClientSegmentations: this.codeTableService.getCodeTable(
        ECodeTables.fidlegClientSegmentation
      ),
      mifidClientSegmentations: this.codeTableService.getCodeTable(
        ECodeTables.mifidClientSegmentation
      ),
      portfolioStrategies: this.codeTableService.getCodeTable(
        ECodeTables.portfolioStrategy
      ),
      portfolioTypes: this.codeTableService.getCodeTable(
        ECodeTables.portfolioType
      ),
      rmLocations: this.codeTableService.getCodeTable(ECodeTables.country),
      rmMarkets: this.codeTableService.getCodeTable(ECodeTables.rmMarket),
      serviceCenters: this.codeTableService.getCodeTable(
        ECodeTables.serviceCenter
      ),
      sustainabilityProfiles: this.codeTableService.getCodeTable(
        ECodeTables.sustainabilityProfile
      ),
    }).subscribe((data) => {
      this.advisoryTypes = filteredOptions(data.advisoryTypes, this.portfolioForm.advisoryType);
      this.businessDivisions = filteredOptions(data.businessDivisions, this.portfolioForm.businessDivision);
      this.businessUnits = filteredOptions(data.businessUnits, this.portfolioForm.bu);
      this.currencies = filteredOptions(data.currencies, this.portfolioForm.referenceCurrency);
      this.fidlegClientSegmentations = filteredOptions(data.fidlegClientSegmentations, this.portfolioForm.fidleg);
      this.mifidClientSegmentations = filteredOptions(data.mifidClientSegmentations, this.portfolioForm.mifid);
      this.portfolioStrategies = filteredOptions(data.portfolioStrategies, this.portfolioForm.model);
      this.portfolioTypes = filteredOptions(data.portfolioTypes, this.portfolioForm.portfolioType);
      this.rmLocations = data.rmLocations
        .filter((item) => this.isRmLocation(item))
        .map((item) => new KeyValue(item.ident, item.name));
      this.rmMarkets = filteredOptions(data.rmMarkets, this.portfolioForm.rmMarket);
      this.serviceCenters = filteredOptions(data.serviceCenters, this.portfolioForm.serviceCenter);
      this.sustainabilityProfiles = filteredOptions(data.sustainabilityProfiles, this.portfolioForm.sustainabilityProfile);
    });
  }

  private isRmLocation(country: CodeTableEntry): boolean {
    return (
      country.ident === 'LIE' || country.ident === 'AUT' || country.ident === 'CHE'
    );
  }

  /**
   * Generate select options for portfolio filter
   * @param filter Portfolio filter
   */
  private getOptions(filter: EPortfolioFilter): IKeyValue[] {
    switch (filter) {
      case EPortfolioFilter.advisoryType:
        return this.advisoryTypes;
      case EPortfolioFilter.businessDivision:
        return this.businessDivisions;
      case EPortfolioFilter.bu:
        return this.businessUnits;
      case EPortfolioFilter.fidleg:
        return [...this.fidlegClientSegmentations];
      case EPortfolioFilter.mifid:
        return this.mifidClientSegmentations;
      case EPortfolioFilter.model:
        return this.portfolioStrategies;
      case EPortfolioFilter.portfolioType:
        return this.portfolioTypes;
      case EPortfolioFilter.referenceCurrency:
        return this.currencies.sort((x, y) => x.value.localeCompare(y.value));
      case EPortfolioFilter.rmLocation:
        return this.rmLocations;
      case EPortfolioFilter.rmMarket:
        return this.rmMarkets;
      case EPortfolioFilter.serviceCenter:
        return this.serviceCenters;
      case EPortfolioFilter.sustainabilityProfile:
        return this.sustainabilityProfiles;
      default:
        return [];
    }
  }

  formHasErrors(hasErrors: boolean): void {
    this.portfolioForm.formHasErrors = hasErrors;
  }
}
