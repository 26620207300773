import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GridFilterOptionsParams, Portfolio, PortfolioService, UserInfo,} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ConfigService} from 'src/app/services/config.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {TranslateService} from '@ngx-translate/core';
import {
  ApplyColumnStateParams,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
} from 'ag-grid-community';
import {ECodeTables, EModalType, EViewRoutes} from 'src/app/util/enum';
import {CodeTableService} from 'src/app/services/code-table.service';
import {
  genBooleanColumn,
  genCodeTableColumn,
  genNumberColumn,
  genPercentageNumberColumn,
  genRiskStateColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel,
} from 'src/app/util/grid/grid-renderer.util';
import {GridComponent, GridResetEvent,} from 'src/app/shared/grid/grid.component';
import {ModalData} from '../../../models/modal.model';
import {DialogHeight, ModalService} from '../../../services/modal.service';
import {PortfolioDetailsComponent} from 'src/app/shared/portfolio-details/portfolio-details.component';
import {genIconLabelColumn} from '../../../shared/grid/cell-renderers/icon-label.renderer';
import {Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {PermissionService} from '../../../services/permission.service';
import {EProtectedActions} from '../../../util/protected-actions';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {LabelBuilder} from "../../../util/label-builder";

/**
 * Component to display all products that can be filtered by tags.
 */
@Component({
  selector: 'app-portfolio-list',
  templateUrl: './portfolio-list.component.html',
})
export class PortfolioListComponent implements OnInit, OnDestroy {
  @ViewChild('gridComponent', { static: true })
  gridComponent: GridComponent;

  @Input()
  hideNavigate = false;

  @Input()
  isPreview = true;

  /**
   * Data Provider for Product grid
   */
  @Input()
  data: GridDataProvider = this.portfolioService.getPortfolios.bind(
    this.portfolioService
  );

  relationshipManagers: UserInfo[];
  advisors: UserInfo[];
  /**
   * Column definition for the "all portfolios" grid
   */
  columnDefs: ColDef[] = [];
  /**
   * Grid options for "all portfolios"
   */
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.portfolioOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
      this.subscription = I18n.getColumns(this.translateService, event.api);
    },
  };

  subscription: Subscription;

  constructor(
    protected portfolioService: PortfolioService,
    protected globalService: GlobalService,
    protected notificationService: NotificationService,
    protected configService: ConfigService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected modalService: ModalService,
    protected permissionService: PermissionService,
    protected labelBuilder: LabelBuilder,
  ) {
    this.columnDefs = this.genColumnDefs();
  }

  ngOnInit(): void {
    if (!this.hideNavigate) {
      this.columnDefs = [
        genLinkColumn({
          field: 'number',
          headerName: I18n.getColName('number'),
          link: (data: any) => `${EViewRoutes.portfolioOverview}${data.id}`,
          filterParamsInfo: {
            // sven: https://github.com/confinale/aspark/issues/8436
            autoCompleteParams: {
              apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
              autoCompleteField: 'number',
            },
            isMultiSelect: true,
          },
        }),
        ...this.columnDefs,
      ];
    } else {
      // for the Targeting and Filtering we don't allow the link to the portfolio else we lose the filter every time we move away
      this.columnDefs = [
        genTextColumnWithAutoCompleteFilter({
          // sven: https://github.com/confinale/aspark/issues/8436
          field: 'number',
          headerName: I18n.getColName('number'),
          autoCompleteParams: {
            apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
            autoCompleteField: 'number',
          },
          isMultiSelect: true,
        }),
        ...this.columnDefs,
      ];
    }

    if (!this.isPreview) {
      this.columnDefs = [
        ...this.columnDefs,
        genBooleanColumn(
          'closed',
          I18n.getColName('CLOSED'),
          this.translateService
        ),
        {
          ...genBooleanColumn(
            'intermediary',
            I18n.getColName('intermediary'),
            this.translateService,
            'isIntermediary'
          ),
          hide: true,
        },
      ];
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  navigateTo(route: string): void {
    if (this.hideNavigate) {
      return;
    }
    this.globalService.navigate(route);
  }

  refresh() {
    this.gridComponent.refresh();
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridFilterReset({ api: event.api });
  }

  private applyDefaultSorting(api: GridApi) {
    // set default sort if nothing is set
    if (api.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'bpNumber',
            sort: 'asc',
          },
        ],
      };
      api.applyColumnState(columnState);
    }
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultSorting(event.api);
    if (!this.isPreview) this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilterModel = api.getColumnFilterModel('closed');
    if (!closedFilterModel) {
      api.setColumnFilterModel('closed', { values: ['false'] })
        .then(() => api.onFilterChanged());
    }
  }

  private fetchAdvisors(params: any) {
    this.portfolioService.getPortfolioAdvisors().subscribe((data) => {
      this.advisors = data;
      params.success(data.map((d) => d.username));
    });
  }

  private fetchRelationshipManagers(params: any) {
    this.portfolioService
      .getPortfolioRelationshipManagers()
      .subscribe((data) => {
        this.relationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private openPortfolioDetailsModal(_data: Portfolio): void {
    const modalData: ModalData = {
      type: EModalType.detailsDialog,
      title: this.translateService.instant('portfolioDetails'),
      data: _data,
      component: PortfolioDetailsComponent,
    };
    this.modalService.openDefaultDialog(modalData, undefined, false, false, undefined, DialogHeight.AUTO);
  }

  private genColumnDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(
      EProtectedActions.sortAndFilterCid
    );
    return [
      {
        ...genIconLabelColumn({
          field: 'id',
          icon: 'info',
          iconClass: 'info-icon',
          label: () => '',
          headerName: '',
          callback: (data: Portfolio) => this.openPortfolioDetailsModal(data),
        }),
        sortable: false,
        floatingFilter: false,
        suppressColumnsToolPanel: true,
      },
      genCodeTableColumn({
        field: 'type',
        dtoField: 'portfolioType',
        headerName: I18n.getColName('portfolioType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.portfolioType),
      }),
      genCodeTableColumn({
        field: 'advisoryType',
        headerName: I18n.getColName('advisoryType'),
        observable: this.codeTableService.getCodeTable(ECodeTables.advisoryType),
      }),
      genCodeTableColumn({
        field: 'strategy',
        headerName: I18n.getColName('strategy'),
        observable: this.codeTableService.getCodeTable(ECodeTables.portfolioStrategy),
      }),
      genTextColumn(
        'referenceCurrency.ident',
        I18n.getColName('referenceCurrency')
      ),
      genNumberColumn(
        'portfolioValue',
        this.labelBuilder.labelWithCurrency('portfolioValue'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'risk',
        I18n.getColName('risk'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'riskSpreadMin',
        I18n.getColName('riskSpreadMin'),
        this.globalService
      ),
      genPercentageNumberColumn(
        'riskSpreadMax',
        I18n.getColName('riskSpreadMax'),
        this.globalService
      ),
      genRiskStateColumn({
        field: 'riskState',
        headerName: I18n.getColName('riskState'),
        observable: this.codeTableService.getCodeTable(ECodeTables.riskState),
      }),
      {
        ...genTextColumn('bpName', I18n.getColName('bpName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      genTextColumnWithAutoCompleteFilter({
        // sven: https://github.com/confinale/aspark/issues/8436
        field: 'bpNumber',
        headerName: I18n.getColName('bpNumber'),
        autoCompleteParams: {
          apiMethod: (data: GridFilterOptionsParams) => this.portfolioService.getGridFilterOptions(data),
          autoCompleteField: 'bpNumber',
        },
      }),
      genCodeTableColumn({
        field: 'preferredLanguage',
        headerName: I18n.getColName('preferredLanguage'),
        observable: this.codeTableService.getCodeTable(ECodeTables.language),
      }),
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          I18n.getColName('relationshipManager'),
          this.fetchRelationshipManagers.bind(this),
          () => this.relationshipManagers
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
      },
      {
        ...genUserEnumColumn(
          'advisor.username',
          I18n.getColName('advisor'),
          this.fetchAdvisors.bind(this),
          () => this.advisors
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.advisor),
      },
      {
        ...genCodeTableColumn({
          field: 'clients.role',
          headerName: I18n.getColName('clientRole'),
          observable: this.codeTableService.getCodeTable(ECodeTables.clientRole),
        }),
        // if in the future sort is enabled: need to add dtoField: 'clients' and r.values?.map((d) => d.clientRoleName))
        valueFormatter: (r) =>
          [...new Set(r.data.clients.map((d) => d.clientRoleName))].sort().join(', '),
        sortable: false, // removed the sortable because we have a limitation with N to N sorting relationships
      },
      genBooleanColumn(
        'optOut',
        I18n.getColName('opt-out'),
        this.translateService
      ),
    ];
  }
}
