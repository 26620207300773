import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_STORIES_CONFIG = {
  id: 18,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Story List',
        en: 'Story List',
      },
      anchor: 'storyList',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'TODO',
        en: 'Story List',
      },
      anchor: 'storyList',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This is the list of stories available in aSpark.',
        en: 'This is the list of stories available in aSpark.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Here we see all Stories that can be used to create campaigns, such stories can be public to all allowed users or private for a specific user.',
        en: 'Here we see all Stories that can be used to create campaigns, such stories can be public to all allowed users or private for a specific user.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Stories can be marked as "hot" by campaign creators. Hot stories will appear in a rotating bar at the top of the list.',
        en: 'Stories can be marked as "hot" by campaign creators. Hot stories will appear in a rotating bar at the top of the list.',
      },
    },
  ],
  view: EViewRoutes.stories,
};
