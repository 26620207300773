import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { PermissionService } from 'src/app/services/permission.service';

export const PermissionGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const permissionService = inject(PermissionService);
  if (!next?.data?.allowedRoles) {
    return false;
  }
  return permissionService.checkRoutePermission(next.data.allowedRoles);
};
