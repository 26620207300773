import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {CampaignActionService, CampaignIntermediaryService, SuitabilityStatus} from 'src/app/api/core';
import {
  GridStateEnum,
  GridSuitabilityIconAndColorClass,
} from 'src/app/models/grid.model';
import {
  genSuitabilityColumn,
  getIconAndColor,
} from 'src/app/shared/grid/cell-renderers/suitability.renderer';
import {genTextColumn} from 'src/app/util/grid/grid-renderer.util';
import {ColDef, GridOptions} from "ag-grid-community";

@Component({
  selector: 'app-campaign-suitability-details',
  templateUrl: './campaign-suitability-details.component.html',
})
export class CampaignSuitabilityDetailsComponent {
  clientFullName: string;
  intermediaryName: string;
  combinedIconAndColorClass: GridSuitabilityIconAndColorClass;
  rowData: SuitabilityStatus[];
  private campaignActionId: number = undefined;
  private campaignIntermediaryPortfolioId: number = undefined;

  detailsColumns: ColDef[] = [
    genTextColumn('product.name', this.translateService.instant('product')),
    genTextColumn('product.isin', this.translateService.instant('isin')),
    {
      ...genSuitabilityColumn({
        translateService: this.translateService,
        field: 'state',
        stateInfo: (data: SuitabilityStatus) => data,
        headerName: this.translateService.instant('state'),
      }),
      suppressHeaderMenuButton: true,
    },
    genTextColumn('category', this.translateService.instant('category')),
    {
      ...genTextColumn('detail', this.translateService.instant('detail')),
      tooltipField: 'detail',
    },
  ];
  detailsGridOptions: GridOptions = {
    rowHeight: 36,
    floatingFiltersHeight: 0,
    suppressContextMenu: true,
    suppressCellFocus: true,
    enableBrowserTooltips: true,
    paginationAutoPageSize: true,
  };

  constructor(
    private readonly translateService: TranslateService,
    private readonly campaignActionService: CampaignActionService,
    private readonly campaignIntermediaryService: CampaignIntermediaryService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        state: GridStateEnum;
        campaignActionId: number;
        campaignIntermediaryPortfolioId: number;
      };
    }
  ) {
    this.campaignActionId = data.data.campaignActionId;
    this.campaignIntermediaryPortfolioId =
      data.data.campaignIntermediaryPortfolioId;
    this.combinedIconAndColorClass = getIconAndColor(data.data.state);
    if (this.campaignActionId) {
      this.campaignActionService
        .getActionSuitabilities(data.data.campaignActionId)
        .subscribe((suitabilities) => this.handleData(suitabilities));
    } else {
      this.campaignIntermediaryService
        .getCampaignIntermediaryPortfolioSuitabilities(this.campaignIntermediaryPortfolioId)
        .subscribe((suitabilities) => this.handleData(suitabilities));
    }
  }

  private handleData(status: SuitabilityStatus[]): void {
    this.clientFullName = status[0]?.client?.fullName;
    this.intermediaryName = status[0]?.intermediary?.name;
    this.rowData = status;
  }
}
