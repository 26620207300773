<form [formGroup]="form" class="aspark-container-padding">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'name' | translate }}</mat-label>
      <input matInput formControlName="name" type="text" />
    </mat-form-field>
    <mat-form-field *ngIf="isGenderEntry">
      <mat-label>{{ 'value' | translate }}</mat-label>
      <mat-select formControlName="gender">
        <mat-option>{{ 'none' | translate }}</mat-option>
        <mat-option *ngFor="let gender of genders" [value]="gender">
          {{ gender.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!isGenderEntry">
      <mat-label>{{ 'value' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'value' | translate }}"
        formControlName="value"
      />
    </mat-form-field>
  </div>
</form>
