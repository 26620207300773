import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_PRODUCTS_CONFIG = {
  id: 17,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Product List',
        en: 'Product List',
      },
      anchor: 'productList',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Product List',
        en: 'Product List',
      },
      anchor: 'productList',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This is the list of the products available in aSpark.',
        en: 'This is the list of the products available in aSpark.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By default, closed Products are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
        en: 'By default, closed Products are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Hot products are also shown on top of the list, as well as inside the list',
        en: 'Hot products are also shown on top of the list, as well as inside the list',
      },
    },
  ],
  view: EViewRoutes.products,
};
