import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_CLIENT_OVERVIEW_CONFIG = {
  id: 3,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Overview',
        en: 'Overview',
      },
      anchor: 'clientOverview',
    },
    {
      title: {
        de: `Details`,
        en: 'Details',
      },
      anchor: 'clientOverviewDetails',
    },
    {
      title: {
        de: 'All Actions',
        en: 'All Actions',
      },
      anchor: 'clientOverviewActions',
    },
    {
      title: {
        de: 'All Campaigns',
        en: 'All Campaigns',
      },
      anchor: 'clientOverviewCampaigns',
    },
    {
      title: {
        de: 'Portfolios',
        en: 'Portfolios',
      },
      anchor: 'clientOverviewPortfolios',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Client Overview',
        en: `Client Overview`,
      },
      anchor: 'clientOverview',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: `Shows the relevant information of a selected client`,
        en: `Shows the relevant information of a selected client`,
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'clientOverviewDetails',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Basic information about the client',
        en: 'Basic information about the client',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: `Client Details`,
        en: 'Client Details',
      },
      url: '/assets/images/help/help_client_overview_details_en.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Direct delivery allows to set publication types for which the client will always be notified directly, in case he is part of the campaign.',
        en: 'Direct delivery allows to set publication types for which the client will always be notified directly, in case he is part of the campaign.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: `Client Direct Delivery`,
        en: 'Client Direct Delivery',
      },
      url: '/assets/images/help/help_client_overview_direct_delivery_en.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'All Actions',
        en: 'All Actions',
      },
      anchor: 'clientOverviewActions',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all campaign actions of this client',
        en: 'Lists all campaign actions of this client',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Client Actions',
        en: 'Client Actions',
      },
      url: '/assets/images/help/help_client_overview_actions_en.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'All Campaigns',
        en: 'All Campaigns',
      },
      anchor: 'clientOverviewCampaigns',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all campaigns that this client is and has been a part of.',
        en: 'Lists all campaigns that this client is and has been a part of.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Campaigns of this client',
        en: 'Campaigns of this client',
      },
      url: '/assets/images/help/help_client_overview_campaigns.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Portfolios',
        en: 'Portfolios',
      },
      anchor: 'clientOverviewPortfolios',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'List all portfolios the client belongs to',
        en: 'List all portfolios the client belongs to',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Client Portfolios',
        en: 'Client Portfolios',
      },
      url: '/assets/images/help/help_client_overview_portfolios_en.png',
    },
  ],
  view: EViewRoutes.clientOverview,
};
