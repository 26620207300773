<div class="file" *ngIf="isEditable">
  <label>
    <input type="file" name="add" (change)="onAdd($event)"/>
    <div class="file-content">
      <app-config-icon iconIdentifier="attach_file_add"></app-config-icon>
      <span>{{'attachmentAdd' | translate}}</span>
    </div>
  </label>
</div>
<div class="files">
  <div class="file"
       *ngFor="let a of attachments; let k = index">
    <app-config-icon
      *ngIf="isEditable"
      iconIdentifier="delete_forever"
      [tooltip]="'attachmentDelete' | translate"
      (click)="onDelete(a)"></app-config-icon>
    <a [href]="a.source"
       (click)="onDownload($event, a)"
       tabindex="-1"
       [title]="'attachmentDownload' | translate">
      <app-config-icon iconIdentifier="save_alt"></app-config-icon>
      <span>{{a.filename}}</span>
    </a>
  </div>
</div>
