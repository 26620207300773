import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelpContent } from 'src/app/models/help.model';
import { EViewRoutes, EViewTitles } from 'src/app/util/enum';
import { HELP_CONFIG } from 'src/assets/config/help_config';
import { ModalComponent } from '../modal/modal.component';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

/**
 * Help Component.
 * Component for help
 */
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit {
  viewTabName: string;
  generalHelp: HelpContent;
  viewHelp: HelpContent;
  helpUrl: SafeUrl;

  constructor(
    protected domSanitizer: DomSanitizer,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      route: EViewRoutes;
      title: EViewTitles;
      helpUrl: string;
    } }
  ) {
    this.viewTabName = this.data.data.title;
    this.generalHelp = HELP_CONFIG.general;
    this.viewHelp = HELP_CONFIG.views.find(
      (view) => view.view === this.data.data.route
    );
    this.helpUrl = data.data.helpUrl ? this.domSanitizer.bypassSecurityTrustResourceUrl(
      data.data.helpUrl
    ) : null;
  }

  ngOnInit() {}
}
