// Do not edit this file; automatically generated.

/* eslint-disable */
;
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD
    define(["./blockly_compressed.js"], factory);
  } else if (typeof exports === 'object') {
    // Node.js
    module.exports = factory(require("./blockly_compressed.js"));
  } else {
    // Browser
    var factoryExports = factory(root.Blockly);
    root.Blockly.JavaScript = factoryExports.javascriptGenerator;
    root.Blockly.JavaScript.__namespace__ = factoryExports.__namespace__;
  }
})(this, function (__parent__) {
  var $ = __parent__.__namespace__;
  var module$exports$Blockly$JavaScript = {},
    module$contents$Blockly$JavaScript_Variables = $.module$build$src$core$variables,
    module$contents$Blockly$JavaScript_stringUtils = $.module$build$src$core$utils$string,
    module$contents$Blockly$JavaScript_CodeGenerator = $.CodeGenerator$$module$build$src$core$generator,
    module$contents$Blockly$JavaScript_inputTypes = $.module$build$src$core$input_types.inputTypes,
    module$contents$Blockly$JavaScript_Names = $.module$build$src$core$names.Names,
    module$contents$Blockly$JavaScript_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator = new $.CodeGenerator$$module$build$src$core$generator("JavaScript");
  module$exports$Blockly$JavaScript.javascriptGenerator.addReservedWords("break,case,catch,class,const,continue,debugger,default,delete,do,else,export,extends,finally,for,function,if,import,in,instanceof,new,return,super,switch,this,throw,try,typeof,var,void,while,with,yield,enum,implements,interface,let,package,private,protected,public,static,await,null,true,false,arguments," + Object.getOwnPropertyNames(globalThis).join(","));
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC = 0;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NEW = 1.1;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER = 1.2;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL = 2;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_INCREMENT = 3;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DECREMENT = 3;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_BITWISE_NOT = 4.1;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_UNARY_PLUS = 4.2;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_UNARY_NEGATION = 4.3;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT = 4.4;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_TYPEOF = 4.5;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_VOID = 4.6;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DELETE = 4.7;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_AWAIT = 4.8;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EXPONENTIATION = 5;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MULTIPLICATION = 5.1;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION = 5.2;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS = 5.3;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION = 6.1;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION = 6.2;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_BITWISE_SHIFT = 7;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL = 8;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_IN = 8;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_INSTANCEOF = 8;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY = 9;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_BITWISE_AND = 10;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_BITWISE_XOR = 11;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_BITWISE_OR = 12;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_AND = 13;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_OR = 14;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_CONDITIONAL = 15;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT = 16;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_YIELD = 17;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_COMMA = 18;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE = 99;
  module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_OVERRIDES = [[module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MULTIPLICATION, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MULTIPLICATION], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_AND, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_AND], [module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_OR, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_OR]];
  module$exports$Blockly$JavaScript.javascriptGenerator.isInitialized = !1;
  module$exports$Blockly$JavaScript.javascriptGenerator.init = function (a) {
    Object.getPrototypeOf(this).init.call(this);
    this.nameDB_ ? this.nameDB_.reset() : this.nameDB_ = new $.module$build$src$core$names.Names(this.RESERVED_WORDS_);
    this.nameDB_.setVariableMap(a.getVariableMap());
    this.nameDB_.populateVariables(a);
    this.nameDB_.populateProcedures(a);
    const b = [];
    var c = $.module$build$src$core$variables.allDeveloperVariables(a);
    for (let d = 0; d < c.length; d++) b.push(this.nameDB_.getName(c[d], $.NameType$$module$build$src$core$names.DEVELOPER_VARIABLE));
    a = $.module$build$src$core$variables.allUsedVarModels(a);
    for (c = 0; c < a.length; c++) b.push(this.nameDB_.getName(a[c].getId(), $.NameType$$module$build$src$core$names.VARIABLE));
    b.length && (this.definitions_.variables = "var " + b.join(", ") + ";");
    this.isInitialized = !0;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.finish = function (a) {
    const b = Object.values(this.definitions_);
    a = Object.getPrototypeOf(this).finish.call(this, a);
    this.isInitialized = !1;
    this.nameDB_.reset();
    return b.join("\n\n") + "\n\n\n" + a;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.scrubNakedValue = function (a) {
    return a + ";\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.quote_ = function (a) {
    a = a.replace(/\\/g, "\\\\").replace(/\n/g, "\\\n").replace(/'/g, "\\'");
    return "'" + a + "'";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.multiline_quote_ = function (a) {
    return a.split(/\n/g).map(this.quote_).join(" + '\\n' +\n");
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.scrub_ = function (a, b, c) {
    let d = "";
    if (!a.outputConnection || !a.outputConnection.targetConnection) {
      var e = a.getCommentText();
      e && (e = $.module$build$src$core$utils$string.wrap(e, this.COMMENT_WRAP - 3), d += this.prefixLines(e + "\n", "// "));
      for (let f = 0; f < a.inputList.length; f++) a.inputList[f].type === $.module$build$src$core$input_types.inputTypes.VALUE && (e = a.inputList[f].connection.targetBlock()) && (e = this.allNestedComments(e)) && (d += this.prefixLines(e, "// "));
    }
    a = a.nextConnection && a.nextConnection.targetBlock();
    c = c ? "" : this.blockToCode(a);
    return d + b + c;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted = function (a, b, c, d, e) {
    c = c || 0;
    e = e || this.ORDER_NONE;
    a.workspace.options.oneBasedIndex && c--;
    const f = a.workspace.options.oneBasedIndex ? "1" : "0";
    let g,
      h = e;
    0 < c ? g = h = this.ORDER_ADDITION : 0 > c ? g = h = this.ORDER_SUBTRACTION : d && (g = h = this.ORDER_UNARY_NEGATION);
    a = this.valueToCode(a, b, h) || f;
    $.module$build$src$core$utils$string.isNumber(a) ? (a = Number(a) + c, d && (a = -a)) : (0 < c ? a = a + " + " + c : 0 > c && (a = a + " - " + -c), d && (a = c ? "-(" + a + ")" : "-" + a), g = Math.floor(g), e = Math.floor(e), g && e >= g && (a = "(" + a + ")"));
    return a;
  };
  var module$exports$Blockly$JavaScript$colour = {};
  module$exports$Blockly$JavaScript.javascriptGenerator.colour_picker = function (a) {
    return [module$exports$Blockly$JavaScript.javascriptGenerator.quote_(a.getFieldValue("COLOUR")), module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.colour_random = function (a) {
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("colourRandom", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}() {
  var num = Math.floor(Math.random() * Math.pow(2, 24));
  return '#' + ('00000' + num.toString(16)).substr(-6);
}
`) + "()", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.colour_rgb = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "RED", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || 0,
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "GREEN", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || 0;
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "BLUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || 0;
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("colourRgb", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(r, g, b) {
  r = Math.max(Math.min(Number(r), 100), 0) * 2.55;
  g = Math.max(Math.min(Number(g), 100), 0) * 2.55;
  b = Math.max(Math.min(Number(b), 100), 0) * 2.55;
  r = ('0' + (Math.round(r) || 0).toString(16)).slice(-2);
  g = ('0' + (Math.round(g) || 0).toString(16)).slice(-2);
  b = ('0' + (Math.round(b) || 0).toString(16)).slice(-2);
  return '#' + r + g + b;
}
`) + "(" + b + ", " + c + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.colour_blend = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "COLOUR1", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "'#000000'",
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "COLOUR2", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "'#000000'";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "RATIO", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || .5;
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("colourBlend", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(c1, c2, ratio) {
  ratio = Math.max(Math.min(Number(ratio), 1), 0);
  var r1 = parseInt(c1.substring(1, 3), 16);
  var g1 = parseInt(c1.substring(3, 5), 16);
  var b1 = parseInt(c1.substring(5, 7), 16);
  var r2 = parseInt(c2.substring(1, 3), 16);
  var g2 = parseInt(c2.substring(3, 5), 16);
  var b2 = parseInt(c2.substring(5, 7), 16);
  var r = Math.round(r1 * (1 - ratio) + r2 * ratio);
  var g = Math.round(g1 * (1 - ratio) + g2 * ratio);
  var b = Math.round(b1 * (1 - ratio) + b2 * ratio);
  r = ('0' + (r || 0).toString(16)).slice(-2);
  g = ('0' + (g || 0).toString(16)).slice(-2);
  b = ('0' + (b || 0).toString(16)).slice(-2);
  return '#' + r + g + b;
}
`) + "(" + b + ", " + c + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  var module$exports$Blockly$JavaScript$lists = {},
    module$contents$Blockly$JavaScript$lists_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_create_empty = function (a) {
    return ["[]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_create_with = function (a) {
    const b = Array(a.itemCount_);
    for (let c = 0; c < a.itemCount_; c++) b[c] = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ADD" + c, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "null";
    return ["[" + b.join(", ") + "]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_repeat = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("listsRepeat", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}
`),
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ITEM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "null";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "NUM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    return [b + "(" + c + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_length = function (a) {
    return [(module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]") + ".length", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_isEmpty = function (a) {
    return ["!" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]") + ".length", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_indexOf = function (a) {
    var b = "FIRST" === a.getFieldValue("END") ? "indexOf" : "lastIndexOf";
    const c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "FIND", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    b = (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]") + "." + b + "(" + c + ")";
    return a.workspace.options.oneBasedIndex ? [b + " + 1", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION] : [b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_getIndex = function (a) {
    const b = a.getFieldValue("MODE") || "GET",
      c = a.getFieldValue("WHERE") || "FROM_START";
    var d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", "RANDOM" === c ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]";
    switch (c) {
      case "FIRST":
        if ("GET" === b) return [d + "[0]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
        if ("GET_REMOVE" === b) return [d + ".shift()", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
        if ("REMOVE" === b) return d + ".shift();\n";
        break;
      case "LAST":
        if ("GET" === b) return [d + ".slice(-1)[0]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
        if ("GET_REMOVE" === b) return [d + ".pop()", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
        if ("REMOVE" === b) return d + ".pop();\n";
        break;
      case "FROM_START":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT");
        if ("GET" === b) return [d + "[" + a + "]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
        if ("GET_REMOVE" === b) return [d + ".splice(" + a + ", 1)[0]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
        if ("REMOVE" === b) return d + ".splice(" + a + ", 1);\n";
        break;
      case "FROM_END":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT", 1, !0);
        if ("GET" === b) return [d + ".slice(" + a + ")[0]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
        if ("GET_REMOVE" === b) return [d + ".splice(" + a + ", 1)[0]", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
        if ("REMOVE" === b) return d + ".splice(" + a + ", 1);";
        break;
      case "RANDOM":
        d = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("listsGetRandomItem", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(list, remove) {
  var x = Math.floor(Math.random() * list.length);
  if (remove) {
    return list.splice(x, 1)[0];
  } else {
    return list[x];
  }
}
`) + "(" + d + ", " + ("GET" !== b) + ")";
        if ("GET" === b || "GET_REMOVE" === b) return [d, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
        if ("REMOVE" === b) return d + ";\n";
    }
    throw Error("Unhandled combination (lists_getIndex).");
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_setIndex = function (a) {
    function b() {
      if (c.match(/^\w+$/)) return "";
      const g = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName("tmpList", $.NameType$$module$build$src$core$names.VARIABLE),
        h = "var " + g + " = " + c + ";\n";
      c = g;
      return h;
    }
    let c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]";
    const d = a.getFieldValue("MODE") || "GET";
    var e = a.getFieldValue("WHERE") || "FROM_START";
    const f = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TO", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "null";
    switch (e) {
      case "FIRST":
        if ("SET" === d) return c + "[0] = " + f + ";\n";
        if ("INSERT" === d) return c + ".unshift(" + f + ");\n";
        break;
      case "LAST":
        if ("SET" === d) return b() + (c + "[" + c + ".length - 1] = " + f + ";\n");
        if ("INSERT" === d) return c + ".push(" + f + ");\n";
        break;
      case "FROM_START":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT");
        if ("SET" === d) return c + "[" + a + "] = " + f + ";\n";
        if ("INSERT" === d) return c + ".splice(" + a + ", 0, " + f + ");\n";
        break;
      case "FROM_END":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT", 1, !1, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION);
        e = b();
        if ("SET" === d) return e + (c + "[" + c + ".length - " + a + "] = " + f + ";\n");
        if ("INSERT" === d) return e + (c + ".splice(" + c + ".length - " + a + ", 0, " + f + ");\n");
        break;
      case "RANDOM":
        a = b();
        e = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName("tmpX", $.NameType$$module$build$src$core$names.VARIABLE);
        a += "var " + e + " = Math.floor(Math.random() * " + c + ".length);\n";
        if ("SET" === d) return a + (c + "[" + e + "] = " + f + ";\n");
        if ("INSERT" === d) return a + (c + ".splice(" + e + ", 0, " + f + ");\n");
    }
    throw Error("Unhandled combination (lists_setIndex).");
  };
  var module$contents$Blockly$JavaScript$lists_getSubstringIndex = function (a, b, c) {
    return "FIRST" === b ? "0" : "FROM_END" === b ? a + ".length - 1 - " + c : "LAST" === b ? a + ".length - 1" : c;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_getSublist = function (a) {
    var b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]",
      c = a.getFieldValue("WHERE1"),
      d = a.getFieldValue("WHERE2");
    if ("FIRST" === c && "LAST" === d) b += ".slice(0)";else if (b.match(/^\w+$/) || "FROM_END" !== c && "FROM_START" === d) {
      switch (c) {
        case "FROM_START":
          c = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1");
          break;
        case "FROM_END":
          c = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1", 1, !1, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION);
          c = b + ".length - " + c;
          break;
        case "FIRST":
          c = "0";
          break;
        default:
          throw Error("Unhandled option (lists_getSublist).");
      }
      switch (d) {
        case "FROM_START":
          d = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2", 1);
          break;
        case "FROM_END":
          d = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2", 0, !1, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION);
          d = b + ".length - " + d;
          break;
        case "LAST":
          d = b + ".length";
          break;
        default:
          throw Error("Unhandled option (lists_getSublist).");
      }
      b = b + ".slice(" + c + ", " + d + ")";
    } else {
      const e = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1");
      a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2");
      const f = {
        FIRST: "First",
        LAST: "Last",
        FROM_START: "FromStart",
        FROM_END: "FromEnd"
      };
      b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("subsequence" + f[c] + f[d], `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(sequence${"FROM_END" === c || "FROM_START" === c ? ", at1" : ""}${"FROM_END" === d || "FROM_START" === d ? ", at2" : ""}) {
  var start = ${module$contents$Blockly$JavaScript$lists_getSubstringIndex("sequence", c, "at1")};
  var end = ${module$contents$Blockly$JavaScript$lists_getSubstringIndex("sequence", d, "at2")} + 1;
  return sequence.slice(start, end);
}
`) + "(" + b + ("FROM_END" === c || "FROM_START" === c ? ", " + e : "") + ("FROM_END" === d || "FROM_START" === d ? ", " + a : "") + ")";
    }
    return [b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_sort = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL) || "[]",
      c = "1" === a.getFieldValue("DIRECTION") ? 1 : -1;
    a = a.getFieldValue("TYPE");
    const d = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("listsGetSortCompare", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(type, direction) {
  var compareFuncs = {
    'NUMERIC': function(a, b) {
        return Number(a) - Number(b); },
    'TEXT': function(a, b) {
        return String(a) > String(b) ? 1 : -1; },
    'IGNORE_CASE': function(a, b) {
        return String(a).toLowerCase() > String(b).toLowerCase() ? 1 : -1; },
  };
  var compare = compareFuncs[type];
  return function(a, b) { return compare(a, b) * direction; };
}
      `);
    return [b + ".slice().sort(" + d + '("' + a + '", ' + c + "))", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_split = function (a) {
    let b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "INPUT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER);
    const c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "DELIM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    a = a.getFieldValue("MODE");
    if ("SPLIT" === a) b || (b = "''"), a = "split";else if ("JOIN" === a) b || (b = "[]"), a = "join";else throw Error("Unknown mode: " + a);
    return [b + "." + a + "(" + c + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.lists_reverse = function (a) {
    return [(module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL) || "[]") + ".slice().reverse()", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  var module$exports$Blockly$JavaScript$logic = {};
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_if = function (a) {
    var b = 0;
    let c = "";
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX && (c += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX, a));
    do {
      const d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "IF" + b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "false";
      let e = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "DO" + b);
      module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX && (e = module$exports$Blockly$JavaScript.javascriptGenerator.prefixLines(module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX, a), module$exports$Blockly$JavaScript.javascriptGenerator.INDENT) + e);
      c += (0 < b ? " else " : "") + "if (" + d + ") {\n" + e + "}";
      b++;
    } while (a.getInput("IF" + b));
    if (a.getInput("ELSE") || module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX) b = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "ELSE"), module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX && (b = module$exports$Blockly$JavaScript.javascriptGenerator.prefixLines(module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX, a), module$exports$Blockly$JavaScript.javascriptGenerator.INDENT) + b), c += " else {\n" + b + "}";
    return c + "\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_ifelse = module$exports$Blockly$JavaScript.javascriptGenerator.controls_if;
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_compare = function (a) {
    const b = {
        EQ: "==",
        NEQ: "!=",
        LT: "<",
        LTE: "<=",
        GT: ">",
        GTE: ">="
      }[a.getFieldValue("OP")],
      c = "==" === b || "!=" === b ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL,
      d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "A", c) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "B", c) || "0";
    return [d + " " + b + " " + a, c];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_operation = function (a) {
    const b = "AND" === a.getFieldValue("OP") ? "&&" : "||",
      c = "&&" === b ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_AND : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_OR;
    let d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "A", c);
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "B", c);
    if (d || a) {
      const e = "&&" === b ? "true" : "false";
      d || (d = e);
      a || (a = e);
    } else a = d = "false";
    return [d + " " + b + " " + a, c];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_negate = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT;
    return ["!" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "BOOL", b) || "true"), b];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_boolean = function (a) {
    return ["TRUE" === a.getFieldValue("BOOL") ? "true" : "false", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_null = function (a) {
    return ["null", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.logic_ternary = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "IF", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_CONDITIONAL) || "false",
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "THEN", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_CONDITIONAL) || "null";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ELSE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_CONDITIONAL) || "null";
    return [b + " ? " + c + " : " + a, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_CONDITIONAL];
  };
  var module$exports$Blockly$JavaScript$loops = {},
    module$contents$Blockly$JavaScript$loops_stringUtils = $.module$build$src$core$utils$string,
    module$contents$Blockly$JavaScript$loops_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_repeat_ext = function (a) {
    let b;
    b = a.getField("TIMES") ? String(Number(a.getFieldValue("TIMES"))) : module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TIMES", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "0";
    let c = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "DO");
    c = module$exports$Blockly$JavaScript.javascriptGenerator.addLoopTrap(c, a);
    a = "";
    const d = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName("count", $.NameType$$module$build$src$core$names.VARIABLE);
    let e = b;
    b.match(/^\w+$/) || $.module$build$src$core$utils$string.isNumber(b) || (e = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName("repeat_end", $.NameType$$module$build$src$core$names.VARIABLE), a += "var " + e + " = " + b + ";\n");
    return a + ("for (var " + d + " = 0; " + d + " < " + e + "; " + d + "++) {\n" + c + "}\n");
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_repeat = module$exports$Blockly$JavaScript.javascriptGenerator.controls_repeat_ext;
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_whileUntil = function (a) {
    const b = "UNTIL" === a.getFieldValue("MODE");
    let c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "BOOL", b ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "false",
      d = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "DO");
    d = module$exports$Blockly$JavaScript.javascriptGenerator.addLoopTrap(d, a);
    b && (c = "!" + c);
    return "while (" + c + ") {\n" + d + "}\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_for = function (a) {
    var b = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE),
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "FROM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "0",
      d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TO", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "0";
    const e = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "BY", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "1";
    let f = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "DO");
    f = module$exports$Blockly$JavaScript.javascriptGenerator.addLoopTrap(f, a);
    if ($.module$build$src$core$utils$string.isNumber(c) && $.module$build$src$core$utils$string.isNumber(d) && $.module$build$src$core$utils$string.isNumber(e)) {
      var g = Number(c) <= Number(d);
      a = "for (" + b + " = " + c + "; " + b + (g ? " <= " : " >= ") + d + "; " + b;
      b = Math.abs(Number(e));
      a = 1 === b ? a + (g ? "++" : "--") : a + ((g ? " += " : " -= ") + b);
      a += ") {\n" + f + "}\n";
    } else a = "", g = c, c.match(/^\w+$/) || $.module$build$src$core$utils$string.isNumber(c) || (g = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName(b + "_start", $.NameType$$module$build$src$core$names.VARIABLE), a += "var " + g + " = " + c + ";\n"), c = d, d.match(/^\w+$/) || $.module$build$src$core$utils$string.isNumber(d) || (c = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName(b + "_end", $.NameType$$module$build$src$core$names.VARIABLE), a += "var " + c + " = " + d + ";\n"), d = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName(b + "_inc", $.NameType$$module$build$src$core$names.VARIABLE), a += "var " + d + " = ", a = $.module$build$src$core$utils$string.isNumber(e) ? a + (Math.abs(e) + ";\n") : a + ("Math.abs(" + e + ");\n"), a += "if (" + g + " > " + c + ") {\n", a += module$exports$Blockly$JavaScript.javascriptGenerator.INDENT + d + " = -" + d + ";\n", a = a + "}\nfor (" + (b + " = " + g + "; " + d + " >= 0 ? " + b + " <= " + c + " : " + b + " >= " + c + "; " + b + " += " + d + ") {\n" + f + "}\n");
    return a;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_forEach = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE);
    var c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "[]";
    let d = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "DO");
    d = module$exports$Blockly$JavaScript.javascriptGenerator.addLoopTrap(d, a);
    a = "";
    let e = c;
    c.match(/^\w+$/) || (e = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName(b + "_list", $.NameType$$module$build$src$core$names.VARIABLE), a += "var " + e + " = " + c + ";\n");
    c = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getDistinctName(b + "_index", $.NameType$$module$build$src$core$names.VARIABLE);
    d = module$exports$Blockly$JavaScript.javascriptGenerator.INDENT + b + " = " + e + "[" + c + "];\n" + d;
    return a + ("for (var " + c + " in " + e + ") {\n" + d + "}\n");
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.controls_flow_statements = function (a) {
    let b = "";
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX && (b += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX, a));
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX && (b += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX, a));
    if (module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX) {
      const c = a.getSurroundLoop();
      c && !c.suppressPrefixSuffix && (b += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX, c));
    }
    switch (a.getFieldValue("FLOW")) {
      case "BREAK":
        return b + "break;\n";
      case "CONTINUE":
        return b + "continue;\n";
    }
    throw Error("Unknown flow statement.");
  };
  var module$exports$Blockly$JavaScript$math = {},
    module$contents$Blockly$JavaScript$math_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator.math_number = function (a) {
    a = Number(a.getFieldValue("NUM"));
    return [a, 0 <= a ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_UNARY_NEGATION];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_arithmetic = function (a) {
    var b = {
      ADD: [" + ", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION],
      MINUS: [" - ", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION],
      MULTIPLY: [" * ", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MULTIPLICATION],
      DIVIDE: [" / ", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION],
      POWER: [null, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE]
    }[a.getFieldValue("OP")];
    const c = b[0];
    b = b[1];
    const d = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "A", b) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "B", b) || "0";
    return c ? [d + c + a, b] : ["Math.pow(" + d + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_single = function (a) {
    const b = a.getFieldValue("OP");
    let c;
    if ("NEG" === b) return a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "NUM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_UNARY_NEGATION) || "0", "-" === a[0] && (a = " " + a), ["-" + a, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_UNARY_NEGATION];
    a = "SIN" === b || "COS" === b || "TAN" === b ? module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "NUM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION) || "0" : module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "NUM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    switch (b) {
      case "ABS":
        c = "Math.abs(" + a + ")";
        break;
      case "ROOT":
        c = "Math.sqrt(" + a + ")";
        break;
      case "LN":
        c = "Math.log(" + a + ")";
        break;
      case "EXP":
        c = "Math.exp(" + a + ")";
        break;
      case "POW10":
        c = "Math.pow(10," + a + ")";
        break;
      case "ROUND":
        c = "Math.round(" + a + ")";
        break;
      case "ROUNDUP":
        c = "Math.ceil(" + a + ")";
        break;
      case "ROUNDDOWN":
        c = "Math.floor(" + a + ")";
        break;
      case "SIN":
        c = "Math.sin(" + a + " / 180 * Math.PI)";
        break;
      case "COS":
        c = "Math.cos(" + a + " / 180 * Math.PI)";
        break;
      case "TAN":
        c = "Math.tan(" + a + " / 180 * Math.PI)";
    }
    if (c) return [c, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
    switch (b) {
      case "LOG10":
        c = "Math.log(" + a + ") / Math.log(10)";
        break;
      case "ASIN":
        c = "Math.asin(" + a + ") / Math.PI * 180";
        break;
      case "ACOS":
        c = "Math.acos(" + a + ") / Math.PI * 180";
        break;
      case "ATAN":
        c = "Math.atan(" + a + ") / Math.PI * 180";
        break;
      default:
        throw Error("Unknown math operator: " + b);
    }
    return [c, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_constant = function (a) {
    return {
      PI: ["Math.PI", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER],
      E: ["Math.E", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER],
      GOLDEN_RATIO: ["(1 + Math.sqrt(5)) / 2", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION],
      SQRT2: ["Math.SQRT2", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER],
      SQRT1_2: ["Math.SQRT1_2", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER],
      INFINITY: ["Infinity", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC]
    }[a.getFieldValue("CONSTANT")];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_number_property = function (a) {
    var b = {
      EVEN: [" % 2 === 0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY],
      ODD: [" % 2 === 1", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY],
      WHOLE: [" % 1 === 0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY],
      POSITIVE: [" > 0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL],
      NEGATIVE: [" < 0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_RELATIONAL],
      DIVISIBLE_BY: [null, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_EQUALITY],
      PRIME: [null, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL]
    };
    const c = a.getFieldValue("PROPERTY"),
      [d, e, f] = b[c];
    b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "NUMBER_TO_CHECK", e) || "0";
    "PRIME" === c ? a = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathIsPrime", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(n) {
  // https://en.wikipedia.org/wiki/Primality_test#Naive_methods
  if (n == 2 || n == 3) {
    return true;
  }
  // False if n is NaN, negative, is 1, or not whole.
  // And false if n is divisible by 2 or 3.
  if (isNaN(n) || n <= 1 || n % 1 !== 0 || n % 2 === 0 || n % 3 === 0) {
    return false;
  }
  // Check all the numbers of form 6k +/- 1, up to sqrt(n).
  for (var x = 6; x <= Math.sqrt(n) + 1; x += 6) {
    if (n % (x - 1) === 0 || n % (x + 1) === 0) {
      return false;
    }
  }
  return true;
}
`) + "(" + b + ")" : "DIVISIBLE_BY" === c ? (a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "DIVISOR", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS) || "0", a = b + " % " + a + " === 0") : a = b + d;
    return [a, f];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_change = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "DELTA", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE);
    return a + " = (typeof " + a + " === 'number' ? " + a + " : 0) + " + b + ";\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_round = module$exports$Blockly$JavaScript.javascriptGenerator.math_single;
  module$exports$Blockly$JavaScript.javascriptGenerator.math_trig = module$exports$Blockly$JavaScript.javascriptGenerator.math_single;
  module$exports$Blockly$JavaScript.javascriptGenerator.math_on_list = function (a) {
    var b = a.getFieldValue("OP");
    switch (b) {
      case "SUM":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "[]";
        a += ".reduce(function(x, y) {return x + y;}, 0)";
        break;
      case "MIN":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = "Math.min.apply(null, " + a + ")";
        break;
      case "MAX":
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = "Math.max.apply(null, " + a + ")";
        break;
      case "AVERAGE":
        b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathMean", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(myList) {
  return myList.reduce(function(x, y) {return x + y;}, 0) / myList.length;
}
`);
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = b + "(" + a + ")";
        break;
      case "MEDIAN":
        b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathMedian", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(myList) {
  var localList = myList.filter(function (x) {return typeof x === 'number';});
  if (!localList.length) return null;
  localList.sort(function(a, b) {return b - a;});
  if (localList.length % 2 === 0) {
    return (localList[localList.length / 2 - 1] + localList[localList.length / 2]) / 2;
  } else {
    return localList[(localList.length - 1) / 2];
  }
}
`);
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = b + "(" + a + ")";
        break;
      case "MODE":
        b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathModes", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(values) {
  var modes = [];
  var counts = [];
  var maxCount = 0;
  for (var i = 0; i < values.length; i++) {
    var value = values[i];
    var found = false;
    var thisCount;
    for (var j = 0; j < counts.length; j++) {
      if (counts[j][0] === value) {
        thisCount = ++counts[j][1];
        found = true;
        break;
      }
    }
    if (!found) {
      counts.push([value, 1]);
      thisCount = 1;
    }
    maxCount = Math.max(thisCount, maxCount);
  }
  for (var j = 0; j < counts.length; j++) {
    if (counts[j][1] === maxCount) {
        modes.push(counts[j][0]);
    }
  }
  return modes;
}
`);
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = b + "(" + a + ")";
        break;
      case "STD_DEV":
        b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathStandardDeviation", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(numbers) {
  var n = numbers.length;
  if (!n) return null;
  var mean = numbers.reduce(function(x, y) {return x + y;}) / n;
  var variance = 0;
  for (var j = 0; j < n; j++) {
    variance += Math.pow(numbers[j] - mean, 2);
  }
  variance = variance / n;
  return Math.sqrt(variance);
}
`);
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = b + "(" + a + ")";
        break;
      case "RANDOM":
        b = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathRandomList", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(list) {
  var x = Math.floor(Math.random() * list.length);
  return list[x];
}
`);
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LIST", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "[]";
        a = b + "(" + a + ")";
        break;
      default:
        throw Error("Unknown operator: " + b);
    }
    return [a, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_modulo = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "DIVIDEND", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "DIVISOR", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS) || "0";
    return [b + " % " + a, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MODULUS];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_constrain = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0",
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "LOW", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "HIGH", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "Infinity";
    return ["Math.min(Math.max(" + b + ", " + c + "), " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_random_int = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "FROM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TO", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("mathRandomInt", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(a, b) {
  if (a > b) {
    // Swap a and b to ensure a is smaller.
    var c = a;
    a = b;
    b = c;
  }
  return Math.floor(Math.random() * (b - a + 1) + a);
}
`) + "(" + b + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_random_float = function (a) {
    return ["Math.random()", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.math_atan2 = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "X", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0";
    return ["Math.atan2(" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "Y", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "0") + ", " + b + ") / Math.PI * 180", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_DIVISION];
  };
  var module$exports$Blockly$JavaScript$procedures = {},
    module$contents$Blockly$JavaScript$procedures_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator.procedures_defreturn = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("NAME"), $.NameType$$module$build$src$core$names.PROCEDURE);
    var c = "";
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX && (c += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_PREFIX, a));
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX && (c += module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX, a));
    c && (c = module$exports$Blockly$JavaScript.javascriptGenerator.prefixLines(c, module$exports$Blockly$JavaScript.javascriptGenerator.INDENT));
    let d = "";
    module$exports$Blockly$JavaScript.javascriptGenerator.INFINITE_LOOP_TRAP && (d = module$exports$Blockly$JavaScript.javascriptGenerator.prefixLines(module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.INFINITE_LOOP_TRAP, a), module$exports$Blockly$JavaScript.javascriptGenerator.INDENT));
    const e = module$exports$Blockly$JavaScript.javascriptGenerator.statementToCode(a, "STACK");
    let f = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "RETURN", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "",
      g = "";
    e && f && (g = c);
    f && (f = module$exports$Blockly$JavaScript.javascriptGenerator.INDENT + "return " + f + ";\n");
    const h = [],
      k = a.getVars();
    for (let l = 0; l < k.length; l++) h[l] = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(k[l], $.NameType$$module$build$src$core$names.VARIABLE);
    c = "function " + b + "(" + h.join(", ") + ") {\n" + c + d + e + g + f + "}";
    c = module$exports$Blockly$JavaScript.javascriptGenerator.scrub_(a, c);
    module$exports$Blockly$JavaScript.javascriptGenerator.definitions_["%" + b] = c;
    return null;
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.procedures_defnoreturn = module$exports$Blockly$JavaScript.javascriptGenerator.procedures_defreturn;
  module$exports$Blockly$JavaScript.javascriptGenerator.procedures_callreturn = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("NAME"), $.NameType$$module$build$src$core$names.PROCEDURE),
      c = [],
      d = a.getVars();
    for (let e = 0; e < d.length; e++) c[e] = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ARG" + e, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "null";
    return [b + "(" + c.join(", ") + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.procedures_callnoreturn = function (a) {
    return module$exports$Blockly$JavaScript.javascriptGenerator.procedures_callreturn(a)[0] + ";\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.procedures_ifreturn = function (a) {
    let b = "if (" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "CONDITION", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "false") + ") {\n";
    module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX && (b += module$exports$Blockly$JavaScript.javascriptGenerator.prefixLines(module$exports$Blockly$JavaScript.javascriptGenerator.injectId(module$exports$Blockly$JavaScript.javascriptGenerator.STATEMENT_SUFFIX, a), module$exports$Blockly$JavaScript.javascriptGenerator.INDENT));
    a.hasReturnValue_ ? (a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "null", b += module$exports$Blockly$JavaScript.javascriptGenerator.INDENT + "return " + a + ";\n") : b += module$exports$Blockly$JavaScript.javascriptGenerator.INDENT + "return;\n";
    return b + "}\n";
  };
  var module$exports$Blockly$JavaScript$texts = {},
    module$contents$Blockly$JavaScript$texts_NameType = $.NameType$$module$build$src$core$names,
    module$contents$Blockly$JavaScript$texts_strRegExp = /^\s*'([^']|\\')*'\s*$/,
    module$contents$Blockly$JavaScript$texts_forceString = function (a) {
      return module$contents$Blockly$JavaScript$texts_strRegExp.test(a) ? [a, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC] : ["String(" + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
    },
    module$contents$Blockly$JavaScript$texts_getSubstringIndex = function (a, b, c) {
      return "FIRST" === b ? "0" : "FROM_END" === b ? a + ".length - 1 - " + c : "LAST" === b ? a + ".length - 1" : c;
    };
  module$exports$Blockly$JavaScript.javascriptGenerator.text = function (a) {
    return [module$exports$Blockly$JavaScript.javascriptGenerator.quote_(a.getFieldValue("TEXT")), module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_multiline = function (a) {
    a = module$exports$Blockly$JavaScript.javascriptGenerator.multiline_quote_(a.getFieldValue("TEXT"));
    const b = -1 !== a.indexOf("+") ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC;
    return [a, b];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_join = function (a) {
    switch (a.itemCount_) {
      case 0:
        return ["''", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
      case 1:
        return a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ADD0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''", module$contents$Blockly$JavaScript$texts_forceString(a);
      case 2:
        var b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ADD0", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
        a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ADD1", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
        return [module$contents$Blockly$JavaScript$texts_forceString(b)[0] + " + " + module$contents$Blockly$JavaScript$texts_forceString(a)[0], module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION];
      default:
        b = Array(a.itemCount_);
        for (let c = 0; c < a.itemCount_; c++) b[c] = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "ADD" + c, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
        return ["[" + b.join(",") + "].join('')", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
    }
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_append = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE);
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    return b + " += " + module$contents$Blockly$JavaScript$texts_forceString(a)[0] + ";\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_length = function (a) {
    return [(module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''") + ".length", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_isEmpty = function (a) {
    return ["!" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''") + ".length", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_LOGICAL_NOT];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_indexOf = function (a) {
    var b = "FIRST" === a.getFieldValue("END") ? "indexOf" : "lastIndexOf";
    const c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "FIND", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    b = (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''") + "." + b + "(" + c + ")";
    return a.workspace.options.oneBasedIndex ? [b + " + 1", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ADDITION] : [b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_charAt = function (a) {
    const b = a.getFieldValue("WHERE") || "FROM_START",
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", "RANDOM" === b ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''";
    switch (b) {
      case "FIRST":
        return [c + ".charAt(0)", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
      case "LAST":
        return [c + ".slice(-1)", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
      case "FROM_START":
        return a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT"), [c + ".charAt(" + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
      case "FROM_END":
        return a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT", 1, !0), [c + ".slice(" + a + ").charAt(0)", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
      case "RANDOM":
        return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("textRandomLetter", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(text) {
  var x = Math.floor(Math.random() * text.length);
  return text[x];
}
`) + "(" + c + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
    }
    throw Error("Unhandled option (text_charAt).");
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_getSubstring = function (a) {
    var b = a.getFieldValue("WHERE1"),
      c = a.getFieldValue("WHERE2"),
      d = "FROM_END" !== b && "LAST" !== b && "FROM_END" !== c && "LAST" !== c,
      e = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "STRING", d ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    if ("FIRST" === b && "LAST" === c) return [e, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE];
    if (e.match(/^'?\w+'?$/) || d) {
      switch (b) {
        case "FROM_START":
          b = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1");
          break;
        case "FROM_END":
          b = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1", 1, !1, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION);
          b = e + ".length - " + b;
          break;
        case "FIRST":
          b = "0";
          break;
        default:
          throw Error("Unhandled option (text_getSubstring).");
      }
      switch (c) {
        case "FROM_START":
          c = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2", 1);
          break;
        case "FROM_END":
          c = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2", 0, !1, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_SUBTRACTION);
          c = e + ".length - " + c;
          break;
        case "LAST":
          c = e + ".length";
          break;
        default:
          throw Error("Unhandled option (text_getSubstring).");
      }
      e = e + ".slice(" + b + ", " + c + ")";
    } else {
      d = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT1");
      a = module$exports$Blockly$JavaScript.javascriptGenerator.getAdjusted(a, "AT2");
      const f = {
        FIRST: "First",
        LAST: "Last",
        FROM_START: "FromStart",
        FROM_END: "FromEnd"
      };
      e = module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("subsequence" + f[b] + f[c], `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(sequence${"FROM_END" === b || "FROM_START" === b ? ", at1" : ""}${"FROM_END" === c || "FROM_START" === c ? ", at2" : ""}) {
  var start = ${module$contents$Blockly$JavaScript$texts_getSubstringIndex("sequence", b, "at1")};
  var end = ${module$contents$Blockly$JavaScript$texts_getSubstringIndex("sequence", c, "at2")} + 1;
  return sequence.slice(start, end);
}
`) + "(" + e + ("FROM_END" === b || "FROM_START" === b ? ", " + d : "") + ("FROM_END" === c || "FROM_START" === c ? ", " + a : "") + ")";
    }
    return [e, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_changeCase = function (a) {
    const b = {
      UPPERCASE: ".toUpperCase()",
      LOWERCASE: ".toLowerCase()",
      TITLECASE: null
    }[a.getFieldValue("CASE")];
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", b ? module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER : module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    return [b ? a + b : module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("textToTitleCase", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(str) {
  return str.replace(/\\S+/g,
      function(txt) {return txt[0].toUpperCase() + txt.substring(1).toLowerCase();});
}
`) + "(" + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_trim = function (a) {
    const b = {
      LEFT: ".replace(/^[\\s\\xa0]+/, '')",
      RIGHT: ".replace(/[\\s\\xa0]+$/, '')",
      BOTH: ".trim()"
    }[a.getFieldValue("MODE")];
    return [(module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''") + b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_print = function (a) {
    return "window.alert(" + (module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''") + ");\n";
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_prompt_ext = function (a) {
    let b = "window.prompt(" + (a.getField("TEXT") ? module$exports$Blockly$JavaScript.javascriptGenerator.quote_(a.getFieldValue("TEXT")) : module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''") + ")";
    "NUMBER" === a.getFieldValue("TYPE") && (b = "Number(" + b + ")");
    return [b, module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_prompt = module$exports$Blockly$JavaScript.javascriptGenerator.text_prompt_ext;
  module$exports$Blockly$JavaScript.javascriptGenerator.text_count = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "SUB", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("textCount", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(haystack, needle) {
  if (needle.length === 0) {
    return haystack.length + 1;
  } else {
    return haystack.split(needle).length - 1;
  }
}
`) + "(" + b + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_replace = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''",
      c = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "FROM", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    a = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TO", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_NONE) || "''";
    return [module$exports$Blockly$JavaScript.javascriptGenerator.provideFunction_("textReplace", `
function ${module$exports$Blockly$JavaScript.javascriptGenerator.FUNCTION_NAME_PLACEHOLDER_}(haystack, needle, replacement) {
  needle = needle.replace(/([-()\\[\\]{}+?*.$\\^|,:#<!\\\\])/g, '\\\\$1')
                 .replace(/\\x08/g, '\\\\x08');
  return haystack.replace(new RegExp(needle, 'g'), replacement);
}
`) + "(" + b + ", " + c + ", " + a + ")", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.text_reverse = function (a) {
    return [(module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "TEXT", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_MEMBER) || "''") + ".split('').reverse().join('')", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_FUNCTION_CALL];
  };
  var module$exports$Blockly$JavaScript$variables = {},
    module$contents$Blockly$JavaScript$variables_NameType = $.NameType$$module$build$src$core$names;
  module$exports$Blockly$JavaScript.javascriptGenerator.variables_get = function (a) {
    return [module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE), module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ATOMIC];
  };
  module$exports$Blockly$JavaScript.javascriptGenerator.variables_set = function (a) {
    const b = module$exports$Blockly$JavaScript.javascriptGenerator.valueToCode(a, "VALUE", module$exports$Blockly$JavaScript.javascriptGenerator.ORDER_ASSIGNMENT) || "0";
    return module$exports$Blockly$JavaScript.javascriptGenerator.nameDB_.getName(a.getFieldValue("VAR"), $.NameType$$module$build$src$core$names.VARIABLE) + " = " + b + ";\n";
  };
  var module$exports$Blockly$JavaScript$variablesDynamic = {};
  module$exports$Blockly$JavaScript.javascriptGenerator.variables_get_dynamic = module$exports$Blockly$JavaScript.javascriptGenerator.variables_get;
  module$exports$Blockly$JavaScript.javascriptGenerator.variables_set_dynamic = module$exports$Blockly$JavaScript.javascriptGenerator.variables_set;
  var module$exports$Blockly$JavaScript$all = module$exports$Blockly$JavaScript;
  module$exports$Blockly$JavaScript.__namespace__ = $;
  return module$exports$Blockly$JavaScript;
});

