<div class="filter-details-intermediary-container">
  <div class="filter-intermediary-details">
    <span class="h3 sub-section">{{ 'intermediaryDetails' | translate }}</span>
    <div class="toggles">
      <app-filter
        class="intermediary-toggle-filter"
        [metaData]="getFilterMetaData(fields.excludeAll)"
        [(value)]="excludeAll"
        *ngIf="fields.excludeAll | isFilterIntermediaryFilterActive"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        class="intermediary-toggle-filter"
        [metaData]="getFilterMetaData(fields.excludeEAM)"
        [(value)]="intermediariesForm.excludeEAM"
        *ngIf="fields.excludeEAM | isFilterIntermediaryFilterActive"
        [readOnly]="readOnly"
      ></app-filter>
      <app-filter
        class="intermediary-toggle-filter"
        [metaData]="getFilterMetaData(fields.excludeEWA)"
        [(value)]="intermediariesForm.excludeEWA"
        *ngIf="fields.excludeEWA | isFilterIntermediaryFilterActive"
        [readOnly]="readOnly"
      ></app-filter>
    </div>
  </div>
</div>
