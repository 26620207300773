import {Component} from '@angular/core';
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";

/**
 * Utility component to determine which campaign overview component to display
 * based on the user's permissions:
 * - If the user has the permission to view compact campaigns, the compact-overview is displayed
 * - Otherwise, the regular overview is displayed
 */
@Component({
  selector: 'campaign-overview-proxy',
  templateUrl: './campaign-overview-proxy.component.html',
})
export class CampaignOverviewProxyComponent {
  isCompactOverviewEnabled = false;
  constructor(
    private readonly permissionService: PermissionService,
  ) {
    this.isCompactOverviewEnabled = permissionService.hasAnyPermission(EProtectedActions.viewCompactCampaigns);
  }
}
