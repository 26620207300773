<app-tag class="primary">
  <div>{{ assetLabel }} ({{ assetName }})</div>
  <app-config-icon *ngIf="!readOnly"
    class="mat-icon"
    iconIdentifier="delete"
    (click)="onDeleteClick()"
  ></app-config-icon>
</app-tag>
<div class="filter-content">
  <div class="exposure-label">
    <mat-form-field>
      <mat-label>{{ 'asPercValue' | translate }}</mat-label>
      <mat-select
        color="accent"
        (selectionChange)="onAsChanged()"
        [(ngModel)]="asSelect"
        [disabled]="readOnly"
      >
        <mat-option
          *ngFor="let option of asOptions; trackBy: trackByFn"
          [value]="option"
        >{{ option | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="exposure-slider" *ngIf="asSelect == 'percentage'">
    <app-filter
      [metaData]="percMeta"
      [value]="filter.range"
      [readOnly]="readOnly"
      (hasErrors)="formHasErrors($event)"
    ></app-filter>
  </div>
  <div class="exposure-slider" *ngIf="asSelect == 'value'">
    <app-filter
      [metaData]="valueMeta"
      [value]="filter.range"
      [readOnly]="readOnly"
      (hasErrors)="formHasErrors($event)"
    ></app-filter>
  </div>
</div>
