import { inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateFn,
} from '@angular/router';
import { EViewRoutes } from './enum';
import { OAuthService } from 'angular-oauth2-oidc';
import {clearTableStatesFromLocalStorage} from "./grid/table-state.util";
import {UserRoleData, UserService} from "../api/core";
import {PermissionService} from "../services/permission.service";

const checkRoles = (router: Router, userRoles: UserRoleData) => {
  if (!userRoles.hubs || userRoles.hubs.length === 0) {
    router.navigate([EViewRoutes.license]);
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const oauthService = inject(OAuthService);
  const userService = inject(UserService);
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  // hasValidIdToken seems to only check if the token is in a valid format
  // error.service.ts will handle any additional 401 errors that occur
  if (oauthService.hasValidIdToken()) {
    //check license
    permissionService.getLicenseData().subscribe({
      next: (license) => {
        if (license.licensed) {
          if (license.resetLocalTableSettings) {
            clearTableStatesFromLocalStorage();
          }
          const userRoles = permissionService.userRoleData;
          if (userRoles) {
            checkRoles(router, userRoles);
          } else {
            userService.getUserRoles().subscribe((user) => {
              permissionService.updateUser(user);
              checkRoles(router, user);
            });
          }
          return;
        } else {
          router.navigate([EViewRoutes.license]);
        }
      },
      error: (_) => {
        router.navigate([EViewRoutes.license]);
      }
    })
    return true;
  }
  sessionStorage.setItem('forwardUrl', state.url);
  router.navigate([EViewRoutes.login]);
};

