/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserRoleType = 'ADMINISTRATOR' | 'CAMPAIGNCREATOR' | 'DISTRIBUTOR' | 'FRONTCAMPAIGNCREATOR' | 'READONLY' | 'DECENTRALIZEDCAMPAIGNCREATOR';

export const UserRoleType = {
    ADMINISTRATOR: 'ADMINISTRATOR' as UserRoleType,
    CAMPAIGNCREATOR: 'CAMPAIGNCREATOR' as UserRoleType,
    DISTRIBUTOR: 'DISTRIBUTOR' as UserRoleType,
    FRONTCAMPAIGNCREATOR: 'FRONTCAMPAIGNCREATOR' as UserRoleType,
    READONLY: 'READONLY' as UserRoleType,
    DECENTRALIZEDCAMPAIGNCREATOR: 'DECENTRALIZEDCAMPAIGNCREATOR' as UserRoleType
};

