import {ModalData} from '../models/modal.model';
import {EModalType} from '../util/enum';
import {ContentPreviewComponent, ContentPreviewParams,} from '../shared/content-preview/content-preview.component';
import {ModalService} from './modal.service';
import {Injectable} from '@angular/core';
import {ContentAction} from "../campaign/views/campaign-overview/campaign-actions-list-utils";


@Injectable({ providedIn: 'root' })
export class CustomPreviewService {

  constructor(
    private readonly modalService: ModalService,
  ) {
  }

  previewCustomContent(
    data: ContentAction,
    isIntermediary: boolean,
    hideChannelType: boolean = true
  ) {
    const contentPreviewParams = {
      campaignId: data.campaignId,
      actionId: data.id,
      channelType: data.channel?.type?.ident,
      channelTypesToShow: [data.channel?.type?.ident],
      hideChannelType,
      isIntermediary,
    } as ContentPreviewParams;
    const modalData: ModalData = {
      type: EModalType.previewContent,
      title: 'contentView',
      data: { contentPreviewParams },
      submitBtn: null,
      component: ContentPreviewComponent,
    };
    this.modalService.openDefaultDialog(modalData, 'custom-content-dialog');
  }
}
