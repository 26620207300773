import { Component, OnInit } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { ConfigService } from 'src/app/services/config.service';
import { GlobalService } from 'src/app/services/global.service';

/**
 * Component used for login page.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  authConfigs: AuthConfig[];
  selectedAuthConfigIndex: number;

  ngOnInit(): void {}

  constructor(
    protected globalService: GlobalService,
    protected configService: ConfigService
  ) {
    this.authConfigs = this.configService.getAllAuths();
    if (this.authConfigs.length === 1) {
      this.onSubmit(0);
    }
  }

  /**
   * Gets executed on submit and performs login function in global service, sending username and password that were
   * entered by user in HTML form.
   */
  onSubmit(selectedAuthConfigIndex: number): void {
    sessionStorage.setItem(
      'selectedAuthConfigIndex',
      selectedAuthConfigIndex.toString()
    );
    this.globalService.login();
  }
}
