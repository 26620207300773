import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {Content, ContentAttachment, DocumentService, UploadInfo} from 'src/app/api/core';
import {FileSavingUtil} from 'src/app/util/file-saving.util';
import {ModalData} from "../models/modal.model";
import {EModalType} from "../util/enum";
import {ContentDownloadDialogComponent} from "../shared/content-download-dialog/content-download-dialog.component";
import {DialogHeight, DialogWidth, ModalService} from "./modal.service";

@Injectable({ providedIn: 'root' })
export class DocumentTransferService {
  constructor(
    private documentService: DocumentService,
    private httpClient: HttpClient
  ) {}

  /**
   * Uploads a given document and returns an observable which emits the id of the uploaded document
   * @param fileType
   * @param document
   */
  uploadDocument(fileType: 'image' | 'video' | 'document'|'archive', document: any): Observable<string> {
    if (typeof document == 'string') {
      document = this.dataURLtoBlob(document);
    }
    return this.documentService.getUploadUrl(fileType).pipe(
      switchMap((info) => this.httpClient
          .put(info.uploadUrl, document)
          .pipe(switchMap(() => of(info.documentId))))
    );
  }

  dataURLtoBlob(dataUrl: string): Blob {
    const [header, base64Data] = dataUrl.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(base64Data);
    const array = new Uint8Array(binary.length);

    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    return new Blob([array], { type: mime });
  }

  uploadAttachment(filename: string, document: any): Observable<UploadInfo> {
    return this.documentService.getUploadUrlMime(filename).pipe(
      switchMap((info) => this.httpClient
        .put(info.uploadUrl, document)
        .pipe(switchMap(() => of(info))))
    );
  }

  downloadDocument(documentId: string) {
    return this.documentService.getFileUrl(documentId).subscribe((url) => this.httpClient
        .get(url, {
          responseType: 'blob',
        })
        .subscribe((blob) => FileSavingUtil.saveFile(blob, documentId)));
  }
  downloadAttachment(attachment: ContentAttachment) {
    return this.documentService.getFileUrl(attachment.source)
      .subscribe(url => {
        this.httpClient
          .get(url, {
            responseType: 'blob',
          })
          .subscribe((blob) => {
            FileSavingUtil.saveFile(
              blob,
              `${attachment.filename}`
            );
          });
      })
  }

  downloadContentAttachment(content: Content, modalService: ModalService, documentTransferService: DocumentTransferService) {
      if (content.attachments.length == 0) return

      const modalData: ModalData = {
        type: EModalType.contentDownload,
        title: 'contentDownload',
        data: { content },
        submitBtn: null,
        component: ContentDownloadDialogComponent,
      }
      const dialog = modalService.openDefaultDialog(
        modalData,
        "content-download-dialog",
        false,
        false,
        DialogWidth.AUTO,
        DialogHeight.AUTO,
        );
      dialog.afterClosed().subscribe(result => {
        const id = +result;
        const attachment = content.attachments.find(a => a.id == id);
        if (attachment) {
          documentTransferService.downloadAttachment(attachment)
        }
      })
  }
}
