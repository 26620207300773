import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Campaign, Story } from 'src/app/api/core';
import { DataService } from 'src/app/services/data.service';
import { EChartTypes } from 'src/app/util/enum';

/**
 * Component for risk return chart card
 */
@Component({
  selector: 'app-risk-return-chart-card',
  templateUrl: './risk-return-chart-card.component.html',
})
export class RiskReturnChartCardComponent implements OnInit {
  campaign$: Observable<Campaign>;
  story$: Observable<Story>;
  loading$: Observable<boolean>;
  chartDataLoading$: Observable<boolean>;

  constructor(private readonly dataService: DataService) {
    this.campaign$ = this.dataService.campaign$;
    this.story$ = this.dataService.story$;
    this.loading$ = this.dataService.loading$;
    this.chartDataLoading$ = this.dataService.chartDataLoading$;
  }

  ngOnInit(): void {}

  get chartTypes() {
    return EChartTypes;
  }
}
