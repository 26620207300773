/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CampaignActionStatus = 'PENDING' | 'NO_ACTION' | 'SENT' | 'DUPLICATE_CLIENT';

export const CampaignActionStatus = {
    PENDING: 'PENDING' as CampaignActionStatus,
    NOACTION: 'NO_ACTION' as CampaignActionStatus,
    SENT: 'SENT' as CampaignActionStatus,
    DUPLICATECLIENT: 'DUPLICATE_CLIENT' as CampaignActionStatus
};

