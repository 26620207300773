<div class="content-def-container aspark-container-padding">
  <app-card class="fbr-container">
    <div card-header>
      <span class="h4">{{ 'fallbackRules' | translate }}</span>
    </div>
    <div card-body class="fbr-content" [formGroup]="formGroup" *ngIf="formGroup">
      <div class="fbr-item">
        <span>EMEA:</span>
        <mat-form-field>
          <mat-select formControlName="fallbackRuleEMEA">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="fbr-item">
        <span>APAC:</span>
        <mat-form-field>
          <mat-select formControlName="fallbackRuleAPAC">
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </app-card>
  <app-card class="content-definitions">
    <div card-header>
      <span>{{ 'contentDefinitions' | translate }}</span>
    </div>
    <div card-action>
      <button mat-stroked-button (click)="onAdd()">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'contentDefinitionAdd' | translate }}
      </button>
    </div>
    <div card-body>
      <app-grid
        tableId="content-definition-list"
        [rowData]="contentDefinitions"
        [columnDefs]="contentDefColumnDefs"
        [gridOptions]="contentDefGridOptions"
      ></app-grid>
    </div>
  </app-card>
  <app-card class="hcd-mappings">
    <div card-header>
      <span>{{ 'hubChannelDefinitionMapping' | translate }}</span>
    </div>
    <div card-action>
      <button mat-stroked-button (click)="onUpdateMappings()">
        <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
        {{ 'hubChannelDefinitionMappingUpdateShort' | translate }}
      </button>
    </div>
    <div card-body>
      <app-grid
        tableId="hcd-mapping-list"
        [rowData]="hubChannelDefinitionMappings"
        [columnDefs]="mappingColumnDefs"
        [gridOptions]="mappingGridOptions"
      ></app-grid>
    </div>
  </app-card>
</div>
