import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {catchError} from "rxjs/operators";
import {Observable, of} from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import {ProgressSocket} from "../../util/progress-socket";
import {ProgressEvent} from "../../util/monitoring-update-progress-socket";

@Component({
  selector: 'app-progress-update',
  templateUrl: './progress-update.component.html'
})
export class ProgressUpdateComponent implements OnInit, OnDestroy {
  @Input()
  progressName: string;
  @Input()
  completedWaitIntervalMs: number = 1000;
  @Input()
  socketCtor: () => ProgressSocket<ProgressEvent>;
  @Output()
  onCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  progressEvent: ProgressEvent;
  socket: ProgressSocket<ProgressEvent>;

  constructor() { }

  ngOnInit() {
    this.socket = this.socketCtor();
    this.socket.connect().subscribe(e => this.updateProgress(e));
  }

  ngOnDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    this.socket = null;
  }

  get message(): string {
    return (this.progressEvent)
      ? `${this.progressName}${this.toCamelCase(this.progressEvent.message)}`
      : ''
  }

  updateProgress(e: ProgressEvent) {
    this.progressEvent = e;
    if (e.type === "COMPLETED") {
      this.doCompleted();
      this.ngOnDestroy();
    }
    if (e.type === "ERROR") {
      this.ngOnDestroy();
    }

  }

private toCamelCase(src: string): string {
    if (src.length < 2) return src;
    return src.slice(0, 1).toUpperCase() + src.slice(1);
}
  private doCompleted() {
    if (this.completedWaitIntervalMs) {
      setTimeout(() => {
        this.onCompleted.emit(true);
      }, this.completedWaitIntervalMs);
      return;
    }
    this.onCompleted.emit(true);
  }
}
