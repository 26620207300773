import { Component, Input } from '@angular/core';
import { Client } from 'src/app/api/core';

/**
 * Component for client overview details
 */
@Component({
  selector: 'app-client-overview-details',
  templateUrl: './client-overview-details.component.html',
})
export class ClientOverviewDetailsComponent {
  @Input() client: Client;

  constructor() {}
}
