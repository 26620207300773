import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_PORTFOLIOS_CONFIG = {
  id: 15,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Portfolio List',
        en: 'Portfolio List',
      },
      anchor: 'portfolioList',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Portfolio List',
        en: 'Portfolio List',
      },
      anchor: 'portfolioList',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This is the list of the portfolios available in aSpark.',
        en: 'This is the list of the portfolios available in aSpark.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By default, closed Portfolios are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
        en: 'By default, closed Portfolios are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Depending on your permissions, some data in this table may be anonymized. Anonymized data will be shown as "***".',
        en: 'Depending on your permissions, some data in this table may be anonymized. Anonymized data will be shown as "***".',
      },
    },
  ],
  view: EViewRoutes.objectsPortfolios,
};
