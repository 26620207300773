import {Component} from "@angular/core";
import {ColDef, GridOptions} from "ag-grid-community";
import {GridComponent} from "../../../../shared/grid/grid.component";
import {MonitoringOutgoingMessage, MonitoringService} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {genTextColumn} from "../../../../util/grid/grid-renderer.util";

@Component({
  selector: 'app-monitoring-outgoing-messages',
  templateUrl: './monitoring-outgoing-messages.component.html'
})
export class MonitoringOutgoingMessagesComponent {
  grid: GridComponent;

  colDefs: ColDef[] = [
    genTextColumn('topic', this.translateService.instant('topic')),
    {field: 'totalSent', headerName: this.translateService.instant('sent'), width: 90 },
    {field: 'totalFailed', headerName: this.translateService.instant('failed'), width: 90 },
    {field: 'averageElapsedTime', headerName: this.translateService.instant('averageElapsedTime'), width: 100},
    {field: 'totalSentLastHour', headerName: this.translateService.instant('totalSentLastHour'), width: 140},
    {field: 'totalFailedLastHour', headerName: this.translateService.instant('totalFailedLastHour'), width: 140},
    {field: 'averageElapsedTimeLastHour', headerName: this.translateService.instant('averageElapsedTimeLastHour'), width: 140},
    {field: 'totalSentLastDay', headerName: this.translateService.instant('totalSentLastDay'), width: 140},
    {field: 'totalFailedLastDay', headerName: this.translateService.instant('totalFailedLastDay'), width: 140},
    {field: 'averageElapsedTimeLastDay', headerName: this.translateService.instant('averageElapsedTimeLastDay'), width: 140},
    {field: 'totalSentLastWeek', headerName: this.translateService.instant('totalSentLastWeek'), width: 140},
    {field: 'totalFailedLastWeek', headerName: this.translateService.instant('totalFailedLastWeek'), width: 140},
    {field: 'averageElapsedTimeLastWeek', headerName: this.translateService.instant('averageElapsedTimeLastWeek'), width: 140},
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };
  rowData: MonitoringOutgoingMessage[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly monitoringService: MonitoringService,
  ) {
    this.monitoringService.getOutgoingMessagesStats().subscribe(data => {
      this.rowData = data.pageItems;
    });
  }

  onRefresh() {
    this.monitoringService.getOutgoingMessagesStats().subscribe(data => {
      this.rowData = data.pageItems;
    });
  }
}
