<div class="intermediary-portfolios-container aspark-container-padding"
     [class.collapsed]="isCollapsed">
  <app-grid
    #portfolios_grid
    tableId="intermediary-portfolios-list"
    [rowData]=portfolios
    [columnDefs]="colDefs"
    [gridOptions]="gridOptions"
    (gridFiltersReset)="gridFilterReset($event)"
  ></app-grid>
  <div class="grid-divider" #separator *ngIf="!isCollapsed">
    <button
      mat-stroked-button
      color="primary"
      (click)="addSelectedPortfolios()"
      aria-label="'addPortfolios' | translate"
      [matTooltip]="'addPortfolios' | translate"
    >
      <app-config-icon iconIdentifier="navigate_before"></app-config-icon>
    </button>
  </div>
  <app-grid
    #select_grid
    tableId="intermediary-portfolios-select-list"
    *ngIf="!isCollapsed"
    [data]=portfoliosProvider
    [columnDefs]="portfolioColDefs"
    [gridOptions]="gridOptionsSelect"
    (gridFiltersReset)="gridFilterReset($event)"
  ></app-grid>
  <button
    mat-flat-button
    color="primary"
    *ngIf="canCollapse"
    class="collapse-button"
    (click)="isCollapsed = !isCollapsed"
  >
    <app-config-icon
      [iconIdentifier]="!isCollapsed ? 'expand_less' : 'expand_more'"
    ></app-config-icon>
    <span>
    {{ (isCollapsed ? 'showMore' : 'showLess') | translate }}
    </span>
  </button>
</div>
