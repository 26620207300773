import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/services/modal.service';
import { Product } from 'src/app/api/core';
import { ModalData } from 'src/app/models/modal.model';
import { EModalType } from 'src/app/util/enum';
import { TranslateService } from '@ngx-translate/core';
import { ProductDetailsFormComponent } from './product-details-form/product-details-form.component';
import { NotificationService } from 'src/app/services/notification.service';
import { EProtectedActions } from '../../../util/protected-actions';

/**
 * Component to display product details and additional sidebar containing linked stories.
 */
@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
})
export class ProductOverviewComponent implements OnDestroy {
  /**
   * Current product object
   */
  @Input() product: Product;
  /**
   * Route subscripotion
   */
  routeSubscription: Subscription;

  @Output() productChange: EventEmitter<Product> = new EventEmitter<Product>();

  productDetailsUpdated = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected translateService: TranslateService,
    protected notificationService: NotificationService
  ) {
    this.routeSubscription = activatedRoute.data.subscribe((data) =>
      this.handleRouteData(data)
    );
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  private handleRouteData(data: any): void {
    if (data.product) {
      this.product = data.product;
    }
  }

  editProductDetails() {
    const modalData: ModalData = {
      type: EModalType.editProduct,
      title: EModalType.editProduct,
      data: { product: this.product },
      submitBtn: {
        label: this.translateService.instant('update'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ProductDetailsFormComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(modalData);

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success && result.product) {
        this.productChange.emit(result.product);
        this.product = result.product;
        this.productDetailsUpdated = true;
        this.notificationService.handleSuccess(
          this.translateService.instant('productUpdateSuccess')
        );
      }
    });
  }
  get protectedActions() {
    return EProtectedActions;
  }
}
