import {Component, Input} from "@angular/core";
import {
  DistributorClientSummary,
  DistributorPortfolioSummary,
  DistributorTotalReceived,
  HomeChartData
} from "../../../api/core";
import {LabelBuilder} from "../../../util/label-builder";
import {EViewRoutes} from "../../../util/enum";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-distributor-summary',
  templateUrl: './distributor-summary.component.html'
})
export class DistributorSummaryComponent {
  @Input()
  summary: DistributorClientSummary | DistributorPortfolioSummary | DistributorTotalReceived | HomeChartData;

  @Input()
  showHeader: boolean = true;

  @Input()
  showReceived: boolean = false;

  @Input()
  showViewed: boolean = false;

  @Input()
  showMonitoringValues: boolean = false;

  @Input()
  showTurnoverDetails: boolean = true;

  @Input()
  goBackRoute: EViewRoutes;

  @Input()
  goBackLinkMsgKey: string;

  constructor(
    protected readonly labelBuilder: LabelBuilder,
    private route: ActivatedRoute
  ) {}

  get activeRoute() {
    return this.route.snapshot.data.viewRoute;
  }

  protected readonly EViewRoutes = EViewRoutes;
}
