<div class="header-bar">
  <button class="choose-csv" mat-raised-button (click)="clickCSV('isin')">
    <app-config-icon iconIdentifier="upload_file"></app-config-icon>
    {{'uploadIsinList' | translate}}
  </button>
  <div class="selected-count">
    {{ 'selectedProducts' | translate}}:
    {{ selectedProducts.length }}
  </div>
  <input type="file" id="fileCSV" accept="text/csv" (change)="chooseCSV($event)"/>
  <app-grid-filter
    *ngIf="gridApi"
    [gridApi]="gridApi"
    [dialogTitle]="'filters' | translate"
    [filterModelSubject]="filterModelSubject"
    [showSearch]="true"
    (searchChanged)="(searchValue = $event)"
  ></app-grid-filter>
</div>
<app-grid
  #product_grid
  [gridFilterEnabled]="false"
  [searchValue]="searchValue"
  tableId="product-search-table"
  [showSearch]="true"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  [data]="gridData"
  (gridFiltersChanged)="filterModelSubject.next($event.api.getFilterModel())"
></app-grid>
<mat-checkbox
  class="select-all-checkbox"
  #selectAllCheckbox
  color="primary"
  [checked]="allSelected"
  [indeterminate]="someSelected"
  [matTooltip]="selectTooltip | translate"
  (click)="toggleSelection()"
></mat-checkbox>
