<div class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    (tabChanged)="onTabChanged($event)"
  >

    <app-config-icon
      tab-action
      *ngIf="protectedActions.editClient | protectedAction"
      [tooltip]="'refresh' | translate"
      (click)="refresh()" iconIdentifier="refresh">
    </app-config-icon>
  </app-tabs-panel>

  <div *ngIf="activeTab === tabs[0].text" class="list-container">
    <app-distributor-clients [refreshEvent]="refreshSubject"></app-distributor-clients>
  </div>
  <div *ngIf="activeTab === tabs[1].text" class="list-container">
    <app-distributor-portfolios [refreshEvent]="refreshSubject"></app-distributor-portfolios>
  </div>
  <div *ngIf="activeTab === tabs[2].text" class="list-container">
    <app-distributor-intermediaries [refreshEvent]="refreshSubject"></app-distributor-intermediaries>
  </div>
  <div *ngIf="activeTab === tabs[3].text" class="list-container">
    <app-distributor-employees [refreshEvent]="refreshSubject"></app-distributor-employees>
  </div>
</div>
