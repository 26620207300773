import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_STORY_OVERVIEW_CONFIG = {
  id: 19,
  version: '0.1',
  navigation: [
    {
      title: {
        de: `Details`,
        en: 'Details',
      },
      anchor: 'storyOverviewDetails',
    },
    {
      title: {
        de: 'Stats',
        en: 'Stats',
      },
      anchor: 'storyOverviewStats',
    },
    {
      title: {
        de: 'Contents and products',
        en: 'Contents and products',
      },
      anchor: 'storyOverviewContentsProducts',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'storyOverviewDetails',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Basic information about the story and the active filters that limit the amount of portfolios.',
        en: 'Basic information about the story and the active filters that limit the amount of portfolios.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: `Story Details`,
        en: 'Story Details',
      },
      url: '/assets/images/help/help_story_overview_details.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Stats',
        en: 'Stats',
      },
      anchor: 'storyOverviewStats',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists story-filtered portfolios, in the top chart we see a scattered-graph of the distribution and in the bottom the charts show more details on the portfolio values.',
        en: 'Lists story-filtered portfolios, in the top chart we see a scattered-graph of the distribution and in the bottom the charts show more details on the portfolio values.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Story Portfolios',
        en: 'Story Portfolios',
      },
      url: '/assets/images/help/help_story_overview_portfolios.png',
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Story Portfolios bar chart',
        en: 'Story Portfolios bar chart',
      },
      url: '/assets/images/help/help_story_overview_portfolios_2.png',
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Story Portfolios pie chart',
        en: 'Story Portfolios pie chart',
      },
      url: '/assets/images/help/help_story_overview_portfolios_3.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Contents and products',
        en: 'Contents and products',
      },
      anchor: 'storyOverviewContentsProducts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists language specific contents that are used for campaign actions and BUY/SELL products linked to the story.',
        en: 'Lists language specific contents that are used for campaign actions and BUY/SELL products linked to the story.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Contents and products',
        en: 'Contents and products',
      },
      url: '/assets/images/help/help_story_overview_contents_products.png',
    },
  ],
  view: EViewRoutes.storyOverview,
};
