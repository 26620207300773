import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthConfig } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class ConfigLoaderService {
  /**
   * URL to config file for auth
   */
  private readonly AUTH_CONFIG_URL = 'assets/config/auth_config.json';
  /**
   * Observable for config content for auth
   */
  private authConfig$: Observable<AuthConfig[]>;

  constructor(private http: HttpClient) {}
  /**
   * load Auth configurations
   */
  loadAuthConfiguration() {
    if (!this.authConfig$) {
      this.authConfig$ = this.http
        .get<AuthConfig[]>(this.AUTH_CONFIG_URL)
        .pipe(shareReplay(1));
    }
    return this.authConfig$;
  }
}
