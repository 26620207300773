import {Component, Input} from "@angular/core";
import {FilterIntermediaries} from "../../../models/filter.model";
import {
  EFilterSubType,
  EFilterType,
  EIntermediaryFilter,
  EIntermediaryFilterTranslation,
} from "../../../util/enum";
import {FilterMetaData, IFilterMetaData} from "../../../models/filter-meta-data.model";

@Component({
  selector: 'app-filter-details-intermediary',
  templateUrl: './filter-details-intermediary.component.html'
})
export class FilterDetailsIntermediaryComponent {
  /**
   * Filter values for all portfolio filters
   */
  @Input() intermediariesForm: FilterIntermediaries;
  /**
   * Current story id
   */
  @Input() storyId: string;

  @Input() readOnly = false;

  get excludeAll() {
    return this.intermediariesForm?.excludeEWA && this.intermediariesForm?.excludeEAM;
  }

  set excludeAll(value: boolean) {
    this.intermediariesForm.excludeEWA = value;
    this.intermediariesForm.excludeEAM = value;
  }

  get fields() {
    return EIntermediaryFilter;
  }

  getFilterMetaData(filter: EIntermediaryFilter): IFilterMetaData {
    switch (filter) {
      case EIntermediaryFilter.excludeAll:
        return new FilterMetaData(
          EFilterType.toggle,
          EIntermediaryFilterTranslation.excludeAll,
          EFilterSubType.toggleRequired
        );
      case EIntermediaryFilter.excludeEAM:
        return new FilterMetaData(
          EFilterType.toggle,
          EIntermediaryFilterTranslation.excludeEAM,
          EFilterSubType.toggleRequired
        );
      case EIntermediaryFilter.excludeEWA:
        return new FilterMetaData(
          EFilterType.toggle,
          EIntermediaryFilterTranslation.excludeEWA,
          EFilterSubType.toggleRequired
        );
      default:
        return null;
    }
  }
}
