<ng-container *ngFor="let item of contentItems" [ngSwitch]="item.type">
  <div
    *ngSwitchCase="helpContentTypes.header"
    class="header-item h2"
    [id]="item.anchor"
    [innerHTML]="item.text[currentLanguage]"
  ></div>
  <div
    *ngSwitchCase="helpContentTypes.subheader"
    class="subheader-item h3"
    [id]="item.anchor"
    [innerHTML]="item.text[currentLanguage]"
  ></div>
  <div
    *ngSwitchCase="helpContentTypes.paragraph"
    class="paragraph-item body"
    [id]="item.anchor"
    [innerHTML]="item.text[currentLanguage]"
  ></div>
  <div
    *ngSwitchCase="helpContentTypes.icon"
    class="icon-item"
    [id]="item.anchor"
  >
    <app-config-icon [iconIdentifier]="item.iconIdentifier"></app-config-icon>
    <span [innerText]="item.text[currentLanguage]"></span>
  </div>
  <div
    *ngSwitchCase="helpContentTypes.image"
    [id]="item.anchor"
    class="image-item"
  >
    <img [src]="item.url | safeUrl" />
    <div class="label" [innerHTML]="item.text[currentLanguage]"></div>
  </div>
</ng-container>
