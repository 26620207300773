<div class="grid-filter-item">
  <div class="grid-filter-item-title h4">{{ filterConfig.name }}</div>
  <div class="grid-filter-item-content">
    <form [formGroup]="textFilterForm">
      <mat-form-field appearance="outline" *ngIf="typeOptions.length > 1">
        <mat-label>{{ 'type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option
            *ngFor="let option of typeOptions"
            [value]="option.value"
            >{{ option.key }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!isMultiSelect">
        <mat-label>{{ 'value' | translate }}</mat-label>
        <input matInput formControlName="filter" [matAutocomplete]="auto" (keyup)="closeAutoCompletePanel($event)"/>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of autoOptions"
            [value]="option"
            >{{ option }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="isMultiSelect">
        <mat-label>{{ 'value' | translate }}</mat-label>
        <mat-chip-grid #chipList aria-label="getLabel()">
          <mat-chip-row
            *ngFor="
              let chip of multiSelected;
              let i = index;
              trackBy: trackByFn
            "
            [removable]="true"
            (removed)="removeChip(i)"
          >
            {{ chip }}
            <app-config-icon
              matChipRemove
              iconIdentifier="cancel"
            ></app-config-icon>
          </mat-chip-row>
          <input
            #chipInput
            [formControl]="chipControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="selectChip($event)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectChip($event)"
        >
          <mat-option
            *ngFor="let filteredChip of autoOptions; trackBy: trackByFn"
            [value]="filteredChip"
          >
            {{ filteredChip }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <button mat-flat-button class="grid-filter-apply" color="primary" (click)="onApplyFilter()">
    {{'close' | translate}}
  </button>
</div>
