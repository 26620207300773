import {Component, OnDestroy, ViewChild} from "@angular/core";
import {
  genBooleanColumn,
  genEnumColumn,
  genNumberColumn,
  genTextColumn
} from "../../../../../util/grid/grid-renderer.util";
import {I18n} from "../../../../../services/i18n.service";
import {ContentService, FieldDefinition, FieldDefinitionType} from "../../../../../api/core";
import {genIconButtonColumn} from "../../../../../shared/grid/cell-renderers/icon-button.renderer";
import {EModalType} from "../../../../../util/enum";
import {TranslateService} from "@ngx-translate/core";
import {ColDef, GridOptions, GridReadyEvent} from "ag-grid-community";
import {Subscription} from "rxjs";
import {ModalData} from "../../../../../models/modal.model";
import {FieldDefinitionDialogComponent} from "../field-definition-dialog/field-definition-dialog.component";
import {DialogHeight, DialogWidth, ModalService} from "../../../../../services/modal.service";
import {GridComponent} from "../../../../../shared/grid/grid.component";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {NotificationService} from "../../../../../services/notification.service";
import {GlobalService} from "../../../../../services/global.service";

@Component({
  selector: 'app-field-definition-details',
  templateUrl: './field-definition-details.component.html'
})
export class FieldDefinitionDetailsComponent implements OnDestroy {
  @ViewChild('grid') grid: GridComponent;

  columnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        callback: (data: FieldDefinition) => this.onEdit(data),
        icon: 'edit_m',
        tooltip: this.translateService.instant('edit'),
      }), sortable: false
    },
    genTextColumn('ident', I18n.getColName('ident')),
    genTextColumn('labelEn', I18n.getColName('labelEnShort')),
    genTextColumn('labelDe', I18n.getColName('labelDeShort')),
    genTextColumn('description', I18n.getColName('description')),
    {
      ...genBooleanColumn(
        'mandatory',
        I18n.getColName('mandatory'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    genEnumColumn({
      field: 'editorType',
      values: Object.values(FieldDefinitionType),
      headerName: I18n.getColName('editorType'),
    }),
    {
      ...genBooleanColumn(
        'customEditable',
        I18n.getColName('customEditable'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    genNumberColumn(
      'maxLength',
      I18n.getColName('maximumCharacters'),
      this.globalService,
      (d) => (!d.value ? '' : this.globalService.getFormattedValue(d.value, 0))
    ),
    genNumberColumn(
      'heightInLines',
      I18n.getColName('heightInLines'),
      this.globalService,
      (d) => (!d.value ? '' : this.globalService.getFormattedValue(d.value, 0))
    ),
    {
      ...genBooleanColumn(
        'showInGeneral',
        I18n.getColName('showInGeneral'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genBooleanColumn(
        'showInCustom',
        I18n.getColName('showInCustom'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
    {
      ...genIconButtonColumn({
        callback: (data: FieldDefinition) => this.onDelete(data),
        icon: 'delete',
        tooltip: this.translateService.instant('delete'),
        hidden: (data: FieldDefinition) => data.systemDefined === true,
      }), sortable: false
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<FieldDefinition>) => {
      this.subscriptions.push(
        I18n.getColumns(this.translateService, event.api)
      );
    }
  };

  fieldDefinitions: FieldDefinition[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly modalService: ModalService,
    protected readonly contentService: ContentService,
    protected readonly notificationService: NotificationService,
    protected readonly globalService: GlobalService,
  ) {
    this.updateTableEntries();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateTableEntries() {
    this.contentService.getFieldDefinitions().subscribe(fieldDefinitions => {
      this.fieldDefinitions = fieldDefinitions;
    });
  }

  getFieldIdents(): string[] {
    return this.fieldDefinitions.map(fd => fd.ident.toUpperCase());
  }

  onAdd() {
    const modalData: ModalData = {
      type: EModalType.fieldDefinitionAdd,
      title: EModalType.fieldDefinitionAdd,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        fieldDefinition: {
          ident: '',
          labelEn: '',
          labelDe: '',
          description: '',
          mandatory: null,
          editorType: null,
          customEditable: null,
          maxLength: false,
          heightInLines: null,
          showInGeneral: null,
          showInCustom: null,
        },
        fieldIdents: this.getFieldIdents(),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: FieldDefinitionDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF,
      DialogHeight.AUTO
    );
    ref.afterClosed().subscribe((result: FieldDefinition | null) => {
      if (!result) return;
      this.contentService.createFieldDefinition(result).subscribe(_ => {
        this.updateTableEntries();
      });
    });
  }

  onEdit(data: FieldDefinition) {
    const modalData: ModalData = {
      type: EModalType.fieldDefinitionEdit,
      title: EModalType.fieldDefinitionEdit,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        fieldDefinition: data,
        fieldIdents: this.getFieldIdents(),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: FieldDefinitionDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF,
      DialogHeight.AUTO
    );
    ref.afterClosed().subscribe((result: FieldDefinition | null) => {
      if (!result) return;
      this.contentService.editFieldDefinitionById(result.id, result).subscribe(_ => {
        this.updateTableEntries();
      });
    });
  }

  onDelete(data: FieldDefinition) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: 'fieldDefinitionDelete',
      component: null,
      data: {
        message: this.translateService.instant('fieldDefinitionDeleteMessage'),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.contentService.deleteFieldDefinitionById(data.id)
            .subscribe({
              next: () => {
                this.updateTableEntries();
                modalRef.close();
              }, error: () => {
                this.notificationService.handleWarning(this.translateService.instant("fieldDefinitionReferenced"));
                modalRef.close();
              }
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }
}
