import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from 'src/app/shared/page/page.component';
import { AuthGuard } from 'src/app/util/auth.guard';
import {
  ERoutePaths,
  EViewRoutes,
  EViewTitles,
} from 'src/app/util/enum';
import { PermissionGuard } from 'src/app/util/permission.guard';
import { ClientResolver } from 'src/app/util/resolvers';
import { ClientCatalogueComponent } from './views/client-catalogue/client-catalogue.component';
import { ClientOverviewComponent } from './views/client-overview/client-overview.component';

/**
 * Client route definition.
 * Contains all routing definitions related to clients
 */
const clientRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: ClientCatalogueComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.clients,
          viewRoute: EViewRoutes.clients,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.overview,
        component: ClientOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.clientOverview,
          viewRoute: EViewRoutes.clientOverview,
          allowedRoles: [],
        },
        resolve: {
          client: ClientResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(clientRoutes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {}
