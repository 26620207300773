import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_MONITORING_RELATIONSHIP_MANAGER_CONFIG = {
  id: 10,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Monitoring relationship managers',
        en: 'Monitoring relationship managers',
      },
      anchor: 'monitoringRelationshipManagers',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Monitoring relationship managers',
        en: 'Monitoring relationship managers',
      },
      anchor: 'monitoringRelationshipManagers',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lists all monitoring statistics grouped by relationship manager.',
        en: 'Lists all monitoring statistics grouped by relationship manager.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Monitoring relationship managers',
        en: 'Monitoring relationship managers',
      },
      url: '/assets/images/help/help_monitoring_relationship_managers.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '1 - Relationship managers',
        en: '1 - Relationship managers',
      },
      anchor: 'monitoringRelationshipManagers',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Relationship manager that has LAUNCHED campaigns were actions have been performed.',
        en: 'Relationship manager that has LAUNCHED campaigns were actions have been performed.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: '2 - Charts',
        en: '2 - Charts',
      },
      anchor: 'monitoringRelationshipManagersCharts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Contact-Rate: Describes how many portfolios where contacted.',
        en: 'Contact-Rate: Describes how many portfolios where contacted.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Executed-rate: Describes how many actions where executed.',
        en: 'Executed-rate: Describes how many actions where executed.',
      },
    },
  ],
  view: EViewRoutes.monitoringRelationshipManager,
};
