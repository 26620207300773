import { EHelpContentType } from 'src/app/util/enum';

export const HELP_GENERAL_CONFIG = {
  id: 0,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Customize tables',
        en: 'Customize tables',
      },
      anchor: 'howto-customize',
      children: [
        {
          title: {
            de: 'Adjusting column widths',
            en: 'Adjusting column widths',
          },
          anchor: 'howto-customize-width',
        },
        {
          title: {
            de: 'Edit the order of columns',
            en: 'Edit the order of columns',
          },
          anchor: 'howto-customize-order',
        },
        {
          title: {
            de: 'Hide and unhide columns ',
            en: 'Hide and unhide columns ',
          },
          anchor: 'howto-customize-hide',
        },
        {
          title: {
            de: 'Reset table properties',
            en: 'Reset table properties',
          },
          anchor: 'howto-customize-reset',
        },
        {
          title: {
            de: 'Sorting and filtering',
            en: 'Sorting and filtering',
          },
          anchor: 'howto-customize-sorting',
        },
        {
          title: {
            de: 'Date filtering',
            en: 'Date filtering',
          },
          anchor: 'howto-date-filtering',
        },
      ],
    },
    {
      title: {
        de: 'Switch Language',
        en: 'Switch Language',
      },
      anchor: 'howto-language',
    },
    {
      title: {
        de: 'Why is a table column missing?',
        en: 'Why is a table column missing?',
      },
      anchor: 'howto-columnmissing',
    },
    {
      title: {
        de: 'See the current aSpark version',
        en: 'See the current aSpark version',
      },
      anchor: 'howto-version',
    },
    {
      title: {
        de: 'When an application error occurs ',
        en: 'When an application error occurs ',
      },
      anchor: 'howto-error',
    },
    {
      title: {
        de: 'See all my open tasks',
        en: 'See all my open tasks',
      },
      anchor: 'howto-opentasks',
    },
    {
      title: {
        de: 'Distribute a launched campaign ',
        en: 'Distribute a launched campaign ',
      },
      anchor: 'howto-distribute',
      children: [
        {
          title: {
            de: 'Open a specific campaign',
            en: 'Open a specific campaign',
          },
          anchor: 'howto-distribute-open',
        },
        {
          title: {
            de: 'Bulk Distribution',
            en: 'Bulk Distribution',
          },
          anchor: 'howto-distribute-distribute',
        },
        {
          title: {
            de: 'Discarding a campaign',
            en: 'Discarding a campaign',
          },
          anchor: 'howto-distribute-discard',
        },
        {
          title: {
            de: 'Customize Channels',
            en: 'Customize Channels',
          },
          anchor: 'howto-distribute-channels',
        },
        {
          title: {
            de: 'Personalize content',
            en: 'Personalize content',
          },
          anchor: 'howto-distribute-personalize',
        },
        {
          title: {
            de: 'Change content Language',
            en: 'Change content Language',
          },
          anchor: 'howto-distribute-language',
        },
        {
          title: {
            de: 'RM & Advisor assignments ',
            en: 'RM & Advisor assignments ',
          },
          anchor: 'howto-distribute-rm',
        },
      ],
    },
    {
      title: {
        de: 'Inspect my client book',
        en: 'Inspect my client book',
      },
      anchor: 'howto-client',
      children: [
        {
          title: {
            de: 'Via Targeting & Filtering',
            en: 'Via Targeting & Filtering',
          },
          anchor: 'howto-client-filtering',
        },
        {
          title: {
            de: 'Via Portfolios Tab',
            en: 'Via Portfolios Tab',
          },
          anchor: 'howto-client-portfolios',
        },
      ],
    },
    {
      title: {
        de: 'Wrong assignee?',
        en: 'Wrong assignee?',
      },
      anchor: 'howto-wrongassignee',
    },
    {
      title: {
        de: 'Suitability',
        en: 'Suitability',
      },
      anchor: 'howto-suitability',
      children: [
        {
          title: {
            de: 'Calculation',
            en: 'Calculation',
          },
          anchor: 'howto-suitability-calculation',
        },
        {
          title: {
            de: 'Pending Suitability',
            en: 'Pending Suitability',
          },
          anchor: 'howto-suitability-pending',
        },
        {
          title: {
            de: 'Faulty Suitabiliy',
            en: 'Faulty Suitabiliy',
          },
          anchor: 'howto-suitability-faulty',
        },
      ],
    },
    {
      title: {
        de: 'See all campaigns for specific portfolio',
        en: 'See all campaigns for specific portfolio',
      },
      anchor: 'howto-campaignsportfolio',
    },
    {
      title: {
        de: 'See all campaigns for specific person',
        en: 'See all campaigns for specific person',
      },
      anchor: 'howto-campaignsclient',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Customize tables',
        en: 'Customize tables',
      },
      anchor: 'howto-customize',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Adjusting column widths',
        en: 'Adjusting column widths',
      },
      anchor: 'howto-customize-width',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you feel that column widths are not distributed optimally there is a column width adjustment button on each table. Just click here to adjust all columns simultaneously: ',
        en: 'If you feel that column widths are not distributed optimally there is a column width adjustment button on each table. Just click here to adjust all columns simultaneously: ',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/1.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you want to adjust a specific column, move your mouse to the edge of a column until a double arrow appears. You can double click now for automatic width adjustment or drag the width to your desired width.',
        en: 'If you want to adjust a specific column, move your mouse to the edge of a column until a double arrow appears. You can double click now for automatic width adjustment or drag the width to your desired width.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/2.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Edit the order of columns ',
        en: 'Edit the order of columns ',
      },
      anchor: 'howto-customize-order',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'You can drag a specific column to your preferred position. Click and hold on a column header to move it to your desired position as indicated below.',
        en: 'You can drag a specific column to your preferred position. Click and hold on a column header to move it to your desired position as indicated below.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/3.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Alternatively, you can change the order of the columns in the hide & unhide columns dialogue.',
        en: 'Alternatively, you can change the order of the columns in the hide & unhide columns dialogue.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Hide and unhide columns',
        en: 'Hide and unhide columns',
      },
      anchor: 'howto-customize-hide',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'There is a button on the top right side of each table.',
        en: 'There is a button on the top right side of each table.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/4.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In the appearing dialogue, you can hide and unhide your columns as well as change the order of the columns.',
        en: 'In the appearing dialogue, you can hide and unhide your columns as well as change the order of the columns.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/5.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Reset table properties',
        en: 'Reset table properties',
      },
      anchor: 'howto-customize-reset',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Click on the restore icon to reset table properties. This will restore all custom sorting and filters. It will also unhide hidden columns.',
        en: 'Click on the restore icon to reset table properties. This will restore all custom sorting and filters. It will also unhide hidden columns.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/6.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Sorting and filtering',
        en: 'Sorting and filtering',
      },
      anchor: 'howto-customize-sorting',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'For sorting a specific column, just click on the corresponding header. You can either sort ascending or descending. The sorting can currently only be applied to one column simultaneously.',
        en: 'For sorting a specific column, just click on the corresponding header. You can either sort ascending or descending. The sorting can currently only be applied to one column simultaneously.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'For filtering, click on the filter icon to apply the specific filter options.',
        en: 'For filtering, click on the filter icon to apply the specific filter options.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/7.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Date filtering',
        en: 'Date filtering',
      },
      anchor: 'howto-date-filtering',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'When filtering for dates you need to use the ISO notation: yyyy-MM-dd. It allows for partial date filtering. The filter shown below would filter for the year 2023',
        en: 'When filtering for dates you need to use the ISO notation: yyyy-MM-dd. It allows for partial date filtering. The filter shown below would filter for the year 2023',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/help-filter1.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To filter by year and month you can enter it as shown below.',
        en: 'To filter by year and month you can enter it as shown below.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/help-filter2.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This type of date filtering can then be combined with different operators from the filter directly. The option to select these operators can be done through clicking on the funnel icon.',
        en: 'This type of date filtering can then be combined with different operators from the filter directly. The option to select these operators can be done through clicking on the funnel icon.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/help-filter3.png',
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/help-filter4.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Switch language',
        en: 'Switch language',
      },
      anchor: 'howto-language',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'On the top right of your screen, you will find the language toggle functionality. English and German are currently supported in aSpark.',
        en: 'On the top right of your screen, you will find the language toggle functionality. English and German are currently supported in aSpark.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/8.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Why is a table column missing?',
        en: 'Why is a table column missing?',
      },
      anchor: 'howto-columnmissing',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Check if the column was accidentally hidden. Go to (Chapter <b>“Customize tables”</b>) to unhide it.',
        en: 'Check if the column was accidentally hidden. Go to (Chapter <b>“Customize tables”</b>) to unhide it.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'See the current aSpark version',
        en: 'See the current aSpark version',
      },
      anchor: 'howto-version',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'You can see the current aSpark version here:',
        en: 'You can see the current aSpark version here:',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/9.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'When an application error occurs',
        en: 'When an application error occurs',
      },
      anchor: 'howto-error',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'For efficient support, please make sure to go to the error tab accessible via the following button (1):',
        en: 'For efficient support, please make sure to go to the error tab accessible via the following button (1):',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/10.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Make sure to double click on the error to switch to the detail view (2). Please take a screenshot of the occurring errors. Send the screenshot together with your documentation on the steps you performed to your system administrator.',
        en: 'Make sure to double click on the error to switch to the detail view (2). Please take a screenshot of the occurring errors. Send the screenshot together with your documentation on the steps you performed to your system administrator.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'See all my open tasks',
        en: 'See all my open tasks',
      },
      anchor: 'howto-opentasks',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Go to the home screen ',
        en: 'Go to the home screen ',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/11.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In this table, you will find all campaigns with <b>pending</b> actions assigned to you. To find actions for a specific campaign or launch date use the filter functionality.',
        en: 'In this table, you will find all campaigns with <b>pending</b> actions assigned to you. To find actions for a specific campaign or launch date use the filter functionality.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To navigate to the specific campaign, click on the <b>campaign name</b>. You will then enter the campaign overview, where you are able to perform a desired action (e.g. send to client via the specified channel, explicitly set “no action”). More details about the distribution are available in the next chapter.',
        en: 'To navigate to the specific campaign, click on the <b>campaign name</b>. You will then enter the campaign overview, where you are able to perform a desired action (e.g. send to client via the specified channel, explicitly set “no action”). More details about the distribution are available in the next chapter.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Distribute a launched campaign',
        en: 'Distribute a launched campaign',
      },
      anchor: 'howto-distribute',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Open a specific campaign',
        en: 'Open a specific campaign',
      },
      anchor: 'howto-distribute-open',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Open the campaign overview tab via the following menu item:',
        en: 'Open the campaign overview tab via the following menu item:',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/12.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Here you find all campaigns with the corresponding status. Only campaigns in status <b>LAUNCHED</b> are relevant for distribution. To navigate to the specific campaign, click on the <b>campaign name</b> or double-click anywhere on the specific line.',
        en: 'Here you find all campaigns with the corresponding status. Only campaigns in status <b>LAUNCHED</b> are relevant for distribution. To navigate to the specific campaign, click on the <b>campaign name</b> or double-click anywhere on the specific line.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Bulk Distribution',
        en: 'Bulk Distribution',
      },
      anchor: 'howto-distribute-distribute',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you want to distribute the campaigns to all clients in one go, select all Items through <b>(1)</b> and then click send <b>(2)</b>. <b>Caution</b>: This will trigger a send action to all account owners and authorities.',
        en: 'If you want to distribute the campaigns to all clients in one go, select all Items through <b>(1)</b> and then click send <b>(2)</b>. <b>Caution</b>: This will trigger a send action to all account owners and authorities.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/13.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you want to distribute a send action to all clients according to pre-selected collection, you will have to open <b>(1)Selection</b>, choose the <b>(2)collection</b> then press <b>(3)send</b>.',
        en: 'If you want to distribute a send action to all clients according to pre-selected collection, you will have to open <b>(1)Selection</b>, choose the <b>(2)collection</b> then press <b>(3)send</b>.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/14.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Discarding a campaign',
        en: 'Discarding a campaign',
      },
      anchor: 'howto-distribute-discard',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you do not wish to send a specific campaign to any client do the following:',
        en: 'If you do not wish to send a specific campaign to any client do the following:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Make sure you select the relevant portfolios which need to be discarded via application of the necessary filter criteria. Usually, it is enough to select all portfolios that are assigned to you.',
        en: 'Make sure you select the relevant portfolios which need to be discarded via application of the necessary filter criteria. Usually, it is enough to select all portfolios that are assigned to you.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Then select all clients through <b>(1)</b> and then press <b>(2)No action</b>. By setting an action for a client to no action, you explicitly declare, that you do not want to send anything to the corresponding client.',
        en: 'Then select all clients through <b>(1)</b> and then press <b>(2)No action</b>. By setting an action for a client to no action, you explicitly declare, that you do not want to send anything to the corresponding client.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/15.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Customize Channels',
        en: 'Customize Channels',
      },
      anchor: 'howto-distribute-channels',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you wish to edit the applied default channel for a client, you can choose from a list of available channels:',
        en: 'If you wish to edit the applied default channel for a client, you can choose from a list of available channels:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Open the channel dialogue by clicking on the channel.',
        en: 'Open the channel dialogue by clicking on the channel.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Open available channels for a specific client',
        en: 'Open available channels for a specific client',
      },
      url: '/assets/images/help/16.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In the following dialogue you can switch the applicable channel according to your needs. The corresponding salutation will be applied to the client communication as well. ',
        en: 'In the following dialogue you can switch the applicable channel according to your needs. The corresponding salutation will be applied to the client communication as well. ',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Available channels for a specific client',
        en: 'Available channels for a specific client',
      },
      url: '/assets/images/help/17.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Personalize content',
        en: 'Personalize content',
      },
      anchor: 'howto-distribute-personalize',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Clicking the <b>(1)preview button</b> shows you the content, that will be sent to the client. You can also personalize the content by clicking on the <b>(2)+</b> button.',
        en: 'Clicking the <b>(1)preview button</b> shows you the content, that will be sent to the client. You can also personalize the content by clicking on the <b>(2)+</b> button.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Open custom content for a client',
        en: 'Open custom content for a client',
      },
      url: '/assets/images/help/18.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'A dialogue will open, where you can edit the specific intro and outro texts.',
        en: 'A dialogue will open, where you can edit the specific intro and outro texts.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Enter your personalized intro and outro here',
        en: 'Enter your personalized intro and outro here',
      },
      url: '/assets/images/help/19.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Change content Language',
        en: 'Change content Language',
      },
      anchor: 'howto-distribute-language',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By default, the applied language will be the pre-loaded default language from the core system. If no content is available in this language, a warning will appear. The content language ultimately used for client communication will be defaulted in this case to an available content according to the following prioritization order: EN-DE-FR-IT.',
        en: 'By default, the applied language will be the pre-loaded default language from the core system. If no content is available in this language, a warning will appear. The content language ultimately used for client communication will be defaulted in this case to an available content according to the following prioritization order: EN-DE-FR-IT.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'The default language is shown in the column <b>Client language</b>. You may select a different language by clicking on the language in the column <b>Selected</b>.',
        en: 'The default language is shown in the column <b>Client language</b>. You may select a different language by clicking on the language in the column <b>Selected</b>.',
      },
      url: '/assets/images/help/20.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'You can change the applied language of the content to any available language manually if desired.',
        en: 'You can change the applied language of the content to any available language manually if desired.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'RM & Advisor assignments ',
        en: 'RM & Advisor assignments ',
      },
      anchor: 'howto-distribute-rm',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By default, campaign actions for which an advisor is linked, will initially be assigned to the corresponding advisor. You will see the Relationship Manager, the Advisor and the current assignee in the following columns. ',
        en: 'By default, campaign actions for which an advisor is linked, will initially be assigned to the corresponding advisor. You will see the Relationship Manager, the Advisor and the current assignee in the following columns. ',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/21.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'As an advisor, if you want to delegate the distribution to the Relationship Manager, you can assign one or more actions to the RM:',
        en: 'As an advisor, if you want to delegate the distribution to the Relationship Manager, you can assign one or more actions to the RM:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Select your desired portfolios/clients to reassign.',
        en: 'Select your desired portfolios/clients to reassign.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'You can assign selected Client/Portfolio Actions to either Relationship Manger or Advisor',
        en: 'You can assign selected Client/Portfolio Actions to either Relationship Manger or Advisor',
      },
      url: '/assets/images/help/22.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Click on “Assign to RM” (1)',
        en: 'Click on “Assign to RM” (1)',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you are an Relationship Manager and want to reassign the corresponding Client/Portfolio Actions to the Advisor, you click on “Assign to advisor” (2).',
        en: 'If you are an Relationship Manager and want to reassign the corresponding Client/Portfolio Actions to the Advisor, you click on “Assign to advisor” (2).',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Inspect my client book',
        en: 'Inspect my client book',
      },
      anchor: 'howto-client',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Via Targeting & Filtering',
        en: 'Via Targeting & Filtering',
      },
      anchor: 'howto-client-filtering',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In this tab, you can freely browse through your portfolio book by applying numerous filter criteria and observe your portfolio list in the “portfolios preview” section.',
        en: 'In this tab, you can freely browse through your portfolio book by applying numerous filter criteria and observe your portfolio list in the “portfolios preview” section.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Please navigate to the targeting and filtering tab and consult the specific help page for further details.',
        en: 'Please navigate to the targeting and filtering tab and consult the specific help page for further details.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/23.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Via Portfolios or Clients Tab',
        en: 'Via Portfolios or Clients Tab',
      },
      anchor: 'howto-client-portfolios',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Please navigate to the objects tab.',
        en: 'Please navigate to the objects tab.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/24.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'There you can choose between the <b>portfolios</b> and <b>clients</b> tab. Please consult the specific help page for further details.',
        en: 'There you can choose between the <b>portfolios</b> and <b>clients</b> tab. Please consult the specific help page for further details.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/25.png',
    },
	{
      type: EHelpContentType.header,
      text: {
        de: 'Wrong assignee?',
        en: 'Wrong assignee?',
      },
      anchor: 'howto-wrongassignee',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you have the feeling that a specific Client Action is wrongly assigned to you, please check:',
        en: 'If you have the feeling that a specific Client Action is wrongly assigned to you, please check:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '1)	If you are a <b>Relationship Manager</b>, check with the corresponding Advisor, if the action was accidentally assigned to you.',
        en: '1)	If you are a <b>Relationship Manager</b>, check with the corresponding Advisor, if the action was accidentally assigned to you.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '2)	If you are an <b>Advisor</b> of the portfolio, please get in touch with the corresponding Relationship Manager and check if the action was really intended to be reassigned to you.',
        en: '2)	If you are an <b>Advisor</b> of the portfolio, please get in touch with the corresponding Relationship Manager and check if the action was really intended to be reassigned to you.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Then reassign the corresponding action as described in "Distribute a launched campaign" -> "RM & Advisor assignments".',
        en: 'Then reassign the corresponding action as described in "Distribute a launched campaign" -> "RM & Advisor assignments".',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Otherwise, please contact your system administrator.',
        en: 'Otherwise, please contact your system administrator.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Suitability',
        en: 'Suitability',
      },
      anchor: 'howto-suitability',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Calculation',
        en: 'Calculation',
      },
      anchor: 'howto-suitability-calculation',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The actual suitability calculation is not part of the product. aSpark rather connects to a dedicated on site rule engine (e.g. implemented in the core banking system) via an interface and gets the results back from the mentioned rule engine. The calculation is retriggered periodically. The exact recalculation period is configurable and depends on the overall performance of the connected rule engine.',
        en: 'The actual suitability calculation is not part of the product. aSpark rather connects to a dedicated on site rule engine (e.g. implemented in the core banking system) via an interface and gets the results back from the mentioned rule engine. The calculation is retriggered periodically. The exact recalculation period is configurable and depends on the overall performance of the connected rule engine.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Pending Suitability ',
        en: 'Pending Suitability ',
      },
      anchor: 'howto-suitability-pending',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If you see an hourglass icon in the suitability results column, the suitability calculation is still pending. Please wait for the calculation to finish.',
        en: 'If you see an hourglass icon in the suitability results column, the suitability calculation is still pending. Please wait for the calculation to finish.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/26.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If the status of the suitability still does not change in reasonable time, please contact your system administrator. ',
        en: 'If the status of the suitability still does not change in reasonable time, please contact your system administrator. ',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Faulty Suitabiliy',
        en: 'Faulty Suitabiliy',
      },
      anchor: 'howto-suitability-faulty',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'If the error icon is displayed in the suitability results column, there was an error during calculation. Please wait for the next calculation cycle.',
        en: 'If the error icon is displayed in the suitability results column, there was an error during calculation. Please wait for the next calculation cycle.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/27.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In case the suitability result still shows an error, please contact your system administrator.',
        en: 'In case the suitability result still shows an error, please contact your system administrator.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'See all campaigns for specific portfolio',
        en: 'See all campaigns for specific portfolio',
      },
      anchor: 'howto-campaignsportfolio',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Go to <b>(1)Objects</b> and then to the <b>(2)Portfolios</b> tab.',
        en: 'Go to <b>(1)Objects</b> and then to the <b>(2)Portfolios</b> tab.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Search for the relevant portfolio. Then click on the <b>(3)portfolio number</b> or double-click on the corresponding line. The portfolio overview section opens and you can access different tabs in there.',
        en: 'Search for the relevant portfolio. Then click on the <b>(3)portfolio number</b> or double-click on the corresponding line. The portfolio overview section opens and you can access different tabs in there.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/28.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To see the campaigns a portfolio was or is involved in, go to the tab "All Campaigns". For further information, please consult the specific help page.',
        en: 'To see the campaigns a portfolio was or is involved in, go to the tab "All Campaigns". For further information, please consult the specific help page.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'See all campaigns for specific client',
        en: 'See all campaigns for specific client',
      },
      anchor: 'howto-campaignsclient',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Go to <b>(1)Objects</b> and then to the <b>(2)Clients</b> tab.',
        en: 'Go to <b>(1)Objects</b> and then to the <b>(2)Clients</b> tab.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Search for the relevant client. Then click on the <b>(3)person number</b> or double-click on the corresponding line. The client overview section opens and you can access different tabs in there.',
        en: 'Search for the relevant client. Then click on the <b>(3)person number</b> or double-click on the corresponding line. The client overview section opens and you can access different tabs in there.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/29.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To see the campaigns a client was or is involved in, go to the tab "All Campaigns". For further information, please consult the specific help page.',
        en: 'To see the campaigns a client was or is involved in, go to the tab "All Campaigns". For further information, please consult the specific help page.',
      },
    },
  ],
};
