import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { INestedMenuItem } from 'src/app/models/menu.model';
import {MatMenu} from "@angular/material/menu";

/**
 * Dynamic Nested Menu Component.
 * Component for dynamically rendering a nested menu.
 */
@Component({
  selector: 'app-dynamic-nested-menu',
  templateUrl: './dynamic-nested-menu.component.html',
})
export class DynamicNestedMenuComponent {
  /**
   * Component input of the different menu items
   */
  @Input() items: INestedMenuItem[];

  @Input() readOnly = false;

  /**
   * Component output when an item was clicked
   */
  @Output() itemClick: EventEmitter<INestedMenuItem> =
    new EventEmitter<INestedMenuItem>();

  /**
   * Viewchild reference of the menu
   */
  @ViewChild('menu', { static: true }) menu: MatMenu;
  /**
   * Viewchild reference of the search field
   */
  @ViewChild('searchField', { static: false }) searchField: ElementRef;
  searchFieldValue: string;

  constructor() {}

  /**
   * Click handler for clicking on menu item
   * @param item Clicked menu item
   */
  onItemClick(item: INestedMenuItem): void {
    this.searchFieldValue = '';
    this.itemClick.emit(item);
  }
}
