<mat-button-toggle-group
  name="andOrToggle"
  [vertical]="false"
  [value]="operator"
  (change)="onOperatorChanged($event)"
  [disabled]="readOnly"
  [hideSingleSelectionIndicator]="true">
  <mat-button-toggle value="mor">{{'or' | translate}}</mat-button-toggle>
  <mat-button-toggle value="and">{{'and' | translate}}</mat-button-toggle>
</mat-button-toggle-group>
