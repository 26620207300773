<div class="suitability-header">
  <div class="h4" *ngIf="clientFullName">{{ 'client' | translate }}: {{ clientFullName }}</div>
  <div class="h4" *ngIf="intermediaryName">{{ 'intermediary' | translate }}: {{ intermediaryName }}</div>
  <div class="status h4">
    <span>{{ 'status' | translate }}:</span>
    <app-config-icon
      [iconIdentifier]="combinedIconAndColorClass.icon"
      [ngClass]="combinedIconAndColorClass.colorClass"
    ></app-config-icon>
  </div>
</div>
  <app-grid
    *ngIf="rowData"
    tableId="campaign-suitability-details-grid"
    [columnDefs]="detailsColumns"
    [gridOptions]="detailsGridOptions"
    [rowData]="rowData"
  >
  </app-grid>
