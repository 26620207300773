import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { DatasourceGridFilter } from '../../datasource-filter';

@Component({
  selector: 'app-toggle-hot-filter',
  templateUrl: './toggle-hot-filter.component.html',
})
export class ToggleHotFilterComponent implements IFilterAngularComp {
  params!: IFilterParams;
  hot = false;
  contentKey = 'showOnlyHotStories';

  constructor() {}

  agInit(params: IFilterParams): void {
    this.params = params;
    const contentKey = (params as any).contentKey;
    if (contentKey) {
      this.contentKey = contentKey;
    }
  }

  getModel(): DatasourceGridFilter {
    const activeFilter: DatasourceGridFilter = {
      filter: 'true',
      filterType: 'text',
      type: 'equals',
    };
    return this.hot ? activeFilter : null;
  }

  setModel(model: any) {}

  updateFilter() {
    this.params.filterChangedCallback();
  }

  isFilterActive(): boolean {
    return !this.hot;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true;
  }
}
