import { Component, HostBinding } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { GridIconInfo } from 'src/app/models/grid.model';
import {
  commonFilterParams,
  commonParams,
} from 'src/app/util/grid/grid-renderer.util';
import {ColDef, ICellRendererParams} from "ag-grid-community";

@Component({
  template: `
    <app-config-icon
      *ngIf="icon | iconRendererIcon: data"
      [ngClass]="iconClass | iconRendererIconClass: data"
      [iconIdentifier]="icon | iconRendererIcon: data"
      [tooltip]="tooltip | rendererTooltip: data"
    ></app-config-icon>
    <span *ngIf="!(icon | iconRendererIcon: data)"></span>
  `,
})
export class IconRenderer implements AgRendererComponent {
  @HostBinding('class.grid__icon-button') theme = true;
  data: any;
  icon: string | ((data: any) => string);
  iconClass: string | ((data: any) => string);
  tooltip: string | ((data: any) => string);

  agInit(params: ICellRendererParams): void {
    const cellRendererParams = params.colDef.cellRendererParams || {};

    this.data = params.data;
    const actionParams = cellRendererParams.actionsParams;
    const iconInfo: GridIconInfo = actionParams.iconInfo;

    if (iconInfo != null) {
      this.icon = iconInfo.icon;
      this.iconClass = iconInfo.iconClass;
      this.tooltip = iconInfo.tooltip;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

export function genIconColumn(iconInfo: GridIconInfo): ColDef {
  const hasHeader = iconInfo.headerName !== undefined && iconInfo.headerName !== '';
  return {
    ...commonParams(iconInfo.field, iconInfo?.headerName || ''),
    cellRenderer: IconRenderer,
    cellClass: iconInfo?.cellClass,
    filter: iconInfo?.customFilter,
    filterParams: {
      ...commonFilterParams(iconInfo.filterParamsInfo),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
    cellRendererParams: {
      actionsParams: {
        iconInfo,
      },
    },
    width: 80,
    minWidth: 80,
    suppressColumnsToolPanel: !hasHeader,
    lockVisible: !hasHeader,
  };
}
