<div *ngIf="employee" class="page-content-container">

  <div class="distributor-details-container aspark-container-padding">
    <div class="half-row">
      <app-card class="details">
        <div card-header>
          <span>{{ 'details' | translate }}</span>
        </div>
        <div class="h4" card-body>
          <div class="detail-items-wrapper more-space-for-value">
            <app-overview-detail-item
              labelRef="employeeName"
              [value]="employee.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="lastName"
              [value]="employee.lastName"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="firstName"
              [value]="employee.firstName"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="gender"
              [value]="employee.gender.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="key"
              [value]="employee.externalKey"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="preferredLanguage"
              [value]="employee.preferredLanguage.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="channel"
              [value]="employee.preferredChannelType.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="closed"
              [value]="employee.closed ? 'yes' : 'no' | translate"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="intermediary"
              valueIcon="pageview"
              [value]="employee.intermediary.name"
              [clickable]="true"
              (editClicked)="showIntermediary()"
            ></app-overview-detail-item>
          </div>
          <a class="color-primary" [routerLink]="EViewRoutes.distributorIntermediaries">
            {{ 'viewAllIntermediaries' | translate }}
          </a>
        </div>
      </app-card>
    </div>
    <div class="half-row">
      <app-employee-publication-types
        class="publication-types"
        [employeeId]="employee.id"
      >
      </app-employee-publication-types>
    </div>
    <div class="full-row">
      <app-grid
        tableId="distributor-employee-actions-grid"
        [rowData]="actions"
        [columnDefs]="colDefs"
        [gridOptions]="gridOptions"
        [noGridFilterTopPadding]="true"
      ></app-grid>
    </div>
  </div>
</div>
