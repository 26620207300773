import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageComponent} from 'src/app/shared/page/page.component';
import {AuthGuard} from 'src/app/util/auth.guard';
import {ERoutePaths, EViewRoutes, EViewTitles,} from 'src/app/util/enum';
import {PermissionGuard} from 'src/app/util/permission.guard';
import {ProductResolver} from 'src/app/util/resolvers';
import {ProductCatalogueComponent} from './views/product-catalogue/product-catalogue.component';
import {ProductOverviewComponent} from './views/product-overview/product-overview.component';
import {EProtectedActions} from '../util/protected-actions';

/**
 * Product route definition.
 * Contains all routing definitions related to products
 */
const productRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'all',
        component: ProductCatalogueComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.products,
          viewRoute: EViewRoutes.products,
          allowedRoles: [EProtectedActions.viewProducts],
        },
      },
      {
        path: ERoutePaths.overview,
        component: ProductOverviewComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: EViewTitles.productOverview,
          viewRoute: EViewRoutes.productOverview,
          allowedRoles: [EProtectedActions.viewProducts],
        },
        resolve: {
          product: ProductResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(productRoutes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {}
