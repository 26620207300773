import {Component, OnDestroy} from "@angular/core";
import {genCodeTableColumn, genTextColumn} from "../../../../../util/grid/grid-renderer.util";
import {I18n} from "../../../../../services/i18n.service";
import {
  CodeTableEntry,
  ContentDefinition,
  ContentService,
  HubChannelDefinitionMapping,
  OrderedFieldDefinition,
  Setting, SettingIdentType
} from "../../../../../api/core";
import {genIconButtonColumn} from "../../../../../shared/grid/cell-renderers/icon-button.renderer";
import {ECodeTables, EModalType} from "../../../../../util/enum";
import {TranslateService} from "@ngx-translate/core";
import {ColDef, GridOptions, GridReadyEvent, ITooltipParams} from "ag-grid-community";
import {combineLatest, Subscription} from "rxjs";
import {ModalData} from "../../../../../models/modal.model";
import {DialogHeight, DialogWidth, ModalService} from "../../../../../services/modal.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {ContentDefinitionDialogComponent} from "../content-definition-dialog/content-definition-dialog.component";
import {CodeTableService} from "../../../../../services/code-table.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  HubChannelDefinitionMappingDialogComponent
} from "../hub-channel-definition-mapping-dialog/hub-channel-definition-mapping-dialog.component";
import {NotificationService} from "../../../../../services/notification.service";

@Component({
  selector: 'app-content-definition-details',
  templateUrl: './content-definition-details.component.html'
})
export class ContentDefinitionDetailsComponent implements OnDestroy {
  languages: CodeTableEntry[] = [];
  fallbackRules: Setting[] = [];
  formGroup: FormGroup;

  contentDefColumnDefs: ColDef[] = [
    {
      ...genIconButtonColumn({
        callback: (data: ContentDefinition) => this.onEdit(data),
        icon: 'edit_m',
        tooltip: this.translateService.instant('edit'),
      }), sortable: false
    },
    genTextColumn('name', I18n.getColName('name')),
    {
      ...genTextColumn(
        'orderedFieldDefinitions',
        I18n.getColName('fieldDefinitions')
      ),
      valueFormatter: (params) =>
        (params.value?.sort((a, b) => a.orderValue - b.orderValue)
          .map((ofd: OrderedFieldDefinition) => ofd.fieldDefinition.ident).join(', ')),
      sortable: false, // Not specified how to sort this column -> disabled for now
      cellStyle:  { 'text-overflow':'ellipsis','white-space':'nowrap', 'overflow': 'hidden' },
      tooltipValueGetter: (params: ITooltipParams<any>) =>
        params.data.orderedFieldDefinitions
          .sort((a, b) => a.orderValue - b.orderValue)
          .map((pt) => pt.fieldDefinition.ident)
          .join('\r, '),
    },
    {
      ...genIconButtonColumn({
        callback: (data: ContentDefinition) => this.onDelete(data),
        icon: 'delete',
        tooltip: this.translateService.instant('delete'),
        hidden: (data: ContentDefinition) =>
          data.name === 'General',
      }), sortable: false
    },
  ];

  mappingColumnDefs: ColDef[] = [
    genCodeTableColumn({
      field: 'hub',
      headerName: I18n.getColName('hub'),
    }),
    genCodeTableColumn({
      field: 'channelType',
      headerName: I18n.getColName('channelType'),
    }),
    genTextColumn('contentDefinition.name', I18n.getColName('definition')),
  ];

  contentDefGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<ContentDefinition>) => {
      this.subscriptions.push(
        I18n.getColumns(this.translateService, event.api)
      );
    }
  };

  mappingGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent<HubChannelDefinitionMapping>) => {
      this.subscriptions.push(
        I18n.getColumns(this.translateService, event.api)
      );
    }
  };

  contentDefinitions: ContentDefinition[];
  hubChannelDefinitionMappings: HubChannelDefinitionMapping[];
  subscriptions: Subscription[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly modalService: ModalService,
    protected readonly contentService: ContentService,
    protected readonly codeTableService: CodeTableService,
    protected readonly fb: FormBuilder,
    protected readonly notificationService: NotificationService,
  ) {
    combineLatest([
      codeTableService.getCodeTable(ECodeTables.language),
      contentService.getFallbackRules(),
    ]).subscribe(([languages, fbr]) => {
      this.languages = languages;
      this.fallbackRules = fbr;
      this.initFallbackRulesForm();
    });
    this.updateTableEntries();
    this.contentService.getHubChannelDefinitionMappings().subscribe(hcdMappings => {
      this.hubChannelDefinitionMappings = hcdMappings;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initFallbackRulesForm() {
    this.formGroup = this.fb.group({
      fallbackRuleEMEA: new FormControl(
        this.languages.find(l => {
          let fbr = this.fallbackRules.find(fr => fr.ident === SettingIdentType.FALLBACKRULEEMEA);
          return fbr?.value === l.ident;
        }),
        Validators.required
      ),
      fallbackRuleAPAC: new FormControl(
        this.languages.find(l => {
          let fbr = this.fallbackRules.find(fr => fr.ident === SettingIdentType.FALLBACKRULEAPAC);
          return fbr?.value === l.ident;
        }),
        Validators.required
      ),
    });
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.onUpdateFallbackRules();
      })
    )
  }

  updateTableEntries() {
    this.contentService.getOriginalContentDefinitions().subscribe(contentDefinitions => {
      this.contentDefinitions = contentDefinitions;
    });
  }

  onUpdateFallbackRules() {
    const fallbackRuleEMEA = this.formGroup.get('fallbackRuleEMEA').value;
    const fallbackRuleAPAC = this.formGroup.get('fallbackRuleAPAC').value;
    this.contentService.updateFallbackRules([
        {
          id: this.fallbackRules.find(fr => fr.ident === SettingIdentType.FALLBACKRULEEMEA).id,
          ident: SettingIdentType.FALLBACKRULEEMEA,
          value: fallbackRuleEMEA.ident
        },
        {
          id: this.fallbackRules.find(fr => fr.ident === SettingIdentType.FALLBACKRULEAPAC).id,
          ident: SettingIdentType.FALLBACKRULEAPAC,
          value: fallbackRuleAPAC.ident
        }
      ]
    ).subscribe(fbr => {
      this.fallbackRules = fbr;
      this.notificationService.handleSuccess(
        this.translateService.instant('updateFallbackRulesSuccess')
      );
    })
  }

  onAdd() {
    const initialFieldDefinitions: OrderedFieldDefinition[] = [];
    const modalData: ModalData = {
      type: EModalType.contentDefinitionAdd,
      title: EModalType.contentDefinitionAdd,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        contentDefinition: {
          name: '',
          orderedFieldDefinitions: initialFieldDefinitions,
        },
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ContentDefinitionDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.DEFAULT
    );
    ref.afterClosed().subscribe((result: ContentDefinition | null) => {
      if (!result) return;
      this.contentService.createContentDefinition(result).subscribe(_ => {
        this.updateTableEntries();
      });
    });
  }

  onEdit(data: ContentDefinition) {
    const modalData: ModalData = {
      type: EModalType.contentDefinitionEdit,
      title: EModalType.contentDefinitionEdit,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        contentDefinition: data,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: ContentDefinitionDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.DEFAULT
    );
    ref.afterClosed().subscribe((result: ContentDefinition | null) => {
      if (!result) return;
      this.contentService.editContentDefinitionById(result.id, result).subscribe(_ => {
        this.updateTableEntries();
      });
    });
  }

  onDelete(data: ContentDefinition) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: 'contentDefinitionDelete',
      component: null,
      data: {
        message: this.translateService.instant('contentDefinitionDeleteMessage'),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.contentService.deleteContentDefinitionById(data.id)
            .subscribe( {
              next: () => {
              this.updateTableEntries();
              modalRef.close();
            }, error: () => {
                this.notificationService.handleWarning(
                  this.translateService.instant('contentDefinitionReferenced')
                );
                modalRef.close();
              }
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }

  onUpdateMappings() {
    const modalData: ModalData = {
      type: EModalType.hubChannelDefinitionMappingUpdate,
      title: EModalType.hubChannelDefinitionMappingUpdate,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        contentDefinitions: this.contentDefinitions,
        hubChannelDefinitionMappings: this.hubChannelDefinitionMappings,
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: HubChannelDefinitionMappingDialogComponent,
    };
    const ref = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      true,
      false,
      DialogWidth.HALF,
      DialogHeight.AUTO
    );
    ref.afterClosed().subscribe((result: HubChannelDefinitionMapping[] | null) => {
      if (!result) return;
      this.contentService.updateHubChannelDefinitionMappings(result)
        .subscribe(hcdMappings => {
          this.hubChannelDefinitionMappings = hcdMappings;
        });
    });
  }
}
