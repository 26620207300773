import { Component, ViewChild } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import {ICellRendererParams, IRowNode} from 'ag-grid-community';
import { GridSelectionUtils } from 'src/app/util/grid/grid-selection.util';
import {MatCheckbox} from "@angular/material/checkbox";

@Component({
  template: ` <div style="display: flex;justify-content: center;align-items: center;"><mat-checkbox
    #checkbox
    *ngIf="isSelectable()"
    [checked]="isChecked()"
    [indeterminate]="someSelected()"
    color="primary"
    (change)="toggleSelection()"
    (click)="$event.stopPropagation()"
  ></mat-checkbox></div>`,
})
export class CheckboxCellRenderer implements AgRendererComponent {
  @ViewChild('checkbox', { static: false }) public checkbox: MatCheckbox;
  public params: ICellRendererParams;
  private gridSelectionUtils: GridSelectionUtils;
  private level: number;
  private rowNode: IRowNode;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowNode = params.node;

    const colDef = params.colDef;
    if (
      colDef &&
      colDef.cellRendererParams &&
      colDef.cellRendererParams.actionsParams
    ) {
      this.gridSelectionUtils =
        colDef.cellRendererParams.actionsParams.gridSelectionUtils;
      this.level = colDef.cellRendererParams.actionsParams.level;
    }
  }

  isChecked(): boolean {
    if (this.level === 1) {
      return (
        this.params.data &&
        this.gridSelectionUtils.isSelected(
          this.params.data[this.gridSelectionUtils.indexField()]
        )
      );
    } else if (this.params.data && this.params.data.actions) {
      return (
        this.params.data.actions.every((action) => this.gridSelectionUtils.isSelected(
            action[this.gridSelectionUtils.indexField()]
          ))
      );
    } else {
      return (
        this.params.data &&
        this.gridSelectionUtils.isSelected(
          this.params.data[this.gridSelectionUtils.indexField()]
        )
      );
    }
  }

  someSelected(): boolean {
    if (this.level === 1 || !this.params.data?.actions) {
      return false;
    }
    return (
      this.params.data &&
      this.params.data.actions &&
      !this.isChecked() &&
      this.params.data.actions.some((action) => this.gridSelectionUtils.isSelected(
        action[this.gridSelectionUtils.indexField()]
      ))
    );
  }

  isSelectable(): boolean {
    return (
      this.params.data && this.gridSelectionUtils.isSelectable(this.params.data)
    );
  }

  toggleSelection() {
    if (this.level === 1) {
      this.gridSelectionUtils.toggleSelection(
        this.params.data[this.gridSelectionUtils.indexField()],
        this.params.data
      );
    } else {
      const items = this.params.data.actions ? this.params.data.actions : [this.params.data];
      this.gridSelectionUtils.setMultipleSelection(
        items,
        !this.isChecked()
      );
    }
    this.rowNode.setSelected(this.isChecked());
  }

  refresh(): boolean {
    return true;
  }
}
