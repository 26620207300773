<app-grid
  #employee_grid
  tableId="distributor-employee-list"
  [data]="data"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
></app-grid>
<app-distributor-summary
  *ngIf="summary"
  [summary]="summary"
  [showReceived]="true"
  [goBackRoute]="EViewRoutes.home"
  [goBackLinkMsgKey]="'backToHome'"
>
</app-distributor-summary>
