import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFilterTag } from 'src/app/models/filter-tag.model';

/**
 * Tag Component.
 * Empty component that serves purely as a wrapper and structure element with specific styling.
 */
@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
})
export class TagComponent {
  @Input() filterTag?: IFilterTag;

  @Input() selectable = false;

  @Output() deleteTag: EventEmitter<IFilterTag> =
    new EventEmitter<IFilterTag>();

  @Output() clickedTag: EventEmitter<IFilterTag> =
    new EventEmitter<IFilterTag>();

  constructor() {}

  protected clicked(): void {
    this.clickedTag.emit(this.filterTag);
  }
  protected delete(): void {
    this.deleteTag.emit(this.filterTag);
  }
}
