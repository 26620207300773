import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_CLIENTS_CONFIG = {
  id: 4,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Client List',
        en: 'Client List',
      },
      anchor: 'clientList',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Client List',
        en: 'Client List',
      },
      anchor: 'clientList',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This is the list of the clients available in aSpark.',
        en: 'This is the list of the clients available in aSpark.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By default, closed Clients are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
        en: 'By default, closed Clients are not shown on this list. To show them in the list, you will need to manually change the filter of the "Closed" column.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Depending on your permissions, some data in this table may be anonymized. Anonymized data will be shown as "***".',
        en: 'Depending on your permissions, some data in this table may be anonymized. Anonymized data will be shown as "***".',
      },
    },
  ],
  view: EViewRoutes.objectsClients,
};
