import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoredError } from 'src/app/models/error.model';

@Component({
  selector: 'app-error-overview',
  templateUrl: './error-overview.component.html',
})
export class ErrorOverviewComponent {
  error: StoredError;
  timestamp: string;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected datePipe: DatePipe
  ) {
    activatedRoute.data.subscribe((data) => {
      this.error = data.error;
      this.timestamp = this.datePipe.transform(
        data.error.timestamp,
        'HH:mm:ss - dd.MM.yyyy'
      );
    });
  }
}
