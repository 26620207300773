<form [formGroup]="formGroup" *ngIf="formGroup" class="aspark-container-padding">
  <mat-form-field class="msg-title">
    <mat-label>{{'title' | translate}}</mat-label>
    <input matInput formControlName="title" type="text" />
  </mat-form-field>
  <mat-form-field class="msg-hub">
    <mat-label>{{'hub' | translate}}</mat-label>
    <mat-select formControlName="hub">
      <mat-option [value]="null">
        [{{ 'none' | translate }}]
      </mat-option>
      <mat-option *ngFor="let hub of hubs" [value]="hub">
        {{ hub.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="msg-valid-from">
    <mat-label>{{ 'validFrom' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="validFromDatePicker"
      [min]="minDate"
      [max]="formGroup.controls.validTo.value"
      (click)="validFromDatePicker.open()"
      formControlName="validFrom"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="validFromDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #validFromDatePicker></mat-datepicker>
    <mat-error *ngIf="!formGroup.controls.validFrom.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
  </mat-form-field>
  <mat-form-field class="msg-valid-to">
    <mat-label>{{ 'validTo' | translate }}</mat-label>
    <input
      matInput
      [matDatepicker]="validToDatePicker"
      [min]="formGroup.controls.validFrom.value"
      [max]="maxDate"
      (click)="validToDatePicker.open()"
      formControlName="validTo"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="validToDatePicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #validToDatePicker></mat-datepicker>
    <mat-error *ngIf="!formGroup.controls.validTo.valid">{{
        'dateNotValid' | translate
      }}</mat-error>
  </mat-form-field>
  <div class="msg-roles">
    <label>{{ 'roles' | translate }}</label>
    <section>
      <mat-checkbox color="primary"
                    [checked]="selectAll" (change)="onSelectAll()">{{ 'selectAll' | translate }}</mat-checkbox>
      <mat-checkbox *ngFor="let role of roles"
                    color="primary"
                    [checked]="selectedRoles.indexOf(role) >= 0" (click)="selectRole(role)">
        {{ role | translate }}
      </mat-checkbox>
    </section>
  </div>
  <div class="msg-content required"
    [class.error]="formGroup.get('content').invalid">
    <mat-label>{{ 'content' | translate }}</mat-label>
    <textarea
      [froalaEditor]="editorOptions"
      matInput
      formControlName="content"
    ></textarea>
    <mat-error *ngIf="formGroup.get('content')?.errors?.required">
      {{ 'inputRequired' | translate }}
    </mat-error>
  </div>
</form>
