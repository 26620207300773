import {Component, Inject, Input} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {Content} from "../../../../api/core";
import {ModalSubComponent} from "../../../../models/modal.model";
import {EModalType} from "../../../../util/enum";


@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html'
})
export class EmailDialogComponent implements ModalSubComponent{
  header: string = '';

  email: FormControl;


  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {data: { header: string | undefined; email: string }}
  ) {
    this.header = data.data.header || translateService.instant('email');
    this.email = new FormControl(
      data.data.email || '',
      [Validators.required, Validators.email]
    );
    this.checkValidity();
    this.email.valueChanges.subscribe(() => {
      this.checkValidity();
    })
  }


  getErrorMessage() {
    if (this.email.hasError('required')) {
      return this.translateService.instant('required');
    }

    if (this.email.hasError('email')) {
      return this.translateService.instant('invalidEmail');
    }
    return '';
  }

  modalAction(modalType: EModalType | undefined): void {
    if (this.email.invalid) {
      this.dialogRef.close({invalid: true});
      return
    }
    const value = this.email.value;
    if (!value) {
      this.dialogRef.close({required: true});
      return;
    }
    this.dialogRef.close(value);
  }

  checkValidity() {
    const isValid = !this.email.invalid;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = !isValid;
  }
}
