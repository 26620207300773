import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_CAMPAIGN_OVERVIEW_CONFIG = {
  id: 1,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'campaignOverview',
      children: [
        {
          title: {
            de: 'Details',
            en: 'Details',
          },
          anchor: 'campaignOverview-details',
        },
        {
          title: {
            de: 'Statistics and Filter',
            en: 'Statistics and Filter',
          },
          anchor: 'campaignOverview-stats',
        },
        {
          title: {
            de: 'Charts',
            en: 'Charts',
          },
          anchor: 'campaignOverview-charts',
        },
        {
          title: {
            de: 'Action States',
            en: 'Action States',
          },
          anchor: 'campaignOverview-action-states',
        },
      ],
    },
    {
      title: {
        de: 'Contents and products',
        en: 'Contents and products',
      },
      anchor: 'campaignOverview-contentsproducts',
      children: [
        {
          title: {
            de: 'Contents',
            en: 'Contents',
          },
          anchor: 'campaignOverview-contents',
        },
        {
          title: {
            de: 'Products',
            en: 'Products',
          },
          anchor: 'campaignOverview-products',
        },
      ],
    },
    {
      title: {
        de: 'Portfolios and clients',
        en: 'Portfolios and clients',
      },
      children: [
        {
          title: {
            de: 'Portfolio and clients',
            en: 'Portfolio and clients',
          },
          anchor: 'campaignOverview-portfolios-clients',
        },
        {
          title: {
            de: 'Edit Options',
            en: 'Edit Options',
          },
          anchor: 'campaignOverview-portfolios-edit',
        },
        {
          title: {
            de: 'Selecting',
            en: 'Selecting',
          },
          anchor: 'campaignOverview-portfolios-select',
        },
        {
          title: {
            de: 'Actions',
            en: 'Actions',
          },
          anchor: 'campaignOverview-portfolios-actions',
        },
        {
          title: {
            de: 'Line Colors',
            en: 'Line Colors',
          },
          anchor: 'campaignOverview-portfolios-line',
        },
      ],
      anchor: 'campaignOverview-portfolios',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'campaignOverview',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Details',
        en: 'Details',
      },
      anchor: 'campaignOverview-details',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Describes basic data of the campaign and its source story.',
        en: 'Describes basic data of the campaign and its source story.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Additionally, describes the active filters that limit the amount of portfolios that will be linked to this campaign.',
        en: 'Additionally, describes the active filters that limit the amount of portfolios that will be linked to this campaign.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Campaign Details',
        en: 'Campaign Details',
      },
      url: '/assets/images/help/campaign-overview-details.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The <b>Use case</b> of a campaign can be either <i>Advised</i> or <i>Unadvised</i>. The difference between the two is how the Suitability will be calculated. <i>Unadvised</i> campaigns will always return an <i>OK</i> without actually checking the core banking system. <i>Advised</i> campaigns will go to the core banking system to calculate the suitabilities.',
        en: 'The <b>Use case</b> of a campaign can be either <i>Advised</i> or <i>Unadvised</i>. The difference between the two is how the Suitability will be calculated. <i>Unadvised</i> campaigns will always return an <i>OK</i> without actually checking the core banking system. <i>Advised</i> campaigns will go to the core banking system to calculate the suitabilities.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In <b>Further Information</b> campaign creators can add some additional instructions for the relationship managers. This is displayed in this section. Click on "Show" will open a dialog with the full text getting displayed.',
        en: 'In <b>Further Information</b> campaign creators can add some additional instructions for the relationship managers. This is displayed in this section. Click on "Show" will open a dialog with the full text getting displayed.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'In <b>Further Links</b> campaign creators can link to some additional information for the relationship managers. It can be opened the same way as Further Information.',
        en: 'In <b>Further Links</b> campaign creators can link to some additional information for the relationship managers. It can be opened the same way as Further Information.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Further Information',
        en: 'Further Information',
      },
      url: '/assets/images/help/campaign-overview-further1.png',
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Full text of the further details',
        en: 'Full text of the further details',
      },
      url: '/assets/images/help/campaign-overview-further2.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Statistics and Filter',
        en: 'Statistics and Filter',
      },
      anchor: 'campaignOverview-stats',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Below the details of the campaign we show some statistics of the campaign and an overview of the selected filters. For a description of the filters check the help for the <b>Targeting / Filtering</b> page.',
        en: 'Below the details of the campaign we show some statistics of the campaign and an overview of the selected filters. For a description of the filters check the help for the <b>Targeting / Filtering</b> page.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Stats and filter',
        en: 'Stats and filter',
      },
      url: '/assets/images/help/campaign-overview-filter.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Charts',
        en: 'Charts',
      },
      anchor: 'campaignOverview-charts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The suitability charts show the suitability (same states as in Avaloq). The first pie chart shows the suitabilities summarized on a per client basis where the second chart shows it on a per portfolio base.',
        en: 'The suitability charts show the suitability (same states as in Avaloq). The first pie chart shows the suitabilities summarized on a per client basis where the second chart shows it on a per portfolio base.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'A suitability with the status of <i>Error</i> would indicate that when calculating the suitability an error occurred',
        en: 'A suitability with the status of <i>Error</i> would indicate that when calculating the suitability an error occurred',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Suitability Charts',
        en: 'Suitability Charts',
      },
      url: '/assets/images/help/campaign-overview-charts1.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The second section of the charts show the status of all the actions currently assigned to you. This shows how many actions are still waiting to be acted upon.',
        en: 'The second section of the charts show the status of all the actions currently assigned to you. This shows how many actions are still waiting to be acted upon.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Action Charts',
        en: 'Action Charts',
      },
      url: '/assets/images/help/campaign-overview-charts2.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Action States',
        en: 'Action States',
      },
      anchor: 'campaignOverview-action-states',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'An action is created for every client in every portfolio. These actions can have different states depending on whether they were processed already and how they were processed:',
        en: 'An action is created for every client in every portfolio. These actions can have different states depending on whether they were processed already and how they were processed:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Pending</b> - this means that the action has not been processed yet',
        en: '<b>Pending</b> - this means that the action has not been processed yet',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Sent</b> - a send action has been triggered for this action that has been sent to the client using the selected channel',
        en: '<b>Sent</b> - a send action has been triggered for this action that has been sent to the client using the selected channel',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>No Action</b> - it is possible to specify that nothing should be sent to a specific client, by setting <i>No Action</i> it gets marked as processed but with nothing sent to the client',
        en: '<b>No Action</b> - it is possible to specify that nothing should be sent to a specific client, by setting <i>No Action</i> it gets marked as processed but with nothing sent to the client',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Duplicate Client</b> - it is possible for a client to appear in a campaign multiple times, when an action is sent the remainder of the campaign is checked to see if this client appears a second time in the campaign and if that is the case that action is marked <i>Duplicate Action</i> to avoid a client receiving 2 notifications for the same campaign',
        en: '<b>Duplicate Client</b> - it is possible for a client to appear in a campaign multiple times, when an action is sent the remainder of the campaign is checked to see if this client appears a second time in the campaign and if that is the case that action is marked <i>Duplicate Action</i> to avoid a client receiving 2 notifications for the same campaign',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Contents and products',
        en: 'Contents and products',
      },
      anchor: 'campaignOverview-contentsproducts',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'There are two separate parts here, the campaign contents and the campaign Buy/Sell products.',
        en: 'There are two separate parts here, the campaign contents and the campaign Buy/Sell products.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Contents',
        en: 'Contents',
      },
      anchor: 'campaignOverview-contents',
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-contents.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Content is created by language. aSpark allows for up to 4 languages: English, German, French, Italian.',
        en: 'Content is created by language. aSpark allows for up to 4 languages: English, German, French, Italian.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'By clicking on one of the lines you can view the details of the content in a separate dialog.',
        en: 'By clicking on one of the lines you can view the details of the content in a separate dialog.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Content details',
        en: 'Content details',
      },
      url: '/assets/images/help/campaign-overview-contentdetails.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'You can also see a preview of the content by clicking on the preview icon. They are available for the channels E-banking and E-mail.',
        en: 'You can also see a preview of the content by clicking on the preview icon. They are available for the channels E-banking and E-mail.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-contentdetails_preview_icon.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Products',
        en: 'Products',
      },
      anchor: 'campaignOverview-products',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The <b>Buy Products</b> and the <b>Sell Products</b> are the products that this campaign advertises.',
        en: 'The <b>Buy Products</b> and the <b>Sell Products</b> are the products that this campaign advertises.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-products.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Portfolios and clients',
        en: 'Portfolios and clients',
      },
      anchor: 'campaignOverview-portfolios',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Portfolio and clients',
        en: 'Portfolio and clients',
      },
      anchor: 'campaignOverview-portfolios-clients',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'When talking about actions, we mean a portfolio-client action. This is a client within a specific portfolio that can receive a notification from this campaign.',
        en: 'When talking about actions, we mean a portfolio-client action. This is a client within a specific portfolio that can receive a notification from this campaign.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'There are two ways to display the portfolios included in the campaign:',
        en: 'There are two ways to display the portfolios included in the campaign:',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>List View</b> : This shows all the actions as a flat list',
        en: '<b>List View</b> : This shows all the actions as a flat list',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Hierarchy View</b> : This shows the actions grouped by portfolio. Portfolios can be expanded to visualize the included clients.',
        en: '<b>Hierarchy View</b> : This shows the actions grouped by portfolio. Portfolios can be expanded to visualize the included clients.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<br>Click on the indicated position to switch between the two views',
        en: '<br>Click on the indicated position to switch between the two views',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'List View',
        en: 'List View',
      },
      url: '/assets/images/help/campaign-overview-portfolios_flat.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Click on the indicated position to expand or collapse the portfolio',
        en: 'Click on the indicated position to expand or collapse the portfolio',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Hierarchy View with expanded portfolio',
        en: 'Hierarchy View with expanded portfolio',
      },
      url: '/assets/images/help/campaign-overview-portfolios_hierarchy.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Edit Options',
        en: 'Edit Options',
      },
      anchor: 'campaignOverview-portfolios-edit',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Before sending an action to a client you have the option to adjust a number of things:',
        en: 'Before sending an action to a client you have the option to adjust a number of things:',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit1.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>1 - Language</b>',
        en: '<b>1 - Language</b>',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The language that is first selected is the default language of the client. You can override this to any other language for which there is content available on the campaign.',
        en: 'The language that is first selected is the default language of the client. You can override this to any other language for which there is content available on the campaign.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To change the language click on the language text itself which will open a dropdown from where you can select any of the other possible languages.',
        en: 'To change the language click on the language text itself which will open a dropdown from where you can select any of the other possible languages.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit2.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'A warning icon beside the language indicates that in this campaign there is no content available in the default language of this client. If the action would be sent without adjusting the language, first English content will be selected, or if no English content is present the first content found will be sent to this client.',
        en: 'A warning icon beside the language indicates that in this campaign there is no content available in the default language of this client. If the action would be sent without adjusting the language, first English content will be selected, or if no English content is present the first content found will be sent to this client.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit3.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>2 - Channel</b>',
        en: '<b>2 - Channel</b>',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The channel indicates the form in which the campaign information will be sent to the client. The channel set by default is the channel defined as the standard channel for the specific client in Avaloq.',
        en: 'The channel indicates the form in which the campaign information will be sent to the client. The channel set by default is the channel defined as the standard channel for the specific client in Avaloq.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'To change the channel, click on the channel text and a dialog will pop up showing all the possible channels for that client. Select one of those and confirm the dialog to change the channel.',
        en: 'To change the channel, click on the channel text and a dialog will pop up showing all the possible channels for that client. Select one of those and confirm the dialog to change the channel.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit4.png',
    },
    /*{
      type: EHelpContentType.paragraph,
      text: {
        de: 'Selecting </i>Manual (Email)</i> as the channel will result in the campaign information being sent to the relationship manager assigned to that client with instruction to print the information and send it all using the postal system.',
        en: 'Selecting </i>Manual (Email)</i> as the channel will result in the campaign information being sent to the relationship manager assigned to that client with instruction to print the information and send it all using the postal system.',
      },
    },*/
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>3 - Custom Content</b>',
        en: '<b>3 - Custom Content</b>',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'When an action is sent to a client it will take the content that is defined for the campaign. It is however possible to override an Intro block and an Outro block. Doing this is done via custom content.',
        en: 'When an action is sent to a client it will take the content that is defined for the campaign. It is however possible to override an Intro block and an Outro block. Doing this is done via custom content.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit5.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '1 - Add custom content',
        en: '1 - Add custom content',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '2 - Preview custom content',
        en: '2 - Preview custom content',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '3 - Edit custom content',
        en: '3 - Edit custom content',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '4 - Delete custom content',
        en: '4 - Delete custom content',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'When creating or editing custom content you get presented with a dialog that allows you to edit the <i>Intro</i> and the <i>Outro</i> blocks. The other blocks are not editable and only there for information. The text that is defined here will be the text that will be sent to the client once the action is sent.',
        en: 'When creating or editing custom content you get presented with a dialog that allows you to edit the <i>Intro</i> and the <i>Outro</i> blocks. The other blocks are not editable and only there for information. The text that is defined here will be the text that will be sent to the client once the action is sent.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit6.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>4 - Sender</b>',
        en: '<b>4 - Sender</b>',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lastly, it is possible to adjust the sender in whose name the action content is sent to the client. Like before, clicking on the sender text will open a dialog that allows selection of a different user as the sender.',
        en: 'Lastly, it is possible to adjust the sender in whose name the action content is sent to the client. Like before, clicking on the sender text will open a dialog that allows selection of a different user as the sender.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-edit7.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Selecting',
        en: 'Selecting',
      },
      anchor: 'campaignOverview-portfolios-select',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'When working with actions, it is possible to do bulk actions. To do that you need to select the actions on which you want to have your action executed. aSpark allows for a number of different ways to select actions.',
        en: 'When working with actions, it is possible to do bulk actions. To do that you need to select the actions on which you want to have your action executed. aSpark allows for a number of different ways to select actions.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-select.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>1 - Single Actions</b> : Selecting or deselecting a single action is done via the checkbox right in front of the action',
        en: '<b>1 - Single Actions</b> : Selecting or deselecting a single action is done via the checkbox right in front of the action',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>2 - Portfolio Selection</b> : You can select all the actions inside a portfolio by clicking the checkbox at the portfolio level.',
        en: '<b>2 - Portfolio Selection</b> : You can select all the actions inside a portfolio by clicking the checkbox at the portfolio level.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>3 - Select All Pages</b> : Clicking this checkbox will select all actions in the campaign available to the current user, even if not in the current grid page',
        en: '<b>3 - Select All Pages</b> : Clicking this checkbox will select all actions in the campaign available to the current user, even if not in the current grid page',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>4 - Preselection</b> : Selects all actions with clients that are part of a pre-defined collection.',
        en: '<b>4 - Preselection</b> : Selects all actions with clients that are part of a pre-defined collection.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>6 - Expand</b> : While not a selection mechanism, this allows expanding all portfolios in the hierarchy view',
        en: '<b>6 - Expand</b> : While not a selection mechanism, this allows expanding all portfolios in the hierarchy view',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>7 - Collapse</b> : While not a selection mechanism, this allows the collapsing of all portfolios in the hierarchy view',
        en: '<b>7 - Collapse</b> : While not a selection mechanism, this allows the collapsing of all portfolios in the hierarchy view',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Actions',
        en: 'Actions',
      },
      anchor: 'campaignOverview-portfolios-actions',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Once you have selected at least one action a menu will appear at the top right of the grid.',
        en: 'Once you have selected at least one action a menu will appear at the top right of the grid.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Depending on the state of the action, different options will be available.',
        en: 'Depending on the state of the action, different options will be available.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: '',
        en: '',
      },
      url: '/assets/images/help/campaign-overview-actions.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>1 - Send</b> : This will send the actions to the specific clients. Once sent it is no longer possible to stop the message reaching the client.',
        en: '<b>1 - Send</b> : This will send the actions to the specific clients. Once sent it is no longer possible to stop the message reaching the client.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>2 - Bulk Edit</b> : Allows to edit the language, channel, and sender for all selected actions at once.',
        en: '<b>2 - Bulk Edit</b> : Allows to edit the language, channel, and sender for all selected actions at once.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>3 - More Options</b> : Click here to show a dropdown with additional options',
        en: '<b>3 - More Options</b> : Click here to show a dropdown with additional options',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>4 - No Action</b> : Selecting this option will mark the action as completed but will not send out anything to the client',
        en: '<b>4 - No Action</b> : Selecting this option will mark the action as completed but will not send out anything to the client',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>5 - Revert Action</b> : This option is only available on completed actions. It allows to reset the action back to the pending state',
        en: '<b>5 - Revert Action</b> : This option is only available on completed actions. It allows to reset the action back to the pending state',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>6 - Assign to advisor</b> : This option is only available on actions that have an advisor, assigns the action to the advisor',
        en: '<b>6 - Assign to advisor</b> : This option is only available on actions that have an advisor, assigns the action to the advisor',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>5 - Assign to RM</b> : Assigns the action to the relationship manager',
        en: '<b>5 - Assign to RM</b> : Assigns the action to the relationship manager',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Line colors',
        en: 'Line colors',
      },
      anchor: 'campaignOverview-portfolios-line',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Lines that are marked green mean two things. At the client level this means that the client was processed. At the portfolio level this indicates that all clients of that portfolio were processed.',
        en: 'Lines that are marked green mean two things. At the client level this means that the client was processed. At the portfolio level this indicates that all clients of that portfolio were processed.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Launched campaign - portfolios',
        en: 'Launched campaign - portfolios',
      },
      url: '/assets/images/help/campaign-overview-done.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Red-marked clients are closed clients for whom no actions can be performed. This can happen when a client, who is in an already running campaign, gets closed in Avaloq.',
        en: 'Red-marked clients are closed clients for whom no actions can be performed. This can happen when a client, who is in an already running campaign, gets closed in Avaloq.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Launched campaign - portfolios',
        en: 'Launched campaign - portfolios',
      },
      url: '/assets/images/help/campaign-overview-closed.png',
    },
  ],
  view: EViewRoutes.campaignOverview,
};
