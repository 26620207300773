<div [className]="selectable ? 'tag selectable' : 'tag'" (click)="clicked()">
  <ng-content></ng-content>
  <app-config-icon
    *ngIf="filterTag"
    class="delete-tag"
    [matTooltip]="'removeFilter' | translate"
    (click)="delete()"
    iconIdentifier="close"
  ></app-config-icon>
</div>
