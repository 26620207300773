import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../shared/shared.module";
import {PortfolioModule} from "../portfolio/portfolio.module";
import {ProductModule} from "../product/product.module";
import {ClientModule} from "../client/client.module";
import {IntermediaryModule} from "../intermediary/intermediary.module";
import {EmployeeModule} from "../employee/employee.module";
import {DistributorRoutingModule} from "./distributor-routing.module";
import {DistributorOverviewComponent} from "./views/distributor-overview/distributor-overview.component";
import {DistributorClientsComponent} from "./views/distributor-clients/distributor-clients.component";
import {DistributorSummaryComponent} from "./views/distributor-summary/distributor-summary.component";
import {
  DistributorIntermediaryOverviewComponent
} from "./views/distributor-details-view/distributor-intermediary-overview/distributor-intermediary-overview.component";
import {DistributorEmployeesComponent} from "./views/distributor-employees/distributor-employees.component";
import {
  DistributorIntermediariesComponent
} from "./views/distributor-intermediaries/distributor-intermediaries.component";
import {
  DistributorEmployeeOverviewComponent
} from "./views/distributor-details-view/distributor-employee-overview/distributor-employee-overview.component";
import {
  DistributorClientOverviewComponent
} from "./views/distributor-details-view/distributor-client-overview/distributor-client-overview.component";
import {DistributorPortfoliosComponent} from "./views/distributor-portfolios/distributor-portfolios.component";
import {
  DistributorPortfolioOverviewComponent
} from "./views/distributor-details-view/distributor-portfolio-overview/distributor-portfolio-overview.component";

@NgModule({
  declarations: [
    DistributorOverviewComponent,
    DistributorClientsComponent,
    DistributorEmployeesComponent,
    DistributorIntermediariesComponent,
    DistributorPortfoliosComponent,
    DistributorSummaryComponent,
    DistributorClientOverviewComponent,
    DistributorEmployeeOverviewComponent,
    DistributorIntermediaryOverviewComponent,
    DistributorPortfolioOverviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DistributorRoutingModule,
    PortfolioModule,
    ProductModule,
    ClientModule,
    IntermediaryModule,
    EmployeeModule,
  ],
  exports: [
    DistributorSummaryComponent
  ],
})
export class DistributorModule {
}
