import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { FilterUtilsService } from 'src/app/services/filter-utils.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Data, StatisticsData } from 'src/app/api/core';
import {LabelBuilder} from "../../util/label-builder";

/**
 * Statistics Component.
 * This component is used to aggregate statistics date so it can be sent to the progress card component.
 */
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
})
export class StatisticsComponent implements OnDestroy {
  /**
   * Current Data subscription
   */
  dataSubscription: Subscription;
  /**
   * Current statistics data
   */
  statistics: StatisticsData;
  /**
   * Object that gets passed to the first progress card component in HTML
   */
  portfolioStatistics = {
    title: 'portfolios',
    currentPercentage: 0,
    actualNumber: 0,
    maxNumber: 0,
  };
  /**
   * Object that gets passed to the second progress card component in HTML
   */
  valueStatistics = {
    title: this.labelBuilder.labelWithCurrency('value'),
    currentPercentage: 0,
    actualNumber: 0,
    maxNumber: 0,
  };

  constructor(
    protected dataService: DataService,
    protected globalService: GlobalService,
    protected filterService: FilterUtilsService,
    protected notificationService: NotificationService,
    protected labelBuilder: LabelBuilder,
  ) {
    // subscribe to current data
    this.dataSubscription = this.dataService.data$.subscribe((data) =>
      this.handleData(data)
    );
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  /**
   * Callback data handler for current data subscription.
   * @param data Data sent by the currentData observable
   */
  handleData(data: Data): void {
    this.statistics = data ? data.statistics : null;
    if (this.statistics) {
      // portfolio statistics
      if (this.statistics.filteredPortfolios !== null) {
        this.portfolioStatistics = {
          ...this.portfolioStatistics,
          actualNumber: this.statistics.filteredPortfolios,
        };
      }
      if (this.statistics.totalPortfolios !== null) {
        this.portfolioStatistics = {
          ...this.portfolioStatistics,
          maxNumber: this.statistics.totalPortfolios,
        };
      }
      // value statistics
      if (this.statistics.filteredVolume !== null) {
        this.valueStatistics = {
          ...this.valueStatistics,
          actualNumber: this.statistics.filteredVolume,
        };
      }
      if (this.statistics.totalVolume !== null) {
        this.valueStatistics = {
          ...this.valueStatistics,
          maxNumber: this.statistics.totalVolume,
        };
      }
    }
  }
}
