import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  CampaignIntermediary,
  CampaignIntermediaryService, CampaignStatus,
  GridFilterOptionsParams,
  IntermediaryPortfolio,
  IntermediaryService,
  PortfolioService,
  UserInfo
} from 'src/app/api/core';
import {
  genNumberColumn,
  genTextColumn,
  genTextColumnWithAutoCompleteFilter,
  genUserEnumColumn,
  usernameValueLabel
} from 'src/app/util/grid/grid-renderer.util';
import {GlobalService} from "../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {ModalComponent} from "../modal/modal.component";
import {LabelBuilder} from "../../util/label-builder";
import {Observable} from "rxjs";
import {ApplyColumnStateParams, ColDef, GridApi, GridOptions, GridReadyEvent} from "ag-grid-community";
import {EProtectedActions} from "../../util/protected-actions";
import {PermissionService} from "../../services/permission.service";
import {GridComponent, GridResetEvent} from "../grid/grid.component";
import {EModalType, EViewRoutes} from "../../util/enum";
import {genLinkColumn} from "../grid/cell-renderers/link.renderer";
import {genIconButtonColumn} from "../grid/cell-renderers/icon-button.renderer";
import {genSuitabilityColumn} from "../grid/cell-renderers/suitability.renderer";
import {ModalData} from "../../models/modal.model";
import {
  CampaignSuitabilityDetailsComponent
} from "../../campaign/views/campaign-overview/campaign-suitability-details/campaign-suitability-details.component";
import {DialogHeight, DialogWidth, ModalService} from "../../services/modal.service";
import {NotificationService} from "../../services/notification.service";
import {PerRowObservable} from "../../models/grid.model";
import {GridDataProvider} from "../grid/data-source";

/**
 * Used in Modal in Intermediary List
 */
@Component({
  selector: 'app-intermediary-portfolios-popup',
  templateUrl: './intermediary-portfolios-popup.component.html',
})
export class IntermediaryPortfoliosPopupComponent implements OnInit {
  @ViewChild("select_grid")
  selectGrid: GridComponent;
  private perRowObservable = new PerRowObservable();

  intermediaryId: number;
  campaignIntermediaryId: number;
  campaignIntermediary: CampaignIntermediary;
  isCollapsed = true;
  canCollapse = false;
  canAdd = false;

  selectedGridApi: GridApi<IntermediaryPortfolio>;

  portfolioRelationshipManagers: UserInfo[];
  portfolios: IntermediaryPortfolio[] = [];
  portfoliosGetter: () => Observable<IntermediaryPortfolio[]>;
  portfoliosProvider: GridDataProvider;
  portfolioColDefs: ColDef[] = [];
  colDefs: ColDef[] = [];

  private getPreviewColDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(
      EProtectedActions.sortAndFilterCid
    );
    return [
      genLinkColumn({
        field: 'number',
        headerName: this.translateService.instant('number'),
        link: (data: any) => `${EViewRoutes.portfolioOverview}${data.portfolioId}`,
        filterParamsInfo: {
          autoCompleteParams: () => this.portfolios.map(p => p.number),
        },
      }),
      genTextColumn(
        'referenceCurrency.ident',
        this.translateService.instant('referenceCurrency')
      ),
      {
        ...genNumberColumn(
            'portfolioValue',
            this.labelBuilder.labelWithCurrency('portfolioValue'),
            this.globalService
          ),
        initialHide: true,
      },
      {
        ...genTextColumn(
          'bpName',
          this.translateService.instant('bpName')
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'bpNumber',
          headerName: this.translateService.instant('bpNumber'),
          autoCompleteParams: () => this.portfolios.map(p => p.bpNumber),
        }),
        initialHide: true,
      },
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchPortfolioRelationshipManagers.bind(this),
          () => this.portfolioRelationshipManagers
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
        initialHide: true,
      },
    ];
  }

  private getDefaultColDefs(): ColDef[] {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(
      EProtectedActions.sortAndFilterCid
    );
    return [
      {
        ...genLinkColumn({
          field: 'number',
          iconClass: 'warning-icon',
          iconAlign: 'right',
          headerName: this.translateService.instant('number'),
          icon: (data: IntermediaryPortfolio) => data.closed ? 'warning' : undefined,
          iconTooltip: (data: IntermediaryPortfolio) => data.closed ? this.translateService.instant('CLOSED') : undefined,
          link: (data) => `${EViewRoutes.portfolioOverview}${data.portfolioId}`,
          filterParamsInfo: {
            autoCompleteParams: () => this.portfolios.map(p => p.number),
          },
        })
      },
      {
        ...genSuitabilityColumn({
          translateService: this.translateService,
          field: 'combinedSuitabilityState',
          stateInfo: (data: IntermediaryPortfolio) => ({
            state: data.combinedSuitabilityState,
            campaignIntermediaryPortfolioId: data.id,
          }),
          headerName: this.translateService.instant('suitability'),
          callback: (data: any) => this.openSuitability(data),
        }),
        suppressHeaderMenuButton: true,
      },
      {
        ...genIconButtonColumn({
          callback: (data: IntermediaryPortfolio) => {
            this.refreshSuitability(data);
          },
          finishedLoading: this.perRowObservable,
          tooltip: this.translateService.instant('updateSuitability'),
          icon: 'sync',
          hidden: (data) => {
            return !this.campaignIntermediary?.hasPendingActions
            || !this.permissionService.hasAnyPermission(
              EProtectedActions.refreshSingleSuitabilityCampaign
            )
          }
        }),
        lockVisible: true,
      },
      genTextColumn(
        'referenceCurrency.ident',
        this.translateService.instant('referenceCurrency')
      ),
      {
        ...genNumberColumn(
            'portfolioValue',
            this.labelBuilder.labelWithCurrency('portfolioValue'),
            this.globalService
          ),
        initialHide: true,
      },
      {
        ...genTextColumn(
          'bpName',
          this.translateService.instant('bpName')
        ),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumnWithAutoCompleteFilter({
          field: 'bpNumber',
          headerName: this.translateService.instant('bpNumber'),
          autoCompleteParams: () => this.portfolios.map(p => p.bpNumber),
        }),
        initialHide: true,
      },
      {
        ...genUserEnumColumn(
          'relationshipManager.username',
          this.translateService.instant('relationshipManager'),
          this.fetchPortfolioRelationshipManagers.bind(this),
          () => this.portfolioRelationshipManagers
        ),
        valueFormatter: (r) => usernameValueLabel(r.data.relationshipManager),
        initialHide: true,
      },
    ];
  }

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: this.gridReady.bind(this),
  };

  gridOptionsSelect: GridOptions = {
    rowHeight: 36,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: this.gridReadySelect.bind(this),
    getRowId: (params) => params.data.id,
  };

  constructor(
    readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly intermediaryService: IntermediaryService,
    private readonly campaignIntermediaryService: CampaignIntermediaryService,
    private readonly labelBuilder: LabelBuilder,
    private readonly permissionService: PermissionService,
    private readonly modalService: ModalService,
    private readonly notificationService: NotificationService,
    private readonly portfoliosService: PortfolioService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: EModalType,
      data: {
        status: CampaignStatus,
        campaignIntermediaryId: number,
        campaignIntermediary: CampaignIntermediary | undefined,
        intermediaryId: number,
        canCollapse: boolean,
        portfoliosGetter: () => Observable<IntermediaryPortfolio[]>,
      }
    }
  ) {
    this.campaignIntermediaryId = data.data.campaignIntermediaryId;
    this.intermediaryId = data.data.intermediaryId;
    this.campaignIntermediary = data.data.campaignIntermediary;
    this.canCollapse = !!data.data.canCollapse;
    this.portfoliosGetter = data.data.portfoliosGetter;
    this.portfoliosGetter().subscribe((portfolios) => {
      this.setPortfolios(portfolios);
    });
    this.portfoliosProvider = campaignIntermediaryService
      .getUnselectedPortfolios.bind(campaignIntermediaryService, this.campaignIntermediaryId);
  }

  ngOnInit() {
    this.colDefs = this.data.type === EModalType.previewIntermediaryPortfolios ?
      this.getPreviewColDefs() : this.getDefaultColDefs();
    if (this.data.data.status === CampaignStatus.FROZEN || this.data.data.status === CampaignStatus.LAUNCHED) {
      this.colDefs = this.getFrozenOrLaunchedColumns(this.colDefs);
      this.portfolioColDefs = this.getSelectColumns(this.colDefs);
    }
  }

  private getFrozenOrLaunchedColumns(defaultColDefs: ColDef[]): ColDef[] {
    return [
      {
        ...genIconButtonColumn({
          icon: 'delete',
          finishedLoading: this.perRowObservable,
          iconOutlined: false,
          callback: p => {
            this.deleteIntermediaryPortfolio(p.id);
          },
          tooltip:this.translateService.instant('delete'),
        }),
        lockPosition: "left",
      },
      ...defaultColDefs,
    ];
  }

  private getSelectColumns(defaultColDefs: ColDef[]): ColDef[] {
    return defaultColDefs
        .filter(colDef =>
          colDef.field !== "combinedSuitabilityState" &&
          colDef.colId !== "icon-button-sync" &&
          colDef.colId !== "icon-button-delete"
        )
      .map(colDef => {
        const copy = {...colDef};
        if (colDef.field === "number") {
          copy.checkboxSelection = true;
          // sven: https://github.com/confinale/aspark/issues/8436
          // both grids seem to use this instead of what available client side
          copy.filterParams.autoCompleteParams = {
            apiMethod: (data: GridFilterOptionsParams) => this.portfoliosService.getGridFilterOptions(data),
            autoCompleteField: 'number'
          }
        } else if (colDef.field === "bpNumber") {
          // sven: https://github.com/confinale/aspark/issues/8436
          // both grids seem to use this instead of what available client side
          copy.filterParams.autoCompleteParams = {
            apiMethod: (data: GridFilterOptionsParams) => this.portfoliosService.getGridFilterOptions(data),
            autoCompleteField: 'bpNumber'
          }
        }
        return copy;
      });
  }

  private gridReady(event: GridReadyEvent): void {
    this.gridFilterReset({api: event.api });
  }

  private gridReadySelect(event: GridReadyEvent): void {
    this.selectedGridApi = event.api;
    this.gridFilterReset({api: event.api });
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultSorting(event.api);
  }

  private applyDefaultSorting(gridApi: GridApi) {
    // set default sort if nothing is set
    if (gridApi.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'bpNumber',
            sort: 'asc',
          },
        ],
      };
      gridApi.applyColumnState(columnState);
    }
  }

  private fetchPortfolioRelationshipManagers(params: any) {
    this.intermediaryService.getPortfolioRelationshipManagers(this.intermediaryId)
      .subscribe((data) => {
        this.portfolioRelationshipManagers = data;
        params.success(data.map((d) => d.username));
      });
  }

  private deleteIntermediaryPortfolio(portfolioId: number) {
    this.campaignIntermediaryService.deleteCampaignIntermediaryPortfolio(this.campaignIntermediaryId, portfolioId)
      .subscribe((portfolios) => {
        this.setPortfolios(portfolios);
        this.selectGrid?.reload();
      });
  }

  private openSuitability(data: any) {
    const modalData: ModalData = {
      type: EModalType.suitabilityDetails,
      title: EModalType.suitabilityDetails,
      data,
      component: CampaignSuitabilityDetailsComponent,
    };
    this.modalService.openDefaultDialog(
      modalData,
      null,
      false,
      false,
      DialogWidth.DEFAULT,
      DialogHeight.DEFAULT
    );
  }

  private refreshSuitability(data: IntermediaryPortfolio) {
    this.campaignIntermediaryService.refreshCampaignIntermediaryPortfolioSuitabilities(data.id).subscribe(() => {
      this.notificationService.handleSuccess(
        this.translateService.instant('refreshSelectedSuitabilitySuccess')
      );
      // refresh portfolios for grid, but wait a bit for the backend to update
      setTimeout(() => {
        this.portfoliosGetter().subscribe((portfolios) => {
          this.setPortfolios(portfolios);
        })}, 200);
    });
  }

  setPortfolios(src: IntermediaryPortfolio[]) {
    const unselected = src.filter(p => p.id == 0);
    const selected = src.filter(p => p.id > 0);
    this.portfolios = [...unselected, ...selected];
  }

  addSelectedPortfolios() {
    const selectedNodes = this.selectedGridApi.getSelectedNodes();
    if (selectedNodes.length == 0) return;
    const portfolioIdents = selectedNodes
      .map(n => n.data.ident);
    this.campaignIntermediaryService.addPortfolios(this.campaignIntermediaryId, portfolioIdents)
      .subscribe(portfolios => {
        this.setPortfolios(portfolios);
        this.selectedGridApi.setNodesSelected({
          nodes: selectedNodes,
          source: 'api',
          newValue: false
        });
        this.selectGrid.reload();
      })
  }

}
