import { Component, EventEmitter, Input, Output } from '@angular/core';

// MINI COMPONENT FOR OVERVIEW DETAILS
@Component({
  selector: 'app-overview-detail-item',
  templateUrl: './overview-detail-item.component.html',
})
export class OverviewDetailItemComponent {
  @Input() labelRef: string;
  @Input() label: string;
  @Input() value: string | number;
  @Input() checked: boolean;
  @Input() icon: string;
  @Input() valueIcon: string;
  @Input() noValuePlaceholder = '-';
  @Input() tooltip = '';
  @Input() editButton = false;
  @Input() editToggle = false;
  @Input() toogleDisabled = false;
  @Input() onToggleLoading = false;
  @Input() clickable = false;
  @Output() editClicked = new EventEmitter();
  @Output() toggled = new EventEmitter();

  constructor() {}

  onEdit() {
    this.editClicked.emit();
  }
  onToggleChange(e: any) {
    this.toggled.emit(!this.checked);
  }
}
