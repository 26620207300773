<div class="filter-assets">
  <div class="filter-asset-column">
    <div class="filter-search">
      <div class="filter-asset-title">
        {{ 'assetsInPortfolio' | translate }}
      </div>
      <button
        class="add-product"
        mat-raised-button
        color="primary"
        [matTooltip]="'addProducts' | translate"
        [disabled]="readOnly"
        (click)="handleAddInclude()">
        <app-config-icon iconIdentifier="add"></app-config-icon>
        {{'add' | translate}}
      </button>
      <div class="spacer"></div>
      <app-filter-details-operator-button
        *ngIf="!readOnly || assetsInclude.children.length > 0"
        [operator]="assetsInclude.operator"
        (operatorChange)="changeOperatorInclude($event)"
        [readOnly]="readOnly"
      ></app-filter-details-operator-button>
    </div>
    <div class="filter-list">
      <app-filter-asset
        *ngFor="let filter of assetsInclude.children"
        [filter]="filter"
        (delete)="deleteFilterInclude(filter.key)"
        [readOnly]="readOnly"
      ></app-filter-asset>
    </div>
  </div>
  <div class="filter-asset-column">
    <div class="filter-search">
      <div class="filter-asset-title">
        {{'assetsNotInPortfolio' | translate}}
      </div>
      <button
        class="add-product"
        mat-raised-button
        color="primary"
        [matTooltip]="'addProducts' | translate"
        [disabled]="readOnly"
        (click)="handleAddExclude()">
        <app-config-icon iconIdentifier="add"></app-config-icon>
        {{'add' | translate}}
      </button>
      <div class="spacer"></div>
      <app-filter-details-operator-button
        *ngIf="!readOnly || assetsExclude.children.length > 0"
        [operator]="assetsExclude.operator"
        (operatorChange)="changeOperatorExclude($event)"
        [readOnly]="readOnly"
      ></app-filter-details-operator-button>
    </div>
    <div class="filter-list">
      <app-filter-asset
        *ngFor="let filter of assetsExclude.children"
        [filter]="filter"
        (delete)="deleteFilterExclude(filter.key)"
        [readOnly]="readOnly"
      ></app-filter-asset>
    </div>
  </div>
</div>
