<div class="primary header progress-card">
  <span class="title h3"
    >{{ title | translate
    }}<mat-spinner *ngIf="dataLoading$ | async" [diameter]="24"></mat-spinner
  ></span>
  <div class="number-container">
    <span
      class="actual-number h4"
      *ngIf="actualNumber !== null && maxNumber !== null"
      >{{ actualNumber | truncateValue: 1 }}</span
    >
    <span
      class="number-divider h4"
      *ngIf="actualNumber !== null && maxNumber !== null"
    >
      /
    </span>
    <span class="max-number h2" *ngIf="maxNumber !== null">{{
      maxNumber | truncateValue: 1
    }}</span>
  </div>
  <div class="indicator" [ngStyle]="getIndicatorStyles()"></div>
</div>
