/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CampaignActionExecutionAction = 'DIRECT_SEND' | 'MANUAL' | 'REVERT';

export const CampaignActionExecutionAction = {
    DIRECTSEND: 'DIRECT_SEND' as CampaignActionExecutionAction,
    MANUAL: 'MANUAL' as CampaignActionExecutionAction,
    REVERT: 'REVERT' as CampaignActionExecutionAction
};

