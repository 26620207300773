<app-card
  class="charts additional-chart"
  [showSpinner]="(loading$ | async) || (chartDataLoading$ | async)"
  [ngSwitch]="selectedChart"
>
  <div card-header>
    <span>{{ additionalCardState | translate }}</span>
  </div>
  <app-config-icon
    *ngIf="additionalCardState | getPortfolioAdditionalCardActionIcon"
    [iconIdentifier]="
      additionalCardState | getPortfolioAdditionalCardActionIcon
    "
    (click)="onAdditionalCardActionClick()"
    [matTooltip]="'close' | translate"
    card-action
  ></app-config-icon>
  <div card-body>
    <div *ngSwitchDefault>
      <ng-container [ngSwitch]="additionalCardState">
        <div
          class="pick-a-chart-container"
          *ngSwitchCase="additionalCardStates.charts"
        >
          <div
            class="pick-chart-tile"
            *ngIf="
              guiConfig.charts['assetClassBar'] ||
              guiConfig.charts['assetClassPie']
            "
          >
            <div class="pick-chart-tile__top">
              <span class="h3">{{ 'assetClass' | translate }}</span>
            </div>
            <div class="pick-chart-tile__bottom">
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['assetClassBar']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="bar_chart"
                    [tooltip]="'openBarChart' | translate"
                    (click)="setChartType(chartTypes.assetClassBar)"
                  ></app-config-icon>
                </span>
              </div>
              <div
                class="pick-chart-tile__bottom__separator"
                *ngIf="
                  guiConfig.charts['assetClassBar'] &&
                  guiConfig.charts['assetClassPie']
                "
              ></div>
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['assetClassPie']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="pie_chart"
                    [tooltip]="'openPieChart' | translate"
                    (click)="setChartType(chartTypes.assetClassPie)"
                  ></app-config-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            class="pick-chart-tile"
            *ngIf="
              guiConfig.charts['currencyBar'] || guiConfig.charts['currencyPie']
            "
          >
            <div class="pick-chart-tile__top">
              <span class="h3">{{ 'currency' | translate }}</span>
            </div>
            <div class="pick-chart-tile__bottom">
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['currencyBar']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="bar_chart"
                    [tooltip]="'openBarChart' | translate"
                    (click)="setChartType(chartTypes.currencyBar)"
                  ></app-config-icon>
                </span>
              </div>
              <div
                class="pick-chart-tile__bottom__separator"
                *ngIf="
                  guiConfig.charts['currencyBar'] &&
                  guiConfig.charts['currencyPie']
                "
              ></div>
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['currencyPie']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="pie_chart"
                    [tooltip]="'openPieChart' | translate"
                    (click)="setChartType(chartTypes.currencyPie)"
                  ></app-config-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            class="pick-chart-tile"
            *ngIf="
              guiConfig.charts['regionBar'] || guiConfig.charts['regionPie']
            "
          >
            <div class="pick-chart-tile__top">
              <span class="h3">{{ 'region' | translate }}</span>
            </div>
            <div class="pick-chart-tile__bottom">
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['regionBar']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="bar_chart"
                    [tooltip]="'openBarChart' | translate"
                    (click)="setChartType(chartTypes.regionBar)"
                  ></app-config-icon>
                </span>
              </div>
              <div
                class="pick-chart-tile__bottom__separator"
                *ngIf="
                  guiConfig.charts['regionBar'] && guiConfig.charts['regionPie']
                "
              ></div>
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['regionPie']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="pie_chart"
                    [tooltip]="'openPieChart' | translate"
                    (click)="setChartType(chartTypes.regionPie)"
                  ></app-config-icon>
                </span>
              </div>
            </div>
          </div>
          <div
            class="pick-chart-tile"
            *ngIf="
              guiConfig.charts['sectorBar'] || guiConfig.charts['sectorPie']
            "
          >
            <div class="pick-chart-tile__top">
              <span class="h3">{{ 'sector' | translate }}</span>
            </div>
            <div class="pick-chart-tile__bottom">
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['sectorBar']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="bar_chart"
                    [tooltip]="'openBarChart' | translate"
                    (click)="setChartType(chartTypes.sectorBar)"
                  ></app-config-icon>
                </span>
              </div>
              <div
                class="pick-chart-tile__bottom__separator"
                *ngIf="
                  guiConfig.charts['sectorBar'] && guiConfig.charts['sectorPie']
                "
              ></div>
              <div
                class="pick-chart-tile__bottom__selection"
                *ngIf="guiConfig.charts['sectorPie']"
              >
                <span>
                  <app-config-icon
                    iconIdentifier="pie_chart"
                    [tooltip]="'openPieChart' | translate"
                    (click)="setChartType(chartTypes.sectorPie)"
                  ></app-config-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <app-chart
      *ngSwitchCase="chartTypes.assetClassBar"
      [chartType]="chartTypes.assetClassBar"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.assetClassPie"
      [chartType]="chartTypes.assetClassPie"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.regionBar"
      [chartType]="chartTypes.regionBar"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.regionPie"
      [chartType]="chartTypes.regionPie"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.sectorBar"
      [chartType]="chartTypes.sectorBar"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.sectorPie"
      [chartType]="chartTypes.sectorPie"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.currencyBar"
      [chartType]="chartTypes.currencyBar"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
    <app-chart
      *ngSwitchCase="chartTypes.currencyPie"
      [chartType]="chartTypes.currencyPie"
      [campaign]="campaign"
      [story]="story"
    ></app-chart>
  </div>
</app-card>
