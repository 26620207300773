/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserInteraction = 'CLICKED_HIERARCHY_VIEW_BUTTON' | 'CLICKED_FLAT_VIEW_BUTTON' | 'SWITCHED_TO_DETAILED_SCREEN' | 'SWITCHED_TO_QUICK_SCREEN' | 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_DE' | 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_EN' | 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_OTHER' | 'CHANGED_CAMPAIGN_ACTION_CHANNEL' | 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_DE' | 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_EN' | 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_OTHER' | 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_CHANNEL' | 'ADDED_CAMPAIGN_ACTION_CONTENT' | 'ADDED_CAMPAIGN_INTERMEDIARY_ACTION_CONTENT' | 'BULK_EDITED_CAMPAIGN_ACTION' | 'BULK_EDITED_CAMPAIGN_INTERMEDIARY_ACTION' | 'OPENED_DISTRIBUTOR_MONITORING';

export const UserInteraction = {
    CLICKEDHIERARCHYVIEWBUTTON: 'CLICKED_HIERARCHY_VIEW_BUTTON' as UserInteraction,
    CLICKEDFLATVIEWBUTTON: 'CLICKED_FLAT_VIEW_BUTTON' as UserInteraction,
    SWITCHEDTODETAILEDSCREEN: 'SWITCHED_TO_DETAILED_SCREEN' as UserInteraction,
    SWITCHEDTOQUICKSCREEN: 'SWITCHED_TO_QUICK_SCREEN' as UserInteraction,
    CHANGEDCAMPAIGNACTIONLANGUAGETODE: 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_DE' as UserInteraction,
    CHANGEDCAMPAIGNACTIONLANGUAGETOEN: 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_EN' as UserInteraction,
    CHANGEDCAMPAIGNACTIONLANGUAGETOOTHER: 'CHANGED_CAMPAIGN_ACTION_LANGUAGE_TO_OTHER' as UserInteraction,
    CHANGEDCAMPAIGNACTIONCHANNEL: 'CHANGED_CAMPAIGN_ACTION_CHANNEL' as UserInteraction,
    CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETODE: 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_DE' as UserInteraction,
    CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETOEN: 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_EN' as UserInteraction,
    CHANGEDCAMPAIGNINTERMEDIARYACTIONLANGUAGETOOTHER: 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_LANGUAGE_TO_OTHER' as UserInteraction,
    CHANGEDCAMPAIGNINTERMEDIARYACTIONCHANNEL: 'CHANGED_CAMPAIGN_INTERMEDIARY_ACTION_CHANNEL' as UserInteraction,
    ADDEDCAMPAIGNACTIONCONTENT: 'ADDED_CAMPAIGN_ACTION_CONTENT' as UserInteraction,
    ADDEDCAMPAIGNINTERMEDIARYACTIONCONTENT: 'ADDED_CAMPAIGN_INTERMEDIARY_ACTION_CONTENT' as UserInteraction,
    BULKEDITEDCAMPAIGNACTION: 'BULK_EDITED_CAMPAIGN_ACTION' as UserInteraction,
    BULKEDITEDCAMPAIGNINTERMEDIARYACTION: 'BULK_EDITED_CAMPAIGN_INTERMEDIARY_ACTION' as UserInteraction,
    OPENEDDISTRIBUTORMONITORING: 'OPENED_DISTRIBUTOR_MONITORING' as UserInteraction
};

