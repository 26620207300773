import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/api/core';

/**
 * Used in Modal in Campaign Portfolio List
 */
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
})
export class ClientDetailsComponent {
  @Input() client: Client;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { data: Client }) {
    if (data.data) {
      this.client = data.data;
    }
  }
}
