import { FeaturesService } from '../api/core';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

export type FeatureFlags = {
  [key: string]: any;
  contentVideo: boolean;
  weightFilters: boolean;
};

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  static FEATURE_FLAGS_KEY = 'featureFlags';
  private flags: FeatureFlags;

  constructor(private readonly featuresService: FeaturesService) {}

  getFeatureFlags(): Observable<FeatureFlags> {
    if (this.flags) {
      return of(this.flags);
    } else {
      return this.featuresService.getFlags().pipe(
        map((d: FeatureFlags) => {
          this.flags = d;
          sessionStorage.setItem(
            FeatureFlagsService.FEATURE_FLAGS_KEY,
            JSON.stringify(this.flags)
          );
          return this.flags;
        })
      );
    }
  }
}
