import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LicenseService, UserService} from "../../../api/core";
import {Router} from "@angular/router";
import {EViewRoutes} from "../../../util/enum";

/**
 * Component used for callback page.
 */
@Component({
  selector: 'app-callback',
  templateUrl: './license.component.html',
})
export class LicenseComponent implements OnInit {
  detailsOpen = true;
  licenseUsername = null;
  licenseMessage = null;
  header = this.translateService.instant('licenseInvalid');

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private licenseService: LicenseService,
    private router: Router
  ) {
  }

  get license_username() {
    return this.licenseUsername;
  }

  get license_message() {
    return this.licenseMessage;
  }

  ngOnInit(): void {
    this.userService.getUserRoles().subscribe((user) => {
      this.licenseUsername = user.username;
      let msg = '';
      if (user.roles.length === 0) {
        msg = this.translateService.instant('warningUserWithoutRoles');
      }
      if (user.hubs.length === 0) {
        msg = msg + ' ' + this.translateService.instant('warningUserWithoutHubs');
      }
      this.licenseMessage = msg;
      this.licenseService.getLicense().subscribe({
        next: (license) => {
          // redirect to home if license is present
          if (license.licensed && user.roles.length > 0 && user.hubs.length > 0) {
            this.router.navigate([EViewRoutes.home]);
          } else if(!license.licensed && license.maintenance) {
            this.header = this.translateService.instant('login');
            this.licenseMessage = this.translateService.instant("warmingMaintenance");
          }
        },
        error: (_) => {
          console.warn('License not found');
        }
      });
    });
  }

  onToggleDetails(): void {
    this.detailsOpen = !this.detailsOpen;
  }
}
