import {Component, OnInit} from "@angular/core";
import {CampaignDistributorIntermediaryAction, CampaignIntermediaryActionService, Employee} from "../../../../api/core";
import {ActivatedRoute} from "@angular/router";
import {GlobalService} from "../../../../services/global.service";
import {EViewRoutes} from "../../../../util/enum";
import {ColDef, GridOptions, RowDoubleClickedEvent, ValueFormatterParams} from "ag-grid-community";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";
import {genCodeTableColumn, genDateColumn} from "../../../../util/grid/grid-renderer.util";
import {genCustomContentColumn} from "../../../../shared/grid/cell-renderers/custom-content.renderer";
import {EProtectedActions} from "../../../../util/protected-actions";
import {ActionType, ContentAction} from "../../../../campaign/views/campaign-overview/campaign-actions-list-utils";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {PermissionService} from "../../../../services/permission.service";
import {CustomPreviewService} from "../../../../services/custom-preview.service";

@Component({
  selector: 'app-distributor-employee-overview',
  templateUrl: './distributor-employee-overview.component.html',
})
export class DistributorEmployeeOverviewComponent implements OnInit {
  employee: Employee;

  actions: CampaignDistributorIntermediaryAction[] = [];
  colDefs: ColDef[] = [
    genLinkColumn({
      field: 'campaign.name',
      headerName: this.translateService.instant('campaignsSent'),
      link: (data: any) => `${EViewRoutes.campaignOverview}${data.campaign.id}`,
    }),
    genDateColumn({
      field: 'sentDate',
      headerName: this.translateService.instant('sent'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.sentDate),
    }),
    {
      ...genCustomContentColumn({
        field: 'content.id',
        headerName: '',
        hidden: (data: any) =>
          !this.permissionService.hasAnyPermission(EProtectedActions.editCampaignCustomContent) ||
          !data.hasCidPermission,
        callback: (data: CampaignDistributorIntermediaryAction) => {
          const contentAction: ContentAction = {
            id: data.id,
            type: ActionType.IntermediateAction,
            campaignId: data.campaign.id,
            language: data.language,
            channel: data.channel,
            content: data.content,
          }
          this.customPreviewService.previewCustomContent(contentAction, true);
        },
      }),
      floatingFilter: false,
      sortable: true,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
    },
    {
      ...genCodeTableColumn({
        field: 'channel.type',
        headerName: this.translateService.instant('channel'),
      }),
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.campaign.id}`,
      ),
  };

  constructor(
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected activatedRoute: ActivatedRoute,
    protected globalService: GlobalService,
    protected permissionService: PermissionService,
    protected customPreviewService: CustomPreviewService,
    protected campaignIntermediaryActionService: CampaignIntermediaryActionService,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.employee = data.employee;
    });
  }

  ngOnInit(): void {
    this.campaignIntermediaryActionService.getDistributorEmployeeCampaignActions(this.employee.id)
      .subscribe((data) => {
        this.actions = data;
      });
  }

  showIntermediary() {
    this.globalService.navigate(`${EViewRoutes.distributorIntermediaryOverview}${this.employee.intermediary.id}`)
  }

  protected readonly EViewRoutes = EViewRoutes;
}
