import {Component} from "@angular/core";
import {genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {CarbonCopy, CarbonCopyService, CodeTableEntry} from "../../../../api/core";
import {GridOptions} from "ag-grid-enterprise";
import {TranslateService} from "@ngx-translate/core";
import {CodeTableService} from "../../../../services/code-table.service";
import {ECodeTables, EModalType} from "../../../../util/enum";
import {combineLatest} from 'rxjs';
import {ModalData} from "../../../../models/modal.model";
import {DialogHeight, DialogWidth, ModalService} from "../../../../services/modal.service";
import {EmailDialogComponent} from "../email-dialog/email-dialog.component";
import {ToastrService} from "ngx-toastr";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {ColDef} from "ag-grid-community";

@Component({
  selector: 'app-cc-framework',
  templateUrl: './cc-framework.component.html'
})
export class CcFrameworkComponent {

  hubs: CodeTableEntry[] = [];
  publicationTypes: CodeTableEntry[] = [];
  selectedHub: CodeTableEntry | null = null;
  selectedPublicationType: CodeTableEntry | null = null;
  rows: CarbonCopy[] = [];

  columns: ColDef[] = [
    {
      ...genTextColumn('email', this.translateService.instant('email')),
      sortable: true,
      sort: "asc",
      flex: 1,
    },
    genIconButtonColumn({
      callback: (data: CarbonCopy) => this.onEdit(data),
      icon: 'edit_m',
      tooltip: this.translateService.instant('edit'),
    }),
    genIconButtonColumn({
      callback: (data: CarbonCopy) => this.onDelete(data),
      icon: 'delete',
      tooltip: this.translateService.instant('delete'),
    }),
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };

  constructor(
    private readonly translateService: TranslateService,
    private readonly codeTableService: CodeTableService,
    private readonly carbonCopyService: CarbonCopyService,
    private readonly modalService: ModalService,
    private readonly toastService: ToastrService
  ) {
    combineLatest([
      codeTableService.getCodeTable(ECodeTables.hub),
      codeTableService.getCodeTable(ECodeTables.publicationType),
      carbonCopyService.getList(null, null),
    ]).subscribe(([hubs, publicationTypes, rows]) => {
      this.hubs = hubs;
      this.publicationTypes = publicationTypes;
      this.rows = rows;
    });
  }

  onAdd() {
    const modalData: ModalData = {
      type: EModalType.addEmail,
      title: EModalType.addEmail,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      data: {
        email: ''
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: EmailDialogComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      undefined,
      undefined,
      DialogWidth.HALF,
      DialogHeight.AUTO,
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      if (result.required) {
        this.toastService.error(this.translateService.instant('required'));
        return;
      }
      if (result.invalid) {
        this.toastService.error(this.translateService.instant('invalidEmail'));
        return;
      }
      this.carbonCopyService.createCarbonCopy(
        { email: result },
        this.selectedHub?.id,
        this.selectedPublicationType?.id,
        ).subscribe(rows => {
        this.rows = rows;
      });
    });
  }
  onEdit(cc: CarbonCopy) {
    const modalData: ModalData = {
      type: EModalType.editEmail,
      title: EModalType.editEmail,
      submitBtn: {
        label: this.translateService.instant('save'),
      },
      data: {
        email: cc.email
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: EmailDialogComponent,
    };
    const dialogRef = this.modalService.openDefaultDialog(
      modalData,
      undefined,
      undefined,
      undefined,
      DialogWidth.HALF,
      DialogHeight.AUTO,
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      if (result.required) {
        this.toastService.error(this.translateService.instant('required'));
        return;
      }
      if (result.invalid) {
        this.toastService.error(this.translateService.instant('invalidEmail'));
        return;
      }
      if (result == cc.email) return; // no change
      this.carbonCopyService.updateCarbonCopy({
        oldEmail: cc.email,
        newEmail: result
      },
        this.selectedHub?.id,
        this.selectedPublicationType?.id,
      ).subscribe(rows => {
        this.rows = rows;
      });
    });
  }
  onDelete(cc: CarbonCopy) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: EModalType.deleteEmail,
      component: null,
      data: {
        message: this.translateService.instant('deleteEmailMessage').replace('EMAIL', cc.email),
      },
      submitBtn: {
        label: this.translateService.instant('delete'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.carbonCopyService.deleteCarbonCopy(
            this.selectedHub?.id,
            this.selectedPublicationType?.id,
            cc.email,
          ).subscribe(rows => {
            this.rows = rows;
            modalRef.close();
          });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }
  onSelectHub(hub?: CodeTableEntry) {
    this.selectedHub = hub;
    this.onSelectedChanged();
  }
  onSelectPubType(pubType?: CodeTableEntry) {
    this.selectedPublicationType = pubType;
    this.onSelectedChanged();
  }
  onSelectedChanged() {
    this.carbonCopyService.getList(
      this.selectedHub?.id,
      this.selectedPublicationType?.id
    ).subscribe(rows => {
      this.rows = rows;
    })
  }
}
