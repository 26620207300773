import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Portfolio, PortfolioPosition, PortfolioService, User} from 'src/app/api/core';
import {usernameValueLabel} from 'src/app/util/grid/grid-renderer.util';
import {GlobalService} from "../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {GridService} from "../../services/grid.service";
import {ModalComponent} from "../modal/modal.component";
import {LabelBuilder} from "../../util/label-builder";
import {ColDef, GridOptions} from "ag-grid-community";

/**
 * Used in Modal in Campaign Portfolio List
 */
@Component({
  selector: 'app-portfolio-details',
  templateUrl: './portfolio-details.component.html',
})
export class PortfolioDetailsComponent {
  portfolio: Portfolio;

  columnDefs: ColDef[] = [];
  autoGroupColumnDef: ColDef;
  gridOptions: GridOptions;
  data: PortfolioPosition[] = [];

  constructor(
    protected readonly globalService: GlobalService,
    protected readonly translateService: TranslateService,
    protected readonly portfolioService: PortfolioService,
    protected readonly labelBuilder: LabelBuilder,
    protected readonly gridService: GridService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { data: Portfolio }
  ) {
    this.autoGroupColumnDef =
      this.gridService.getPortfolioPositionAutoGroupColumnDef(this);
    this.portfolio = data.data;
    this.gridOptions = {
      rowHeight: 36,
      suppressContextMenu: true,
      suppressCellFocus: true,
      groupDefaultExpanded: 1,
      suppressAggFuncInHeader: true,
      paginationAutoPageSize: true,
    };
    this.columnDefs = this.gridService.getPortfolioPositionColDef(this);
    const portfolioId = this.portfolio.id;
    if (portfolioId != null) {
      this.portfolioService.getPortfolioPositions(portfolioId).subscribe({
        next: (result) => {
          this.data = result;
        },
      });
    }
  }

  usernameLabel(u: User) {
    return usernameValueLabel(u);
  }
}
