import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { IntermediaryRoutingModule } from './intermediary-routing.module';
import { IntermediaryOverviewComponent } from './views/intermediary-overview/intermediary-overview.component';
import { IntermediaryOverviewEmployeesComponent } from "./views/intermediary-overview/intermediary-overview-employees/intermediary-overview-employees.component";
import { IntermediaryListComponent } from './views/intermediary-list/intermediary-list.component';
import { IntermediaryOverviewPortfoliosComponent } from "./views/intermediary-overview/intermediary-overview-portfolios/intermediary-overview-portfolios.component";
import { IntermediaryOverviewCampaignsComponent } from "./views/intermediary-overview/intermediary-overview-campaigns/intermediary-overview-campaigns.component";

/**
 * Intermediary Module.
 * Contains all components related to intermediaries
 */
@NgModule({
  declarations: [
    IntermediaryListComponent,
    IntermediaryOverviewComponent,
    IntermediaryOverviewPortfoliosComponent,
    IntermediaryOverviewEmployeesComponent,
    IntermediaryOverviewCampaignsComponent
  ],
  imports: [CommonModule, IntermediaryRoutingModule, SharedModule],
  exports: [IntermediaryListComponent, IntermediaryOverviewPortfoliosComponent, IntermediaryOverviewEmployeesComponent],
})
export class IntermediaryModule {}
