/**
 * Interface for key value pairs
 */
export interface IKeyValue {
  /**
   * Key
   */
  key: string;
  /**
   * Value
   */
  value: any;
}

/**
 * Class that implements {@link IKeyValue}
 */
export class KeyValue implements IKeyValue {
  constructor(
    public key: string,
    public value: any
  ) {}
}

/**
 * Interface for key value with optional properties
 */
export interface IOptionalKeyValue {
  /**
   * Key
   */
  key?: any;
  /**
   * Value
   */
  value?: any;
}

/**
 * Interface for min max pairs
 */
export interface IMinMax {
  /**
   * Max value
   */
  max?: number;
  /**
   * Min value
   */
  min?: number;
}
