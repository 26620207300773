<div class="auth-container login">
  <app-card>
    <div card-header>
      <span>{{ 'loginWith' | translate }}</span>
    </div>
    <div card-body>
      <button
        *ngFor="let config of authConfigs; let i = index"
        mat-flat-button
        color="primary"
        type="submit"
        (click)="onSubmit(i)"
        [matTooltip]="
          'startAuthenticationWith' | translate : { configName: config.name }
        "
      >
        {{ config.name }}
      </button>
    </div>
  </app-card>
</div>
