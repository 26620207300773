import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() tabText: string;
  @Input() isActive = false;
  @Output() tabClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onTabClick(): void {
    this.tabClicked.emit(this.tabText);
  }
}
