import {
  Component,
  ComponentRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router, Event } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter } from 'rxjs';
import { IModalData, ModalButton } from 'src/app/models/modal.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { EModalType } from 'src/app/util/enum';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
  @HostBinding('class.aspark-modal') reclaimerTheme = true;
  /**
   * Reference to view container to attach content component to
   */
  @ViewChild('modalTarget', { read: ViewContainerRef, static: true })
  vcRef: ViewContainerRef;

  /**
   * Reference to content component that will be created
   */
  componentRef: ComponentRef<any>;
  /**
   * Modal type
   */
  type: EModalType;
  /**
   * Content data
   */
  content: any;
  /**
   * Content component type
   */
  component: any;
  /**
   * Modal Title
   */
  title: string;
  /**
   * Provide modal type enum to html
   */
  get modalTypes() {
    return EModalType;
  }
  /**
   * Updated by child modal component
   */
  toolbarActionData = {
    updateLoading: false,
    btnDisabled: false, // disables submit button
  };
  /**
   * Tooltip that's shown when btn is disabled
   */
  toolbarBtnTooltipDisabled: string;
  /**
   * Submit button
   */
  submitBtn: ModalButton;
  /**
   * Submit button
   */
  cancelBtn: ModalButton;
  /**
   * Whether or not closing of modal is disabled
   */
  disableClose: boolean;
  /**
   * Router subscription
   */
  routerSubscription: Subscription;
  /**
   * Prompt Text
  */
  promptText: string;
  /**
   * Prompt Placeholder
   */
  promptPlaceholder: string;

  constructor(
    protected dataService: DataService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected router: Router,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: IModalData
  ) {
    if (data) {
      this.type = data.type;
      this.content = data.data;
      this.component = data.component;
      this.title = data.title;
      this.submitBtn = data.submitBtn;
      this.cancelBtn = data.cancelBtn;
      this.disableClose = this.dialogRef.disableClose;
      this.promptText = data.data?.promptText ?? '';
    }
    this.routerSubscription = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnInit(): void {
    if (this.component) {
      // create content component from input data
      this.componentRef = this.vcRef.createComponent(this.component);
    }
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    this.routerSubscription.unsubscribe();
  }

  /**
   * Closes modal
   * @param data for passing after close
   */
  close(data?: any, callback?: (param?: any) => void): void {
    if (!this.toolbarActionData.updateLoading) {
      if (callback) {
        callback(this.dialogRef);
      } else if (data) {
        this.dialogRef.close(data);
      } else {
        this.dialogRef.close(false);
      }
    }
  }

  /**
   * Triggered by HTML.
   * Triggeres modalAction function of child modal component and sends type so that
   * the child component knows what to do (update/create)
   */
  toolbarAction(callback: (param?: any) => void): void {
    this.toolbarActionData.btnDisabled = true;
    this.toolbarActionData.updateLoading = true;
    if (callback) {
      callback(this.dialogRef);
    } else {
      this.componentRef.instance.modalAction(this.type);
    }
  }

  resetToolbarActionButtons(): void {
    this.toolbarActionData.btnDisabled = false;
    this.toolbarActionData.updateLoading = false;
  }
}
