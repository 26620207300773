import {Component} from "@angular/core";
import {Tab} from "../../../models/tabs.model";
import {DISTRIBUTOR_TABS} from "../../../util/tab.constants";
import {GlobalService} from "../../../services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../services/permission.service";
import {EProtectedActions} from "../../../util/protected-actions";
import {Subject} from "rxjs";

@Component({
  selector: 'app-distributor-overview',
  templateUrl: './distributor-overview.component.html'
})
export class DistributorOverviewComponent {
  tabs: Tab[] = DISTRIBUTOR_TABS;

  refreshSubject = new Subject<void>();
  activeTab: string;

  constructor(
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
  ) {
  }

  get actions() {
    return EProtectedActions
  }

  onTabChanged(tab: string): void {
    if (this.activeTab !== tab) {
      this.activeTab = tab;
    }
  }

  get protectedActions() {
    return EProtectedActions;
  }

  protected readonly DISTRIBUTOR_TABS = DISTRIBUTOR_TABS;

  refresh() {
    this.refreshSubject.next();
  }
}
