import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ContentDefinition, HubChannelDefinitionMapping} from "../../../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {EModalType} from "../../../../../util/enum";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {GlobalService} from "../../../../../services/global.service";

@Component({
  selector: 'app-hub-channel-definition-mapping-dialog',
  templateUrl: './hub-channel-definition-mapping-dialog.component.html'
})
export class HubChannelDefinitionMappingDialogComponent implements OnInit, OnDestroy, ModalSubComponent {
  readonly contentDefinitions: ContentDefinition[] = [];
  readonly mappings: HubChannelDefinitionMapping[] = [];
  mappingForm: FormGroup;
  formBuilt: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    protected translateService: TranslateService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected readonly fb: FormBuilder,
    protected readonly globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      contentDefinitions: ContentDefinition[];
      hubChannelDefinitionMappings: HubChannelDefinitionMapping[];
    } }
  ) {
    this.contentDefinitions = data.data.contentDefinitions;
    this.mappings = data.data.hubChannelDefinitionMappings;
    this.buildForm();
  }

  ngOnInit() {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.mappingForm.invalid;
    this.subscriptions.push(
      this.mappingForm.statusChanges.subscribe(() => {
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.mappingForm.invalid;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private buildForm(): void {
    this.mappingForm = this.fb.record({})
    this.mappings.forEach(mapping => {
      let formControl = new FormControl(
        this.contentDefinitions.find(cd => cd.id === mapping.contentDefinition.id),
        Validators.required
      );
      this.mappingForm.registerControl(mapping.id.toString(), formControl);
    });
    this.formBuilt = true;
  }

  modalAction(modalType: EModalType | undefined) {
    const mappings = this.getMappingsFromForm();
    this.dialogRef.close(mappings);
  }

  private getMappingsFromForm(): HubChannelDefinitionMapping[] {
    const mappings: HubChannelDefinitionMapping[] = [];
    this.mappings.forEach(mapping => {
      const formControl = this.mappingForm.get(mapping.id.toString());
      if (formControl) {
        mappings.push({
          ...mapping,
          contentDefinition: formControl.value
        });
      }
    });
    return mappings;
  }
}
