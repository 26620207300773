<ng-container *ngIf="assetClasses.length > 0">
  <app-filter-details-operator-button
    *ngIf="!readOnly || filterForm.children.length > 0"
    [operator]="filterForm.operator"
    (operatorChange)="changeOperator($event)"
    [readOnly]="readOnly"
  ></app-filter-details-operator-button>
  <app-filter-range-weight
    *ngFor="let filter of filterForm.children"
    [filterCategory]="filterCategories.assetClass"
    [filter]="filter"
    [label]="getLabel(filter.key)"
    (delete)="deleteFilter(filter.key)"
    [readOnly]="readOnly"
  ></app-filter-range-weight>
  <app-filter-add
    *ngIf="availableAssetClasses.length && !readOnly"
    [options]="availableAssetClasses"
    (selectChange)="addFilter($event)"
  ></app-filter-add>
</ng-container>
