import { EApiCountryKey } from './enum';
import { INestedMenuItem, NestedMenuItem } from 'src/app/models/menu.model';

export const CONTINENTS: INestedMenuItem[] = [
  new NestedMenuItem('af', undefined, [
    new NestedMenuItem(EApiCountryKey.DZA),
    new NestedMenuItem(EApiCountryKey.AGO),
    new NestedMenuItem(EApiCountryKey.BEN),
    new NestedMenuItem(EApiCountryKey.BFA),
    new NestedMenuItem(EApiCountryKey.BDI),
    new NestedMenuItem(EApiCountryKey.CMR),
    new NestedMenuItem(EApiCountryKey.CPV),
    new NestedMenuItem(EApiCountryKey.TCD),
    new NestedMenuItem(EApiCountryKey.COM),
    new NestedMenuItem(EApiCountryKey.COD),
    new NestedMenuItem(EApiCountryKey.DJI),
    new NestedMenuItem(EApiCountryKey.EGY),
    new NestedMenuItem(EApiCountryKey.GNQ),
    new NestedMenuItem(EApiCountryKey.ERI),
    new NestedMenuItem(EApiCountryKey.SWZ),
    new NestedMenuItem(EApiCountryKey.ETH),
    new NestedMenuItem(EApiCountryKey.GAB),
    new NestedMenuItem(EApiCountryKey.GMB),
    new NestedMenuItem(EApiCountryKey.GHA),
    new NestedMenuItem(EApiCountryKey.GIN),
    new NestedMenuItem(EApiCountryKey.GNB),
    new NestedMenuItem(EApiCountryKey.CIV),
    new NestedMenuItem(EApiCountryKey.KEN),
    new NestedMenuItem(EApiCountryKey.LSO),
    new NestedMenuItem(EApiCountryKey.LBR),
    new NestedMenuItem(EApiCountryKey.LBY),
    new NestedMenuItem(EApiCountryKey.MDG),
    new NestedMenuItem(EApiCountryKey.MWI),
    new NestedMenuItem(EApiCountryKey.MLI),
    new NestedMenuItem(EApiCountryKey.MRT),
    new NestedMenuItem(EApiCountryKey.MUS),
    new NestedMenuItem(EApiCountryKey.MAR),
    new NestedMenuItem(EApiCountryKey.MOZ),
    new NestedMenuItem(EApiCountryKey.NAM),
    new NestedMenuItem(EApiCountryKey.NER),
    new NestedMenuItem(EApiCountryKey.NGA),
    new NestedMenuItem(EApiCountryKey.RWA),
    new NestedMenuItem(EApiCountryKey.STP),
    new NestedMenuItem(EApiCountryKey.SEN),
    new NestedMenuItem(EApiCountryKey.SYC),
    new NestedMenuItem(EApiCountryKey.SLE),
    new NestedMenuItem(EApiCountryKey.SOM),
    new NestedMenuItem(EApiCountryKey.ZAF),
    new NestedMenuItem(EApiCountryKey.SSD),
    new NestedMenuItem(EApiCountryKey.SDN),
    new NestedMenuItem(EApiCountryKey.TZA),
    new NestedMenuItem(EApiCountryKey.TGO),
    new NestedMenuItem(EApiCountryKey.TUN),
    new NestedMenuItem(EApiCountryKey.UGA),
    new NestedMenuItem(EApiCountryKey.ZMB),
    new NestedMenuItem(EApiCountryKey.ZWE),
  ]),
  new NestedMenuItem('as', undefined, [
    new NestedMenuItem(EApiCountryKey.AFG),
    new NestedMenuItem(EApiCountryKey.ARM),
    new NestedMenuItem(EApiCountryKey.AZE),
    new NestedMenuItem(EApiCountryKey.BHR),
    new NestedMenuItem(EApiCountryKey.BGD),
    new NestedMenuItem(EApiCountryKey.BTN),
    new NestedMenuItem(EApiCountryKey.BWA),
    new NestedMenuItem(EApiCountryKey.BRN),
    new NestedMenuItem(EApiCountryKey.KHM),
    new NestedMenuItem(EApiCountryKey.CHN),
    new NestedMenuItem(EApiCountryKey.GEO),
    new NestedMenuItem(EApiCountryKey.HKG),
    new NestedMenuItem(EApiCountryKey.IND),
    new NestedMenuItem(EApiCountryKey.IDN),
    new NestedMenuItem(EApiCountryKey.IRN),
    new NestedMenuItem(EApiCountryKey.IRQ),
    new NestedMenuItem(EApiCountryKey.ISR),
    new NestedMenuItem(EApiCountryKey.JPN),
    new NestedMenuItem(EApiCountryKey.JOR),
    new NestedMenuItem(EApiCountryKey.KAZ),
    new NestedMenuItem(EApiCountryKey.KWT),
    new NestedMenuItem(EApiCountryKey.KGZ),
    new NestedMenuItem(EApiCountryKey.LAO),
    new NestedMenuItem(EApiCountryKey.LBN),
    new NestedMenuItem(EApiCountryKey.MAC),
    new NestedMenuItem(EApiCountryKey.MYS),
    new NestedMenuItem(EApiCountryKey.MDV),
    new NestedMenuItem(EApiCountryKey.MNG),
    new NestedMenuItem(EApiCountryKey.MMR),
    new NestedMenuItem(EApiCountryKey.NPL),
    new NestedMenuItem(EApiCountryKey.PRK),
    new NestedMenuItem(EApiCountryKey.OMN),
    new NestedMenuItem(EApiCountryKey.PAK),
    new NestedMenuItem(EApiCountryKey.PSE),
    new NestedMenuItem(EApiCountryKey.PHL),
    new NestedMenuItem(EApiCountryKey.QAT),
    new NestedMenuItem(EApiCountryKey.SAU),
    new NestedMenuItem(EApiCountryKey.SGP),
    new NestedMenuItem(EApiCountryKey.KOR),
    new NestedMenuItem(EApiCountryKey.LKA),
    new NestedMenuItem(EApiCountryKey.TWN),
    new NestedMenuItem(EApiCountryKey.TJK),
    new NestedMenuItem(EApiCountryKey.THA),
    new NestedMenuItem(EApiCountryKey.TLS),
    new NestedMenuItem(EApiCountryKey.TUR),
    new NestedMenuItem(EApiCountryKey.TKM),
    new NestedMenuItem(EApiCountryKey.ARE),
    new NestedMenuItem(EApiCountryKey.UZB),
    new NestedMenuItem(EApiCountryKey.VNM),
    new NestedMenuItem(EApiCountryKey.YEM),
  ]),
  new NestedMenuItem('eu', undefined, [
    new NestedMenuItem(EApiCountryKey.ALB),
    new NestedMenuItem(EApiCountryKey.AND),
    new NestedMenuItem(EApiCountryKey.AUT),
    new NestedMenuItem(EApiCountryKey.BLR),
    new NestedMenuItem(EApiCountryKey.BEL),
    new NestedMenuItem(EApiCountryKey.BIH),
    new NestedMenuItem(EApiCountryKey.BGR),
    new NestedMenuItem(EApiCountryKey.HRV),
    new NestedMenuItem(EApiCountryKey.CYP),
    new NestedMenuItem(EApiCountryKey.CZE),
    new NestedMenuItem(EApiCountryKey.DNK),
    new NestedMenuItem(EApiCountryKey.EST),
    new NestedMenuItem(EApiCountryKey.FIN),
    new NestedMenuItem(EApiCountryKey.FRA),
    new NestedMenuItem(EApiCountryKey.DEU),
    new NestedMenuItem(EApiCountryKey.GRC),
    new NestedMenuItem(EApiCountryKey.HUN),
    new NestedMenuItem(EApiCountryKey.ISL),
    new NestedMenuItem(EApiCountryKey.IRL),
    new NestedMenuItem(EApiCountryKey.ITA),
    new NestedMenuItem(EApiCountryKey.LVA),
    new NestedMenuItem(EApiCountryKey.LIE),
    new NestedMenuItem(EApiCountryKey.LTU),
    new NestedMenuItem(EApiCountryKey.LUX),
    new NestedMenuItem(EApiCountryKey.MKD),
    new NestedMenuItem(EApiCountryKey.MLT),
    new NestedMenuItem(EApiCountryKey.MDA),
    new NestedMenuItem(EApiCountryKey.MCO),
    new NestedMenuItem(EApiCountryKey.MNE),
    new NestedMenuItem(EApiCountryKey.NLD),
    new NestedMenuItem(EApiCountryKey.NOR),
    new NestedMenuItem(EApiCountryKey.POL),
    new NestedMenuItem(EApiCountryKey.PRT),
    new NestedMenuItem(EApiCountryKey.ROU),
    new NestedMenuItem(EApiCountryKey.RUS),
    new NestedMenuItem(EApiCountryKey.SMR),
    new NestedMenuItem(EApiCountryKey.SRB),
    new NestedMenuItem(EApiCountryKey.SVK),
    new NestedMenuItem(EApiCountryKey.SVN),
    new NestedMenuItem(EApiCountryKey.ESP),
    new NestedMenuItem(EApiCountryKey.SWE),
    new NestedMenuItem(EApiCountryKey.CHE),
    new NestedMenuItem(EApiCountryKey.UKR),
    new NestedMenuItem(EApiCountryKey.GBR),
  ]),
  new NestedMenuItem('na', undefined, [
    new NestedMenuItem(EApiCountryKey.ATG),
    new NestedMenuItem(EApiCountryKey.BHS),
    new NestedMenuItem(EApiCountryKey.BRB),
    new NestedMenuItem(EApiCountryKey.BLZ),
    new NestedMenuItem(EApiCountryKey.CAN),
    new NestedMenuItem(EApiCountryKey.CRI),
    new NestedMenuItem(EApiCountryKey.CUB),
    new NestedMenuItem(EApiCountryKey.DMA),
    new NestedMenuItem(EApiCountryKey.DOM),
    new NestedMenuItem(EApiCountryKey.SLV),
    new NestedMenuItem(EApiCountryKey.GRD),
    new NestedMenuItem(EApiCountryKey.GTM),
    new NestedMenuItem(EApiCountryKey.HTI),
    new NestedMenuItem(EApiCountryKey.HND),
    new NestedMenuItem(EApiCountryKey.JAM),
    new NestedMenuItem(EApiCountryKey.MEX),
    new NestedMenuItem(EApiCountryKey.NIC),
    new NestedMenuItem(EApiCountryKey.PAN),
    new NestedMenuItem(EApiCountryKey.KNA),
    new NestedMenuItem(EApiCountryKey.LCA),
    new NestedMenuItem(EApiCountryKey.VCT),
    new NestedMenuItem(EApiCountryKey.TTO),
    new NestedMenuItem(EApiCountryKey.USA),
  ]),
  new NestedMenuItem('oc', undefined, [
    new NestedMenuItem(EApiCountryKey.AUS),
    new NestedMenuItem(EApiCountryKey.FJI),
    new NestedMenuItem(EApiCountryKey.KIR),
    new NestedMenuItem(EApiCountryKey.MHL),
    new NestedMenuItem(EApiCountryKey.NRU),
    new NestedMenuItem(EApiCountryKey.NCL),
    new NestedMenuItem(EApiCountryKey.NZL),
    new NestedMenuItem(EApiCountryKey.PLW),
    new NestedMenuItem(EApiCountryKey.PNG),
    new NestedMenuItem(EApiCountryKey.WSM),
    new NestedMenuItem(EApiCountryKey.SLB),
    new NestedMenuItem(EApiCountryKey.TON),
    new NestedMenuItem(EApiCountryKey.TUV),
    new NestedMenuItem(EApiCountryKey.VUT),
  ]),
  new NestedMenuItem('sa', undefined, [
    new NestedMenuItem(EApiCountryKey.ARG),
    new NestedMenuItem(EApiCountryKey.BOL),
    new NestedMenuItem(EApiCountryKey.BRA),
    new NestedMenuItem(EApiCountryKey.CHL),
    new NestedMenuItem(EApiCountryKey.COL),
    new NestedMenuItem(EApiCountryKey.ECU),
    new NestedMenuItem(EApiCountryKey.GUY),
    new NestedMenuItem(EApiCountryKey.PRY),
    new NestedMenuItem(EApiCountryKey.PER),
    new NestedMenuItem(EApiCountryKey.SUR),
    new NestedMenuItem(EApiCountryKey.URY),
    new NestedMenuItem(EApiCountryKey.VEN),
  ]),
];
