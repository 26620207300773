import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {TranslateService} from '@ngx-translate/core';
import {Block} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';

export class BlockFilterModifier extends BlockDefinition {

  constructor(ident: string, readonly operator: string, active: boolean, readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_modifier',
      ident,
      active,
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    block.appendValueInput('value')
      .setCheck('Boolean')
      .appendField(translateService.instant(this.operator));
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const order = javascriptGenerator.ORDER_NONE;
    const value = javascriptGenerator.valueToCode(block as Block, 'value', order) || 'null';
    let children: any[];
    try {
      children = JSON.parse(value);
    } catch (e) {
      children = [];
    }
    if (!Array.isArray(children)) {
      children = [children];
    }
    return {
      result: {
        type: 'modifier',
        operator: this.operator,
        children,
      },
      order,
    };
  }

}
