import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from 'src/app/shared/page/page.component';
import { AuthGuard } from 'src/app/util/auth.guard';
import {
  ERoutePaths,
  EViewRoutes,
  EViewTitles,
} from 'src/app/util/enum';
import { PortfolioCatalogueComponent } from './views/portfolio-catalogue/portfolio-catalogue.component';
import { PortfolioOverviewComponent } from './views/portfolio-overview/portfolio-overview.component';
import { PortfolioResolver } from 'src/app/util/resolvers';
import { PermissionGuard } from 'src/app/util/permission.guard';

/**
 * Portfolio route definition.
 * Contains all routing definitions related to products
 */
const portfolioRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: PortfolioCatalogueComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.portfolios,
          viewRoute: EViewRoutes.portfolios,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.overview,
        component: PortfolioOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.portfolioOverview,
          viewRoute: EViewRoutes.portfolioOverview,
          allowedRoles: [],
        },
        resolve: {
          portfolio: PortfolioResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(portfolioRoutes)],
  exports: [RouterModule],
})
export class PortfolioRoutingModule {}
