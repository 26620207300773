import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {PageComponent} from "../shared/page/page.component";
import {AuthGuard} from "../util/auth.guard";
import {PermissionGuard} from "../util/permission.guard";
import {ERoutePaths, EViewRoutes, EViewTitles} from "../util/enum";
import {DistributorOverviewComponent} from "./views/distributor-overview/distributor-overview.component";
import {EProtectedActions} from "../util/protected-actions";
import {
  DistributorIntermediaryOverviewComponent
} from "./views/distributor-details-view/distributor-intermediary-overview/distributor-intermediary-overview.component";
import {ClientResolver, EmployeeResolver, IntermediaryResolver, PortfolioResolver} from "../util/resolvers";
import {
  DistributorClientOverviewComponent
} from "./views/distributor-details-view/distributor-client-overview/distributor-client-overview.component";
import {
  DistributorEmployeeOverviewComponent
} from "./views/distributor-details-view/distributor-employee-overview/distributor-employee-overview.component";
import {PortfolioOverviewComponent} from "../portfolio/views/portfolio-overview/portfolio-overview.component";
import {
  DistributorPortfolioOverviewComponent
} from "./views/distributor-details-view/distributor-portfolio-overview/distributor-portfolio-overview.component";

const distributorRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DistributorOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.distributor,
          viewRoute: EViewRoutes.distributorClients,
          allowedRoles: [EProtectedActions.distributor],
        },
      },
      {
        path: 'clients',
        component: DistributorOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.clients,
          viewRoute: EViewRoutes.distributorClients,
          allowedRoles: [EProtectedActions.distributor],
        },
      },
      {
        path: 'clients/overview/:id',
        component: DistributorClientOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.clientOverview,
          viewRoute: EViewRoutes.distributorClientOverview,
          allowedRoles: [EProtectedActions.distributor],
        },
        resolve: {
          client: ClientResolver,
        }
      },
      {
        path: 'employees',
        component: DistributorOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.employees,
          viewRoute: EViewRoutes.distributorEmployees,
          allowedRoles: [EProtectedActions.distributor],
        },
      },
      {
        path: 'employees/overview/:id',
        component: DistributorEmployeeOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.employeeOverview,
          viewRoute: EViewRoutes.distributorEmployeeOverview,
          allowedRoles: [EProtectedActions.distributor],
        },
        resolve: {
          employee: EmployeeResolver,
        }
      },
      {
        path: 'intermediaries',
        component: DistributorOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.intermediaries,
          viewRoute: EViewRoutes.distributorIntermediaries,
          allowedRoles: [EProtectedActions.distributor],
        },
      },
      {
        path: 'intermediaries/overview/:id',
        component: DistributorIntermediaryOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.intermediaryOverview,
          viewRoute: EViewRoutes.distributorIntermediaryOverview,
          allowedRoles: [EProtectedActions.distributor],
        },
        resolve: {
          intermediary: IntermediaryResolver,
        }
      },
      {
        path: 'portfolios',
        component: DistributorOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.portfolios,
          viewRoute: EViewRoutes.distributorPortfolios,
          allowedRoles: [EProtectedActions.distributor],
        },
      },
      {
        path: 'portfolios/overview/:id',
        component: DistributorPortfolioOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.portfolioOverview,
          viewRoute: EViewRoutes.distributorPortfolioOverview,
          allowedRoles: [EProtectedActions.distributor],
        },
        resolve: {
          portfolio: PortfolioResolver,
        },
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(distributorRoutes)],
  exports: [RouterModule],
})
export class DistributorRoutingModule {
}
