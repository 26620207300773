import {Component, Input, OnInit} from '@angular/core';
import {CampaignInfoExtended, CampaignStatus, Intermediary, IntermediaryService} from '../../../../api/core';
import {TranslateService} from '@ngx-translate/core';
import {GlobalService} from '../../../../services/global.service';
import {ColDef, GridOptions, RowDoubleClickedEvent, ValueFormatterParams} from 'ag-grid-community';
import {EViewRoutes} from '../../../../util/enum';
import {genBooleanColumn, genDateColumn, genEnumColumn, genTextColumn} from '../../../../util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";

@Component({
  selector: 'app-intermediary-overview-campaigns',
  templateUrl: './intermediary-overview-campaigns.component.html',
})
export class IntermediaryOverviewCampaignsComponent implements OnInit {
  @Input() intermediary: Intermediary;

  campaigns: CampaignInfoExtended[] = [];
  colDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: this.translateService.instant('campaignName'),
      link: (data: any) => '/redirect',
      getQueryParams: (data) => ({
        target: 'campaign-intermediaries',
        params: `ident=${data.ident}`
      })
    }),
    genDateColumn({
      field: 'createdAt',
      headerName: this.translateService.instant('creationDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.createdAt),
    }),
    genDateColumn({
      field: 'validFrom',
      headerName: this.translateService.instant('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
    }),
    genDateColumn({
      field: 'validTo',
      headerName: this.translateService.instant('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
    genTextColumn('storyName', this.translateService.instant('storyName')),
    genEnumColumn({
      field: 'status',
      values: [
        CampaignStatus.LAUNCHED,
        CampaignStatus.FROZEN,
        CampaignStatus.CLOSED,
        CampaignStatus.DRAFT,
        CampaignStatus.TERMINATED,
      ],
      headerName: this.translateService.instant('campaignStatus')
    }),
    {
      ...genBooleanColumn(
        'processing',
        this.translateService.instant('processing'),
        this.translateService
      ), // needed because cellType inferred before transformation to text
      cellDataType: 'text',
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(
        `${EViewRoutes.campaignOverview}${event.data.id}`
      ),
  };

  constructor(
    private translateService: TranslateService,
    private globalService: GlobalService,
    private intermediaryService: IntermediaryService,
  ) {}

  ngOnInit(): void {
    this.intermediaryService.getIntermediaryCampaigns(this.intermediary.id).subscribe({
      next: (rows: CampaignInfoExtended[]) => {
        this.campaigns = rows;
      },
    });
  }
}
