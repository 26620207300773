<app-card class="grid-container">
  <div card-header>{{ 'loggedErrors' | translate }}</div>
  <app-grid
    tableId="error-catalogue"
    [columnDefs]="errorColumns"
    [gridOptions]="errorGridOptions"
    [rowData]="rowData"
    [pagination]="false"
    card-body
  >
  </app-grid>
</app-card>
