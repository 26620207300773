import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_HOME_CONFIG = {
  id: 7,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Start Screen',
        en: 'Start Screen',
      },
      anchor: 'start',
    },
    {
      title: {
        de: 'Menubar',
        en: 'Menubar',
      },
      anchor: 'home-menubar',
      children: [
        {
          title: {
            de: 'Main Menu',
            en: 'Main Menu',
          },
          anchor: 'home-mainmenu',
        },
        {
          title: {
            de: 'Other Options',
            en: 'Other Options',
          },
          anchor: 'home-otheroptions',
        },
      ],
    },
    {
      title: {
        de: 'User Menu',
        en: 'User Menu',
      },
      anchor: 'home-usermenu',
    },
    {
      title: {
        de: 'Pending actions',
        en: 'Pending actions',
      },
      anchor: 'home-pendingactions',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Start Screen',
        en: 'Start Screen',
      },
      anchor: 'start',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This is the starting screen of <b>aSpark</b>. We have 3 main areas here. <b>(1) Menu</b>, <b>(2) User Menu</b> and <b>(3) Pending Actions List</b>',
        en: 'This is the starting screen of <b>aSpark</b>. We have 3 main areas here. <b>(1) Menu</b>, <b>(2) User Menu</b> and <b>(3) Pending Actions List</b>',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Home screen',
        en: 'Home screen',
      },
      url: '/assets/images/help/home.png',
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Menubar',
        en: 'Menubar',
      },
      anchor: 'home-menubar',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The menu items visible to you are dependent on the user role assigned. Not all users will be able to see the mentioned menu options.',
        en: 'The menu items visible to you are dependent on the user role assigned. Not all users will be able to see the mentioned menu options.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'The menubar will automatically expand when you hover over it.',
        en: 'The menubar will automatically expand when you hover over it.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Expand menubar',
        en: 'Expand menubar',
      },
      url: '/assets/images/help/home-expand.png',
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Main Menu',
        en: 'Main Menu',
      },
      anchor: 'home-mainmenu',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Home</b> - The starting screen that contains the pending actions list.',
        en: '<b>Home</b> - The starting screen that contains the pending actions list.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Campaigns</b> - Campaigns list allowing the creation and management of campaigns.',
        en: '<b>Campaigns</b> - Campaigns list allowing the creation and management of campaigns.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Targeting / Filtering</b> - Simulator environment to test filter without affecting any campaigns or stories.',
        en: '<b>Targeting / Filtering</b> - Simulator environment to test filter without affecting any campaigns or stories.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Objects</b> - Lists all clients and portfolios available in aSpark.',
        en: '<b>Objects</b> - Lists all clients and portfolios available in aSpark.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>My Settings</b> - Settings for the current user, allowing the creation and management of client collections and deputy relations. Here you can also reset the table settings.',
        en: '<b>My Settings</b> - Settings for the current user, allowing the creation and management of client collections and deputy relations. Here you can also reset the table settings.',
      },
    },
    {
      type: EHelpContentType.subheader,
      text: {
        de: 'Other Options',
        en: 'Other Options',
      },
      anchor: 'home-otheroptions',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Help</b> - Displays this help dialog.',
        en: '<b>Help</b> - Displays this help dialog.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Information</b> - Shows the current aSpark version.',
        en: '<b>Information</b> - Shows the current aSpark version.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Errors</b> - Opens a list of recorded errors.',
        en: '<b>Errors</b> - Opens a list of recorded errors.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'User Menu',
        en: 'User Menu',
      },
      anchor: 'home-usermenu',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>User</b> - Allows logging out of the current user and selecting deputies.',
        en: '<b>User</b> - Allows logging out of the current user and selecting deputies.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<b>Language Selector</b> - Allows switching of the language in which aSpark is displayed. Click on the menu item to switch between English or German',
        en: '<b>Language Selector</b> - Allows switching of the language in which aSpark is displayed. Click on the menu item to switch between English or German',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Pending actions',
        en: 'Pending actions',
      },
      anchor: 'home-pendingactions',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This list shows all LAUNCHED campaigns with pending actions.',
        en: 'This list shows all LAUNCHED campaigns with pending actions.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'A pending action is an action on a specific campaign that requires attention. This is always part of a LAUNCHED campaign and can be handled in a number of ways (see Help on the Campaigns).',
        en: 'A pending action is an action on a specific campaign that requires attention. This is always part of a LAUNCHED campaign and can be handled in a number of ways (see Help on the Campaigns).',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        de: 'Pending action',
        en: 'Pending action',
      },
      url: '/assets/images/help/home-pending.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Click on the campaign name to navigate directly to the campaign and portfolios where the actions are located.',
        en: 'Click on the campaign name to navigate directly to the campaign and portfolios where the actions are located.',
      },
    },
  ],
  view: EViewRoutes.home,
};
