import { EHelpContentType, EViewRoutes } from 'src/app/util/enum';

export const HELP_CAMPAIGNS_CONFIG = {
  id: 2,
  version: '0.1',
  navigation: [
    {
      title: {
        de: 'Campaign List',
        en: 'Campaign List',
      },
      anchor: 'campaignList',
    },
    {
      title: {
        de: 'Processing',
        en: 'Processing',
      },
      anchor: 'campaignList-processing',
    },
  ],
  content: [
    {
      type: EHelpContentType.header,
      text: {
        de: 'Campaign List',
        en: 'Campaign List',
      },
      anchor: 'campaignList',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'This table shows all the campaigns visible to the current user.',
        en: 'This table shows all the campaigns visible to the current user.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Campaigns can be in several different states (DRAFT, FROZEN, LAUNCHED, CLOSED, TERMINATED). Depending on the state of the campaign, it will be visible to different user roles.',
        en: 'Campaigns can be in several different states (DRAFT, FROZEN, LAUNCHED, CLOSED, TERMINATED). Depending on the state of the campaign, it will be visible to different user roles.',
      },
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: '<i>CAMPAIGN CREATORS</i> will see campaigns in all the different states, where <i>DISTRIBUTORS</i> will only see campaigns in the states LAUNCHED, CLOSED and TERMINATED.',
        en: '<i>CAMPAIGN CREATORS</i> will see campaigns in all the different states, where <i>DISTRIBUTORS</i> will only see campaigns in the states LAUNCHED, CLOSED and TERMINATED.',
      },
    },
    {
      type: EHelpContentType.header,
      text: {
        de: 'Processing',
        en: 'Processing',
      },
      anchor: 'campaignList-processing',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'A campaign marked as being in processing (the column for processing contains the value Yes) means that the campaign is currently changing state. While this is happening it is possible to see the progress, but no other options are available.',
        en: 'A campaign marked as being in processing (the column for processing contains the value Yes) means that the campaign is currently changing state. While this is happening it is possible to see the progress, but no other options are available.',
      },
    },
    {
      type: EHelpContentType.image,
      text: {
        en: 'Campaign processing',
        de: 'Campaign processing',
      },
      url: '/assets/images/help/campaignList-processing.png',
    },
    {
      type: EHelpContentType.paragraph,
      text: {
        de: 'Changing the state on a campaign can take from less than a minute, up to multiple minutes depending on how many portfolios are contained in the campaign. No change in the progress of the campaign for over 30 minutes indicates that an error has occurred. This should be reported to the help desk.',
        en: 'Changing the state on a campaign can take from less than a minute, up to multiple minutes depending on how many portfolios are contained in the campaign. No change in the progress of the campaign for over 30 minutes indicates that an error has occurred. This should be reported to the help desk.',
      },
    },
  ],
  view: EViewRoutes.campaigns,
};
