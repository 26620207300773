export class RatingSorter {
  sorts: Record<string, number> = {};

  constructor(...order: string[]) {
    order.forEach((s, k) => {
      this.sorts[s] = k;
    });
  }

  sort<T extends any>(list: T[], fnGet: (t: T) => string): T[] {
    return [...list]
      .sort((a, b) => this.sorts[fnGet(a)] - this.sorts[fnGet(b)]);
  }
}

export const ratingsOrder = new RatingSorter(
  'attractive',
  'buy',
  'hold',
  'sell',
  'unattractive',
);

export const moodyRatingOrder = new RatingSorter(
  'Aaa',
  'Aa1',
  'Aa2',
  'Aa3',
  'A1',
  'A2',
  'A3',
  'Baa1',
  'Baa2',
  'Baa3',
  'Ba1',
  'Ba2',
  'Ba3',
  'B1',
  'B2',
  'B3',
  'Caa1',
  'Caa2',
  'Caa3',
  'Ca',
  'C',
);
export const spRatingOrder = new RatingSorter(
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'BB+',
  'BB',
  'BB-',
  'B+',
  'B',
  'B-',
  'CCC+',
  'CCC0',
  'CCC-',
  'CC', // missing in DB
  'C',
  'D',
);
export const sustainabilityRatingOrder = new RatingSorter(
  'lgt$ass_5star',
  'lgt$ass_4star',
  'lgt$ass_3star',
  'lgt$ass_2star',
  'lgt$ass_1star',
);

