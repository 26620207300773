<div *ngIf="client" class="page-content-container">
  <app-tabs-panel
    [tabs]="tabs"
    [defaultTab]="defaultTab"
    (tabChanged)="onTabChanged($event)"
  ></app-tabs-panel>

  <div
    *ngIf="activeTab === tabs[0].text"
    class="client-overview-container aspark-container-padding"
  >
    <app-client-overview-details
      [client]="client"
      class="details"
    ></app-client-overview-details>
    <app-client-publication-types
      class="publication-types"
      [clientId]="client.id"
    >
    </app-client-publication-types>
  </div>

  <div *ngIf="activeTab === tabs[1].text" class="client-overview-container">
    <app-client-overview-actions
      [client]="client"
    ></app-client-overview-actions>
  </div>

  <div *ngIf="activeTab === tabs[2].text" class="client-overview-container">
    <app-client-overview-campaigns
      [client]="client"
    ></app-client-overview-campaigns>
  </div>

  <div *ngIf="activeTab === tabs[3].text" class="client-overview-container">
    <app-client-overview-portfolios
      [client]="client"
    ></app-client-overview-portfolios>
  </div>
</div>
