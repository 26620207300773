import {Component, Inject} from "@angular/core";
import {Content} from "../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../modal/modal.component";

@Component({
  selector: 'app-content-download-dialog',
  templateUrl: './content-download-dialog.component.html'
})
export class ContentDownloadDialogComponent {
  content: Content;
  constructor(
    readonly dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {data: { content: Content }}
  ) {
    this.content = data.data.content;
  }

  onClick(e: Event, id: number) {
    e.preventDefault()
    this.dialogRef.close(id);
  }

}
