import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Params, Router} from '@angular/router';
import {NotificationService} from './notification.service';
import {EViewRoutes} from 'src/app/util/enum';
import {INestedMenuItem, NestedMenuItem} from 'src/app/models/menu.model';
import {TranslateService} from '@ngx-translate/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {ConfigService} from './config.service';
import {PermissionService} from './permission.service';
import {formatDate} from "@angular/common";
import {UserRoleData} from "../api/core";

/**
 * Service for handling all functions that are globally required and do not belong to a specific workflow
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private permissionService: PermissionService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected configService: ConfigService,
    @Inject(LOCALE_ID) protected locale: string
  ) {}

  /**
   * API call to authenticate current user.
   * Updates currently logged in user if authentication is successful
   */
  login(): void {
    // check auth.go for password
    this.oauthService.configure(this.configService.getAuthConfig());
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      // Optional
      this.oauthService.setupAutomaticSilentRefresh();
      this.oauthService.initCodeFlow();
    });
  }

  toHome(): void {
    this.router.navigate([EViewRoutes.home]);
  }

  /**
   * Actively logout user by removing JWT token from localStorage
   */
  logout(): void {
    this.oauthService.logOut();
    sessionStorage.clear();
    this.router.navigate([EViewRoutes.login]);
  }

  getIdentityClaims(): object {
    return this.oauthService.getIdentityClaims() ?? {};
  }

  getCurrentUserId(): string {
    return this.getCurrentUserData()?.username;
  }

  getCurrentUserData(): UserRoleData {
    return this.permissionService.userRoleData;
  }

  getCurrentUserGroups(): string[] {
    return this.getCurrentUserData()?.roles;
  }

  loadedProfile() {
    this.toHome();
  }

  /**
   * Navigates to provided route
   * @param route Route to navigate to
   * @param queryParams Parameters that should be set as query params in URL
   * @param fragment
   */
  navigate(route: string, queryParams?: Params, fragment?: string): void {
    this.router.navigate([route], { queryParams, fragment });
  }

  /**
   * Format value to swiss formatted decimal vlaue
   * @param value Value
   * @param decimals Number of decimals
   */
  getFormattedValue(value: number, decimals = 2): string {
    if (value == null) {
      return '-';
    }
    switch (this.translateService.currentLang) {
      case 'en':
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
      default:
        return new Intl.NumberFormat('de-CH', {
          style: 'decimal',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(value);
    }
  }

  /**
   * Returns null if parsed value is NaN
   * @param value
   * @private
   */
  private parseFloatString(value: string): number {
    const f = parseFloat(value);
    return isNaN(f) ? null : f;
  }

  /**
   * Removes the decimal separator from the formatted value
   * @param value
   */
  parseFormattedValue(value: string | number): number {
    if (value == null) {
      return null;
    }
    if (typeof value === 'string') {
      switch (this.translateService.currentLang) {
        case 'en':
          return this.parseFloatString(value.replace(/,/g, ''));
        default:
          return this.parseFloatString(value.replace(/’/g, ''));
      }
    }
    return value;
  }

  /**
   * Format date to fr-CH locale date string
   * @param date Provided date string
   */
  dateToFrChLocale(date: string): string {
    if (date == null) {
      return '-';
    }

    const newDate = new Date(date);
    // handle invalid string input
    if (newDate instanceof Date && isFinite(newDate.getDate())) {
      const utcDate = new Date(
        newDate.getTime() + newDate.getTimezoneOffset() * (60 * 1000)
      );
      return utcDate.toLocaleDateString('fr-CH');
    }
    return '-';
  }

  /**
   * Format date to fr-CH locale time string
   * @param date Provided date string
   */
  timeToFrChLocale(date: string): string {
    if (date == null) {
      return '-';
    }

    const newDate = new Date(date);
    // handle invalid string input
    if (newDate instanceof Date && isFinite(newDate.getDate())) {
      const utcDate = new Date(
        newDate.getTime() + newDate.getTimezoneOffset() * (60 * 1000)
      );
      return utcDate.toLocaleTimeString('fr-CH');
    }
    return '-';
  }

  /**
   * Takes two dates and formats it as a period in string with the format 'dd.MM.yyyy'.
   * @param from
   * @param to
   */
  formatDatePeriod(from: string | Date, to: string | Date){
    const fromStr = from ? formatDate(from,'dd.MM.yyyy', 'en-US') : null;
    const toStr = to ? formatDate(to,'dd.MM.yyyy', 'en-US') : null;
    let result: string;
    if (fromStr && toStr) {
      result = fromStr + ' - ' + toStr;
    } else if (fromStr) {
      result = this.translateService.instant('from').toLowerCase() + ' ' + fromStr;
    } else if (toStr) {
      result = this.translateService.instant('to').toLowerCase() + ' ' + toStr;
    }
    return result;
  }

  /**
   * Format percentage value to display
   * @param p Provided percentage
   * @param multiply Flag whether the percentage needs to be multiplied by 100
   * @param addPlusSign Flag whether the + sign should be added to positive percentages
   * @param decimals
   */
  getFormattedPercentage(
    p: number,
    multiply: boolean = true,
    addPlusSign: boolean = false,
    decimals = 2
  ): string {
    if (p == null) {
      return '-';
    }
    const perc = multiply ? p * 100 : p;
    return perc < 0
      ? perc.toFixed(decimals) + '%'
      : addPlusSign
      ? '+' + perc.toFixed(decimals) + '%'
      : perc.toFixed(decimals) + '%';
  }

  /**
   * Transforms a formatted percentage back into a decimal number
   * @param p
   */
  parseFormattedPercentage(
    p: string
  ): number {
    if (p == null) {
      return null;
    }
    const str = (p.toString()).replace(/%/g, '');
    let d = parseFloat(str);
    d  = isNaN(d) ? null : d;
    return d != null ? d/100 : null;
  }

  /**
   * Deep copy of nested menu item object according to level
   * @param item Nested Menu Item object
   */
  deepCopyNestedMenuItems(item: INestedMenuItem): INestedMenuItem {
    if (item?.children?.length) {
      return new NestedMenuItem(
        item.key,
        item.label,
        item.children.map((child) => this.deepCopyNestedMenuItems(child))
      );
    } else {
      return new NestedMenuItem(item.key, item.label, []);
    }
  }

  /**
   * Removes the ID of an overview url
   * @param url complete url
   * @returns eviewRoute
   */
  getOverviewPathByUrl(url: string): EViewRoutes {
    const viewRouteArr = url.split('/');
    viewRouteArr.pop();
    return viewRouteArr.join('/').concat('/') as EViewRoutes;
  }
}
