/**
 * aSpark core API
 * The REST API of aSpark\'s core service 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppMessageState = 'DRAFT' | 'PUBLISHED' | 'CLOSED';

export const AppMessageState = {
    DRAFT: 'DRAFT' as AppMessageState,
    PUBLISHED: 'PUBLISHED' as AppMessageState,
    CLOSED: 'CLOSED' as AppMessageState
};

