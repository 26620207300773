import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {
  AdvancedFilterField,
  WeightRange,
} from '../../../../models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import {Block, FieldDropdownValidator, MenuGenerator} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import * as Blockly from 'blockly';

export class BlockFilterWeighted extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-weighted',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const weights: MenuGenerator = [
      [translateService.instant('underweight'), WeightRange.WeightEnum.Under],
      [translateService.instant('balance'), WeightRange.WeightEnum.Balance],
      [translateService.instant('overweight'), WeightRange.WeightEnum.Over],
    ];
    const weightValidator: FieldDropdownValidator = (newValue) => {
      block.removeInput('field_exposure', true);
      block.removeInput('field_exposure_range', true);
      if (newValue.length > 0) {
        block.appendDummyInput('field_exposure');
      }
      return newValue;
    };

    block
      .appendDummyInput('field_value')
      .appendField(translateService.instant(this.ident) + ':')
      .appendField(new Blockly.FieldDropdown(this.fields[0].entries), 'value');
    block
      .appendDummyInput('field_weight')
      .appendField(translateService.instant('weight'))
      .appendField(
        new Blockly.FieldDropdown(weights, weightValidator),
        'weight'
      );
    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const target = block.getFieldValue('value');
    const weight = block.getFieldValue('weight');
    return {
      result: {
        type: 'list',
        name: `${this.ident}.Weight`,
        field: target,
        valueList: [weight]
      },
      order: javascriptGenerator.ORDER_NONE,
    };
  }
}
