<ul>
  <ng-container *ngFor="let item of navigationItems; let i = index">
    <li class="navigation-item h3">
      <app-config-icon
        (click)="toggle(i)"
        [iconIdentifier]="toggles[i] ? 'expand_more' : 'chevron_right'"
        *ngIf="item?.children?.length > 0; else noChildren"
      ></app-config-icon>
      <ng-template #noChildren>
        <span class="hyphen-symbol">-</span>
      </ng-template>
      <span (click)="onItemClick(item.anchor)">{{
        item.title[currentLanguage]
      }}</span>
    </li>
    <ng-container *ngIf="toggles[i] && item.children?.length">
      <div class="sub-navigation-container">
        <li class="navigation-subitem h4" *ngFor="let child of item.children">
          <span (click)="onItemClick(child.anchor)">{{
            child.title[currentLanguage]
          }}</span>
        </li>
      </div>
    </ng-container>
  </ng-container>
</ul>
