<section class="page-side-bar"
         (mousemove)="onPageSidebarMouseMove($event)"
         (mouseout)="onPageSidebarMouseOut($event)"
         (mouseup)="onPageSidebarMouseClick()"
         (transitionend)="onPageSidebarTransitioned($event)">
  <div class="navigation">
    <div class="navigation-item home" (click)="onHomeClick()">
      <app-config-icon
        class="icon-large"
        iconIdentifier="home"
      ></app-config-icon>
      <div class="navigation-item-name color-white">
        {{ 'home' | translate }}
      </div>
      <div
        *ngIf="
          currentViewRoute === '/home' || activeParentRoute === '/home'
        "
        class="highlight"
      ></div>
    </div>
    <ng-container *ngFor="let item of sidenavConfig; let i = index">
      <div
        *ngIf="item.active && (item.allowedRoles | protectedRoute)"
        class="navigation-item"
        (click)="openPath(item.route)"
      >
        <app-config-icon
          class="icon-large"
          [iconIdentifier]="item.icon"
        ></app-config-icon>
        <div class="navigation-item-name color-white">
          {{ item.title | translate }}
        </div>
        <div
          *ngIf="
            item.route === currentViewRoute || item.route === activeParentRoute
          "
          class="highlight"
        ></div>
      </div>
    </ng-container>
    <div *ngIf="adminMenu" class="navigation-item" (click)="onSettingsClick()">
      <app-config-icon
        class="icon-large"
        iconIdentifier="settings"
      ></app-config-icon>
      <div class="navigation-item-name color-white">
        {{ 'settings' | translate }}
      </div>
      <div
        *ngIf="
          currentViewRoute === '/settings' || activeParentRoute === '/settings'
        "
        class="highlight"
      ></div>
    </div>
  </div>
  <div class="helpers">
    <div class="separator"></div>
    <div *ngIf="hasAnyRole" class="helper-item" (click)="onHelpClick()">
      <app-config-icon
        class="icon-large"
        iconIdentifier="help_outline"
      ></app-config-icon>
      <div class="helper-item-name">{{ 'help' | translate }}</div>
    </div>
    <div class="helper-item" [matMenuTriggerFor]="infoMenu">
      <app-config-icon
        class="icon-large"
        iconIdentifier="info"
        [outlined]="true"
      ></app-config-icon>
      <div class="helper-item-name">{{ 'info' | translate }}</div>
      <mat-menu #infoMenu="matMenu" class="info-menu">
        <button mat-menu-item *ngIf="info">
          {{ 'asparkVersion' | translate }}{{ info.version }}
        </button>
      </mat-menu>
    </div>
    <div *ngIf="hasAnyRole" class="helper-item" (click)="onErrorClick()">
      <app-config-icon
        class="icon-large"
        iconIdentifier="warning"
      ></app-config-icon>
      <div class="helper-item-name">{{ 'errors' | translate }}</div>
    </div>
  </div>
</section>
<section class="page-header">
  <div class="header-content">
    <div class="title">
      <ng-container *ngIf="guiConfig.general?.showLogo === 'true'">
        <div class="logo h1">aSpark®</div>
        <div class="separator"></div>
      </ng-container>
      <div *ngIf="!titleExtension" class="h2">
        {{ title | translate }}
      </div>
      <div *ngIf="titleExtension" class="h2">
        {{ title | translate }} - {{ titleExtension | translate }}
      </div>
    </div>
    <div class="user-menu">
      <div class="currency" [matMenuTriggerFor]="currencyMenu">
        <app-config-icon
          class="icon-large"
          iconIdentifier="monetization_on"
          [matTooltip]="'currency' | translate"
        ></app-config-icon>
        <div>{{ currentUser?.selectedCurrency?.ident }}</div>
        <app-config-icon iconIdentifier="expand_more"></app-config-icon>
        <mat-menu #currencyMenu="matMenu" class="user-menu">
          <button
            mat-menu-item
            [routerLink]="viewRoutes.myFavoriteCurrencies"
            class="mat-menu-item-flex"
          >
            <app-config-icon
              iconIdentifier="monetization_on"
            ></app-config-icon>
            <span>{{ 'editCurrencyList' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <ng-container *ngIf="favoriteCurrencies.length > 0">
            <button
              mat-menu-item
              *ngFor="let c of favoriteCurrencies"
              class="mat-menu-item-flex"
              (click)="updateSelectedCurrency(c)"
            >
              <span>{{ c.ident }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div class="user" [matMenuTriggerFor]="userMenu">
        <app-config-icon
          class="icon-large"
          iconIdentifier="account_circle"
          [matTooltip]="'user' | translate"
        ></app-config-icon>
        <div>{{ currentUser?.username }}</div>
        <app-config-icon iconIdentifier="expand_more"></app-config-icon>
        <mat-menu #userMenu="matMenu" class="user-menu">
          <button mat-menu-item (click)="signOut()" class="mat-menu-item-flex">
            <app-config-icon iconIdentifier="logout"></app-config-icon>
            <span>{{ 'logoff' | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <ng-container *ngIf="dynamicUsers.length === 0">
            <button
              mat-menu-item
              [routerLink]="viewRoutes.myDynamicUsers"
              class="mat-menu-item-flex"
            >
              <app-config-icon
                iconIdentifier="supervised_user_circle"
              ></app-config-icon>
              <span>{{ 'editDeputiesList' | translate }}</span>
            </button>
          </ng-container>
          <ng-container *ngIf="dynamicUsers.length > 0">
            <button
              mat-menu-item
              *ngFor="let u of dynamicUsers"
              class="mat-menu-item-flex"
              (click)="$event.stopPropagation()"
            >
              <mat-checkbox
                color="primary"
                [(ngModel)]="u.enabled"
                (ngModelChange)="updateDynamicUsers()"
              ></mat-checkbox>
              <span>&nbsp;{{ u.fullname }} ({{ u.username }})</span>
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div class="separator"></div>
      <app-config-icon
        (click)="showOldMessages()"
        iconIdentifier="notifications"
        >
      </app-config-icon>
      <div class="separator"></div>
      <div class="language" (click)="langSelect.open()">
        <mat-select
          #langSelect
          class="language-select"
          (selectionChange)="setLanguage($event)"
          [(value)]="currentLang"
        >
          <mat-option
            class="language-option"
            *ngFor="let lang of translateService.getLangs()"
            [value]="lang"
          >
            {{ lang.toUpperCase() }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <div class="header-separator"></div>
</section>
<section class="page-body">
  <router-outlet></router-outlet>
</section>
