export const fnaAssetClassValue = 'assetClass.Value';
export const fnaAssetClassWeight = 'assetClass.Weight';
export const fnaClientAge = 'client.Age';
export const fnaClientBusinessUnit = 'client.BusinessUnit';
export const fnaClientDomicile = 'client.Domicile';
export const fnaClientGender = 'client.Gender';
export const fnaExcludeEWA = 'excludeEWA';
export const fnaExcludeEAM = 'excludeEAM';
export const fnaCurrencyValue = 'currency.Value';
export const fnaCurrencyWeight = 'currency.Weight';
export const fnaPortfolioAdvisor = 'portfolio.Advisor';
export const fnaPortfolioAdvisoryType = 'portfolio.AdvisoryType';
export const fnaPortfolioAllowOptOut = 'portfolio.AllowOptOut';
export const fnaPortfolioBusinessDivision = 'portfolio.BusinessDivision';
export const fnaPortfolioBusinessUnit = 'portfolio.BusinessUnit';
export const fnaPortfolioFidleg = 'portfolio.Fidleg';
export const fnaPortfolioLanguage = 'portfolio.Language';
export const fnaPortfolioLiquidity = 'portfolio.Liquidity';
export const fnaPortfolioManager = 'portfolio.Manager';
export const fnaPortfolioMifid = 'portfolio.Mifid';
export const fnaPortfolioOptOut = 'portfolio.OptOut';
export const fnaPortfolioQualifiedInvestor = 'portfolio.QualifiedInvestor';
export const fnaPortfolioReferenceCurrency = 'portfolio.ReferenceCurrency';
export const fnaPortfolioReturn = 'portfolio.Return';
export const fnaPortfolioRisk = 'portfolio.Risk';
export const fnaPortfolioRiskProfile = 'portfolio.RiskProfile';
export const fnaPortfolioRiskState = 'portfolio.RiskState';
export const fnaPortfolioRmDepartment = 'portfolio.RmDepartment';
export const fnaPortfolioRmLocation = 'portfolio.RmLocation';
export const fnaPortfolioRmMarket = 'portfolio.RmMarket';
export const fnaPortfolioServiceCenter = 'portfolio.ServiceCenter';
export const fnaPortfolioSustainabilityProfile = 'portfolio.SustainabilityProfile';
export const fnaPortfolioType = 'portfolio.Type';
export const fnaPortfolioValue = 'portfolio.Value';
export const fnaPortfolioWaiveOfAdvice = 'portfolio.WaiveOfAdvice';
export const fnaPositionClass = 'position.Class';
export const fnaPositionCountry = 'position.Country';
export const fnaPositionCurrency = 'position.Currency';
export const fnaPositionGicsIndustry = 'position.GicsIndustry';
export const fnaPositionGicsIndustryGroup = 'position.GicsIndustryGroup';
export const fnaPositionGicsSector = 'position.GicsSector';
export const fnaPositionIds = 'position.Ids';
export const fnaPositionIsin = 'position.Isin';
export const fnaPositionIsinPercentage = 'position.IsinPercentage';
export const fnaPositionIsinTotalValue = 'position.IsinTotalValue';
export const fnaPositionMaturity = 'position.Maturity';
export const fnaPositionMoodyRating = 'position.MoodyRating';
export const fnaPositionNextCall = 'position.NextCall';
export const fnaPositionPerformance = 'position.Performance';
export const fnaPositionPrice = 'position.Price';
export const fnaPositionQuantity = 'position.Quantity';
export const fnaPositionRanking = 'position.Ranking';
export const fnaPositionRating = 'position.Rating';
export const fnaPositionRatingSP = 'position.RatingSP';
export const fnaPositionRatingSourceLGT = 'position.RatingSourceLGT';
export const fnaPositionRegion = 'position.Region';
export const fnaPositionSubClass = 'position.SubClass';
export const fnaPositionSustainabilityRating = 'position.SustainabilityRating';
export const fnaPositionProductRatingApac = 'position.ProductRatingApac';
export const fnaPositionProductRatingMe = 'position.ProductRatingMe';
export const fnaPositionProductInvestmentHorizon = 'position.ProductInvestmentHorizon';
export const fnaPositionIssuerName = 'position.IssuerName';
export const fnaPositionType = 'position.Type';
export const fnaOrgPosition = 'orgPosition';
export const fnaPositionValue = 'position.Value';
export const fnaRegionValue = 'region.Value';
export const fnaRegionWeight = 'region.Weight';
export const fnaSectorValue = 'sector.Value';
export const fnaSectorWeight = 'sector.Weight';
