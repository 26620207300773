<div class="filter-form-container">
  <div class="grid-filter-container">
    <ng-container *ngFor="let config of filterConfig" [ngSwitch]="config.type">
      <mat-menu #filterMenu="matMenu" class="mat-menu-filter">
        <app-grid-filter-item-date #cmp
          *ngSwitchCase="gridFilterTypes.date"
          [filterConfig]="config"
          [filterModel]="activeFilterModel[config.key]"
          (click)="$event.stopPropagation();"
          (applyFilter)="onApplyFilter(config, filterMenu, filterTrigger)"
        ></app-grid-filter-item-date>
        <app-grid-filter-item-numeric #cmp
          *ngSwitchCase="gridFilterTypes.numeric"
          [filterConfig]="config"
          [filterModel]="activeFilterModel[config.key]"
          (click)="$event.stopPropagation();"
          (applyFilter)="onApplyFilter(config, filterMenu, filterTrigger)"
        ></app-grid-filter-item-numeric>
        <app-grid-filter-item-set #cmp
          *ngSwitchCase="gridFilterTypes.set"
          [filterConfig]="config"
          [filterModel]="activeFilterModel[config.key]"
          (click)="$event.stopPropagation();"
          (applyFilter)="onApplyFilter(config, filterMenu, filterTrigger)"
        ></app-grid-filter-item-set>
        <app-grid-filter-item-text #cmp
          *ngSwitchCase="gridFilterTypes.text"
          [filterConfig]="config"
          [filterModel]="activeFilterModel[config.key]"
          [maxAutoCompleteOptions]="maxAutoCompleteOptions"
          (click)="$event.stopPropagation();"
          (applyFilter)="onApplyFilter(config, filterMenu, filterTrigger)"
        ></app-grid-filter-item-text>
      </mat-menu>
      <div class="grid-filter-item-title h4"
           [attr.data-config]="config.key"
           [class.is-selected]="hasFilterValue(config)">
        <button
          mat-button
          class="filter-header"
          #filterTrigger
          [matMenuTriggerFor]="filterMenu"
          [matTooltip]="values[config.key]">
          <span class="filter-header-name">{{config.name}}:&nbsp;</span>
          <span class="filter-header-value"
                [style]="styles[config.key]"
          >{{ styles[config.key] ? (values[config.key] || ' ') : '&nbsp;' }}</span>
          <app-config-icon iconIdentifier="expand_more"></app-config-icon>
        </button>
        <app-config-icon iconIdentifier="close"
                         *ngIf="hasFilterValue(config)"
                         (click)="clearFilter(config)"></app-config-icon>
      </div>
    </ng-container>
  </div>
  <div class="grid-active-filter-container">
    <button mat-flat-button class="clear-all-filters" (click)="clearAllFilters()">
      <div class="filter-text color-primary">{{ 'clearAll' | translate }}</div>
    </button>
    <div class="spacer">&nbsp;</div>
    <button mat-button color="primary" *ngIf="hasMoreFilters" (click)="loadMoreFilters()">
      <span>{{ 'moreFilters' | translate }}</span>
    </button>
    <button mat-button color="primary" *ngIf="hasHiddenFilters" (click)="showLessFilters()">
      <span>{{ 'showLess' | translate }}</span>
    </button>
  </div>
</div>
