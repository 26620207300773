import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpContentItem } from 'src/app/models/help.model';
import { EHelpContentType } from 'src/app/util/enum';

/**
 * Help Content Component.
 * Component for dynamic display of help content
 */
@Component({
  selector: 'app-help-content',
  templateUrl: './help-content.component.html',
})
export class HelpContentComponent implements OnInit {
  @Input() contentItems: HelpContentItem[];

  currentLanguage: string;

  constructor(protected readonly translateService: TranslateService) {
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnInit() {}

  get helpContentTypes() {
    return EHelpContentType;
  }
}
