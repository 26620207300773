import {Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, ValueFormatterParams} from 'ag-grid-community';
import {CampaignActionService, CodeTableEntry} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {EViewRoutes} from 'src/app/util/enum';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {I18n} from '../../../services/i18n.service';
import {PermissionService} from '../../../services/permission.service';
import {genDateColumn, genNumberColumn} from '../../../util/grid/grid-renderer.util';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {productSearchDialog} from "../../../product/shared/product-search-dialog/product-search-dialog.component";
import {ModalService} from "../../../services/modal.service";

/**
 * Component that shows Home Navigation and routes user to selected view.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnDestroy {
  hasAnyRole = true;
  columnDefs: ColDef[] = [
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('campaignName'),
      link: (data: any) => `${EViewRoutes.campaignOverview}${data.id}`,
    }),
    genNumberColumn(
      'portfolios',
      I18n.getColName('portfolios'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'intermediaries',
      I18n.getColName('intermediaries'),
      this.globalService,
      (d) => this.globalService.getFormattedValue(d.value, 0)
    ),
    {
      ...genDateColumn({
          field: 'launchedAt',
          headerName: I18n.getColName('launchedAt'),
          dateFormatter: (params: ValueFormatterParams) =>
            this.globalService.dateToFrChLocale(params.data.launchedAt),
      }),
      sort: 'desc',
    },
    genDateColumn({
      field: 'validTo',
      headerName: I18n.getColName('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
  ];

  gridApi: GridApi;
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event) => {
      this.gridApi = event.api;
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    },
  };

  subscriptions: Subscription[] = [];

  languages: CodeTableEntry[];
  campaigns: Object[] = [];

  constructor(
    protected globalService: GlobalService,
    protected campaignActionService: CampaignActionService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
    protected modalService: ModalService,
    protected router: Router,
  ) {
    this.hasAnyRole = this.permissionService.userRoleData.roles.length > 0;
    this.subscriptions.push(this.permissionService.user$.subscribe(_ => {
      this.refreshGrid();
    }))
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  refreshGrid() {
    this.campaignActionService.getPendingCampaignActions()
      .subscribe(page => {
        this.campaigns = page.pageItems;
      })
  }
}
