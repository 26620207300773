export const environment = {
  production: true,
  // OWNER: 'ISP',
  OWNER: 'LGT',
  DEFAULT_LANG: 'en',
  AVAIL_LANGS: ['en', 'de'],
  ALLOWED_HEALTCHECK_BREACH_COUNT: 4,
  BASE_PATH_ASS: '/api/asset',
  BASE_PATH_CAMP: '/api/campaign',
  BASE_PATH_CLT: '/api/client',
  BASE_PATH_COR: '/api',
  BASE_PATH_COTA: '/api/code-tables',
  BASE_PATH_DOC: '/api/documents',
  BASE_PATH_PORT: '/api/portfolio-manager',
  BASE_PATH_SIM: '/api/simulator',
  BASE_PATH_STO: '/api/story',
  BASE_FONT_SIZE: '1rem',
  BASE_FONT_WEIGHT: '400',
  BASE_FONT_FAMILY: 'Roboto, sans serif',
  BASE_FONT_COLOR: '#FFF',
  PRIMARY_COLOR: '#3a36db',
  SECONDARY_COLOR: '#4844de',
  THIRD_COLOR: '#e8f0fb',
  DISABLE_COLOR: '#a6a4ef',
  SUCCESS_COLOR: '#27ae60',
  WARNING_COLOR: '#f8c529',
  ERROR_COLOR: '#dc362e',
  GENERAL_PIE_COLORS: [
    '#157afe',
    '#219ebc',
    '#e76f51',
    '#588157',
    '#3d4058',
    '#985de5',
    '#80ed99',
    '#e85d04',
    '#c9184a',
    '#40916c',
  ],
  SUITABILITY_PIE_COLORS: [
    '#07825E',
    '#9CCDBF',
    '#FFDE66',
    '#CB2C30',
    '#D5D1CD',
    '#84344E',
    '#737A9B',
  ],
  ACTION_STATES_PIE_COLORS: ['#737A9B', '#D5D1CD', '#07825E', '#FFDE66'],
  YES_NO_PIE_COLORS: ['#3a36db', '#f8c529'],
  YES_NO_HOME_PIE_COLORS: ['#3a36db', '#737A9B'],
};
