import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  BusinessUnit,
  CodeTableEntry,
  OrgPosition,
  OrgPositionService,
  User,
} from '../../../api/core';
import {combineLatest, finalize, Observable, timeout} from 'rxjs';
import {NotificationService} from 'src/app/services/notification.service';
import * as Blockly from 'blockly';
import {Block, ConnectionType, Workspace} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import {CodeTableService} from 'src/app/services/code-table.service';
import {ECodeTables} from 'src/app/util/enum';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';
import {AdvancedFilterField, FilterConfig} from 'src/app/models/filter.model';
import {BlockCategory} from './blocks/Base';
import {BlockFilterNumber} from './blocks/FilterNumber';
import {BlockFilterList} from './blocks/FilterList';
import {BlockFilterBool} from './blocks/FilterBool';
import {BlockFilterInclExclAsset} from './blocks/FilterInclExclAsset';
import {BlockFilterDate} from './blocks/FilterDate';
import {BlockFilterAsset} from './blocks/FilterAsset';
import {BlockFilterWeighted} from './blocks/FilterWeighted';
import {BlockFilterExposure} from './blocks/FilterExposure';
import {BlockFilterAge} from './blocks/FilterAge';
import {BlockFilterModifier} from './blocks/FilterModifier';
import {BlockFilterComposite} from './blocks/FilterComposite';
import {
  fnaClientAge,
  fnaClientDomicile,
  fnaClientGender,
  fnaOrgPosition,
  fnaPortfolioAdvisoryType,
  fnaPortfolioBusinessDivision,
  fnaPortfolioBusinessUnit,
  fnaPortfolioFidleg,
  fnaPortfolioLiquidity,
  fnaPortfolioManager,
  fnaPortfolioMifid,
  fnaPortfolioOptOut,
  fnaPortfolioQualifiedInvestor,
  fnaPortfolioReferenceCurrency,
  fnaPortfolioReturn,
  fnaPortfolioRisk,
  fnaPortfolioRiskProfile,
  fnaPortfolioRmDepartment,
  fnaPortfolioRmLocation,
  fnaPortfolioRmMarket,
  fnaPortfolioServiceCenter,
  fnaPortfolioSustainabilityProfile,
  fnaPortfolioType,
  fnaPortfolioValue,
  fnaPortfolioWaiveOfAdvice,
  fnaPositionClass,
  fnaPositionIds,
  fnaPositionMaturity,
  fnaPositionMoodyRating,
  fnaPositionNextCall,
  fnaPositionPerformance,
  fnaPositionProductInvestmentHorizon,
  fnaPositionProductRatingApac,
  fnaPositionProductRatingMe,
  fnaPositionIssuerName,
  fnaPositionRanking,
  fnaPositionRatingSourceLGT,
  fnaPositionRatingSP,
  fnaPositionSubClass,
  fnaPositionSustainabilityRating,
  fnaPositionType,
  fnaPositionValue,
} from '../../../services/filter.names';
import {moodyRatingOrder, spRatingOrder, sustainabilityRatingOrder,} from '../../../services/ratings.service';
import {BlockFilterOrder} from './blocks/FilterOrder';
import {BlockFilterLike} from './blocks/FilterLike';
import {UiFilterConfigFlags, UiFilterConfigService} from '../../../services/ui-filter-config.service';
import {pickOrgPositionUsersDialog} from '../../org-position-users/org-position-users.component';
import {ModalService} from '../../../services/modal.service';
import {BlockFilterListEditorCallback} from './blocks/FilterListEditorCallback';
import {LabelBuilder} from "../../../util/label-builder";

const SECTION_COLORS = {
  OPERATORS: environment.GENERAL_PIE_COLORS[0],
  ORG_POSITION: environment.GENERAL_PIE_COLORS[1],
  PORTFOLIO: environment.GENERAL_PIE_COLORS[2],
  POSITION: environment.GENERAL_PIE_COLORS[3],
  ASSET: environment.GENERAL_PIE_COLORS[4],
  CURRENCY: environment.GENERAL_PIE_COLORS[5],
  REGION: environment.GENERAL_PIE_COLORS[6],
  SECTOR: environment.GENERAL_PIE_COLORS[7],
  ASSET_CLASS: environment.GENERAL_PIE_COLORS[8],
  CLIENT: environment.GENERAL_PIE_COLORS[10],
};

interface OrgPositionEntry {
  id: number;
  ident: string;
  name: string;
}

@Component({
  selector: 'app-filter-details-advanced',
  templateUrl: './filter-details-advanced.component.html',
})
export class FilterDetailsAdvancedComponent implements OnInit, OnDestroy {
  /**
   * Filter config
   */
  @Input() filterForm: FilterConfig;

  @Input() readOnly = false;
  /**
   *
   * Data fields
   */
  genders: CodeTableEntry[] = [];
  currencies: CodeTableEntry[] = [];
  countries: CodeTableEntry[] = [];
  portfolioTypes: CodeTableEntry[] = [];
  assetTypes: CodeTableEntry[] = [];
  assetClasses: CodeTableEntry[] = [];
  assetSubClasses: CodeTableEntry[] = [];
  portfolioStrategies: CodeTableEntry[] = [];
  businessUnits: BusinessUnit[] = [];
  serviceCenters: CodeTableEntry[] = [];
  businessDivisions: CodeTableEntry[] = [];
  advisoryTypes: CodeTableEntry[] = [];
  sustainabilityProfiles: CodeTableEntry[] = [];
  productRatingsApac: CodeTableEntry[] = [];
  productRatingsMe: CodeTableEntry[] = [];
  productInvestmentHorizons: CodeTableEntry[] = [];
  mifidClientSegmentations: CodeTableEntry[] = [];
  fidlegClientSegmentations: CodeTableEntry[] = [];
  rmLocations: CodeTableEntry[] = [];
  rmMarketes: CodeTableEntry[] = [];
  moodyRatings: CodeTableEntry[] = [];
  spRatings: CodeTableEntry[] = [];
  sustainabilityRatings: CodeTableEntry[] = [];
  rankings: CodeTableEntry[] = [];
  ratingSources: CodeTableEntry[] = [];
  regions: CodeTableEntry[] = [];
  sectors: CodeTableEntry[] = [];
  relManagers: User[] = [];
  orgPositions: OrgPositionEntry[] = [];
  allOrgPositions: OrgPosition[] = [];
  blocklyInitialized = false;
  codetablesLoaded = false;

  clientAgeFields: AdvancedFilterField[];
  portfolioNumberFields: AdvancedFilterField[];
  positionNumberFields: AdvancedFilterField[];
  positionDateFields: AdvancedFilterField[];
  clientListFields: AdvancedFilterField[];
  portfolioListFields: AdvancedFilterField[];
  portfolioBoolFields: AdvancedFilterField[];
  portfolioLikeFields: AdvancedFilterField[];
  positionListFields: AdvancedFilterField[];
  positionOrderFields: AdvancedFilterField[];
  positionInclExclAssetFields: AdvancedFilterField[];
  assetClassFields: AdvancedFilterField[];
  currencyFields: AdvancedFilterField[];
  regionFields: AdvancedFilterField[];
  sectorFields: AdvancedFilterField[];
  orgPositionFields: AdvancedFilterField[];
  positionLikeFields: AdvancedFilterField[];

  constructor(
    protected codeTableService: CodeTableService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected filterConfigService: UiFilterConfigService,
    protected orgPositionService: OrgPositionService,
    protected modalService: ModalService,
    protected labelBuilder: LabelBuilder,
  ) {
  }

  ngOnInit() {
    const div = document.getElementById('blocklyDiv');
    if (div && !this.blocklyInitialized) {
      this.init();
      this.blocklyInitialized = true;
    }
  }

  ngOnDestroy() {
    const div = document.getElementById('blocklyDiv');
    if (div && this.blocklyInitialized) {
      Blockly.getMainWorkspace().dispose();
    }
  }

  onFilterSubmit(): void {
    if (this.blocklyInitialized) {
      this.updateFilterConfigContent();
    }
  }

  private init() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.gender),
      this.codeTableService.getCodeTable(ECodeTables.currency),
      this.codeTableService.getCodeTable(ECodeTables.country),
      this.codeTableService.getCodeTable(ECodeTables.portfolioType),
      this.codeTableService.getCodeTable(ECodeTables.assetType),
      this.codeTableService.getCodeTable(ECodeTables.assetClass),
      this.codeTableService.getCodeTable(ECodeTables.portfolioStrategy),
      this.codeTableService.getCodeTable(ECodeTables.assetSubClass),
      this.codeTableService.getCodeTable(ECodeTables.businessUnit),
      this.codeTableService.getCodeTable(ECodeTables.serviceCenter),
      this.codeTableService.getCodeTable(ECodeTables.businessDivision),
      this.codeTableService.getCodeTable(ECodeTables.advisoryType),
      this.codeTableService.getCodeTable(ECodeTables.sustainabilityProfile),
      this.codeTableService.getCodeTable(ECodeTables.mifidClientSegmentation),
      this.codeTableService.getCodeTable(ECodeTables.fidlegClientSegmentation),
      this.codeTableService.getCodeTable(ECodeTables.rmMarket),
      this.codeTableService.getCodeTable(ECodeTables.ratingMoody),
      this.codeTableService.getCodeTable(ECodeTables.ratingSp),
      this.codeTableService.getCodeTable(ECodeTables.ratingSustainability),
      this.codeTableService.getCodeTable(ECodeTables.ranking),
      this.codeTableService.getCodeTable(ECodeTables.ratingSource),
      this.codeTableService.getCodeTable(ECodeTables.region),
      this.codeTableService.getCodeTable(ECodeTables.gics),
      this.codeTableService.getCodeTable(ECodeTables.relManager),
      this.codeTableService.getCodeTable(ECodeTables.productRatingApac),
      this.codeTableService.getCodeTable(ECodeTables.productRatingMe),
      this.codeTableService.getCodeTable(ECodeTables.productInvestmentHorizon),
      this.orgPositionService.getAll() as Observable<any[]>,
    ]).pipe(
      timeout(60 * 1000), // timeout after 60 seconds
      finalize( () => { // set codetables loaded to true, even on error
        this.codetablesLoaded = true;
        this.blocklyInit();
        this.validateFiltersLoading();
      })
    ).subscribe({
      error: (error) => {
        console.warn('Error loading filter code tables', error.message)
      },
      next: (data) => {
        let k = 0;
        this.genders = data[k++];
        this.currencies = data[k++];
        this.countries = data[k++];
        this.portfolioTypes = data[k++];
        this.assetTypes = data[k++];
        this.assetClasses = data[k++];
        this.portfolioStrategies = data[k++];
        this.assetSubClasses = data[k++];
        this.businessUnits = data[k++];
        this.serviceCenters = data[k++];
        this.businessDivisions = data[k++];
        this.advisoryTypes = data[k++];
        this.sustainabilityProfiles = data[k++];
        this.mifidClientSegmentations = data[k++];
        this.fidlegClientSegmentations = data[k++];
        this.rmLocations = data[2].filter((item) => this.isRmLocation(item));
        this.rmMarketes = data[k++];
        this.moodyRatings = data[k++];
        this.spRatings = data[k++];
        this.sustainabilityRatings = data[k++];
        this.rankings = data[k++];
        this.ratingSources = data[k++];
        this.regions = data[k++];
        this.sectors = data[k++];
        this.relManagers = data[k++];
        this.productRatingsApac = data[k++];
        this.productRatingsMe = data[k++];
        this.productInvestmentHorizons = data[k++];
        this.allOrgPositions = data[k++];
        this.orgPositions = this.allOrgPositions.filter(d => d)
          .map(d => ({id: 0, ident: d.ident, name: `${d.description} (${d.ident})`}));
      },
    });
  }

  /**
   * Checks if the filters are correctly loaded, showing a warning if not
   */
  private validateFiltersLoading() {
    const entries = {
      'genders': this.genders,
      'currencies': this.currencies,
      'countries': this.countries,
      'portfolioTypes': this.portfolioTypes,
      'assetTypes': this.assetTypes,
      'assetClasses': this.assetClasses,
      'portfolioStrategies': this.portfolioStrategies,
      'assetSubClasses': this.assetSubClasses,
      'businessUnits': this.businessUnits,
      'serviceCenters': this.serviceCenters,
      'businessDivisions': this.businessDivisions,
      'advisoryTypes': this.advisoryTypes,
      'sustainabilityProfiles': this.sustainabilityProfiles,
      'mifidClientSegmentations': this.mifidClientSegmentations,
      'fidlegClientSegmentations': this.fidlegClientSegmentations,
      'rmLocations': this.rmLocations,
      'rmMarkets': this.rmMarketes,
      'moodyRatings': this.moodyRatings,
      'spRatings': this.spRatings,
      'sustainabilityRatings': this.sustainabilityRatings,
      'rankings': this.rankings,
      'ratingSources': this.ratingSources,
      'regions': this.regions,
      'sectors': this.sectors,
      'relManagers': this.relManagers,
      'productRatingsApac': this.productRatingsApac,
      'productRatingsMe': this.productRatingsMe,
      'productInvestmentHorizons': this.productInvestmentHorizons,
      'allOrgPositions': this.allOrgPositions,
      'orgPositions': this.orgPositions
    }
    const missing = Object.keys(entries).filter(key => entries[key].length === 0);
    if (missing.length > 0) {
      this.notificationService.handleWarning(
        this.translateService.instant('someFiltersNotLoaded', {filters: missing.join(',')})
      );
    }
  }

  private initFields(): void {
    const sort = this.sort;
    const map = this.map;
    this.initClientFields();
    this.initPortfolioFields();
    this.initPositionFields();
    const config = this.filterConfigService.getConfigFlags();
    this.assetClassFields = [
      {
        ident: 'assetClass',
        name: this.translateService.instant('assetClass'),
        entries: map(sort(this.assetClasses)),
        active: config.advanced.assetClass.active,
      },
    ].filter(d => d.entries.length > 0);
    this.currencyFields = [
      {
        ident: 'currency',
        name: this.translateService.instant('currency'),
        entries: map(sort(this.currencies), 'name', 'ident'),
        active: config.advanced.currency.active,
      },
    ].filter(d => d.entries.length > 0);
    this.regionFields = [
      {
        ident: 'region',
        name: this.translateService.instant('region'),
        entries: map(sort(this.regions), 'name', 'ident'),
        active: config.advanced.region.active,
      },
    ].filter(d => d.entries.length > 0);
    this.sectorFields = [
      {
        ident: 'sector',
        name: this.translateService.instant('sector'),
        entries: map(sort(this.sectors), 'name', 'ident'),
        active: config.advanced.sector.active,
      },
    ].filter(d => d.entries.length > 0);
  }

  private initClientFields() {
    const sort = this.sort;
    const map = this.map;
    const config = this.filterConfigService.getConfigFlags();

    this.clientAgeFields = [
      {
        ident: fnaClientAge,
        name: this.translateService.instant('age'),
        active: config.advanced.client.filters.ageRange,
      },
    ];
    this.clientListFields = [
      {
        ident: fnaClientGender,
        name: this.translateService.instant('gender'),
        entries: map(sort(this.genders)),
        active: config.advanced.client.filters.gender,
      },
      {
        ident: fnaClientDomicile,
        name: this.translateService.instant('clientDomicile'),
        entries: map(sort(this.countries), 'name', 'ident'),
        active: config.advanced.client.filters.domiciles,
      },
    ].filter(d => d.entries.length > 0);
  }

  private initPortfolioFields() {
    const sort = this.sort;
    const map = this.map;
    const config = this.filterConfigService.getConfigFlags();

    this.portfolioNumberFields = [
      {
        ident: fnaPortfolioRisk,
        name: this.translateService.instant('risk'),
        suffix: '%',
        active: config.advanced.portfolio.filters.riskRange,
      },
      {
        ident: fnaPortfolioReturn,
        name: this.translateService.instant('return'),
        suffix: '%',
        active: config.advanced.portfolio.filters.returnRange,
      },
      {
        ident: fnaPortfolioValue,
        name: this.translateService.instant('value'),
        suffix: this.labelBuilder.selectedCurrencyLabel(),
        active: config.advanced.portfolio.filters.valueRange,
      },
      {
        ident: fnaPortfolioLiquidity,
        name: this.translateService.instant('liquidity'),
        suffix: '%',
        active: config.advanced.portfolio.filters.liquidityRange,
      },
    ];
    this.portfolioListFields = [
      {
        ident: fnaPortfolioReferenceCurrency,
        name: this.translateService.instant('referenceCurrency'),
        entries: map(sort(this.currencies), 'name', 'ident'),
        active: config.advanced.portfolio.filters.referenceCurrency,
      },
      {
        ident: fnaPortfolioRiskProfile,
        name: this.translateService.instant('riskProfile'),
        entries: map(sort(this.portfolioStrategies), 'name', 'ident'),
        active: config.advanced.portfolio.filters.model,
      },
      {
        ident: fnaPortfolioType,
        name: this.translateService.instant('portfolioType'),
        entries: map(sort(this.portfolioTypes)),
        active: config.advanced.portfolio.filters.portfolioType,
      },
      {
        ident: fnaPortfolioBusinessUnit,
        name: this.translateService.instant('bu'),
        entries: map(sort(this.businessUnits)),
        active: config.advanced.portfolio.filters.bu,
      },
      {
        ident: fnaPortfolioServiceCenter,
        name: this.translateService.instant('serviceCenter'),
        entries: map(sort(this.serviceCenters)),
        active: config.advanced.portfolio.filters.serviceCenter,
      },
      {
        ident: fnaPortfolioBusinessDivision,
        name: this.translateService.instant('businessDivision'),
        entries: map(sort(this.businessDivisions)),
        active: config.advanced.portfolio.filters.businessDivision,
      },
      {
        ident: fnaPortfolioAdvisoryType,
        name: this.translateService.instant('advisoryType'),
        entries: map(sort(this.advisoryTypes)),
        active: config.advanced.portfolio.filters.advisoryType,
      },
      {
        ident: fnaPortfolioSustainabilityProfile,
        name: this.translateService.instant('sustainabilityProfile'),
        entries: map(sort(this.sustainabilityProfiles)),
        active: config.advanced.portfolio.filters.sustainabilityProfile,
      },
      {
        ident: fnaPortfolioMifid,
        name: this.translateService.instant('mifidClientSegmentation'),
        entries: map(sort(this.mifidClientSegmentations)),
        active: config.advanced.portfolio.filters.mifid,
      },
      {
        ident: fnaPortfolioFidleg,
        name: this.translateService.instant('fidlegClientSegmentation'),
        entries: map(sort(this.fidlegClientSegmentations)),
        active: config.advanced.portfolio.filters.fidleg,
      },
      {
        ident: fnaPortfolioRmLocation,
        name: this.translateService.instant('rmLocation'),
        entries: map(sort(this.rmLocations), 'name', 'ident'),
        active: config.advanced.portfolio.filters.rmLocation,
      },
      {
        ident: fnaPortfolioRmMarket,
        name: this.translateService.instant('rmMarket'),
        entries: map(sort(this.rmMarketes)),
        active: config.advanced.portfolio.filters.rmMarket,
      },
      {
        ident: fnaPortfolioManager,
        name: this.translateService.instant('relationshipManager'),
        entries: map(sort(this.relManagers), 'name', 'ident'),
        active: config.advanced.portfolio.filters.manager,
      },
    ].filter(d => d.entries.length > 0);
    this.portfolioBoolFields = [
      {
        ident: fnaPortfolioQualifiedInvestor,
        name: this.translateService.instant('qualifiedInvestor'),
        active: config.advanced.portfolio.filters.qualifiedInvestor,
      },
      {
        ident: fnaPortfolioWaiveOfAdvice,
        name: this.translateService.instant('waiveOfAdvice'),
        active: config.advanced.portfolio.filters.waiveOfAdvice,
      },
      {
        ident: fnaPortfolioOptOut,
        name: this.translateService.instant('includeOptOutClients'),
        active: config.advanced.portfolio.filters.optOut,
      },
    ];
    this.portfolioLikeFields = [
      {
        ident: fnaPortfolioRmDepartment,
        name: this.translateService.instant('rmDepartment'),
        active: config.advanced.portfolio.filters.rmDepartment,
      },
    ];
  }

  private initPositionFields() {
    const sort = this.sort;
    const map = this.map;
    const config = this.filterConfigService.getConfigFlags();

    this.positionNumberFields = [
      {
        ident: fnaPositionValue,
        name: this.translateService.instant('positionValue'),
        active: config.advanced.position.filters.valueRange,
        suffix: this.labelBuilder.selectedCurrencyLabel(),
      },
      {
        ident: fnaPositionPerformance,
        name: this.translateService.instant('performance'),
        suffix: '%',
        active: config.advanced.position.filters.performance,
      },
    ];
    this.positionDateFields = [
      {
        ident: fnaPositionNextCall,
        name: this.translateService.instant('nextCallDate'),
        active: config.advanced.position.filters.nextCallDate,
      },
      {
        ident: fnaPositionMaturity,
        name: this.translateService.instant('maturityDate'),
        active: config.advanced.position.filters.maturityDate,
      },
    ];
    this.positionListFields = [
      {
        ident: fnaPositionType,
        name: this.translateService.instant('assetType'),
        entries: map(sort(this.assetTypes)),
        active: config.advanced.position.filters.assetType,
      },
      {
        ident: fnaPositionClass,
        name: this.translateService.instant('assetClass'),
        entries: map(sort(this.assetClasses)),
        active: config.advanced.position.filters.assetClass,
      },
      {
        ident: fnaPositionSubClass,
        name: this.translateService.instant('assetSubClass'),
        entries: map(sort(this.assetSubClasses)),
        active: config.advanced.position.filters.assetSubClass,
      },
      {
        ident: fnaPositionRanking,
        name: this.translateService.instant('ranking'),
        entries: map(sort(this.rankings)),
        active: config.advanced.position.filters.ranking,
      },
      {
        ident: fnaPositionRatingSourceLGT,
        name: this.translateService.instant('ratingSource'),
        entries: map(sort(this.ratingSources)),
        active: config.advanced.position.filters.ratingSourceLGT,
      },
    ].filter(d => d.entries.length > 0);
    this.positionOrderFields = [
      {
        ident: fnaPositionMoodyRating,
        name: this.translateService.instant('moodyRating'),
        entries: map(moodyRatingOrder.sort(this.moodyRatings, (r) => r.name)),
        active: config.advanced.position.filters.ratingMoody,
      },
      {
        ident: fnaPositionRatingSP,
        name: this.translateService.instant('s&pRating'),
        entries: map(spRatingOrder.sort(this.spRatings, (r) => r.name)),
        active: config.advanced.position.filters.ratingSP,
      },
      {
        ident: fnaPositionSustainabilityRating,
        name: this.translateService.instant('sustainabilityRating'),
        entries: map(
          sustainabilityRatingOrder.sort(
            this.sustainabilityRatings,
            (r) => r.name
          )
        ),
        active: config.advanced.position.filters.sustainabilityRating,
      },
      {
        ident: fnaPositionProductRatingApac,
        name: this.translateService.instant('productRatingApac'),
        entries: map(sort(this.productRatingsApac)),
        active: config.advanced.position.filters.productRatingApac,
      },
      {
        ident: fnaPositionProductRatingMe,
        name: this.translateService.instant('productRatingMe'),
        entries: map(sort(this.productRatingsMe)),
        active: config.advanced.position.filters.productRatingMe,
      },
      {
        ident: fnaPositionProductInvestmentHorizon,
        name: this.translateService.instant('productInvestmentHorizon'),
        entries: map(sort(this.productInvestmentHorizons)),
        active: config.advanced.position.filters.productInvestmentHorizon,
      },
    ].filter(d => d.entries.length > 0);
    this.positionInclExclAssetFields = [
      {
        ident: fnaPositionIds,
        name: this.translateService.instant('assets(Available)'),
        active: config.advanced.position.filters.ids,
      },
    ];
    this.orgPositionFields = [
      {
        ident: fnaOrgPosition,
        name: this.translateService.instant('orgPosition'),
        entries: map(sort(this.orgPositions)),
        active: config.orgPositions.active,
      },
    ].filter(d => d.entries.length > 0);
    this.positionLikeFields = [
      {
        ident: fnaPositionIssuerName,
        name: this.translateService.instant('issuerName'),
        active: config.advanced.position.filters.issuerName,
      },
    ];
  }

  private blocklyInit(): void {
    this.initFields();
    this.defineBaseBlocksAndPatches();
    const config = {
      kind: 'categoryToolbox',
      contents: [],
    };
    const filterConfig = this.filterConfigService.getConfigFlags();

    const categories = [
      new BlockCategory('#operators', SECTION_COLORS.OPERATORS, [
        new BlockFilterModifier('operators', 'not', true),
        new BlockFilterComposite('operators', ['and', 'or', 'nor'], ['Boolean'], true),
      ]),
      new BlockCategory('#client', '#f8c529', [
        new BlockFilterComposite('client', ['each', 'all', 'none'], ['ClientBoolean'], true, 'clients'),
        new BlockFilterAge('client', this.clientAgeFields, ['Boolean', 'ClientBoolean']),
        new BlockFilterList('client', this.clientListFields, ['Boolean', 'ClientBoolean']),
      ]), // #client
      new BlockCategory('#portfolio', SECTION_COLORS.PORTFOLIO, [
        new BlockFilterNumber('portfolio', this.portfolioNumberFields, undefined, this.labelBuilder.selectedExchange()),
        new BlockFilterList('portfolio', this.portfolioListFields),
        new BlockFilterBool('portfolio', this.portfolioBoolFields),
        new BlockFilterLike('portfolio', this.portfolioLikeFields),
      ]), // #portfolio
      new BlockCategory('#position', SECTION_COLORS.POSITION, [
        new BlockFilterComposite('position', ['each', 'all', 'none'], ['PositionBoolean'], true, 'positionData.positions'),
        new BlockFilterNumber('position', this.positionNumberFields, ['Boolean', 'PositionBoolean'], this.labelBuilder.selectedExchange()),
        new BlockFilterList('position', this.positionListFields, ['Boolean', 'PositionBoolean']),
        new BlockFilterInclExclAsset('position', this.positionInclExclAssetFields, ['Boolean', 'PositionBoolean']),
        new BlockFilterDate('position', this.positionDateFields, ['Boolean', 'PositionBoolean']),
        new BlockFilterOrder('position', this.positionOrderFields, ['Boolean', 'PositionBoolean']),
        new BlockFilterLike('position', this.positionLikeFields),
      ]), // #position
      new BlockCategory('#asset', SECTION_COLORS.ASSET, [
        new BlockFilterAsset(
          'asset',
          filterConfig.advanced.asset.active,
          false,
          this.labelBuilder.selectedCurrencyLabel(),
          undefined,
          this.labelBuilder.selectedExchange(),
        ),
        new BlockFilterAsset(
          'asset',
          filterConfig.advanced.asset.active,
          true,
          '%'
        ),
      ]), // #asset
      new BlockCategory('#assetClass', SECTION_COLORS.ASSET_CLASS, this.assetClassBlocks(filterConfig)), // #assetClass
      new BlockCategory('#currency', SECTION_COLORS.CURRENCY, this.currencyBlocks(filterConfig)), // #currency
      new BlockCategory('#region', SECTION_COLORS.REGION, this.regionBlocks(filterConfig)), // #region
      new BlockCategory('#sector', SECTION_COLORS.SECTOR, this.sectorBlocks(filterConfig)), // #assetClass
      this.orgPositions.length > 0 ? new BlockCategory('#orgPosition', SECTION_COLORS.ORG_POSITION, [
        new BlockFilterListEditorCallback(
          'orgPosition',
          this.orgPositionFields,
          ['Boolean', 'OrgPositionBoolean'],
          'in',
          this.editOrgPositions.bind(this)
        ),
      ]): undefined,
    ].filter(d => d);
    config.contents.push(
      ...categories
        .map((cat) => cat.define(this.translateService))
        .filter(Boolean)
    );

    const blocklyDiv = document.getElementById('blocklyDiv');
    const inject = Blockly.inject as any;
    inject(blocklyDiv, {
      sounds: false,
      trashcan: true,
      media: './assets/images/blockly/',
      toolbox: config,
      readOnly: this.readOnly,
    });
    // load filter content
    this.loadFilterConfigContent();
  }

  private async editOrgPositions(block: Block) {
    const valueField = block.getField('value');
    const positions = [{ident: valueField.getValue()}] as unknown as OrgPosition[];
    const allowed = await pickOrgPositionUsersDialog(
      this.orgPositionService, this.codeTableService, this.translateService,
      this.modalService, { users: [], positions}, true,
    );
    if (allowed?.positions?.length) {
      const selected = allowed.positions[0].ident;
      valueField.setValue(selected);
    }
  }

  /**
   * Generates a complete XML export of the workspace containing metadata
   * for positions and blocks.
   * @private
   */
  private workspaceToXml(): string {
    const ws = Blockly.getMainWorkspace();
    const xml = Blockly.Xml;
    const snapshot = xml.workspaceToDom(ws);
    return xml.domToText(snapshot);
  }

  /**
   * Generates a sanitized JSON-object export of the block conditions
   * @private
   */
  private workspaceToJson(): object {
    const ws = Blockly.getMainWorkspace();
    const wrapper = ws
      .getTopBlocks(false)
      .find((t) => t.type === 'filter_wrapper');

    if (wrapper) {
      javascriptGenerator.init(ws);
      const code =
        javascriptGenerator.valueToCode(
          wrapper,
          'value',
          javascriptGenerator.ORDER_NONE
        ) || 'null';
      return JSON.parse(code);
    }
    return null;
  }

  /**
   * Updates the filterConfig with the changes from the workspace
   * @private
   */
  private updateFilterConfigContent() {
    cleanWorkspace(Blockly.getMainWorkspace());
    const data = this.workspaceToJson();
    if (data) {
      this.filterForm.advanced = {
        content: data,
        metadata: this.workspaceToXml(),
      };
    } else {
      this.filterForm.advanced = null;
    }
  }

  /**
   * Loads the filterConfig into the visual workspace
   * @private
   */
  private loadFilterConfigContent() {
    const ws = Blockly.getMainWorkspace() as Blockly.WorkspaceSvg;
    const metaDataKey = 'metadata';
    if (this.filterForm.advanced && this.filterForm.advanced[metaDataKey]) {
      const snapshot = Blockly.utils.xml.textToDom(this.filterForm.advanced[metaDataKey]);
      ws.clear();
      Blockly.Xml.domToWorkspace(snapshot, ws);
    } else {
      const block = ws.newBlock('filter_wrapper') as Blockly.BlockSvg;
      block.initSvg();
      block.moveBy(200, 50);
      block.setDeletable(false);
      ws.render();
    }
  }

  /**
   * Maps the given array of objects into a two-entries array with the given name and ID properties as values.
   *
   * @param arr source objects array
   * @param nameProp name property to be placed at index 0 of the array
   * @param idProp ident property to be placed at index 1 of the array
   * @param cleanup TRUE if the string must have its blank spaces replaced due to rendering issues
   * @private array of two-value entries arrays with mapped values using 'name' and 'id' given properties
   */
  private map(
    arr: any[],
    nameProp: string = 'name',
    idProp: string = 'ident',
    cleanup: boolean = false
  ): any[][] {
    const count = (a) =>
      a
        .map((d) => d[0])
        .reduce((acc, curr) => (acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc), {});
    const list = arr.map((d) => {
      let label = cleanup ? sanitizeLabel(d[nameProp]) : d[nameProp];
      return [ d['closed'] ? `*${label}`: label, d[idProp]];
    });
    const frequencies = count(list);
    return list.map((it) => {
      let result = it;
      if (frequencies[it[0]] > 1) {
        result = [`${it[0]}(${it[1]})`, it[1]];
      }
      return result;
    });
  }

  private sort(arr: any[]): any[] {
    return arr.sort((a, b) =>
      (a['name'] || '').toLowerCase() > (b['name'] || '').toLowerCase() ? 1 : -1
    );
  }

  /****************************************************/
  /**
   * Patch for supporting dynamic blocks on drag-drop events
   * Note: This patch is broken since blockly release 10.x, check in https://github.com/confinale/aspark/issues/8608
   */
  private defineInsertionPatch(): void {
    Blockly.InsertionMarkerManager.prototype.update = function(
      dxy,
      deleteArea
    ) {
      const self = this as any;
      const candidate = self.getCandidate(dxy);

      self.wouldDeleteBlock = self.shouldDelete(candidate, deleteArea);
      const shouldUpdate =
        self.wouldDeleteBlock || self.shouldUpdatePreviews(candidate, dxy);

      if (shouldUpdate) {
        // Begin monkey patch
        if (
          candidate &&
          candidate.closest &&
          candidate.closest.sourceBlock_.onPendingConnection
        ) {
          candidate.closest.sourceBlock_.onPendingConnection(candidate.closest);
          if (!self.pendingBlocks) {
            self.pendingBlocks = new Set();
          }
          self.pendingBlocks.add(candidate.closest.sourceBlock_);
        }
        // End monkey patch
        // Don't fire events for insertion marker creation or movement.
        Blockly.Events.disable();
        self.maybeHidePreview(candidate);
        self.maybeShowPreview(candidate);
        Blockly.Events.enable();
      }
    };

    const oldDispose = Blockly.InsertionMarkerManager.prototype.dispose;
    Blockly.InsertionMarkerManager.prototype.dispose = function() {
      const self = this as any;
      if (self.pendingBlocks) {
        self.pendingBlocks.forEach((block) => {
          if (block.finalizeConnections) {
            block.finalizeConnections();
          }
        });
      }
      oldDispose.call(self);
    };
  }

  private defineFilterWrapper(): void {
    const filterWrapperKey = 'filter_wrapper';
    Blockly.Blocks[filterWrapperKey] = {
      init() {
        this.appendValueInput('value')
          .setCheck('Boolean')
          .appendField('Filter');
        this.setColour(30);
        this.setTooltip('');
        this.setHelpUrl('');
      },
    };

    javascriptGenerator[filterWrapperKey] = (block) => {
      const value =
        javascriptGenerator.valueToCode(
          block,
          'value',
          javascriptGenerator.ORDER_NONE
        ) || 'null';
      return `{"advanced": ${value}}`;
    };
  }

  /**
   * Additional patch and blocks for blockly components.
   */
  private defineBaseBlocksAndPatches(): void {
    this.defineInsertionPatch();
    this.defineFilterWrapper();
  }

  private isRmLocation(country: CodeTableEntry): boolean {
    return (
      country.ident === 'LI' || country.ident === 'AT' || country.ident === 'CH'
    );
  }

  private assetClassBlocks(filterConfig: UiFilterConfigFlags) {
    if (filterConfig.advanced.assetClass.filters.weight === true) {
      return [
        new BlockFilterWeighted('assetClass', this.assetClassFields),
        new BlockFilterExposure('assetClass', this.assetClassFields)
      ];
    }
    return [new BlockFilterExposure('assetClass', this.assetClassFields)];
  }

  private currencyBlocks(filterConfig: UiFilterConfigFlags) {
    if (filterConfig.advanced.currency.filters.weight === true) {
      return [
        new BlockFilterWeighted('currency', this.currencyFields),
        new BlockFilterExposure('currency', this.currencyFields)
      ];
    }
    return [new BlockFilterExposure('currency', this.currencyFields)];
  }

  private regionBlocks(filterConfig: UiFilterConfigFlags) {
    if (filterConfig.advanced.region.filters.weight === true) {
      return [
        new BlockFilterWeighted('region', this.regionFields),
        new BlockFilterExposure('region', this.regionFields)
      ];
    }
    return [new BlockFilterExposure('region', this.regionFields)];
  }

  private sectorBlocks(filterConfig: UiFilterConfigFlags) {
    if (filterConfig.advanced.sector.filters) {
      return [
        new BlockFilterWeighted('sector', this.sectorFields),
        new BlockFilterExposure('sector', this.sectorFields)
      ];
    }
    return [new BlockFilterExposure('sector', this.sectorFields)];
  }
}

function sanitizeLabel(str) {
  return str.replace(/\s\s+/g, ' ').split(' ').join('_');
}

/**
 * Removes empty blocks non-effective blocks from workspace. It also removes top blocks
 * that are not being used.
 * @param ws workspace to clean
 * @private
 */
function cleanWorkspace(ws: Workspace): Workspace {
  ws.getTopBlocks(false)
    .filter(d => d.type !== 'filter_wrapper')
    .forEach(d => {
      ws.removeTopBlock(d);
    });
  const parent = ws.getTopBlocks(false)[0];
  if (parent) {
    cleanChildren(ws, parent);
  }
  return ws;
}

/**
 * Cleans a specific block within the given workspace, this function is used
 * recursively to reverse into the leaf blocks and clean all blocks.
 * @param ws tarter workspace
 * @param inputBlock block to clean
 * @private
 */
function cleanChildren(ws: Workspace, inputBlock: Block) {
  const block = ws.getBlockById(inputBlock.id);
  if (!block) {
    return;
  }
  block.getChildren(true).reverse().forEach(c => {
    const inputCount = c.getConnections_(false).filter(d => d.type === ConnectionType.INPUT_VALUE).length;

    if (inputCount > 0) {
      cleanChildren(ws, c);

      const children = c.getChildren(false);
      const childrenCount = children.length;
      const blockOperator = c.getFieldValue('operator') || '';
      const isReducibleBlock = !['all', 'none'].some(d => d === blockOperator);
      const parent = c.getParent();

      if (isReducibleBlock && childrenCount === 1 && inputCount > childrenCount) {
        const child = children[0];

        const input = parent.getInput('ADD1');
        if (input) {
          const parentConnection = input.connection;
          parentConnection.connect(child.outputConnection);
        }
        c.dispose(true);
        compactConnections(parent);
      } else if (childrenCount === 0) {
        c.dispose(true);
        compactConnections(parent);
      }
    }
  });
}

/**
 * Removes empty inputs from dynamic blocks.
 * @param b
 * @private
 */
function compactConnections(b: Block): void {
  if (b && typeof (b as any).compactConnections === 'function') {
    (b as any).compactConnections();
  }
}
