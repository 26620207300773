import {Component, Input} from "@angular/core";
import {PageBarButton} from "../../services/page.bar.service";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../services/permission.service";

@Component({
  selector: 'app-multi-purpose-button',
  templateUrl: './multi-purpose-button.component.html'
})
export class MultiPurposeButtonComponent {
  @Input()
  primaryButtons: PageBarButton[] = [];
  @Input()
  dropdownButtons: PageBarButton[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly permissionService: PermissionService,
  ) {
  }

  get hasMenuButtons() {
    return this.dropdownButtons.some(b => !b.show || b.show());
  }
}
