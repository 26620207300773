import {Component} from '@angular/core';
import {Intermediary, UserInfo,} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {ActivatedRoute} from '@angular/router';
import {INTERMEDIARY_TABS} from 'src/app/util/tab.constants';
import {Tab} from 'src/app/models/tabs.model';
import {usernameValueLabel} from "../../../util/grid/grid-renderer.util";

@Component({
  selector: 'app-intermediary-overview',
  templateUrl: './intermediary-overview.component.html',
})
export class IntermediaryOverviewComponent {

  intermediary: Intermediary;
  tabs: Tab[] = INTERMEDIARY_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected globalService: GlobalService,
  ) {
    activatedRoute.data.subscribe((data) => {
      this.intermediary = data.intermediary;
    });
  }

  usernameLabel(u: UserInfo) {
    return usernameValueLabel(u);
  }

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }
}
