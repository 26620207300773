import {Component, Inject, OnInit} from "@angular/core";
import {
  ContentDefinition,
  ContentService,
  FieldDefinition,
  FieldDefinitionType,
  OrderedFieldDefinition
} from "../../../../../api/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../../shared/modal/modal.component";
import {EModalType} from "../../../../../util/enum";
import {ModalSubComponent} from "../../../../../models/modal.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ColDef, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent} from "ag-grid-community";
import {
  genBooleanColumn,
  genEnumColumn,
  genNumberColumn,
  genTextColumn
} from "../../../../../util/grid/grid-renderer.util";
import {I18n} from "../../../../../services/i18n.service";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {genIconButtonColumn} from "../../../../../shared/grid/cell-renderers/icon-button.renderer";
import {GlobalService} from "../../../../../services/global.service";

@Component({
  selector: 'app-content-definition-dialog',
  templateUrl: './content-definition-dialog.component.html'
})
export class ContentDefinitionDialogComponent implements OnInit, ModalSubComponent {

  readonly contentDefinition: ContentDefinition;
  formGroup: FormGroup;

  initialSelected: FieldDefinition[] = [];
  available: FieldDefinition[] = []

  selectedColDefs: ColDef[] = [];
  availableColDefs: ColDef[] = [];

  selectedGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onGridReady: (event: GridReadyEvent) => {
      this.gridReadySelected(event);
    },
    getRowId: (params: GetRowIdParams<FieldDefinition>) => `${params.data.id}`,
    rowDragManaged: true,
    rowDragEntireRow: true,
    rowDragText: (params) => params.rowNode.data.ident,
  };

  availableGridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    onGridReady: (event: GridReadyEvent) => {
      this.gridReadyAvailable(event);
    },
    getRowId: (params: GetRowIdParams<FieldDefinition>) => `${params.data.id}`,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      this.rowsSelected = event.api.getSelectedRows().length > 0;
    },
  };

  selectedGridApi: GridApi<FieldDefinition>;
  availableGridApi: GridApi<FieldDefinition>;

  rowsSelected = false;
  subscriptions: Subscription[] = [];

  constructor(
    protected translateService: TranslateService,
    protected contentService: ContentService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected readonly fb: FormBuilder,
    protected readonly globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: {
      contentDefinition: ContentDefinition;
    } }
  ) {
    const contentDefinition = this.contentDefinition = data.data.contentDefinition;
    this.initialSelected = contentDefinition.orderedFieldDefinitions
        .sort((a, b) => a.orderValue - b.orderValue)
        .map(ofd => ofd.fieldDefinition);
    this.contentService.getFieldDefinitions().subscribe(fieldDefinitions => {
      this.available = fieldDefinitions
        .filter(fd => !this.initialSelected.find(s => fd.id === s.id));
    });
    this.formGroup = this.fb.group({
      name: new FormControl(
        contentDefinition.name,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
          Validators.pattern(/^(?!general$)/i) // name must not be 'general' (case-insensitive)
        ]
      ),
    });
    if (contentDefinition.id) {
      this.formGroup.get('name').disable();
    }
    this.selectedColDefs = this.generateSelectedColDefs();
    this.availableColDefs = this.generateAvailableColDefs();
  }

  ngOnInit() {
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.formGroup.invalid;
    this.subscriptions.push(
      this.formGroup.statusChanges.subscribe(() => {
        this.dialogRef.componentInstance.toolbarActionData.btnDisabled = this.formGroup.invalid;
      })
    );
  }

  gridReadySelected(event: GridReadyEvent) {
    this.selectedGridApi = event.api;
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
  }

  gridReadyAvailable(event: GridReadyEvent) {
    this.availableGridApi = event.api;
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
  }

  canAdd(): boolean {
    return this.rowsSelected;
  }

  addSelection() {
    if (!this.availableGridApi) {
      return;
    }
    const selected = this.availableGridApi.getSelectedRows();
    const toAdd: FieldDefinition[] = selected
      .filter(fd => !this.selectedGridApi.getRowNode(`${fd.id}`));
    this.selectedGridApi.applyTransaction({ add: toAdd });
    this.available = this.available.filter(fd => !selected.find(s => s.id === fd.id));
    this.availableGridApi.deselectAll();
  }

  private deleteEntry(entry: FieldDefinition) {
    this.selectedGridApi.applyTransaction({ remove: [entry] });
    this.available = [...this.available, entry];
  }

  private getCommonColDefs(): ColDef[] {
    return [
      genTextColumn('ident', I18n.getColName('ident')),
      genTextColumn('labelEn', I18n.getColName('labelEnShort')),
      genTextColumn('labelDe', I18n.getColName('labelDeShort')),
      genTextColumn('description', I18n.getColName('description')),
      {
        ...genBooleanColumn(
          'mandatory',
          I18n.getColName('mandatory'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      },
      genEnumColumn({
        field: 'editorType',
        values: Object.values(FieldDefinitionType),
        headerName: I18n.getColName('editorType'),
      }),
      {
        ...genBooleanColumn(
          'customEditable',
          I18n.getColName('customEditable'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      },
      genNumberColumn(
        'maxLength',
        I18n.getColName('maximumCharacters'),
        this.globalService,
        (d) => (!d.value ? '' : this.globalService.getFormattedValue(d.value, 0))
      ),
      genNumberColumn(
        'heightInLines',
        I18n.getColName('heightInLines'),
        this.globalService,
        (d) => (!d.value ? '' : this.globalService.getFormattedValue(d.value, 0))
      ),
      {
        ...genBooleanColumn(
          'showInGeneral',
          I18n.getColName('showInGeneral'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      },
      {
        ...genBooleanColumn(
          'showInCustom',
          I18n.getColName('showInCustom'),
          this.translateService
        ), // needed because cellType inferred before transformation to text
        cellDataType: 'text',
      },
    ];
  }

  private generateSelectedColDefs(): ColDef[] {
    let colDefs = [
      {
        ...genIconButtonColumn({
          callback: (data: FieldDefinition) => this.deleteEntry(data),
          icon: 'delete',
          tooltip: this.translateService.instant('remove'),
        }),
        floatingFilter: false,
        colId: 'icon-button-delete',
      },
      ...this.getCommonColDefs(),
    ];
    colDefs.forEach(colDef => {
        colDef.sortable = false;
        colDef.floatingFilter = false;
    });
    return colDefs;
  }

  private generateAvailableColDefs(): ColDef[] {
    return [
      {
        checkboxSelection: true,
        floatingFilter: false,
        sortable: false,
        lockPosition: 'left',
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        suppressHeaderMenuButton: true,
        resizable: true,
      },
      ...this.getCommonColDefs(),
    ];
  }

  modalAction(modalType: EModalType | undefined) {
    const contentDefinition = this.getContentDefinitionFromForm();
    this.dialogRef.close(contentDefinition);
  }

  private getContentDefinitionFromForm(): ContentDefinition {
    let orderedFieldDefinitions: OrderedFieldDefinition[] = [];
    this.selectedGridApi.forEachNode((node, index) => {
      orderedFieldDefinitions.push({
        fieldDefinition: node.data,
        orderValue: index,
      });
    });
    return {
      id: this.contentDefinition.id,
      name: this.formGroup.get('name').value,
      orderedFieldDefinitions: orderedFieldDefinitions,
      isCopy: false,
    };
  }
}
