<div *ngIf="intermediary" class="page-content-container">

  <div class="distributor-details-container aspark-container-padding">
    <div class="flex-row" [ngClass]="{'max-size': intermediary.type != 'EWA'}">
      <app-card class="distributor-details">
        <div card-header>
          <span>{{ 'details' | translate }}</span>
        </div>
        <div class="h4" card-body>
          <div class="detail-items-wrapper">
            <app-overview-detail-item
              labelRef="intermediaryName"
              [value]="intermediary.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="key"
              [value]="intermediary.externalKey"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="type"
              [value]="intermediary.type"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="hub"
              [value]="intermediary.hub?.name"
            ></app-overview-detail-item>
            <app-overview-detail-item
              labelRef="leadRelationshipManager"
              [value]="usernameLabel(intermediary.leadRelationshipManager)"
            ></app-overview-detail-item>
          </div>
        </div>
      </app-card>
      <div class="flex-end">
        <app-intermediary-overview-employees [intermediary]="intermediary" [fromDistributor]="true">
        </app-intermediary-overview-employees>
      </div>
    </div>
    <div class="full-row">
      <app-intermediary-overview-portfolios
        *ngIf="intermediary.type == 'EWA'" [intermediary]="intermediary" [fromDistributor]="true">
      </app-intermediary-overview-portfolios>
    </div>
  </div>

</div>
