import {CodeTableEntry, GridFilterOptionsParams, User, UserInfo} from 'src/app/api/core';
import {GridDateInfo, GridFilterParamsInfo} from 'src/app/models/grid.model';
import {GlobalService} from 'src/app/services/global.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from "rxjs";
import {CodeTableFilterCellRenderer} from "../../shared/grid/cell-renderers/code-table.renderer";
import {ColDef, IFilterOptionDef, KeyCreatorParams, TextMatcherParams, ValueFormatterParams} from "ag-grid-community";
import {ISimpleFilterModelType} from "ag-grid-community/dist/types/core/filter/provided/simpleFilter";
import {SetFilterValuesFuncParams} from "ag-grid-enterprise";

type FilterPredicateFn<T> = (filterValues: T[], value: T) => boolean;

export function commonParams(
  fieldName: string,
  headerLabel?: string,
  valueFormatterFunc?: (data: any) => string
): ColDef {
  return {
    field: fieldName,
    headerName: headerLabel,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    valueFormatter: valueFormatterFunc,
    resizable: true,
    sort: null,
  };
}

export function commonFilterParams(
  filterParamsInfo?: GridFilterParamsInfo
): object {
  return {
    ...filterParamsInfo,
    maxNumConditions: 1,
  };
}

export function genDateColumn(dateInfo: GridDateInfo): ColDef {
  return {
    ...commonParams(
      dateInfo.field,
      dateInfo?.headerName,
      dateInfo?.dateFormatter
    ),
    filterParams: {
      ...commonFilterParams(dateInfo.filterParamsInfo),
      filterOptions: [
        'contains',
        'equals',
        'notEqual',
        'lessThan',
        'lessThanOrEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'inRange',
      ],
      filterType: 'date'
    },
  };
}

export interface EnumColumnParams {
  field: string;
  values: string[] | ((params: SetFilterValuesFuncParams) => void);
  headerName?: string;
  filterParamsInfo?: GridFilterParamsInfo;
  valueFormatterFunc?: (data: any) => string;
}

// See https://ag-grid.com/angular-data-grid/filter-set-filter-list/#asynchronous-values if you need to load values
// asynchronously
export function genEnumColumn(
  params: EnumColumnParams
): ColDef {
  const filterParamsInfo = params.filterParamsInfo ?? {};
  const values = params.values;
  return {
    ...commonParams(params.field, params.headerName, params.valueFormatterFunc),
    filter: 'agSetColumnFilter',
    filterParams: {
      ...commonFilterParams(filterParamsInfo),
      filterOptions: ['equals', 'notEqual'],
      values,
      keyCreator: (filterParamsInfo as any)?.keyCreator || ((p: KeyCreatorParams) => p.value),
      valueFormatter: filterParamsInfo?.valueFormatter || ((p) => p.value)
    },
  };
}

/*
* Remark: The subscription should be safe here, as unsubscribe happens on complete, and we have either:
* http request (unsub after response by default)
* or a finite list that is delivered as a whole (unsub after list is delivered)
* Could add .pipe(take(1)) before subscribe to be sure.
*/
/**
 * If neither filterValues nor observable is provided, filter values are taken from the grid (only works client-side)
 * @param field field to render
 * @param dtoField if the dto field is different from the database field (only server-side row model)
 * @param headerName column header
 * @param observable observable that provides the filter values, i.e. code table entries. Subscription should be only
 * on a finite list, i.e. http request or a list that is delivered in one go. Otherwise, unsubscribe is not guaranteed
 * and filterValues should be used instead.
 * @param filterValues needs to be objects with fields id and name, i.e. code table entries. If closed is not provided,
 * the filter values are returned as is, otherwise closed values are marked as such and sorted last.
 * @param hasPrimitiveCellValues if true, the cell values are assumed to be primitive strings, otherwise code table entries
 */
export interface CodeTableColumnParams {
  field: string;
  dtoField?: string;
  headerName?: string;
  observable?: Observable<any[]>;
  filterValues?: () => any[];
  hasPrimitiveCellValues?: boolean;
  customPath?: string;
}
export function genCodeTableColumn(
  params: CodeTableColumnParams,
): ColDef {
  const field = params.dtoField ?? params.field;
  const valueFormatterFunc = (params: ValueFormatterParams) => params.value?.name
  // defines comparison key for cell and filter value
  const keyCreatorFunc = params.hasPrimitiveCellValues ?
    (params: KeyCreatorParams) => params.value?.name ?? params.value :
    (params: KeyCreatorParams) => params.value?.id;
  const values = params.observable ?
    (sfvParams: SetFilterValuesFuncParams) =>
      params.observable.subscribe(data => sfvParams.success(data))
    : params.filterValues ?
      (sfvParams: SetFilterValuesFuncParams<CodeTableEntry, CodeTableEntry>) =>
        sfvParams.success(params.filterValues())
      : null;
  return {
    ...commonParams(field, params?.headerName, valueFormatterFunc),
    filter: 'agSetColumnFilter',
    filterParams: {
      ...commonFilterParams(),
      customPath: params.customPath ?? `${params.field}.id`,
      filterOptions: ['equals', 'notEqual'],
      values,
      keyCreator: keyCreatorFunc,
      valueFormatter: valueFormatterFunc, // same function as for cells as we provide code table objects as values
      cellRenderer: CodeTableFilterCellRenderer,
      comparator: (a, b) => { // sorts filter values by name, closed values last
        if (a === null || b === null) {
          return a === null ? -1 : 1;
        } else if (a.closed == b.closed) {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.closed ? 1 : -1;
        }
      }
    },
  };
}

export function genRiskStateColumn(
  params: CodeTableColumnParams,
): ColDef{
  return {
    ...genCodeTableColumn(
      params
    ),
    cellClassRules: {
      'color-success': (params) => params.value.ident === 'within',
      'color-danger': (params) => params.value.ident !== 'within',
    },
  };
}

/**
 * Generates a column-definition for user enumerations where the users are provided as a whole and filtered
 * through their unique usernames
 * @param field field to render
 * @param headerName column header
 * @param values enum values, array of unique usernames
 * @param users users to format the values in the structure `${user.fullname} (${user.username})`
 * @param customPath
 * @param refreshValuesOnOpen
 */
export function genUserEnumColumn(
  field: string,
  headerName: string,
  values: string[] | ((params: SetFilterValuesFuncParams) => void),
  users: () => UserInfo[],
  customPath?: string,
  refreshValuesOnOpen?: boolean,
): ColDef {
  return {
    ...genTextColumn(field, headerName),
    filter: 'agSetColumnFilter',
    filterParams: {
      customPath,
      values,
      valueFormatter: (params) => {
        const user = users().find((d) => d?.username === params.value);
        return user ? usernameValueLabel(user) : undefined;
      },
      refreshValuesOnOpen,
    },
  };
}

export function usernameValueLabel(u: UserInfo) {
  return u && u.username.trim().length > 0
    ? `${u.fullname} (${u.username})`
    : '';
}

export function usernamesValueLabels(list: User[]) {
  if (!list || list.length === 0) {
    return '';
  }
  return list
    .filter((u) => u.username.trim().length > 0)
    .map((t) => usernameValueLabel(t))
    .join(', ');
}

export function genPercentageNumberColumn(
  field: string,
  headerName: string,
  globalService: GlobalService,
  multiply: boolean = true,
  addPlusSign: boolean = false,
  filterParamsInfo: GridFilterParamsInfo = {}
) {
  const textFormatter = filterParamsInfo?.textFormatter || globalService.parseFormattedPercentage;
  const col = genNumberColumn(
    field,
    headerName,
    globalService,
    (r) => globalService.getFormattedPercentage(r?.value, multiply, addPlusSign),
    {
      ...filterParamsInfo,
      textFormatter,
    },
    null,
    multiply ? 100 : 1
  );
  col.filterParams.filterOptions = col.filterParams.filterOptions.filter(f => f !== 'inRange');
  col.filterParams.textMatcher = (params: TextMatcherParams) => {
    const filter = params.filterOption;
    const filterText = params.filterText;
    const value = params.value;
    let filterFunc = (a: number, b: number) => a === b;
    switch (filter) {
      case 'notEqual':
        filterFunc = (a: number, b: number) => a !== b;
        break;
      case 'lessThan':
        filterFunc = (a: number, b: number) => a < b;
        break;
      case 'lessThanOrEqual':
        filterFunc = (a: number, b: number) => a <= b;
        break;
      case 'greaterThan':
        filterFunc = (a: number, b: number) => a > b;
        break;
      case 'greaterThanOrEqual':
        filterFunc = (a: number, b: number) => a >= b;
        break;
    }
    if (multiply) {
      return filterFunc(Number(value) * 100, Number(filterText));
    } else {
      return filterFunc(Number(value), Number(filterText));
    }
  }
  return col;
}

export function genNumberColumn(
  field: string,
  headerName: string,
  globalService: GlobalService,
  valueFormatterFunc: (data: any) => string = null,
  filterParamsInfo: GridFilterParamsInfo = {},
  translateService: TranslateService = null,
  factor: number = 1,
): ColDef {
  let textFormatter = filterParamsInfo?.textFormatter;
  if (valueFormatterFunc === null) {
    valueFormatterFunc = (data) => globalService.getFormattedValue(data?.value);
    textFormatter = (params) => globalService.parseFormattedValue(params);
  }
  return {
    ...commonParams(field, headerName, valueFormatterFunc),
    cellClass: 'cell-align-right',
    filterParams: {
      ...commonFilterParams(filterParamsInfo),
      filterOptions: [
        getFilterNumberOption(
          globalService,
          'equals',
          (a,v) =>  a.length && round(v * factor) == a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'notEqual',
          (a,v) => a.length && round(v * factor) != a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'lessThan',
          (a,v) => a.length && round(v * factor) < a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'lessThanOrEqual',
          (a,v) => a.length && round(v * factor) <= a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'greaterThan',
          (a,v) => a.length && round(v * factor) > a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'greaterThanOrEqual',
          (a,v) => a.length && round(v * factor) >= a[0],
          translateService
        ),
        getFilterNumberOption(
          globalService,
          'inRange',
          (a,v) => a.length && (a[0] <= round(v * factor)) && (a.length == 1 || a[1] >= round(v * factor)),
          translateService,
          2
        ),
      ],
      textFormatter,
      filterType: 'number'
    },
    valueFormatter: (data) => valueFormatterFunc(data),
  };
}

/**
 * Used to round numbers to two decimal values
 * e.g. 10.111111 -> 10.11
 */
function round(num: number): number {
  return Math.round( num * 100 + Number.EPSILON ) / 100;
}

function getFilterNumberOption(
  globalService: GlobalService,
  name: string,
  cmp: FilterPredicateFn<number>,
  translateService?: TranslateService,
  numberOfInputs:  0 | 1 | 2 = 1,
): IFilterOptionDef | ISimpleFilterModelType {
  return {
    displayKey: name,
    displayName: translateService ? translateService.instant(name) : name,
    numberOfInputs,
    predicate: (filterValues: any[], cellValue: string) =>
      filterNumberColumn(globalService, filterValues, cellValue, cmp)
  };
}

function filterNumberColumn(
  globalService: GlobalService,
  filterValues: any[],
  cellValue: string,
  cmp: FilterPredicateFn<number>) {
  const value = globalService.parseFormattedValue(cellValue);
  const values = filterValues.map(v => globalService.parseFormattedValue(v.toString()));
  return cmp(values, value);
}

export function genTextColumn(
  field: string,
  headerName?: string,
  valueFormatterFunc: (data: any) => string = null,
  filterParamsInfo: GridFilterParamsInfo = {},
): ColDef {
  return {
    ...commonParams(field, headerName, valueFormatterFunc),
    filterParams: {
      ...commonFilterParams(filterParamsInfo),
      // TODO this will be removed again in a later stage, currently added to disable certaing filter shortcuts
      //textFormatter: (v: string) => v.replace(/,/g, '').toLowerCase(),
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
      ],
    },
  };
}

export function genTranslatedTextColumn(
  field: string,
  headerName: string = null,
  dtoField: string = null
): ColDef {
  return genTextColumn(dtoField || field + '.name', headerName, null, {
    customPath: field + '.translations.value',
  });
}

/**
 * @param apiMethod Must be provided as (data: GridFilterOptionsParams) => this.<someService>.<apiMethod>(data)
 * @param autoCompleteField Entity field (Not client.fullName but fullName)
 * @param autoCompleteContextId Currently not used - for context (e.g. campaignId)
 */
export interface AutoCompleteApiParams {
  apiMethod: (data: GridFilterOptionsParams) => Observable<string[]>,
  autoCompleteField: string,
  autoCompleteContextId?: number,
}

/**
 * @param autoCompleteParams string[] expected to be sorted, without *** and duplicate entries either in colDef or genColumnMethod
 * Use () => string[] if the table entries change (e.g. user collection picker) or problem with data loading
 */
export interface AutoCompleteColumnParams {
  field: string,
  headerName: string,
  autoCompleteParams: AutoCompleteApiParams | string[] | (() => string[]),
  isMultiSelect?: boolean, // currently only works with server-side grids https://github.com/confinale/aspark/issues/9187
  valueFormatterFunc?: (data: any) => string,
  filterParamsInfo?: GridFilterParamsInfo,
}

export function genTextColumnWithAutoCompleteFilter(
  params: AutoCompleteColumnParams
): ColDef {
  return {
    ...genTextColumn(
      params.field,
      params.headerName,
      params.valueFormatterFunc,
      {
        ...params.filterParamsInfo,
        autoCompleteParams: Array.isArray(params.autoCompleteParams) ?
          [...new Set(params.autoCompleteParams)].filter(s => s !== '***').sort() : params.autoCompleteParams,
        isMultiSelect: params.isMultiSelect ?? false,
      },
    ),
  };
}

export function genBooleanColumn(
  field: string,
  headerName: string,
  translateService: TranslateService,
  customPath?: string,
): ColDef {
  return {
    ...genTextColumn(
      field,
      headerName,
      (d) => translateService.instant(d.value ? 'yes' : 'no')
    ),
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      valueFormatter: (params) => translateService.instant(params.value ? 'yes' : 'no'),
      customPath,
    },
  };
}
