<app-notifier></app-notifier>
<div class="modal-title">
  <div class="modal-title-text h2">
    {{ title | translate }}
  </div>
  <div class="modal-title-actions">
    <app-config-icon
      *ngIf="!disableClose"
      iconIdentifier="close"
      (click)="close()"
      class="close-btn"
      [matTooltip]="'close' | translate"
    ></app-config-icon>
  </div>
</div>
<div
  class="modal-content"
  [class.confirmation-modal]="
    type === modalTypes.confirmationDialog || type === modalTypes.promptText
  "
>
  <ng-template #modalTarget></ng-template>
  <div
    *ngIf="
      content?.message &&
      (type === modalTypes.confirmationDialog || type === modalTypes.promptText)
    "
  >
    {{ content?.message }}
  </div>
  <mat-form-field *ngIf="type === modalTypes.promptText">
    <input
      matInput
      [(ngModel)]="promptText"
      [placeholder]="promptPlaceholder || ''"
    />
  </mat-form-field>
</div>
<div
  *ngIf="submitBtn || cancelBtn"
  class="modal-workflow-btn-container"
  [class.confirmation-modal-btn-container]="
    type === modalTypes.confirmationDialog || type === modalTypes.promptText
  "
>
  <span
    class="tooltip-fix"
    [matTooltip]="
      toolbarActionData.btnDisabled ? toolbarBtnTooltipDisabled : ''
    "
  >
    <button
      *ngIf="submitBtn"
      mat-flat-button
      color="primary"
      (click)="toolbarAction(submitBtn?.callback)"
      [class.inner-spinner]="toolbarActionData.updateLoading"
      [disabled]="toolbarActionData.btnDisabled"
      [ngClass]="submitBtn?.class"
    >
      {{ submitBtn.label }}
    </button>
  </span>
  <button
    *ngIf="cancelBtn"
    mat-stroked-button
    (click)="close(null, cancelBtn?.callback)"
    [ngClass]="cancelBtn?.class"
  >
    {{ cancelBtn.label }}
  </button>
</div>
