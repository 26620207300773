<div *ngIf="loading" class="loading-container">Loading</div>
<div *ngIf="showWarning" class="warning-message">
  {{ 'warning' | translate }}: {{ 'warningNoChannel' | translate }}
</div>
<div *ngIf="readonly" class="readonly-message">
  {{ 'readonlyChannel' | translate }}
</div>
<ng-container *ngIf="!loading && rowData">
  <app-grid
    tableId="action-channels-grid"
    [columnDefs]="channelColumns"
    [gridOptions]="channelGridOptions"
    [rowData]="rowData"
    [pagination]="false"
  >
  </app-grid>
</ng-container>
