/**
 * Interface to render nested menu/select
 */
export interface INestedMenuItem {
  key: string;
  label?: string;
  children?: INestedMenuItem[];
}

/**
 * Class that implementes {@link INestedMenuItem}
 */
export class NestedMenuItem implements INestedMenuItem {
  constructor(
    public key: string,
    public label?: string,
    public children?: INestedMenuItem[]
  ) {}
}
