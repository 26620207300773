<app-grid
  #distributor_portfolios
  tableId="distributor-portfolios"
  [data]="data"
  [columnDefs]="colDefs"
  [gridOptions]="gridOptions"
></app-grid>
<app-distributor-summary
  *ngIf="summary"
  [summary]="summary"
  [showMonitoringValues]="true"
  [goBackRoute]="EViewRoutes.home"
  [goBackLinkMsgKey]="'backToHome'"
>
</app-distributor-summary>
