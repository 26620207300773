<div class="sub-header-container" [class.small]="isSmall">
  <div class="sub-header-content">
    <div class="breadcrumbs" *ngIf="!isSmall && breadcrumbs.length > 1">
      <ng-container *ngFor="let crumb of breadcrumbs; let isLast = last">
        <div
          class="breadcrumb"
          [class.active]="isLast"
          (click)="onBreadcrumbClick(crumb, isLast)"
        >
          {{ viewTitles[crumb] | translate }}
        </div>
        <div *ngIf="!isLast" class="separator"></div>
      </ng-container>
    </div>
    <div class="h4">
      <span class="header">{{ header | translate }}</span>
      <small *ngIf="subHeader">{{subHeader}}</small>
    </div>
  </div>
  <div class="sub-header-actions">
    <ng-content></ng-content>
  </div>
</div>
