import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'src/app/shared/shared.module';
import {ClientCatalogueComponent} from './views/client-catalogue/client-catalogue.component';
import {ClientRoutingModule} from './client-routing.module';
import {ClientOverviewComponent} from './views/client-overview/client-overview.component';
import {
  ClientOverviewDetailsComponent
} from './views/client-overview/client-overview-details/client-overview-details.component';
import {
  ClientOverviewPortfoliosComponent
} from './views/client-overview/client-overview-portfolios/client-overview-portfolios.component';
import {
  ClientOverviewActionsComponent
} from './views/client-overview/client-overview-actions/client-overview-actions.component';
import {
  ClientOverviewCampaignsComponent
} from './views/client-overview/client-overview-campaigns/client-overview-campaigns.component';
import {CollectionEditComponent} from './views/client-catalogue/collection-edit/collection-edit.component';
import {
  ClientPublicationTypesComponent
} from './views/client-overview/client-publication-types/client-publication-types.component';

/**
 * Client Module.
 * Contains all components related to clients
 */
@NgModule({
    declarations: [
        ClientCatalogueComponent,
        ClientOverviewComponent,
        ClientOverviewDetailsComponent,
        ClientOverviewPortfoliosComponent,
        ClientOverviewActionsComponent,
        ClientOverviewCampaignsComponent,
        CollectionEditComponent,
        ClientPublicationTypesComponent,
    ],
    imports: [CommonModule, ClientRoutingModule, SharedModule],
  exports: [
    ClientCatalogueComponent,
    ClientOverviewDetailsComponent,
    ClientPublicationTypesComponent,
    ClientOverviewCampaignsComponent,
    ClientOverviewPortfoliosComponent
  ]
})
export class ClientModule {}
