<div class="dialog-content">
  <app-grid
    #grid
    tableId="org-positions-grid"
    [columnDefs]="columnDefsTree"
    [gridOptions]="gridOptionsTree"
    [rowData]="orgPositions"
    [pagination]="false"
    [noGridFilterPadding]="true"
  ></app-grid>
  <ng-container *ngIf="!justSelectPosition">
    <div class="buttons">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!canAdd"
        (click)="addWithChildren()"
        aria-label="Add Selected with Children"
        [matTooltip]="
          translateService.instant('org_add_selected_with_children')
        "
      >
        <app-config-icon iconIdentifier="double_arrow"></app-config-icon>
      </button>
      <div class="spacer"></div>
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!canAdd"
        (click)="addOnly()"
        aria-label="Add Selected Only"
        [matTooltip]="translateService.instant('org_add_selected_only')"
      >
        <app-config-icon iconIdentifier="chevron_right"></app-config-icon>
      </button>
    </div>
    <div class="user-container">
      <app-grid
        #gridPosition
        tableId="org-positions-users-grid"
        [columnDefs]="positionColumnDefs"
        [gridOptions]="gridOptionsPosition"
        [rowData]="positions"
        [pagination]="false"
        [gridFilterEnabled]="false"
      ></app-grid>
      <app-grid
        #grid2
        tableId="org-positions-users-grid-2"
        [columnDefs]="columnDefsList"
        [gridOptions]="gridOptionsList"
        [rowData]="users"
        [pagination]="false"
        [gridFilterEnabled]="false"
      ></app-grid>
      <mat-form-field>
        <input
          type="text"
          matInput
          [formControl]="userControl"
          [matAutocomplete]="auto"
          [placeholder]="translateService.instant('org_pick_user')"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          (optionSelected)="onUserSelect($event)"
        >
          <mat-option
            *ngFor="let option of filteredUsers$ | async"
            [value]="option"
          >
            {{ option.fullname }} ({{ option.username }})
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-container>
</div>
<div class="dialog-actions">
  <button
    mat-stroked-button
    color="warn"
    *ngIf="!justSelectPosition && (users.length > 0 || positions.length > 0)"
    (click)="removeAll()"
  >
    <app-config-icon iconIdentifier="delete"></app-config-icon>
    {{ 'deleteAll' | translate }}
  </button>
  <button
    mat-stroked-button
    mat-dialog-close
  >
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    mat-dialog-close
    cdkFocusInitial
    (click)="okClicked()"
  >
    {{ 'update' | translate }}
  </button>
</div>
