<app-card
  class="scatter"
  [showSpinner]="(loading$ | async) || (chartDataLoading$ | async)"
>
  <div card-header>
    <span>{{ 'historicalRiskAndReturn' | translate }}</span>
  </div>
  <div card-body>
    <app-chart
      [chartType]="chartTypes.scatter"
      [campaign]="campaign$ | async"
      [story]="story$ | async"
    ></app-chart>
  </div>
</app-card>
