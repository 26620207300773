<div class="auth-container callback">
  <app-card>
    <div card-header>
      <span>{{ 'redirecting' | translate }}</span>
    </div>
    <div card-body>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <div class="authentication-details">
        <div class="toggle" (click)="onToggleDetails()">
          <app-config-icon
            [iconIdentifier]="detailsOpen ? 'expand_less' : 'expand_more'"
          ></app-config-icon>
        </div>
        <ng-container *ngIf="detailsOpen">
          <div class="btn-container">
            <button
              mat-flat-button
              color="primary"
              type="submit"
              (click)="onSubmit()"
            >
              {{ 'restartAuthentication' | translate }}
            </button>
            <button mat-flat-button color="warn" (click)="signOut()">
              {{ 'logoff' | translate }} {{ getUserName() }}
            </button>
          </div>

          <br /><br /><br />
          <table>
            <tr>
              <td><b>access_token_expiration</b></td>
              <td>
                {{ access_token_expiration | date : 'dd.MM.yyyy - hh:mm:ss' }}
              </td>
            </tr>
            <tr>
              <td><b>id_token_expiration:</b></td>
              <td>
                {{ id_token_expiration | date : 'dd.MM.yyyy - hh:mm:ss' }}
              </td>
            </tr>
            <tr>
              <td><b>hasValidAccessToken:</b></td>
              <td>{{ hasValidAccessToken }}</td>
            </tr>
            <tr>
              <td><b>hasValidIdToken:</b></td>
              <td>{{ hasValidIdToken }}</td>
            </tr>
          </table>
          <div *ngIf="claims">
            <br />
            <pre>{{ claims | json }}</pre>
          </div>
        </ng-container>
      </div>
    </div>
  </app-card>
</div>
