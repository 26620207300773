import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/api/core';
import { ActivatedRoute } from '@angular/router';
import { CLIENT_TABS } from 'src/app/util/tab.constants';
import { Tab } from 'src/app/models/tabs.model';

/**
 * Component for client overview.
 */
@Component({
  selector: 'app-client-overview',
  templateUrl: './client-overview.component.html',
})
export class ClientOverviewComponent implements OnInit {
  client: Client;
  tabs: Tab[] = CLIENT_TABS;
  defaultTab = this.tabs[0].text;
  activeTab = this.defaultTab;

  constructor(protected activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe((data) => {
      this.client = data.client;
    });
  }

  ngOnInit(): void {}

  onTabChanged(tab: string): void {
    this.activeTab = tab;
  }
}
