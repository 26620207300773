<app-tabs-picker-toolbar>
  <button mat-stroked-button (click)="onRefresh()">
    <app-config-icon iconIdentifier="refresh"></app-config-icon>
    {{ 'refresh' | translate }}
  </button>
</app-tabs-picker-toolbar>
<app-grid
  #grid
  tableId="outgoing-messages"
  [columnDefs]="colDefs"
  [data]="data"
  [gridOptions]="gridOptions"
></app-grid>
