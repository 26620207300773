import {Component, ViewChild} from "@angular/core";
import {ColDef, GridOptions, ValueFormatterParams} from "ag-grid-community";
import {GridComponent} from "../../../../shared/grid/grid.component";
import {CodeTableEntry, SentMessage, SentMessageService, SentMessageType} from "../../../../api/core";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../../services/global.service";
import {GridDataProvider} from "../../../../shared/grid/data-source";
import {genDateColumn, genEnumColumn, genNumberColumn, genTextColumn} from "../../../../util/grid/grid-renderer.util";
import {genIconButtonColumn} from "../../../../shared/grid/cell-renderers/icon-button.renderer";
import {ModalService} from "../../../../services/modal.service";
import {ModalData} from "../../../../models/modal.model";
import {EModalType, EViewRoutes} from "../../../../util/enum";
import {MatDialogRef} from "@angular/material/dialog";
import {ModalComponent} from "../../../../shared/modal/modal.component";
import {genLinkColumn} from "../../../../shared/grid/cell-renderers/link.renderer";

@Component({
  selector: 'app-sent-messages',
  templateUrl: './sent-messages.component.html'
})
export class SentMessagesComponent {
  @ViewChild('grid') grid: GridComponent;
  hubs: CodeTableEntry[];
  colDefs: ColDef[] = [
    genLinkColumn({
      field: 'ident',
      headerName: this.translateService.instant('ident'),
      link: (data: SentMessage) => '/redirect',
      getQueryParams: (data: SentMessage) => ({
        target: `${data.type == 'ACTION' ? 'client' : 'employee'}-overview`,
        params: `ident="${data.ident}"`
      })
    }),
    genLinkColumn({
      field: 'campaignIdent',
      headerName: this.translateService.instant('campaign'),
      link: (data: SentMessage) => '/redirect',
      getQueryParams: (data: SentMessage) => ({
        target: 'campaign-overview',
        params: `ident="${data.campaignIdent}"`
      })
    }),
    genEnumColumn({
      field: 'type',
      headerName: this.translateService.instant('type'),
      values: Object.values(SentMessageType)
    }),
    genLinkColumn({
      field: 'containerIdent',
      headerName: this.translateService.instant('container'),
      link: (data: SentMessage) => '/redirect',
      getQueryParams: (data: SentMessage) => ({
        target: `${data.type == 'ACTION' ? 'portfolio' : 'intermediary'}-overview`,
        params: `ident="${data.containerIdent}"`
      })
    }),
    genDateColumn({
      field: 'firstSentAt',
      headerName: this.translateService.instant('firstSentDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        [
          this.globalService.dateToFrChLocale(params.data.firstSentAt),
          this.globalService.timeToFrChLocale(params.data.firstSentAt),
        ].join(' ')
    }),
    {
      ...genDateColumn({
        field: 'lastSentAt',
        headerName: this.translateService.instant('lastSentDate'),
        dateFormatter: (params: ValueFormatterParams) =>
          [
            this.globalService.dateToFrChLocale(params.data.lastSentAt),
            this.globalService.timeToFrChLocale(params.data.lastSentAt),
          ].join(' ')
      }),
      sort: 'desc'
    },
    genNumberColumn(
      'sentCount',
      this.translateService.instant('sentCount'),
      this.globalService,
      d => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'releasedCount',
      this.translateService.instant('releasedCount'),
      this.globalService,
      d => this.globalService.getFormattedValue(d.value, 0)
    ),
    genNumberColumn(
      'rejectedCount',
      this.translateService.instant('rejectedCount'),
      this.globalService,
      d => this.globalService.getFormattedValue(d.value, 0)
    ),
    genIconButtonColumn({
      callback: (data: SentMessage) => this.onDelete(data),
      icon: 'cancel_schedule_send',
      tooltip: this.translateService.instant('release'),
      hidden: (data: SentMessage) => data.sentCount <= data.releasedCount
    })
  ];
  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
  };
  data: GridDataProvider = this.sentMessageService.getMessages.bind(this.sentMessageService);

  constructor(
    private readonly translateService: TranslateService,
    private readonly globalService: GlobalService,
    private readonly sentMessageService: SentMessageService,
    private readonly modalService: ModalService,
  ) {

  }

  onRefresh() {
    this.grid.reload();
  }
  onDelete(row: SentMessage) {
    const modalData: ModalData = {
      type: EModalType.confirmationDialog,
      title: EModalType.sentMessagesDelete,
      component: null,
      data: {
        message: this.translateService.instant('sentMessagesDeleteMessage'),
      },
      submitBtn: {
        label: this.translateService.instant('release'),
        callback: (modalRef: MatDialogRef<ModalComponent>) => {
          this.sentMessageService.releaseMessage(row.ident, row.campaignIdent, row.type)
            .subscribe(() => {
              this.onRefresh();
              modalRef.close();
            });
        }
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
    }
    this.modalService.openConfirmationDialog(modalData);
  }
}
