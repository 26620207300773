<div class="auth-container" xmlns="http://www.w3.org/1999/html">
  <app-card>
    <div card-header>
      <span>{{header}}</span>
    </div>
    <div card-body>
      <div class="authentication-details">
        <div class="toggle" (click)="onToggleDetails()">
          <app-config-icon
            [iconIdentifier]="detailsOpen ? 'expand_less' : 'expand_more'"
          ></app-config-icon>
        </div>
        <ng-container *ngIf="detailsOpen">
          <span>{{ 'pleaseContactAdministrator' | translate }}</span>
          <table>
            <tr>
              <td><b>{{'username' | translate}}</b>:</td>
              <td>
                {{ license_username }}
              </td>
            </tr>
            <tr>
              <td><b>{{'message' | translate}}</b>:</td>
              <td>
                {{ license_message }}
              </td>
            </tr>
          </table>
        </ng-container>
      </div>
    </div>
  </app-card>
</div>
