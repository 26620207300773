<div class="detail-item body">
  <app-config-icon [iconIdentifier]="icon" *ngIf="icon" [class.warning]="icon=='warning'"></app-config-icon>
  <span class="label" *ngIf="labelRef">{{ labelRef | translate }}:</span>
  <span class="label" *ngIf="label">{{ label }}:</span>

  <ng-container *ngIf="editToggle">
    <mat-slide-toggle
      color="primary"
      [checked]="checked"
      [disabled]="toogleDisabled"
      (toggleChange)="onToggleChange($event)"
      *ngIf="!onToggleLoading"
    >
    </mat-slide-toggle>
    <mat-spinner color="primary" *ngIf="onToggleLoading" [diameter]="24">
    </mat-spinner>
  </ng-container>
  <div class="value" [class.clickable]="clickable" [class.no-label]="!labelRef" *ngIf="!editToggle" (click)="onEdit()">
    <app-config-icon [iconIdentifier]="valueIcon" [outlined]="true" *ngIf="valueIcon"></app-config-icon>
    <span *ngIf="value; else noValue">
      {{ value }}
    </span>
  </div>
  <app-config-icon
    iconIdentifier="edit_m"
    [tooltip]="tooltip | translate"
    (click)="onEdit()"
    card-action
    *ngIf="editButton"
  ></app-config-icon>
  <ng-template #noValue><span>{{ noValuePlaceholder }}</span></ng-template>
</div>
