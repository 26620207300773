import {Component, Input} from "@angular/core";


type ValueType = 'text' | 'integer' | 'number' | 'percentage'

@Component({
  selector: 'app-block-value',
  templateUrl: './block-value.component.html'
})
export class BlockValueComponent {
  @Input()
  valueType: ValueType;
  @Input()
  blockTitle: string;
  @Input()
  text: string;
  @Input()
  number: number;
}
