import {Component, OnDestroy, OnInit} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {ColDef, GridApi, GridOptions, GridReadyEvent, RowDoubleClickedEvent,} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {EmployeeService,} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {GlobalService} from 'src/app/services/global.service';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {ECodeTables, EViewRoutes} from 'src/app/util/enum';
import {genBooleanColumn, genCodeTableColumn, genTextColumn,} from 'src/app/util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {GridResetEvent} from '../../../shared/grid/grid.component';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {EProtectedActions} from "../../../util/protected-actions";
import {PermissionService} from "../../../services/permission.service";

@Component({
  selector: 'app-employee-catalogue',
  templateUrl: './employee-catalogue.component.html',
})
export class EmployeeCatalogueComponent implements OnInit, OnDestroy {

  /**
   * Data Provider for grid
   */
  data: GridDataProvider = this.employeeService.getEmployees.bind(
    this.employeeService
  );

  columnDefs: ColDef[] = [];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.globalService.navigate(`${EViewRoutes.employeeOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => {
      this.gridReady(event);
      this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    },
  };

  subscriptions: Subscription[] = [];

  constructor(
    protected employeeService: EmployeeService,
    protected globalService: GlobalService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    const isCidFilterAllowed = this.permissionService.hasAnyPermission(EProtectedActions.sortAndFilterCid);
    this.columnDefs = this.createColumnDefs(isCidFilterAllowed);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
  }

  gridReady(event: GridReadyEvent): void {
    this.gridFilterReset({ api: event.api });
  }

  private createColumnDefs(isCidFilterAllowed: boolean): ColDef[] {
    return [
      {
        ...genLinkColumn({
          field: 'name',
          headerName: I18n.getColName('name'),
          link: (data: any) => `${EViewRoutes.employeeOverview}${data.id}`,
        }),
        sortable: isCidFilterAllowed,
        floatingFilter: isCidFilterAllowed,
      },
      {
        ...genTextColumn('firstName', I18n.getColName('firstName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      {
        ...genTextColumn('lastName', I18n.getColName('lastName')),
        floatingFilter: isCidFilterAllowed,
        sortable: isCidFilterAllowed,
      },
      genCodeTableColumn({
        field: 'gender',
        headerName: I18n.getColName('gender'),
        observable: this.codeTableService.getCodeTable(ECodeTables.gender),
      }),
      genTextColumn('externalKey', I18n.getColName('key')),
      {
        ...genTextColumn('intermediary.name', I18n.getColName('intermediary')),
        sortable: isCidFilterAllowed,
        floatingFilter: isCidFilterAllowed,
      },
      {
        ...genTextColumn('intermediary.externalKey', I18n.getColName('intermediaryExternalKey')),
        hide: true,
      },
      genCodeTableColumn({
        field: 'preferredLanguage',
        headerName: I18n.getColName('preferredLanguage'),
        observable: this.codeTableService.getCodeTable(ECodeTables.language),
      }),
      genCodeTableColumn({
        field: 'preferredChannel.type',
        dtoField: 'preferredChannelType',
        headerName: I18n.getColName('channel'),
        observable: this.codeTableService.getCodeTable(ECodeTables.channelType),
      }),
      genBooleanColumn(
        'closed',
        I18n.getColName('CLOSED'),
        this.translateService
      ),
    ];
  }

  gridFilterReset(event: GridResetEvent) {
    this.applyDefaultFilters(event.api);
  }

  private applyDefaultFilters(api: GridApi) {
    const closedFilterModel = api.getColumnFilterModel('closed');
    if (!closedFilterModel) {
      api.setColumnFilterModel('closed', { values: ['false'] })
        .then(() => api.onFilterChanged());
    }
  }
}
