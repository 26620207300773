import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NotificationService } from 'src/app/services/notification.service';
import { ErrorService } from 'src/app/services/error.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    const errorService = this.injector.get(ErrorService);
    if (error instanceof HttpErrorResponse) {
      notificationService.handleError(error);
      errorService.registerServerError(error);
      if (error.status === HttpStatusCode.Forbidden) {
        errorService.handleForbiddenError();
      } else if (error.status === HttpStatusCode.Unauthorized) {
        errorService.handleUnauthorizedError();
      }
    } else {
      errorService.handleClientError(error);
      if (error.stack) {
        // if error has a stack, print it in the console
        console.log(error.stack);
      }
    }
    super.handleError(error);
  }
}
