<form [formGroup]="mappingForm" *ngIf="formBuilt" class="modal-form">
  <div class="row row-double h3 header-padding">
    <span>{{ 'hub' | translate }}</span>
    <span>{{ 'channelType' | translate }}</span>
    <span>{{ 'definition' | translate }}</span>
  </div>
  <mat-divider></mat-divider>
  @for (mapping of this.mappings; track mapping.id) {
    <div class="row row-double">
      <span>{{mapping.hub.name}}</span>
      <span>{{mapping.channelType.name}}</span>
      <mat-form-field>
        <mat-select [formControlName]="mapping.id.toString()">
          <mat-option *ngFor="let cd of contentDefinitions" [value]="cd">
            {{ cd.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    @if (!$last) {
      <mat-divider></mat-divider>
    }
  }
</form>
