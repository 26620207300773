import {BlockDefinition, BlockOutput, BlockOutputArg} from './Base';
import {AdvancedFilterField} from 'src/app/models/filter.model';
import {TranslateService} from '@ngx-translate/core';
import {Block, MenuOption} from 'blockly';
import {javascriptGenerator} from 'blockly/javascript';
import * as Blockly from 'blockly';
import {fnaPositionIds} from '../../../../services/filter.names';

export class BlockFilterInclExclAsset extends BlockDefinition {
  constructor(ident: string, readonly fields: AdvancedFilterField[], readonly outputCheckTypes: string[] = ['Boolean']) {
    super(
      'filter_field-asset',
      ident,
      fields.some((d) => d.active)
    );
  }

  init(translateService: TranslateService, block: Block, color: string) {
    const searchPlaceholder: string = translateService.instant('typeHere..');
    const activeFields = this.fields
      .filter((f) => f.active)
      .map((d) => d.ident);
    const operators: MenuOption[] = ['=', '!='].map(op => [translateService.instant(op), op]);
    block
      .appendDummyInput('field_input')
      .appendField(
        translateService.instant(this.ident) +
        ': ' +
        translateService.instant('ISIN')
      )
      .appendField(new Blockly.FieldDropdown(operators), 'operator');

    block
      .appendDummyInput('field_value')
      .appendField(new Blockly.FieldTextInput(searchPlaceholder), 'value');

    block.setInputsInline(true);
    block.setNextStatement(false, null);
    block.setOutput(true, this.outputCheckTypes);
    block.setColour(color);
    block.setTooltip('');
    block.setHelpUrl('');
  }

  output(block: BlockOutputArg): BlockOutput {
    const operator = block.getFieldValue('operator');
    const target = block.getFieldValue('value');
    return {
      result: (operator === '=') ? {
        type: 'list',
        name: fnaPositionIds,
        field: target,
        operator: 'eq',
        valueList: [target],
      } : {
        type: 'modifier',
        operator: 'not',
        children: [{
          type: 'list',
          name: fnaPositionIds,
          field: target,
          operator: 'eq',
          valueList: [target]
        }]
      },
      order: javascriptGenerator.ORDER_NONE,
    };
  }
}
