import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import videojs from 'video.js';
import * as Record from 'videojs-record/dist/videojs.record';
import { DocumentService, Gui } from '../../api/core';
import { DocumentTransferService } from '../../services/document-transfer.service';
import { NotificationService } from '../../services/notification.service';
import { FileSavingUtil } from '../../util/file-saving.util';
import { ModalComponent } from '../modal/modal.component';

/**
 * Component to content form
 */
@Component({
  selector: 'app-content-form-video',
  templateUrl: './content-form-video.component.html',
})
export class ContentFormVideoComponent implements AfterViewInit, OnDestroy {
  @Input()
  documentId: string = undefined;
  @Input()
  guiConfig: Gui;
  documentUrl: string = undefined;
  private initialized = false;
  isUploading = false;
  isCapturing = false;
  private plugin: any;
  resolutions = {
    360: {
      width: 480,
      height: 360,
    },
    480: {
      width: 640,
      height: 480,
    },
    720: {
      width: 1080,
      height: 720,
    },
    1080: {
      width: 1920,
      height: 1080,
    },
  };

  private captureConfig = {
    controls: true,
    autoplay: true,
    fluid: false,
    loop: false,
    bigPlayButton: true,
    width: 640,
    height: 480,
    controlBar: {
      volumePanel: false,
    },
    plugins: {
      // configure videojs-record plugin
      record: {
        maxLength: 180,
        audio: true,
        debug: false,
        video: {},
        // dimensions of captured video frames
      },
    },
  };

  constructor(
    private documentTransferService: DocumentTransferService,
    private documentService: DocumentService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ModalComponent>
  ) {
    this.plugin = Record;
  }

  ngAfterViewInit(): void {
    if (this.documentId) {
      this.documentService.getFileUrl(this.documentId).subscribe((url) => {
        this.documentUrl = url;
        this.initialized = false;
        this.setupVideoPlayback();
      });
    } else {
      this.disposePlayer('video_playback');
      setTimeout(() => {
        this.setupVideoCapture();
      });
    }
  }

  ngOnDestroy() {
    ['video_playback', 'video_capture'].forEach((d) => this.disposePlayer(d));
  }

  private disposePlayer(elementId: string) {
    const player = this.videoPlayer(elementId);
    if (player) {
      player.dispose();
    }
  }

  private videoPlayer(elementId: string) {
    const div = document.getElementById(elementId);
    if (div) {
      return videojs(div);
    }
    return undefined;
  }

  // followed guide at https://collab-project.github.io/videojs-record/#/usage?id=setup
  setupVideoCapture(disableUpdate = true): void {
    const toolbar = this.dialogRef.componentInstance.toolbarActionData;
    toolbar.btnDisabled = disableUpdate;
    this.isCapturing = true;
    setTimeout(() => {
      // ID with which to access the template's video element
      const div = document.getElementById('video_capture');
      if (!this.initialized && div) {
        // set resolution
        const resolution =
          parseInt(this.guiConfig.general.videoCaptureResolution, 10) || 1080;

        if (this.guiConfig.general.videoCaptureMaxLength) {
          this.captureConfig.plugins.record.maxLength = parseInt(
            this.guiConfig.general.videoCaptureMaxLength,
            10
          );
        }
        this.captureConfig.plugins.record.video = {
          // video media constraints: set resolution of camera
          width: {
            min: this.resolutions[360].width,
            ideal: this.resolutions[resolution].width,
            max: this.resolutions[resolution].width,
          },
          height: {
            min: this.resolutions[360].height,
            ideal: this.resolutions[resolution].height,
            max: this.resolutions[resolution].height,
          },
        };
        // setup the player via the unique element ID
        const player = videojs(div, this.captureConfig) as any;
        // user completed recording and stream is available
        player.on('finishRecord', () => {
          this.isUploading = true;
          // stop device stream, otherwise it will continue to capture
          player.record().stopStream();

          this.documentTransferService
            .uploadDocument('video', player.recordedData)
            .subscribe({
              next: (d) => {
                this.documentId = d;
                toolbar.btnDisabled = false;
                this.isUploading = false;
                this.notificationService.handleSuccess(
                  this.translateService.instant('videoRecordingSuccess')
                );
              },
              error: () => {
                this.isUploading = false;
                this.notificationService.handleError(
                  this.translateService.instant('videoRecordingFailure')
                );
              },
            });
        });
        // error handling
        player.on('error', () => {
          this.notificationService.handleError(
            this.translateService.instant('videoRecordingFailure')
          );
        });
        player.on('deviceError', () => {
          this.notificationService.handleError(
            this.translateService.instant('videoRecordingFailure')
          );
        });
        this.initialized = true;
      }
    });
  }

  setupVideoPlayback(): void {
    // ID with which to access the template's video element
    const div = document.getElementById('video_playback');
    if (!this.initialized && div) {
      this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
      // setup the player via the unique element ID
      const player = videojs(div, {
        controls: true,
        autoplay: true,
        fluid: false,
        loop: false,
        bigPlayButton: true,
        width: 640,
        height: 480,
        controlBar: {
          volumePanel: false,
        },
      });
      player.on('ready', () => {
        if (this.documentUrl) {
          player.src({ type: 'video/mp4', src: this.documentUrl });
        }
      });
      // error handling
      player.on('error', () => {
        this.notificationService.handleError(
          this.translateService.instant('videoRecordingFailure')
        );
      });
      player.on('deviceError', () => {
        this.notificationService.handleError(
          this.translateService.instant('videoRecordingFailure')
        );
      });
      this.initialized = true;
    }
  }

  downloadDocument() {
    if (this.documentUrl) {
      this.httpClient
        .get(this.documentUrl, {
          responseType: 'blob',
        })
        .subscribe((blob) =>
          FileSavingUtil.saveFile(blob, `${this.documentId}.mp4`)
        );
    }
  }

  deleteDocument() {
    this.documentId = undefined;
    this.initialized = false;
    this.disposePlayer('video_playback');
    this.setupVideoCapture(false);
  }
}
