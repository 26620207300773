import { EFilterCategories, EClientFilter } from 'src/app/util/enum';

/**
 * Interface for filter tags.
 * Provides all necessary data to render filter tag
 */
export interface IFilterTag {
  /**
   * Text to be displayed in tag
   */
  text: string;
  /**
   * Filter category (used for search)
   */
  category: EFilterCategories;
  /**
   * Filter field (used for search)
   */
  field: string;
  /**
   * Optional filter subcategory
   * Only expected for nested filter categories
   */
  subcategory?: EClientFilter;
  type?: string;
}

/**
 * Class that implements {@link IFilterTag}
 */
export class FilterTag implements IFilterTag {
  constructor(
    public text: string,
    public category: EFilterCategories,
    public field: string,
    public subcategory?: EClientFilter,
    public type?: string,
  ) {}
}
