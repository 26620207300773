import {Component, Input} from '@angular/core';
import {Employee} from 'src/app/api/core';
import {GlobalService} from "../../../../services/global.service";
import {EViewRoutes} from "../../../../util/enum";

/**
 * Component for employee overview details
 */
@Component({
  selector: 'app-employee-overview-details',
  templateUrl: './employee-overview-details.component.html',
})
export class EmployeeOverviewDetailsComponent {
  @Input() employee: Employee;

  constructor(
    protected globalService: GlobalService,
  ) {}

  showIntermediary() {
    this.globalService.navigate(`${EViewRoutes.intermediaryOverview}${this.employee.intermediary.id}`)
  }
}
