<div class="add-filter-container">
  <app-config-icon
    *ngIf="!readOnly"
    iconIdentifier="add_circle_outline"
    #menuTrigger
    [matMenuTriggerFor]="filterMenu.menu">
  </app-config-icon>
  <app-dynamic-nested-menu
    #filterMenu
    [readOnly]="readOnly"
    [items]="options"
    (itemClick)="onAddFilterSelect($event)">
  </app-dynamic-nested-menu>
</div>
