import {AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  GridFilterConfig,
  GridFilterItemComponent,
  GridFilterModel,
  GridFilterModelItem
} from 'src/app/models/grid.model';
import {KeyValue} from 'src/app/models/key-value.model';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EGridFilterType} from 'src/app/util/enum';
import {Subject, takeUntil} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {identifyActiveFilters} from "../grid-filter/grid-filter.component";
import {DialogHeight} from "../../../../services/modal.service";
import {GuiService} from "../../../../api/core";
import {MatMenu} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {GridFilterItemTextComponent} from "../grid-filter-items/grid-filter-item-text/grid-filter-item-text.component";

@Component({
  selector: 'app-grid-filter-form',
  templateUrl: './grid-filter-form.component.html',
})
export class GridFilterFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  activeFilters: KeyValue[] = [];
  activeFilterModel: GridFilterModel;
  originalFilterConfig: GridFilterConfig[];
  filterConfig: GridFilterConfig[];
  hasMoreFilters = false;
  hasHiddenFilters = false;
  values: Record<string, string> = {};
  styles: Record<string, string> = {};
  @ViewChildren('cmp') filters: QueryList<GridFilterItemComponent>;
  @ViewChildren(GridFilterItemTextComponent) textFilters: QueryList<GridFilterItemTextComponent>;

  observedFilters: String[] = [];
  maxAutoCompleteOptions: number;
  filterHeaderStyle: string = "";

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    private guiService: GuiService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        config: GridFilterConfig[];
        model: GridFilterModel;
      };
    }
  ) {
    this.originalFilterConfig = [...data.data.config];
    this.activeFilterModel = structuredClone(data.data.model);
    this.filterConfig = this.defaultFilters(this.originalFilterConfig, this.activeFilterModel);
    this.hasMoreFilters = this.originalFilterConfig.length > this.filterConfig.length;
    this.guiService.getConfig().subscribe((config) => {
      this.maxAutoCompleteOptions = config.autoComplete;
    });
    this.originalFilterConfig.forEach(config => {
      this.updateValues(config, this.activeFilterModel[config.key]);
    });
  }

  ngOnInit(): void {
    this.setActiveFilters();
    this.resizeDialog();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupFilterChangeSubscription();
      this.filters.changes
        .pipe(
          takeUntil(this.ngUnsubscribe)) // takeUntil should (almost always) be last
        .subscribe(() => {
          this.setupFilterChangeSubscription();
        });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Sets up the subscription for the filter changes
   * This is needed to update the filter tags
   */
  setupFilterChangeSubscription() {
    this.filters.forEach((filter) => {
      const entry = filter.getModel();
      if (entry?.form && !this.observedFilters.includes(entry.config.key)) {
        this.updateValues(entry.config, entry.form.value);
        this.observedFilters.push(entry.config.key);
        entry.form.valueChanges
          .pipe(
            debounceTime(200),
            takeUntil(this.ngUnsubscribe)) // takeUntil should (almost always) be last
          .subscribe((value) => {
            this.updateValues(entry.config, value);
            if (!value.filter && !value.filterTo &&
              !Object.values(value)?.some((v) => v === true)) {
              this.activeFilters = this.activeFilters.filter((filter) =>
                filter.key !== entry.config.key);
            } else if (!this.activeFilters.some((filter) => filter.key === entry.config.key)) {
                this.activeFilters.push(new KeyValue(entry.config.key, entry.config.name));
            } // else: filter is already in activeFilters -> do nothing
          })
      }
    });
  }

  get gridFilterTypes() {
    return EGridFilterType;
  }

  modalAction(): void {
    this.filters.forEach((filter) => {
      const entry = filter.getModel();
      if (entry) {
        this.onFilterChanged(entry.model, entry.config)
      }
    });
    this.dialogRef.close(this.activeFilterModel);
  }

  onFilterChanged(filterItem: GridFilterModelItem, config: GridFilterConfig): void {
    if (filterItem) {
      const itemModel = this.activeFilterModel[config.key];
      if (itemModel) {
        this.activeFilterModel[config.key] = {
          ...this.activeFilterModel[config.key],
          ...filterItem,
        };
      } else {
        this.activeFilterModel[config.key] = filterItem;
      }
    } else {
      delete this.activeFilterModel[config.key];
    }
  }

  private setActiveFilters(): void {
    this.activeFilters = identifyActiveFilters(this.activeFilterModel, this.filterConfig);
  }

  loadMoreFilters() {
    this.hasHiddenFilters = this.filterConfig.length < this.originalFilterConfig.length;
    this.filterConfig = [...this.originalFilterConfig];
    this.resizeDialog();
    this.hasMoreFilters = false;
  }

  showLessFilters() {
    this.hasHiddenFilters = false;
    this.filterConfig = this.defaultFilters(this.originalFilterConfig, this.activeFilterModel);
    this.resizeDialog();
    this.hasMoreFilters = true;
  }

  clearAllFilters(): void {
    this.activeFilters.forEach((filter) => {
      this.clearFilter(filter);
    });
    this.activeFilters = [];
  }

  /**
   * Removes a specific filter from the filter form and resets all its values
   * For sets to false, and all others to empty string
   */
  clearFilter(config: {key: string}): void {
    const filterModel = this.filters.find((f) =>
      f.getModel()?.config.key === config.key)?.getModel()

    if (filterModel?.model?.filterType === 'set') {
      Object.keys(filterModel.form.controls).forEach((key) => {
        filterModel.form.controls[key].setValue(false);
      });
    } else {
      filterModel.form.patchValue({
        filter: '',
        filterTo: '',
      });
      if (filterModel?.config?.isMultiSelect) {
        this.textFilters.find((f) =>
          f.filterConfig.key === config.key)?.clearMultiSelect();
      }
    }
  }

  /**
   * Returns the default filters to be shown in the filter dialog. These are the filters that are shown w/o
   * clicking on "More filters".
   * @param originalFilterConfig
   * @param activeFilterModel
   */
  private defaultFilters(
    originalFilterConfig: GridFilterConfig[],
    activeFilterModel: GridFilterModel
  ): GridFilterConfig[] {
    return originalFilterConfig.filter(c => {
      return (c.hide === undefined || c.hide === false)
        || c.important === true
        || activeFilterModel[c.key] !== undefined
    });
  }

  /**
   * Resizes the dialog initially and after 'More filters' or 'Show less' is clicked
   */
  resizeDialog(): void {
    this.dialogRef.updateSize('90vw', DialogHeight.AUTO);
    this.dialogRef.updatePosition({top: undefined, left: undefined});
    setTimeout(() => {
      Array.from(document.querySelectorAll(".grid-filter-item-title"))
        .forEach((el: HTMLElement) => {
          const key = el.dataset.config;
          const elValue = el.querySelector([
            "button.mat-mdc-menu-trigger",
            ".mdc-button__label",
            ".filter-header-value"
          ]?.join(" ")) as HTMLElement;
          if (elValue) {
            if (elValue.hasAttribute("style")) return;
            const wValue = elValue.clientWidth;
            this.styles[key] = `max-width: calc(${wValue}px - 0.5rem);`
          }
        });
    });
  }

  updateValues(config: GridFilterConfig, value: any) {
    if (!value) {
      this.values[config.key] = undefined;
      return;
    }
    switch (config.type) {
      case 'text':
        this.values[config.key] = config.isMultiSelect ? value.filter.replace(/,/g, ', ') : value.filter;
        break;
      case 'set':
        const setKeys = Object.keys(value);
        const allKeys = setKeys.every(key => !!value[key]);
        const someKeys = setKeys.filter(key => !!value[key]);
        if (allKeys || someKeys.length == 0) {
          this.values[config.key] = undefined;
        } else {
          this.values[config.key] = someKeys.join(", ");
        }
        break;
      case 'numeric':
        const numType = value.type;
        const numValue = value.filter;
        const numTo = value.filterTo;
        if (numValue == undefined || numValue == '') {
          this.values[config.key] = undefined;
          return;
        }
        switch (numType) {
          case 'equals':
            if (numValue)
              this.values[config.key] = ['=', numValue].join(" ");
            break;
          case 'notEqual':
            this.values[config.key] = ['≠', numValue].join(" ");
            break;
          case 'lessThan':
            this.values[config.key] = ['<', numValue].join(" ");
            break;
          case 'lessThanOrEqual':
            this.values[config.key] = ['≤', numValue].join(" ");
            break;
          case 'greaterThan':
            this.values[config.key] = ['>', numValue].join(" ");
            break;
          case 'greaterThanOrEqual':
            this.values[config.key] = ['≥', numValue].join(" ");
            break;
          case 'inRange':
            this.values[config.key] = [numValue, numTo].join(" - ");
            break;
        }
        break;
      case 'date':
        const dateType = value.type;
        const dateValue = value.filter;
        const dateTo = value.filterTo;
        if (dateValue == undefined || dateValue == '') {
          this.values[config.key] = undefined;
          return;
        }
        switch (dateType) {
          case'contains':
            this.values[config.key] = value.filter;
            break;
          case 'eq':
            this.values[config.key] = ['=', dateValue].join(" ");
            break;
          case 'notEqual':
            this.values[config.key] = ['≠', dateValue].join(" ");
            break;
          case 'lessThan':
            this.values[config.key] = ['<', dateValue].join(" ");
            break;
          case 'lessThanOrEqual':
            this.values[config.key] = ['≤', dateValue].join(" ");
            break;
          case 'greaterThan':
            this.values[config.key] = ['>', dateValue].join(" ");
            break;
          case 'greaterThanOrEqual':
            this.values[config.key] = ['≥', dateValue].join(" ");
            break;
          case 'inRange':
            this.values[config.key] = [dateValue, '-', dateTo].join(" ");
            break;
          default:
            this.values[config.key] = value.filter;
            break;
        }
        break;
      default:
        this.values[config.key] = value.filter;
        break;
    }
  }
  hasFilterValue(config: GridFilterConfig) {
    const value = this.values[config.key];
    return value != undefined && value != '';
  }
  onApplyFilter(config: GridFilterConfig, menu: MatMenu, button: MatButton) {
    button._elementRef.nativeElement.click();
  }
}
