import {Component} from '@angular/core';

/**
 * Component to display all products that can be filtered by tags.
 */
@Component({
  selector: 'app-portfolio-catalogue',
  templateUrl: './portfolio-catalogue.component.html',
})
export class PortfolioCatalogueComponent {

}
