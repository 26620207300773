import { Component, Input, OnInit } from '@angular/core';
import {
  EClientFilter,
  EClientFilterTranslation,
  ECodeTables,
  EFilterSubType,
  EFilterType,
} from 'src/app/util/enum';
import {
  FilterMetaData,
  IFilterMetaData,
} from 'src/app/models/filter-meta-data.model';
import { IKeyValue, KeyValue } from 'src/app/models/key-value.model';
import { INestedMenuItem } from 'src/app/models/menu.model';
import { CONTINENTS } from 'src/app/util/helpers';
import {CodeTableEntry} from 'src/app/api/core';
import { forkJoin } from 'rxjs';
import { CodeTableService } from 'src/app/services/code-table.service';
import { FilterClient } from 'src/app/models/filter.model';
import {UiFilterConfigService} from '../../../services/ui-filter-config.service';
import {filteredOptions} from "../filter-details.component";

// TODO Fix errors and refactor

/**
 * Client Filter Details Component.
 * Component for filter details for category client
 */
@Component({
  selector: 'app-filter-details-client',
  templateUrl: './filter-details-client.component.html',
})
export class FilterDetailsClientComponent implements OnInit {
  /**
   * Filter values for all client filters
   */
  @Input() clientForm: FilterClient;
  /**
   * Gender key value pairs
   */
  genders: CodeTableEntry[] = [];
  continents: CodeTableEntry[];
  countries: CodeTableEntry[];
  /**
   * Domicile key value pairs
   */
  domiciles: INestedMenuItem[] = [];

  @Input()
  readOnly = false;

  constructor(
    protected codeTableService: CodeTableService,
    protected filterConfigService: UiFilterConfigService,
  ) {
    this.initCodeTables();
  }

  ngOnInit(): void {}

  private initCodeTables(): void {
    this.codeTableService
      .getCodeTable(ECodeTables.gender)
      .subscribe((data) => (this.genders = data));
    forkJoin([
      this.codeTableService.getCodeTable(ECodeTables.continent),
      this.codeTableService.getCodeTable(ECodeTables.country),
    ]).subscribe((results) => {
      this.continents = results[0];
      this.countries = results[1];

      this.domiciles = CONTINENTS;
      this.domiciles.forEach((continent) => {
        continent.label = this.continents.find(
          (c) => c.ident === continent.key
        ).name;
        continent.children.forEach(
          (country) =>
            (country.label = this.countries.find(
              (c) => c.ident.toLowerCase() === country.key
            ).name)
        );
      });
    });
  }

  /**
   * Provides client filter enum to html
   */
  get fields() {
    return EClientFilter;
  }

  /**
   * Generates meta data for given client filter
   * @param filter Client filter
   * @param filterKey Optional filter key
   */
  getFilterMetaData(
    filter: EClientFilter,
    filterKey?: string
  ): IFilterMetaData {
    switch (filter) {
      case EClientFilter.ageRange:
        return new FilterMetaData(
          EFilterType.rangeSlider,
          EClientFilterTranslation.ageRange,
          EFilterSubType.rangeFromTo
        );
      case EClientFilter.domiciles:
        return new FilterMetaData(
          EFilterType.countryMultiSelect,
          EClientFilterTranslation.domiciles,
          undefined,
          undefined,
          this.getNestedOptions(filter)
        );
      case EClientFilter.gender:
        return new FilterMetaData(
          EFilterType.multiSelectDropdown,
          EClientFilterTranslation.gender,
          undefined,
          this.getOptions(filter)
        );
      default:
        return null;
    }
  }

  /**
   * Generate select options for client filter
   * @param filter Client filter
   */
  private getOptions(filter: EClientFilter): IKeyValue[] {
    switch (filter) {
      case EClientFilter.gender:
        return filteredOptions(this.genders, this.clientForm.gender)
      default:
        return [];
    }
  }

  /**
   * Generate nested select options for client filter
   * @param filter Client filter
   */
  private getNestedOptions(filter: EClientFilter): INestedMenuItem[] {
    switch (filter) {
      case EClientFilter.domiciles:
        return this.domiciles;
      default:
        return [];
    }
  }

  /**
   * Provide filter categories to html
   */
  get clientFilters() {
    return EClientFilter;
  }

  /**
   * Provides boolean for html for whether or not
   * personal details should be visible.
   */
  get personalDetailsVisible() {
    const config = this.filterConfigService.getConfigFlags();
    return (
      config.client.filters.ageRange ||
      config.client.filters.domiciles ||
      config.client.filters.gender
    );
  }
}
