import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageComponent} from 'src/app/shared/page/page.component';
import {AuthGuard} from 'src/app/util/auth.guard';
import {ERoutePaths, EViewRoutes, EViewTitles,} from 'src/app/util/enum';
import {PermissionGuard} from 'src/app/util/permission.guard';
import {EmployeeCatalogueComponent} from "./views/employee-catalogue/employee-catalogue.component";
import {EmployeeOverviewComponent} from "./views/employee-overview/employee-overview.component";
import {EmployeeResolver} from "../util/resolvers";

/**
 * Employee route definition.
 * Contains all routing definitions related to employees
 */
const employeeRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: EmployeeCatalogueComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.employees,
          viewRoute: EViewRoutes.employees,
          allowedRoles: [],
        },
      },
      {
        path: ERoutePaths.overview,
        component: EmployeeOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.employeeOverview,
          viewRoute: EViewRoutes.employeeOverview,
          allowedRoles: [],
        },
        resolve: {
          employee: EmployeeResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(employeeRoutes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
