<div
  *ngFor="let action of visibleActions"
  class="{{ action.className }}"
  [class.disabled]="action.disabled && action.disabled()"
  (click)="action.click()"
  [matTooltip]="action.tooltip ? action.tooltip() : ('' | translate)"
>
  <app-config-icon
    *ngIf="action.icon"
    iconIdentifier="{{ action.icon }}"
  ></app-config-icon>
  <span>{{ action.text | translate }}</span>
</div>
<ng-container
  *ngIf="forceVisible || (useDropdownSelect && isVisible && visibleHiddenActions.length)"
>
  <mat-form-field *ngIf="label" class="mat-form-field-small" appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <mat-select [placeholder]="placeholder" [(value)]="selectedAction">
      <mat-option class="dropdown-selection-bar">{{ 'none' | translate }}</mat-option>
      <mat-optgroup class="dropdown-selection-bar" label="{{ 'myCollections' | translate }}" *ngIf="privateCollections.length">
        <mat-option
          *ngFor="let action of privateCollections; trackBy: trackByFn"
          class="dropdown-selection-bar"
          [value]="action"
          [disabled]="action.disabled && action.disabled()"
        >
          {{ action.text | translate }}
        </mat-option>
      </mat-optgroup>
      <mat-optgroup class="dropdown-selection-bar" label="{{ 'publicCollections' | translate }}" *ngIf="publicCollections.length">
        <mat-option
          *ngFor="let action of publicCollections; trackBy: trackByFn"
          class="dropdown-selection-bar"
          [value]="action"
          [disabled]="action.disabled && action.disabled()"
        >
          {{ action.text | translate }}
        </mat-option>
      </mat-optgroup>
      <mat-option *ngIf="createCollection"
                  class="{{ createCollection.className }} dropdown-selection-bar"
                  [disabled]="createCollection.disabled && createCollection.disabled()"
                  (click)="createCollection.click()"
      >
        <span>+ {{ createCollection.text | translate }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-select *ngIf="!label" [placeholder]="placeholder" [(value)]="selectedAction">
    <mat-option class="dropdown-selection-bar">{{ 'none' | translate }}</mat-option>
    <mat-optgroup class="dropdown-selection-bar" label="{{ 'myCollections' | translate }}" *ngIf="privateCollections.length">
      <mat-option
        *ngFor="let action of privateCollections; trackBy: trackByFn"
        class="dropdown-selection-bar"
        [value]="action"
        [disabled]="action.disabled && action.disabled()"
      >
        {{ action.text | translate }}
      </mat-option>
    </mat-optgroup>
    <mat-optgroup class="dropdown-selection-bar" label="{{ 'publicCollections' | translate }}" *ngIf="publicCollections.length">
      <mat-option
        *ngFor="let action of publicCollections; trackBy: trackByFn"
        class="dropdown-selection-bar"
        [value]="action"
        [disabled]="action.disabled && action.disabled()"
      >
        {{ action.text | translate }}
      </mat-option>
    </mat-optgroup>
    <mat-option *ngIf="createCollection"
                class="{{ createCollection.className }} dropdown-selection-bar"
                [disabled]="createCollection.disabled && createCollection.disabled()"
                (click)="createCollection.click()"
    >
      <span>+ {{ createCollection.text | translate }}</span>
    </mat-option>
  </mat-select>
</ng-container>

<ng-container
  *ngIf="!useDropdownSelect && isVisible && visibleHiddenActions.length"
>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions Menu">
    <app-config-icon iconIdentifier="more_vert"></app-config-icon>
  </button>
  <mat-menu #menu="matMenu" class="dropdown-selection-wrapper">
    <button
      mat-menu-item
      *ngFor="let action of visibleHiddenActions"
      class="{{ action.className }} dropdown-selection-bar"
      [disabled]="action.disabled && action.disabled()"
      (click)="action.click()"
    >
      <app-config-icon
        *ngIf="action.icon"
        iconIdentifier="{{ action.icon }}"
      ></app-config-icon>
      <span>{{ action.text | translate }}</span>
    </button>
  </mat-menu>
</ng-container>
